/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import CaseInfoData from '../core/models/CaseInfoData';

export const customHistory = createBrowserHistory();

/**
 * This method will be used to change the current case with routing
 * @param path path of the page
 * @param currentCase : Current case
 */
// eslint-disable-next-line no-shadow
export function redirectWithCaseData(path: string, currentCase?: CaseInfoData, isDashboard?: boolean): void {
  customHistory.push({
    pathname: `/${path}`,
    state: {
      headerData: {
        currentCase,
        isDashboard
      },
    },
  });

  window.location.reload(false);
}


/**
 * This method will be used to change the current case with routing
 * @param path path of the page
 * @param currentCase : Current case
 */
// eslint-disable-next-line no-shadow
// export function reDirectToAnnotation(img: any, currentCase: CaseInfoData, isDashboard: boolean, report: boolean, viewer: boolean): void {
//   customHistory.push({
//     pathname: `/annotation`,
//     state: {
//       imageDetails: {
//         image: img,
//       },
//       headerData: {
//         currentCase,
//         isDashboard,
//         isReport: report,
//         isViewer: viewer
//       },
//     },
//   });

//   window.location.reload(false);
// }