/* eslint-disable no-console */
/* eslint-disable no-useless-catch */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* istanbul ignore file */
const Schema = require('../../protofile/convivo_pb');

const decodeUserDetails = async (data: ArrayBuffer): Promise<any> => {
    try {
        const sitesListData: any = [];
        const byteArray: Uint8Array = new Uint8Array(data);
        const deserializeUsersData = Schema.UserList.deserializeBinary(byteArray);
        const users = deserializeUsersData.getUsersList();
        if (typeof users !== 'undefined' && users.length > 0) {
            const message = users[0];
            const siteList = message.getSitesList();
            const rolesmessage = message.getRolesList();
            var rolesList: string[] = [];
            var medicalRole: string = '';
            if (rolesmessage) {
                rolesmessage.forEach(function (roledata: any) {
                    let roleobject = roledata.toObject();
                    rolesList.push(roleobject.name);
                    if (roleobject.name.toString().toLowerCase() == "pathologist" || roleobject.name.toString().toLowerCase() == "surgeon")
                        medicalRole = roleobject.name;
                });
            }

            if (siteList && siteList.length) {
                for (let index = 0; index < siteList.length; index += 1) {
                    const siteListResponse = siteList[index];
                    const site: any = siteListResponse.toObject();
                    sitesListData.push(
                        site
                    );
                }
            }
            console.log(sitesListData, '----user site')
            // let preferencesData = message.getPreferences().toObject();
            // let internalnotification = false;
            // let emailnotification = false;
            // let bothcommunication = false;
            let emailcommunication = false;
            let smscommunincation = false;
            // let nonecommunication=false;
            let preferencesData = message.getPreferences().toObject();
            // { language: "", emailcommunication: "true", smscommunincation:"true", systemnotification: 0 }
            if (preferencesData.emailcommunication == 'true')
                emailcommunication = true;
            if (preferencesData.smscommunincation == 'true')
                smscommunincation = true;
            // if (preferencesData.systemnotification == 0) {
            //     internalnotification = true;
            //     emailnotification = false;
            // }
            // else if (preferencesData.systemnotification == 1) {
            //     internalnotification = false;
            //     emailnotification = true;
            // }
            // else {
            //     internalnotification = false;
            //     emailnotification = false;
            // }
            // if (preferencesData.emailcommunication == 'true' && (preferencesData.smscommunincation == 'false' || preferencesData.smscommunincation == '')) {
            //     emailcommunication = true;
            //     bothcommunication = false;
            //     smscommunincation = false;
            //     nonecommunication=false;
            // }
            // else if ((preferencesData.emailcommunication == 'false' || preferencesData.emailcommunication == '') && preferencesData.smscommunincation == 'true') {
            //     emailcommunication = false;
            //     bothcommunication = false;
            //     smscommunincation = true;
            //     nonecommunication=false;
            // }
            // else if (preferencesData.emailcommunication == 'true' && preferencesData.smscommunincation == 'true') {
            //     emailcommunication = false;
            //     bothcommunication = true;
            //     smscommunincation = false;
            //     nonecommunication=false;
            // }
            // else if (preferencesData.emailcommunication == 'false' && preferencesData.smscommunincation == 'false') {
            //     emailcommunication = false;
            //     bothcommunication = false;
            //     smscommunincation = false;
            //     nonecommunication=true;
            // }
            // else {
            //     emailcommunication = false;
            //     bothcommunication = false;
            //     smscommunincation = false;
            //     nonecommunication=false;
            // }
            return {
                id: message.getId() ? message.getId() : '--',
                firstName: message.getFirstname() ? message.getFirstname() : '--',
                lastName: message.getLastname() ? message.getLastname() : '--',
                emailAddress: message.getEmailaddress() ? message.getEmailaddress() : '--',
                phoneNumber: message.getPhonenumber() ? message.getPhonenumber() : '--',
                status: message.getStatus() ? message.getStatus() : '--',
                preferences: preferencesData,
                lastLoginDateTime: message.getLastlogindatetime() ? message.getLastlogindatetime() : '--',
                createdDate: message.getCreateddate() ? message.getCreateddate() : '--',
                accountName: message.getAccount().getName() ? message.getAccount().getName() : '--',
                accountID: message.getAccount().getId() ? message.getAccount().getId() : '--',
                CRMAccountNumber: message.getAccount().getSapid() ? message.getAccount().getSapid() : '--',
                siteList: sitesListData && sitesListData.length ? sitesListData : [],
                rolesList: rolesList,
                medicalRole: medicalRole,
                UserStatus: message.UserStatus ? message.UserStatus : '--',
                // internalnotification: internalnotification,
                // emailnotification: emailnotification,
                // both: bothcommunication,
                emailcommunication: emailcommunication,
                smscommunincation: smscommunincation,
                // nonecommunication:nonecommunication,
            };
        } else {
            return null;
        }
    } catch (error) {
        throw error;
    }
};

export { decodeUserDetails };

