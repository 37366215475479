/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { env } from '../../../environments/environment';
/* istanbul ignore next */
const fetchCasesData = async (pageNumber: number) => {
  const url = env.activeCasesEndpoint ;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/protobuf' },
  });
  return response;
};

const fetchCompleteCasesData = async (accountId: string, caseId: string) => {
  const url = env.getCompleteCaseEndpoint + accountId + "/cases/" + caseId;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/protobuf' },
  });
  return response;
};


const fetchHistoryCasesData = async (pageNumber: number, continuationToken?: any) => {
  let url: any;
  if (continuationToken) {
    url = env.caseHistoryEndpoint + continuationToken
  }
  else {
    url = env.caseHistoryEndpoint + ""
  }
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/protobuf' },
  });
  return response;
};

export { fetchCasesData, fetchHistoryCasesData, fetchCompleteCasesData };

