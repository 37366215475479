/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import * as actionCreators from '../actionCreators/admin/getSiteAssociatesListActionCreator';
import * as actionTypes from '../actionTypes/admin/getSiteAssocatieListActionTypes';
import { decodeSiteAssociatessBuffer } from '../protobuf/deserialization/SiteAssociatesListDeserialize';
import { fetchSiteAssocaitesList } from '../services/GET/getSiteAssociatesListService';

/* istanbul ignore next */
function* siteAssociatesListAsync(action: any) {
  try {
    const response: Response = yield fetchSiteAssocaitesList(action.accountID, action.siteId);
    const apiStatus: number = response.status;
    switch (apiStatus) {
      case 200:
        const data: ArrayBuffer = yield response.arrayBuffer();
        const decodedData: any[] = yield decodeSiteAssociatessBuffer(data);
        if (decodedData.length) { yield put(actionCreators.setSiteAssociateList(decodedData[0])); }
        else yield put(actionCreators.setSiteAssociateList([]));
        break;
      case 401:
      case 500:
      case 403:
        console.log('get  site Assocatite  Api Failed ');
        yield put(actionCreators.getSiteAssociateListFail('GetSiteAssocatiteFailMessage'));
        break;
      default:
        console.log('get  site Assocatite  Api Failed ');
        yield put(actionCreators.getSiteAssociateListFail('GetSiteAssocatiteFailMessage'));
        break;
    }
  } catch (error) {
    console.log('get  site Assocatite  Api Failed ', error);
    yield put(actionCreators.getSiteAssociateListFail('GetSiteAssocatiteFailMessage'));
  }
}

export function* watchPageEntries() {
  yield takeEvery(actionTypes.GET_SITE_ASSOCAITES_LIST, siteAssociatesListAsync);
}

export default function* GetSiteAssociatesListSaga() {
  yield all([fork(watchPageEntries)]);
}
