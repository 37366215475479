/* eslint-disable no-console */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import * as actionCreators from '../actionCreators/galleryActionCreator';
import * as actionTypes from '../actionTypes/galleryActionTypes';
// import GalleryModel from '../models/galleryModelData';
import { decodeBlob } from '../protobuf/deserialization/galleryDeserialize';
import { gallerySpotDeserialize } from '../protobuf/deserialization/gallerySpotDeserialize';
import { fetchGalleryData } from '../services/GET/galleryService';
import { fetchGallerySpotData } from '../services/GET/gallerySpotService';
/* istanbul ignore next */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getGalleryResponse(action: any) {
  try {
    const response: Response = yield fetchGalleryData(action.caseId, action.devicesiteId, action.IsEdited, action.ImageType, action.IsShortlistedForReport, action.QualityProbabilityFrom, action.QualityProbabilityTo, action.isExternal, action.accountID, action.spot);
    const apiStatus: number = response.status;
    switch (apiStatus) {
      case 200:
        const data: ArrayBuffer = yield response.arrayBuffer();
        const galleryInfo: any = yield decodeBlob(data);
        if (action.isExternal) {
          yield put(actionCreators.setExternalListData(galleryInfo.spotimagesList.imagesList));
        }
        else {
          yield put(actionCreators.setGalleryListData(galleryInfo.spotimagesList, action));

        }
        break;
      case 400:
      case 401:
      case 500:
      case 403:
        yield put(actionCreators.getGalleryListFail());
        break;
      default:
        break;
    }
  } catch (error) {
    console.log("gallerySaga Page", error);
    yield put(actionCreators.getGalleryListFail());
  }
}
function* getSpotResponse(action: any) {
  try {
    const response: Response = yield fetchGallerySpotData(action.caseId, action.devicesiteId, action.IsEdited, action.ImageType, action.IsShortlistedForReport, action.QualityProbabilityFrom, action.QualityProbabilityTo, action.isExternal, action.accountID);
    const apiStatus: number = response.status;
    switch (apiStatus) {
      case 200:
        const data: ArrayBuffer = yield response.arrayBuffer();
        const gallerySpot: any = yield gallerySpotDeserialize(data);
        console.log("gallerySpot", gallerySpot);
        yield put(actionCreators.SetGallerySpotList(gallerySpot.spotsList));

        break;
      case 400:
      case 401:
      case 500:
      case 403:
        yield put(actionCreators.getGallerySpotListFail());
        break;
      default:
        break;
    }
  } catch (error) {
    console.log("getGallery SpotListFail ", error);
    yield put(actionCreators.getGallerySpotListFail());
  }
}


export function* watchGalleryEnteries() {
  yield takeEvery(actionTypes.GET_GALLERY_LIST, getGalleryResponse);
}
export function* watchGallerySpotList() {
  yield takeEvery(actionTypes.GET_GALLERY_SPOT_LIST, getSpotResponse);
}


export default function* GetGallerySaga() {
  yield all([fork(watchGalleryEnteries), fork(watchGallerySpotList),]);
}
