/* eslint-disable react/prefer-stateless-function */
/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ViewerControl } from '../../constants/viewControl';
import { LineColor } from './annotation/lineColorSelect';
import { PenColor } from './annotation/penColorSelect';
import { TextColor } from './annotation/textColorSelect';


const envData = require('../../constants/constants.json');

interface Props {
  selectedItemMain: any;
  annotationSetting: any;
  handleClose: ()=> void;
}

interface State {
  activeThickPen: any;
  activeThickLine: any
  activeThickText: any;
  activeColor: any;
  activeColorLine: any;
  activeColorText: any;
}

export class AnnotateSettings extends React.Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props);
    this.state = {
      activeThickPen: this.props.annotationSetting.activeThickPen,
      activeThickLine: this.props.annotationSetting.activeThickLine,
      activeThickText: this.props.annotationSetting.activeThickText,
      activeColor: "",
      activeColorLine: "",
      activeColorText: ""
    }
  }


  selectedColor = (key: any) => {
    this.props.selectedItemMain.selectedColor(key);
    this.setState({ activeColor: key });
  };

  selectedColorLine = (key: any) => {
    this.props.selectedItemMain.selectedColorLine(key);
    this.setState({ activeColorLine: key });
  };

  selectedColorText = (key: any) => {
    this.props.selectedItemMain.selectedColorText(key);
    this.setState({ activeColorText: key });
  };


  selectedThicknessPen = (key: any) => {
    this.props.selectedItemMain.selectedThicknessPen(key);
    this.setState({ activeThickPen: key });
  };

  selectedThicknessLine = (key: any) => {
    this.props.selectedItemMain.selectedThicknessLine(key);
    this.setState({ activeThickLine: key });
  };

  selectedThicknessText = (key: any) => {
    this.props.selectedItemMain.selectedThicknessText(key);
    this.setState({ activeThickText: key });
  };

  renderThicknessPen = () => {
    return envData.thickness.map((thick: any) => {
      return (
        <div
          key={thick.key}
          id={thick.key}
          onClick={() => this.selectedThicknessPen(thick.value)}
          className={'thick ' + thick.key + (thick.value === this.state.activeThickPen ? " active" : "")}>
          { thick.value === this.state.activeThickPen ? (
            <ViewerControl icon={'thick-icon-' + thick.key + '-active'} />
          ) : (
            <ViewerControl icon={'thick-icon-' + thick.key} />
          )}

        </div>
      );
    });
  };

  renderThicknessLine = () => {
    return envData.thickness.map((thick: any) => {
      return (
        <div
          key={thick.key}
          id={thick.key}
          onClick={() => this.selectedThicknessLine(thick.value)}
          className={'thick ' + thick.key + (thick.value === this.state.activeThickLine ? " active" : "")}>
          { thick.value === this.state.activeThickLine ? (
            <ViewerControl icon={'thick-icon-' + thick.key + '-active'} />
          ) : (
            <ViewerControl icon={'thick-icon-' + thick.key} />
          )}

        </div>
      );
    });
  };

  renderFontSize = () => {
    return envData.fontSize.map((font: any) => {
      return (
        <div
          key={font.key}
          id={font.key}
          onClick={() => this.selectedThicknessText(font.value)}
          className={'font ' + font.key + (font.value === this.state.activeThickText ? " active" : "")}>
          { font.value === this.state.activeThickText ? (
            <ViewerControl icon={'font-icon-' + font.key + '-active'} />
          ) : (
            <ViewerControl icon={'font-icon-' + font.key} />
          )}

        </div>
      );
    });
  };

  render() {
    return (
      <Popover id="popover-shape-settings">
        <Popover.Content>
          <div className="settings-head">
            <p><FormattedMessage id="annotationSettings" /></p>
            <span className="settings-close" onClick={this.props.handleClose}><ViewerControl icon="annotate-settings-close" /></span>
          </div>
          <div className="settings-list-main">
            <div className="setting-list">
              <p><FormattedMessage id="pen" /></p>
              <div className="setting-block">
                <div className="left"><FormattedMessage id="color" /></div>
                <div className="right">
                  <OverlayTrigger trigger="click" rootClose placement="top" overlay={<PenColor annotationSetting={this.props.annotationSetting} selectedColor={this.selectedColor} />}>
                    <div className="color-selected-pen" style={{ backgroundColor: this.props.annotationSetting.activeColor }} >
                      <span><ViewerControl icon="annotate-dropdown" /></span>
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
              <div className="setting-block">
                <div className="left"><FormattedMessage id="thickness" /></div>
                <div className="right">
                  <div className="thickness">
                    {this.renderThicknessPen()}
                  </div>
                </div>
              </div>
            </div>
            <div className="setting-list">
              <p><FormattedMessage id="line" />, <FormattedMessage id="circle" /> <FormattedMessage id="and" /> <FormattedMessage id="square" /></p>
              <div className="setting-block">
                <div className="left"><FormattedMessage id="color" /></div>
                <div className="right">
                  <OverlayTrigger trigger="click" rootClose placement="top" overlay={<LineColor annotationSetting={this.props.annotationSetting} selectedColorLine={this.selectedColorLine} />}>
                    <div className="color-selected-pen" style={{ backgroundColor: this.props.annotationSetting.activeColorLine }}>
                      <span><ViewerControl icon="annotate-dropdown" /></span>
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
              <div className="setting-block">
                <div className="left"><FormattedMessage id="thickness" /></div>
                <div className="right">
                  <div className="thickness">
                    {this.renderThicknessLine()}
                  </div>
                </div>
              </div>
            </div>
            <div className="setting-list">
              <p><FormattedMessage id="text" /></p>
              <div className="setting-block">
                <div className="left"><FormattedMessage id="color" /></div>
                <div className="right">
                  <OverlayTrigger trigger="click" rootClose placement="top" overlay={<TextColor annotationSetting={this.props.annotationSetting} selectedColorText={this.selectedColorText} />}>
                    <div className="color-selected-pen" style={{ backgroundColor: this.props.annotationSetting.activeColorText }}>
                      <span><ViewerControl icon="annotate-dropdown" /></span>
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
              <div className="setting-block">
                <div className="left"><FormattedMessage id="FontSize" /></div>
                <div className="right">
                  <div className="font-size">
                    {this.renderFontSize()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Popover.Content>
      </Popover>
    );
  }
}

export default AnnotateSettings;
