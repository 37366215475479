import { invokePostAPI } from './InvokePostAPI';
import { searchHistoryURL } from './fetchPostUrl';

const searchHistory = async (searchMessage: any) => {
    const url = searchHistoryURL();
    // TODO : Need to serialize the data before sending to API
    const response = await invokePostAPI(url, searchMessage);
    return response;
};
export { searchHistory };
