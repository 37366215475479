import { fabric } from 'fabric';
import ImageResizeListner from './imageResizeListner';

export default class ResizeToOriginalCanvas {
    public static resizeImage(imageData: string[], reportName: any, requestType: any, listener: ImageResizeListner): void {
        let convertedImage: string[] = [];
        imageData.forEach((base64data, index) => {
            let cvFile = new fabric.Canvas('cvFile');
            fabric.Image.fromURL('data:image/jpg;base64,' + base64data, function (img) {
                cvFile.setWidth(1920);
                cvFile.setHeight(1080);
                img.set({ width: 1920, height: 1080, });
                img.scaleToHeight(1080);
                img.scaleToWidth(1920);
                img.scale(1);
                cvFile.add(img);
                cvFile.renderAll();
                let jpegUrl: any = cvFile.toDataURL({ format: 'jpeg' });
                let base64Data: any = jpegUrl.split(",").pop().toString();
                convertedImage.push(base64Data);
                console.log('convertedImage>>', convertedImage);
                if (index == (imageData.length - 1)) {
                    listener.onResizeFinish(convertedImage, reportName, requestType);
                }
            });
        });
    }
}

