import AppUtils from '../core/utils/AppUtils';

const baseUrl = `//${window.location.host}`;
const accounts = `convivo/api/accounts`;
const users = `convivo/api/users`;

let wsProtocol = AppUtils.getWebsocketSchema(baseUrl);
let httpProtocol = AppUtils.getHttpSchema(baseUrl);
console.log(wsProtocol, ' websocket protocol link');
console.log(httpProtocol, ' http protocol link');

// Add timeout in minutes 1,2...15 etc
const env = {
  websocketEndpoint: `${wsProtocol}:${baseUrl}/mw`,
  imagesocketEndpoint: `${wsProtocol}:${baseUrl}/applyfilter`,
  denoiseSocketEndpoint: `${wsProtocol}:${baseUrl}/imagefilter`,
  // websocketEndpoint: `ws:localhost:80`,
  userInfoEndpoint: `${httpProtocol}:${baseUrl}/convivo/api/oauth/userinfo`,
  loginEndpoint: `${httpProtocol}:${baseUrl}/convivo/api/oauth/login`,
  activeCasesEndpoint: `${httpProtocol}:${baseUrl}/convivo/api/workplace/activecases?PageSize=20&ContinuationToken=`,
  annotationEndpoint: `${httpProtocol}:${baseUrl}/convivo/api/workplace/accounts/`,
  caseHistoryEndpoint: `${httpProtocol}:${baseUrl}/convivo/api/workplace/historicalcases?PageSize=10&ContinuationToken=`,
  galleryEndpoint: `${httpProtocol}:${baseUrl}/convivo/api/workplace/accounts/`,
  generalCaseEndpoint: `${httpProtocol}:${baseUrl}/convivo/api/workplace/cases/`,
  updateCaseEndpoint: `${httpProtocol}:${baseUrl}/convivo/api/workplace/accounts/`,
  addImagesToCaseEndpoint: `${httpProtocol}:${baseUrl}/convivo/api/workplace/cases/`,
  // API to get the site users of specific site
  getSiteUsersListEndPoint: `${httpProtocol}:${baseUrl}/convivo/api/accounts/`,
  getAccountDetailsEndPoint: `${httpProtocol}:${baseUrl}/convivo/api/accounts/`,
  getUserDetailsEndPoint: `${httpProtocol}:${baseUrl}/convivo/api/users/`,
  updateUserDetailsEndPoint: `${httpProtocol}:${baseUrl}/convivo/api/`,
  createSiteEndpoint: `${httpProtocol}:${baseUrl}/${accounts}/`,
  updateSiteEndpoint: `${httpProtocol}:${baseUrl}/${accounts}/`,
  inviteUserEndpoint: `${httpProtocol}:${baseUrl}/${accounts}/`,
  getCompleteCaseEndpoint: `${httpProtocol}:${baseUrl}/convivo/api/workplace/accounts/`,
  rolesEndpoint: `${httpProtocol}:${baseUrl}/${users}/roles`,
  caseFilterApi: `${httpProtocol}:${baseUrl}/convivo/api/workplace/cases/filter`,
  activateOrDeactivateSiteEndPoint: `${httpProtocol}:${baseUrl}/${accounts}/`,
  createAssociations: `${httpProtocol}:${baseUrl}/${accounts}/`,
  searchHistoryURL: window.location.protocol + '/convivo/api/workplace/cases/search',
  notificationEndpoint: `${httpProtocol}:${baseUrl}/convivo/api/notification`,
  notificationSocketEndPoint: `${wsProtocol}:${baseUrl}/convivo/api/notificationws`,
  caseFileUploadEndPoint: `${httpProtocol}:${baseUrl}/convivo/api/workplace/`,
  dicomGenerateUrl: `${httpProtocol}:${baseUrl}/convivo/api/workplace/`, // to generate the url for dicom file
  accountsBaseUrl: `${httpProtocol}:${baseUrl}/convivo/api/workplace/accounts/`, // to create the report url
  timeout: 15, // 15 minutes
  websocketPingConnect: 25000,
  reConnectAttemptOnError: 6,
  domain: '.zeiss.com',
  siteadminrole: 'site admin',
  pathoRoleWeightage: 100,
  surgeonRoleWeightage: 150,
};

export { env };
