/* eslint-disable no-plusplus */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* istanbul ignore file */
const Schema = require('../../protofile/convivo_pb');

const gallerySpotDeserialize = async (data: ArrayBuffer): Promise<any[]> => {
  try {
    let messageData: any;
    const byteArray: Uint8Array = new Uint8Array(data);
    const message = Schema.Spots.deserializeBinary(byteArray);
    messageData =  message.toObject();
    return messageData;

  } catch (error) {
    console.log("error",error)  ;
    throw error;
  }
};

export { gallerySpotDeserialize };
