/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import * as actionCreators from '../actionCreators/admin/getSiteDetailsActionCreator';
import * as actionTypes from '../actionTypes/admin/getSiteDetailsActionType';
import SiteInfo from "../models/SiteInfo";
import { decodeSiteDetailssBuffer } from '../protobuf/deserialization/siteDetailsDeserialze';
import { fetchSiteDetailsList } from '../services/GET/getSiteDetailsService';

/* istanbul ignore next */
function* getSiteDetails(action: any) {
  const response = yield fetchSiteDetailsList(action.accountID, action.siteId);
  const data: ArrayBuffer = yield response.arrayBuffer();
  const siteDetailDecoded: SiteInfo = yield decodeSiteDetailssBuffer(data);
  if (siteDetailDecoded) yield put(actionCreators.setSiteDetails(siteDetailDecoded));
}

export function* watchPageEntries() {
  yield takeEvery(actionTypes.GET_ACTION_SITE_DETAIL, getSiteDetails);
}


export default function* GetSiteUsersSaga() {
  yield all([fork(watchPageEntries)]);
}
