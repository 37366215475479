/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { env } from '../../../environments/environment';
/* istanbul ignore next */
const getNotifications = async (pathologistId: any) => {
    const url = `${env.notificationEndpoint}/messages?pathologistId=${pathologistId}`;
    const response = await fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/protobuf' },
    });
    return response;
};
export { getNotifications };

