/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import * as actionCreators from '../actionCreators/caseActionCreator';
import * as actionTypes from '../actionTypes/caseActionTypes';
import Case from "../models/Case";
import CaseUserInfo from "../models/Case/CaseUserInfo";
import { decodeCompleteCaseData } from "../protobuf/deserialization/decodeCompletCaseDataDeserialize";
import { fetchCompleteCasesData } from '../services/GET/casesService';

export interface CaseState {
    caseData: Case,
    caseId: string,
    accountId: string,
    fetchCaseMessage: string,
    fetchCaseSpinner: boolean,
}
const initialUserInfoState: CaseUserInfo = {
    firstName: '',
    lastName: '',
    userID: '',
    emailAddress: '',
    role: [],
    title: '',
    accountID: '',
    phonenumber: '',
    isSiteAdmin: false,
    isAccountAdmin: false,
    roleInfo: [],
    status: '',

}

// const initialCaseState: Case = {
//     caseID: '--',
//     isResearchData: false,
//     patient: {
//         patientID: {
//             patientID: '',
//             issuer: '',
//         },
//         sex: Sex.UNKNOWN,
//         firstName: '',
//         lastName: '',
//         dateOfBirth: '',
//         comment: '',
//         age: 0,
//         otherPatientID: '',
//     },
//     deviceSurgeonData: initialUserInfoState,
//     medicalInfo: {
//         tissuelocation: '',
//         brainside: '',
//         tissuetype: '',
//     },
//     deviceSiteID: '',
//     deviceSiteName: '',
//     consultationSiteID: '',
//     consultationSiteName: '',
//     caseStatus: CaseStatus.CaseEnded,
//     additionalInfo: {
//         alertTimer: 0,
//         pathologistInfo: {
//             userId: '',
//             name: '',
//             dateTime: ''
//         },
//         thumbnailUrl: '',
//     },
//     createdDate: '',
//     interpretationText: '',
//     comment: '',
//     externalFiles: [],
//     reports: [],
//     caseAliasID: '',
//     numberOfImages: 0,
//     caseStartDateTime: '',
//     accountID: '',
//     caseEndDate: '',
//     duration: '',
// };
/* istanbul ignore next */
function* GetFullCaseAsync(action: any) {
    try {
        let decodedData: any = initialUserInfoState;
        const response: Response = yield fetchCompleteCasesData(action.accountId, action.caseId);
        const apiStatus: number = response.status;
        switch (apiStatus) {
            case 200:
                const data: ArrayBuffer = yield response.arrayBuffer();
                decodedData = yield decodeCompleteCaseData(data);
                yield put(actionCreators.getCaseSuccess());
                yield put(actionCreators.setCompleteCaseAction(decodedData));
                break;
            case 401:
            case 500:
            case 403:
                console.log('get  case  Api Failed ');
                yield put(actionCreators.getCaseFail('GetCaseFailMessage', false));
                break;
            default:
                console.log('get  case  Api Failed ');
                yield put(actionCreators.getCaseFail('GetCaseFailMessage', false));
                break;
        }
    } catch (error) {
        console.log('get case Api Failed ', error);
        yield put(actionCreators.getCaseFail('GetCaseFailMessage', false));
    }
}

export function* watchCompleteCase() {
    yield takeEvery(actionTypes.GET_CASE, GetFullCaseAsync);
}

export default function* GetCaseDetailsByIdSaga() {
    yield all([fork(watchCompleteCase)]);
}
