/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { all, fork } from 'redux-saga/effects';
import submitContactFormSaga from '../saga/submitContactFormSaga';
import AccountSaga from './accountDetailSaga';
import CaptureImageSaga from './captureImageSaga';
import caseFileUploadFilter from './caseFileUploadSaga';
import GetCaseFilter from './caseFilterSaga';
import ActiveCaseSaga from './casesSaga';
import CommentSaga from './commentSaga';
import createAssocation from './createAssocation';
import createReportSaga from './CreateReportSaga';
import CreateSiteSaga from './createSiteSaga';
import deleteMemberSaga from './deleteMemberSaga';
import downloadReportSaga from './downloadReportsSaga';
import exportDicomSaga from './ExportDicomSaga';
import GallerySaga from './gallerySaga';
import AccAssoicateListSaga from './getAccAssoicateListSaga';
import GetCaseDetailsByIdSaga from './getCaseDetailsByIdSaga';
import SiteAssociateSaga from './getSiteAssoicateListSaga';
import SiteDetails from './getSiteDetailsSaga';
import SiteSaga from './getSitesSaga';
import SiteUsers from './getSiteUsersSaga';
import AnnotationSaga from './imageDetailsSaga';
import inviteAdminUserStatusSaga from './inviteAdminUserStatusSaga';
import inviteExistingUserSaga from './inviteExistingUserSaga';
import InviteSiteAdminUserStatusSaga from './inviteSiteAdminStatusSaga';
import inviteUserSaga from './inviteUserSaga';
import InviteUserStatusSaga from './InviteUserStatusSaga';
import mangeAccountSaga from './mangeAccountSaga';
import NotificationSaga from './notificationSaga';
import GetSearch from './searchHistorySaga';
import PostUpdateCase from './updateCaseHistorySaga';
import UpdateDefaultSiteAssociationSaga from './UpdateDefaultSiteAssociationSaga';
import UpdateSiteAssociationSaga from './UpdateSiteAssociationSaga';
import UpdateSiteSaga from './updateSiteSaga';
import UserSaga from './userDetailsSaga';
import LoginSaga from './userInfoSaga';
import userRolesSaga from './userRolesSaga';
import confirmReportSaga from './confirmReportSaga';


export default function* rootSaga() {
  yield all([
    fork(LoginSaga),
    fork(ActiveCaseSaga),
    fork(AnnotationSaga),
    fork(GallerySaga),
    fork(CaptureImageSaga),
    fork(CommentSaga),
    fork(SiteUsers),
    fork(SiteSaga),
    fork(SiteAssociateSaga),
    fork(AccountSaga),
    fork(SiteDetails),
    fork(UserSaga),
    fork(CreateSiteSaga),
    fork(UpdateSiteSaga),
    fork(inviteUserSaga),
    fork(inviteExistingUserSaga),
    fork(createAssocation),
    fork(GetSearch),
    fork(NotificationSaga),
    fork(AccAssoicateListSaga),
    fork(deleteMemberSaga),
    fork(UpdateSiteAssociationSaga),
    fork(UpdateDefaultSiteAssociationSaga),
    fork(mangeAccountSaga),
    fork(InviteUserStatusSaga),
    fork(userRolesSaga),
    fork(GetCaseFilter),
    fork(caseFileUploadFilter),
    fork(PostUpdateCase),
    fork(inviteAdminUserStatusSaga),
    fork(exportDicomSaga),
    fork(createReportSaga),
    fork(submitContactFormSaga),
    fork(InviteSiteAdminUserStatusSaga),
    fork(GetCaseDetailsByIdSaga),
    fork(downloadReportSaga),
    fork(confirmReportSaga),
  ]);
}
