/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ViewerControl } from '../../../constants/viewControl';
import ContactForm from '../../../containers/header/contactFormContainer';
import GeneralSettings from '../../../containers/header/GeneralSettingsContainer';
import WebSocketManager from "../../../core/webscoket/WebSocketManager";
import LogOutConfirm from "../../overlays/logOutConfirm";
import ProductInformation from '../../overlays/productInformation';
const constants = require('../../../constants/constants.json');

// const envData = require('../../../constants/constants.json');

interface Props {
  getUserDetails: (email: string) => void;
  updateUserDetails: (userId: string, userDetails: any, communication: any) => void;
  userId: string;
  email: string;
  userDetails: any;
  menudata: any;
  accountId: any;
  updateSettingsSpinner: any;
  updateUserDetailsComplete: any;
  contactFormSpinner: any;
  responseMsg: any;
  submitContactFormComplete: any;
  caseId: any;
  language: string; // language code
}

interface State {
  isOpen?: boolean;
  modalshow: boolean;
  modalContactShow: boolean;
  showProductModal: boolean;
  selectedLanguage: string;
  prerequisiteData: any;
  productInfo: any[];
  productInfoURL: string;
  openCloseLogout: boolean;
}

export class ProfileMenu extends React.Component<Props, State> {
  private logoutRef = React.createRef<HTMLFormElement>();
  constructor(props: Props) {
    super(props);
    this.state = {
      showProductModal: false,
      modalContactShow: false,
      isOpen: false,
      modalshow: false,
      selectedLanguage: 'en',
      prerequisiteData: {},
      productInfo: [],
      productInfoURL: '',
      openCloseLogout: false
    };
  }

  public getDataByLocale = (key: string, locale: string) => {// function declaration to query the json data based on key and locale
    if (this.props.menudata && this.props.menudata.hasOwnProperty(key)) {
      let data: any = this.props.menudata[key];
      locale = locale.replace('-', '');
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (element[locale]) {
          return element[locale]['uri'];
        }
      }
    }
  }
  handleMouseHover = () => {
    this.setState(this.toggleHoverState);
  };

  handleModalShowHide = () => {
    this.setState({ modalshow: !this.state.modalshow });
  };
  handleProductModalShowHide = () => {
    this.setState({ showProductModal: !this.state.showProductModal });
  }
  // updateUserDetailsHandler = () => {
  //   const { userId, updateUserDetails, userDetails } = this.props;
  //   // if (userId)
  //   updateUserDetails(userId, userDetails);
  // }
  handleContactModalShowHide = () => {
    this.setState({ modalContactShow: !this.state.modalContactShow });
  };

  showPopUpDetailsContact = () => {
    this.setState({ modalContactShow: !this.state.modalContactShow });
  };

  showPopUpDetails = () => {
    const { email, getUserDetails } = this.props;
    getUserDetails(email);
    this.setState({ modalshow: !this.state.modalshow });
  };
  toggleHoverState = (state: any) => {
    /* istanbul ignore next */
    return {
      isOpen: !state.isOpen,
    };
  };

  handleInfoClick = () => {
    const cancel = document.getElementsByClassName('info-link-btn')[0] as HTMLElement;
    cancel.click();
  };

  openCloseLogout = () => {
    this.setState({ openCloseLogout: !this.state.openCloseLogout });
  };
  OKConfirmLogout = () => {
    this.setState({ openCloseLogout: !this.state.openCloseLogout });
    this.logout();
  };
  /* Logout the current session * */
  logout = () => {
    localStorage.removeItem('isLoggedIn');
    console.log("---------- profile menu socket closing------");
    WebSocketManager.getWebsocketManager().closeStreamingWS();
    // e.preventDefault();

    /* istanbul ignore next */
    this.logoutRef.current && this.logoutRef.current.submit();
  };

  // Find matching language with backend no key found make english as default 
  getLangInfo = () => {
    let lang = 'en';
    const { language } = this.props;
    for (let index = 0; index < constants.languages.length; index++) {
      const element = constants.languages[index].key;
      if (element == language) {
        return language;
      }
    }
    return lang;
  }

  showPublisherDetails = () => {
    let menuUrl;
    const language = this.getLangInfo();
    menuUrl = this.getDataByLocale('publisher', language);
    if (!menuUrl) {
      menuUrl = this.getDataByLocale('publisher', "en");
    }
    window.open(menuUrl, '_blank');

  }
  showPrivacyDetails = () => {
    let menuUrl;
    const language = this.getLangInfo();
    menuUrl = this.getDataByLocale('privacypolicy', language);//calling function to get the url based on locale and key 
    if (!menuUrl) {
      menuUrl = this.getDataByLocale('privacypolicy', "en");
    }
    window.open(menuUrl, '_blank');
  }
  showTermsDetails = () => {
    let menuUrl;
    const language = this.getLangInfo();
    menuUrl = this.getDataByLocale('tc', language);
    if (!menuUrl) {
      menuUrl = this.getDataByLocale('tc', "en");
    }
    window.open(menuUrl, '_blank');
  }
  showProductDetails = () => {
    let menuUrl;
    const language = this.getLangInfo();
    menuUrl = this.getDataByLocale('productlabel', language);
    if (!menuUrl) {
      menuUrl = this.getDataByLocale('productlabel', "en");
    }
    this.setState({
      productInfoURL: menuUrl,
      showProductModal: !this.state.showProductModal
    })
  }
  showInstructionsDetails = () => {
    let menuUrl;
    const language = this.getLangInfo();
    menuUrl = this.getDataByLocale('ifu', language);
    if (!menuUrl) {
      menuUrl = this.getDataByLocale('ifu', "en");
    }
    window.open(menuUrl, '_blank');
  }
  showHelpDetails = () => {

  }
  // selectedLang = (key: any) => {
  //   /* istanbul ignore next */
  //   store.dispatch(changeLanguage(key));
  // };

  // renderLang = () => {
  //   const slectedLang = AppUtils.getCurrentLanguageKey();
  //   return envData.languages.map((lang: any) => {
  //     return (
  //       <li
  //         key={lang.key}
  //         id={lang.key}
  //         className={lang.key === slectedLang ? 'active' : ''}
  //         onClick={() => this.selectedLang(lang.key)}>
  //         <a>
  //           {lang.title} <img alt="selectpick" src={Icons.selectick} />
  //         </a>
  //       </li>
  //     );
  //   });
  // };

  render() {
    const { getUserDetails, email, userDetails, userId, accountId, updateSettingsSpinner, updateUserDetailsComplete, contactFormSpinner, submitContactFormComplete, responseMsg, caseId } = this.props;

    // let productLabel: any;
    // if (this.state.productInfo.length) {
    //   let productInfo = this.state.productInfo;
    //   for (let index = 0; index < productInfo.length; index++) {
    //     const element = productInfo[index];
    //     if (element.hasOwnProperty(this.state.lang))
    //       productLabel = element[this.state.lang]['uri'];
    //   }
    // }
    return (
      <>
        <NavDropdown title={<ViewerControl icon="dot-icon-dashboard" />} id="main-dropdown">
          <NavDropdown.Item href="" onClick={() => this.showPopUpDetails()}>
            <FormattedMessage id="UserProfilePage" />
          </NavDropdown.Item>
          <NavDropdown.Item onClick={() => this.showInstructionsDetails()}><FormattedMessage id="IFU" /></NavDropdown.Item>

          <NavDropdown.Item href="" onClick={() => this.showPopUpDetailsContact()}>
            <FormattedMessage id="ContactCustomerServiceForm" />
          </NavDropdown.Item>
          <NavDropdown.Item onClick={() => this.showProductDetails()}><FormattedMessage id="ProductAboutPage" /></NavDropdown.Item>
          <NavDropdown.Item onClick={() => this.showPublisherDetails()}><FormattedMessage id="publisher" /></NavDropdown.Item>
          <NavDropdown.Item onClick={() => this.showTermsDetails()}><FormattedMessage id="TermsConditions" /></NavDropdown.Item>
          <NavDropdown.Item onClick={() => this.showPrivacyDetails()}><FormattedMessage id="privacyPolicy" /></NavDropdown.Item>
          <NavDropdown.Item onClick={this.openCloseLogout}>
            <label style={{ cursor: 'pointer' }}>
              {' '}
              <FormattedMessage id="logout" />
            </label>
            <form
              id="sessionLogout"
              ref={this.logoutRef}
              action="/convivo/api/oauth/logout"
              method="post"
              style={{ display: 'none' }}
            />
          </NavDropdown.Item>
          {/* <label className="language" onClick={this.handleMouseHover}>
            Language select{' '}
            <i className="arrow-item">
              <ViewerControl icon="arrow-right" />
            </i>
            {this.state.isOpen ? (
              <div className="language-select">
                <ul>{this.renderLang()}</ul>
              </div>
            ) : (
              <i />
            )}
          </label> */}




          {/* <NavDropdown.Item onClick={() => this.showHelpDetails()}>Help</NavDropdown.Item> */}

        </NavDropdown>
        <ProductInformation modalshow={this.state.showProductModal} cancelClickhandler={this.handleProductModalShowHide} productInfoURL={this.state.productInfoURL}></ProductInformation>
        <GeneralSettings modalshow={this.state.modalshow} cancelClickhandler={this.handleModalShowHide} userDetails={userDetails} userId={userId} updateSettingsSpinner={updateSettingsSpinner} updateUserDetailsComplete={updateUserDetailsComplete} getUserDetails={getUserDetails} email={email} />
        <ContactForm
          modalshow={this.state.modalContactShow}
          cancelContactClickhandler={this.handleContactModalShowHide}
          accountId={accountId}
          contactFormSpinner={contactFormSpinner}
          responseMsg={responseMsg} submitContactFormComplete={submitContactFormComplete}
          caseId={caseId}
        />
        <LogOutConfirm
          modalshow={this.state.openCloseLogout}
          cancelClickhandler={this.openCloseLogout}
          OkClickhandler={this.OKConfirmLogout}
        />
      </>
    );
  }
}

export default ProfileMenu;
