/* eslint-disable no-console */
/* eslint-disable no-useless-catch */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* istanbul ignore file */
const Schema = require('../../protofile/convivo_pb');

const decodeUserInfo = async (data: ArrayBuffer): Promise<any> => {
  try {
    let role;
    const byteArray: Uint8Array = new Uint8Array(data);
    const message = Schema.UserInfo.deserializeBinary(byteArray);
    if (message.getRoleList() == 1) role = 'SURGEON';
    else if (message.getRoleList() == 2) role = 'PATHOLOGIST';
    else role = 'NONE';
    return {
      userId: message.getUserid(),
      email: message.getEmailaddress(),
      firstName: message.getFirstname(),
      lastName: message.getLastname(),
      accountID: message.getAccountid(),
      role: role,
      title: message.getTitle(),
      isAccAdmin: message.getIsaccountadmin(),
      isSiteAdmin: message.getIssiteadmin(),
      language: message.getLanguage(),
    };
  } catch (error) {
    console.log("userinfo error", error);
    throw error;
  }
};

export { decodeUserInfo };

