/* eslint-disable react/prefer-stateless-function */
/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import { Popover } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const envData = require('../../../constants/constants.json');


interface Props {
  selectedColor(key: any): void;
  annotationSetting: any;
}
interface State {
  activeColor: any
}

export class PenColor extends React.Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props);
    this.state = {
      activeColor: this.props.annotationSetting.activeColor,
    }
  }

  selectedColor = (key: any) => {
    this.props.selectedColor(key);
    this.setState({ activeColor: key });
  };

  renderColor = () => {
    console.log("this.props.annotationSetting.activeColor " + this.props.annotationSetting.activeColor);
    return envData.color.map((color: any) => {
      return (
        <div
          key={color.key}
          id={color.key}
          onClick={() => this.selectedColor(color.title)}
          className={'color-item' + (color.title === this.props.annotationSetting.activeColor ? " active" : "")}><div className={'color ' + color.key}></div></div>
      );
    });
  };


  render() {
    return (
      <Popover id="popover-color-select" {...this.props}>
        <Popover.Content>
          <p><FormattedMessage id="standardColors" /></p>
          <div className="color-list">
            {this.renderColor()}
          </div>
        </Popover.Content>
      </Popover>
    );
  }
}

export default PenColor;
