/* eslint-disable react/prefer-stateless-function */
/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import { Popover } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

interface Props {
  imgQuality: string;
  focusDepth: number;
  laserPower: number;
  gain: number;
}

export class ImageInfo extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { imgQuality, focusDepth, laserPower, gain } = this.props;
    return (
      <Popover id="popover-footer-info">
        <Popover.Content>
          <div className="info">
            {/* <p>
              Image Resolution <span>{imgQuality}</span>
            </p> */}
            <p>
              <FormattedMessage id="ImageResolution" />
              {imgQuality.toLocaleLowerCase() === 'not available' && (
                <span>
                  <FormattedMessage id="NA" />
                </span>
              )}
              {imgQuality.toLocaleLowerCase() === 'maximum' && (
                <span>
                  <FormattedMessage id="Max" />
                </span>
              )}
              {imgQuality.toLocaleLowerCase() === 'high' && (
                <span>
                  <FormattedMessage id="High" />
                </span>
              )}
              {imgQuality.toLocaleLowerCase() === 'medium' && (
                <span>
                  <FormattedMessage id="Medium" />
                </span>
              )}
              {imgQuality.toLocaleLowerCase() === 'low' && (
                <span>
                  <FormattedMessage id="Low" />
                </span>
              )}
            </p>
            <p>
            <FormattedMessage id="FocusDepth" /> <span>{focusDepth} <i style={{textTransform:"none"}}>&#956;</i>m</span>
            </p>
            <p>
            <FormattedMessage id="LaserPower" /> <span>{laserPower}%</span>
            </p>
            <p>
            <FormattedMessage id="Gain" /> <span>{gain}</span>
            </p>
          </div>
        </Popover.Content>
      </Popover>
    );
  }
}

export default ImageInfo;
