export const GET_ACTION_ACCOUNT_DETAIL = 'accountActionType/GET_ACTION_DETAIL';
export const GET_ACTION_ACCOUNT_DETAIL_SUCCESS = 'accountActionType/GET_ACTION_DETAIL_SUCCESS';

export interface GetAccountDetailAction {
    type: typeof GET_ACTION_ACCOUNT_DETAIL;
    accountId: string
}

export interface GetAccountDetailActionSuccess {
    type: typeof GET_ACTION_ACCOUNT_DETAIL_SUCCESS;
    accountData: any;
}

export type ACTIONS = GetAccountDetailAction | GetAccountDetailActionSuccess;