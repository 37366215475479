/* eslint-disable no-console */
import { env } from '../../../environments/environment';
/* istanbul ignore next */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const fetchUserInfo = async () => {
  const url = env.userInfoEndpoint;
  const response = await fetch(url, {
    method: 'GET',
    mode: 'no-cors',
    headers: { 'Content-Type': 'application/protobuf' },
  });
  console.log('fetUserINfo response --> ', response);
  
  return response;
};

export { fetchUserInfo };
