/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { useLocationHook } from './useLocationHook';

/**
 * This is High ordered component , with useLocation hook
 * @param Component component
 */

export const locationHOC = (Component: any) => {
  return (props: any) => {
    const data = useLocationHook();
    return <Component locationHook={data} {...props} />;
  };
};
