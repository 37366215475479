/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Icons, ViewerControl } from '../../../constants/viewControl';
import { Sex } from "../../../core/models/Case/Sex";
const moment = require('moment');
interface Props {
  clickhandle: (event: React.MouseEvent<HTMLElement>) => void;
  modalshow: boolean;
  caseData: any;
  caseDataNew?: any;
}

export default function InfoModal(props: Props) {
  const { modalshow, clickhandle, caseData, caseDataNew } = props;
  let patientAge: any = caseDataNew && caseDataNew.patient?.age ? caseDataNew.patient?.age : undefined;
  if (patientAge && patientAge == -1) {
    patientAge = '>90';
  }
  let sideText = caseData.brainside;
  let tissueType = caseData.tissuetype;
  if (sideText && sideText.charAt(0) == '#') {
    sideText = sideText.slice(1)
  }
  if (tissueType && tissueType.charAt(0) == '#') {
    tissueType = tissueType.slice(1)
  }

  const displayGender = () => {
    let genderVal = parseInt(caseDataNew.patient?.sex);
    switch (genderVal) {
      // case Sex.UNKNOWN:
      //   return  <ViewerControl icon="gender-unknown" />
      case Sex.FEMALE:
        return <img alt="female" src={Icons.femaleGenderIcon} />
      case Sex.MALE:
        return <img alt="male" src={Icons.maleGenderIcon} />
      case Sex.OTHER:
        return <ViewerControl icon="gender-other" />
      default:
        console.log("no gender")
    }
  }
  return (
    <Modal className="info-modal" show={modalshow} aria-labelledby="contained-modal-title-vcenter" centered onHide={clickhandle}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"><FormattedMessage id="Info" /></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body">
          <div className="info-main">
            <div className="patient-info">
              <p><FormattedMessage id="Patient" /></p>
              <p>

                {caseDataNew?.patient?.firstname || caseDataNew?.patient?.lastname ? <>{caseDataNew?.patient?.firstname}  {caseDataNew?.patient?.lastname}</> : caseDataNew?.patient?.patientid?.patientid}
              </p>
              <p>
                {displayGender()}
                {
                  caseDataNew && caseDataNew.patient?.dateofbirth ? `${caseDataNew.patient?.sex != '0' ? `| ${caseDataNew && moment(caseDataNew.patient.dateofbirth).format('DD. MMM. YYYY')}${patientAge && ` (${patientAge})`}` : `${caseDataNew && moment(caseDataNew.patient.dateofbirth).format('DD. MMM. YYYY')}${patientAge && ` (${patientAge})`}`}`
                    : `${patientAge && caseDataNew?.patient?.sex != '0' ? `| (${patientAge})` : patientAge ? `(${patientAge})` : ''}`}
                {(caseDataNew?.patient?.firstname || caseDataNew?.patient?.lastname) ?
                  `${caseDataNew?.patient?.sex != '0' || (caseDataNew && caseDataNew.patient?.dateofbirth || patientAge) ? ` | ${caseDataNew?.patient?.patientid?.patientid}` : `${caseDataNew?.patient?.patientid?.patientid}`}` : ''}
                {/* {caseDataNew && caseDataNew.patient?.dateofbirth && caseDataNew.patient?.sex != '0' ? 
                `| ${caseDataNew && moment(caseDataNew.patient.dateofbirth).format('DD. MMM. YYYY')}${patientAge && ` (${patientAge})`}` 
                : `${patientAge && caseDataNew?.patient?.sex != '0' ? `| (${patientAge})` : patientAge ? `(${patientAge})` : ''}`}
                {(caseDataNew?.patient?.firstname || caseDataNew?.patient?.lastname) ?
                  `${caseDataNew?.patient?.sex != '0' || (caseDataNew && caseDataNew.patient?.dateofbirth || patientAge) ? ` | ${caseDataNew?.patient?.patientid?.patientid}` : `${caseDataNew?.patient?.patientid?.patientid}`}` : ''} */}


                {/* {caseDataNew && caseDataNew.patient.dateOfBirth && caseData.genderValue !== '0' ?`| ${caseDataNew.patient.dateOfBirth}${patientAge?`(${patientAge})`:''}`:''}
                               {caseData.firstName || caseData.lastName?
                               `${caseData.genderValue !== '0' && (caseDataNew && caseDataNew.patient.dateOfBirth || patientAge)?` | ${caseData.patientId}`: `${caseData.patientId}`}`:'' } */}
                {/* {caseData.genderValue=='0' || !caseDataNew.patient.dateOfBirth? '':<i className="separator" />}
                {caseDataNew.patient.dateOfBirth} <span>{caseDataNew.patient.dateOfBirth} {caseDataNew.patient.age ? ` (${caseDataNew.patient.age}) ` : '' }</span>
                
                {caseData.firstName || caseData.lastName ? 
                caseData.genderValue=='0' && !caseDataNew.patient.dateOfBirth ? 
                <><span>{caseData.patientId}</span></>:<><i className="separator" /> <span>{caseData.patientId}</span></> : ''} */}
              </p>
            </div>
            <div className="surgeon-info">
              <p><FormattedMessage id="Surgeon" /></p>
              <p>
                {' '}
                {caseData.title}  {caseData.surgeonFirstName} {caseData.surgeonLastName}
              </p>
              <p>
                <span>{caseDataNew?.deviceSiteName}</span>{' '}
                {caseDataNew.deviceSurgeonData.phonenumber ? <><i className="separator" /><span>{caseDataNew.deviceSurgeonData.phonenumber}</span></> : ''}
              </p>
            </div>
          </div>
          <div className="info-sub">
            <div className="item">
              <p><FormattedMessage id="TumorLocation" /></p>
              <p>{caseData.tissuelocation}{sideText ? caseData.tissuelocation ? `, ${sideText}` : `${sideText}` : ''}</p>
              {/* <p>{caseData.tissuelocation}{sideText ? ',' : ''} {sideText}</p> */}
            </div>
            <div className="item">
              <p><FormattedMessage id="PreoperativeDiagnosis" /></p>
              <p>{tissueType}</p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
