import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import HomeScreen from '../../components/dashboard/home';
import { getActiveCases, getCaseInfo, getHistoryCaseList, getMenuDataList } from '../../core/actionCreators/caseActionCreator';
import { downloadReport } from '../../core/actionCreators/downloadReportActionCreator';
import { fetchData } from '../../core/actionCreators/fetchActionCreator';
import { AppState } from '../../core/reducers/rootReducer';
import { setContactFormCaseIdAction } from '../../core/actionCreators/contactFormActionCreators';

const mapStateToProps = (state: AppState) => {
  return {
    caseInfo: state.caseInfo,
    casedata: state.caseActivity.casedata,
    historydata: state.caseActivity.historydata,
    userData: state.login.userData,
    activeCaseTotalRec: state.caseActivity.activeCaseTotalRec,
    historyCaseTotalRec: state.caseActivity.historyCaseTotalRec,
    currentpage: state.caseActivity.currentpage,
    role: state.login.userData.role,
    menudata: state.caseActivity.menudata,
    activeCaseSppiner: state.caseActivity.activeCaseSppiner,
    historyCaseSppiner: state.caseActivity.historyCaseSppiner,
    isFetchData: state.isFetchData,
    caseData: state.UpdateCaseReducer.caseData,
    isLoginSuccess: state.login.isLoginSuccess,

  };
};

const mapDispachToProps = (dispatch: Dispatch) => {
  return {
    activeCasesList: (pageNumber: number) => dispatch(getActiveCases(pageNumber)),
    getHistoryCaseList: (pageNumber: number) => dispatch(getHistoryCaseList(pageNumber)),
    getMenuDataList: () => dispatch(getMenuDataList()),
    fetchData: (isFetchData: boolean) => dispatch(fetchData(isFetchData)),
    downloadReport: (accountId: any, caseId: string, reportName: string) => dispatch(downloadReport(accountId, caseId, reportName)),
    getCaseInfo: (accountID: any, caseId: string) => dispatch(getCaseInfo(accountID, caseId)),
    setContactFormCaseIdAction: (caseId: string, accountId: any) => dispatch(setContactFormCaseIdAction(caseId, accountId)),

  };
};
export default connect(mapStateToProps, mapDispachToProps)(HomeScreen);
