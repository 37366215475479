/* eslint-disable no-plusplus */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* istanbul ignore file */
const Schema = require('../../protofile/convivo_pb');

const decodeNotificationResponse = async (data: ArrayBuffer): Promise<any[]> => {
    try {
        const messageData: any[] = [];
        const byteArray: Uint8Array = new Uint8Array(data);
        const message = Schema.WebNotifications.deserializeBinary(byteArray);
        const getdatalist = message.getNotificationsList();
        getdatalist.forEach((item: any) => {
            messageData.push(item.toObject());
        });
        console.log(messageData, '---------deserialized response data for notification')
        return messageData;
    } catch (error) {
        throw error;
    }
};

export { decodeNotificationResponse };

