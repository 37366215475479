import * as React from 'react';
import { useIntl } from 'react-intl';

interface Props {
    errormessageid: string;
}


function HeaderElementErrorMessage(props: Props) {

    const intl = useIntl();
    const showmessage = (message: string) => {
        return intl.formatMessage({ id: message })
    }
    return props.errormessageid ? (<h5 className="errorMsg"> {showmessage(props.errormessageid)}</h5>) : (<h5></h5>)
}

export default HeaderElementErrorMessage;