/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/prefer-default-export */
import * as actions from '../actionTypes/fetchActionTypes';

export function fetchData(isFetchData: boolean) {
    console.log(isFetchData,'------------------creator check')
  return {
    type: actions.FETCH_DATA,
    isFetchData
  };
}
