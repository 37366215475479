/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Case from "../../../core/models/Case";
interface Props {
  msgType: any;
  currentCase: any;
  caseData?: Case;
}

export class SurgeonInfo extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { msgType, currentCase, caseData } = this.props;
    console.log(caseData, '>>>casedata', currentCase, '>>>currentcase');
    return (
      <>
        <div className="surgeon">
          <div className="surgeon-name">
            <label className="head">
              <FormattedMessage id="SurgeonInformation" />
            </label>
            <p>
              {currentCase.title} {currentCase.surgeonFirstName} {currentCase.surgeonLastName}
            </p>
            <div className="surgeon-details">
              <p>
                {' '}
                {caseData?.deviceSiteName}
                {caseData?.deviceSurgeonData?.phonenumber && msgType != 9 ?caseData?.deviceSiteName?` | ${caseData?.deviceSurgeonData.phonenumber}`:`${caseData?.deviceSurgeonData.phonenumber}`:''}
                {/* {caseData?.deviceSurgeonData.phonenumber && msgType != 9 ? <span className="separator"> | </span> : '' } 
                {caseData?.deviceSurgeonData.phonenumber && msgType != 9 ? caseData?.deviceSurgeonData.phonenumber : ''} */}
              </p>
            </div>
          </div>
          {/* <div className="surgeon-name">
            <label className="head">             &nbsp;
            </label>
            <p>
              {currentCase.caseId}
            </p>
            <div className="surgeon-details">
              <p>
              CASE ID
              </p>
            </div>
          </div> */}
        </div>
      </>
    );
  }
}

export default SurgeonInfo;
