// eslint-disable-next-line import/no-cycle
import Case from '../models/Case';
import CaseInfoState from '../models/CaseInfoData';

export const SET_CASE = 'caseActionType/SET_CASE';
export const GET_CASE = 'caseActionType/GET_CASE';
export const SET_COMPLETE_CASE = 'caseActionType/SET_COMPLETE_CASE';

export const GET_CASE_SUCCESS = 'caseActionType/GET_CASE_SUCCESS';
export const GET_CASE_FAILURE = 'caseActionType/GET_CASE_FAILURE';
export const GET_CASE_CLEAR = 'caseActionType/GET_CASE_CLEAR';

export const GET_ACTIVE_CASES = 'caseActionType/GET_ACTIVE_CASES';
export const GET_ACTIVE_CASES_FAIL = 'caseActionType/GET_ACTIVE_CASES_FAIL';
export const SET_ACTIVE_CASES_LIST = 'caseActionType/SET_ACTIVE_CASES_LIST';

export const GET_HISTORY_CASES = 'caseActionType/GET_HISTORY_CASES';
export const GET_HISTORY_CASES_FAIL = 'caseActionType/GET_HISTORY_CASES_FAIL';
export const SET_HISTORY_CASES_LIST = 'caseActionType/SET_HISTORY_CASES_LIST';
export const CLEAR_HISTORY_ON_MOUNT = 'caseActionType/CLEAR_HISTORY_ON_MOUNT';

export const GET_MENU_DATALIST = 'caseActionType/GET_MENU_DATALIST';
export const SET_MENU_DATALIST = 'caseActionType/SET_MENU_DATALIST';

export interface SetCaseAction {
  type: typeof SET_CASE;
  casedata: CaseInfoState;
}

export interface SetCompleteCaseAction {
  type: typeof SET_COMPLETE_CASE;
  casedata: Case;
}

export interface GetCaseAction {
  type: typeof GET_CASE;
  caseId: string;
  accountId: string;
}

export interface GetCaseSuccess {
  type: typeof GET_CASE_SUCCESS;
  fetchCaseMessage: string;
  fetchCaseSpinner: boolean;
}

export interface GetCaseFail {
  type: typeof GET_CASE_FAILURE;
  fetchCaseMessage: string;
  fetchCaseSpinner: boolean
}

export interface GetCaseClear {
  type: typeof GET_CASE_CLEAR;
  fetchCaseMessage: string;
}


export interface GetActiveCases {
  type: typeof GET_ACTIVE_CASES;
}

export interface GetActiveCases {
  type: typeof GET_ACTIVE_CASES;
}
export interface GetActiveCasesFail {
  type: typeof GET_ACTIVE_CASES_FAIL;
}

export interface SetActiveCasesList {
  type: typeof SET_ACTIVE_CASES_LIST;
  casedata: [];
  activeCaseTotalRec: number;
  currentpage: number;
}

export interface GetHistoryCases {
  type: typeof GET_HISTORY_CASES;
  pageCount: number;
  isReportHistory?: boolean;
  continuationToken?: any;
}

export interface GetHistoryCasesFail {
  type: typeof GET_HISTORY_CASES_FAIL;
}
export interface SetHistoryCasesList {
  type: typeof SET_HISTORY_CASES_LIST;
  historydata: [];
  historyCaseTotalRec: number;
  continuationToken?: any;
}
export interface ClearOldHistoryData {
  type: typeof CLEAR_HISTORY_ON_MOUNT;
  historydata: [];
}
export interface GetMenuDataList {
  type: typeof GET_MENU_DATALIST;
}

export interface SetMenuDataList {
  type: typeof SET_MENU_DATALIST;
  menudata: any;
}

export type ACTIONS =
  | SetCaseAction
  | GetCaseAction
  | GetActiveCases
  | GetActiveCasesFail
  | SetActiveCasesList
  | GetHistoryCases
  | GetHistoryCasesFail
  | SetHistoryCasesList
  | ClearOldHistoryData
  | GetMenuDataList
  | SetMenuDataList
  | SetCompleteCaseAction
  | GetCaseSuccess
  | GetCaseFail
  | GetCaseClear;
