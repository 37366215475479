/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as actions from '../actionTypes/caseActionTypes';

const initialState = {
  casedata: [],
  activeCaseTotalRec: 0,
  historydata: [],
  caseHistoryData:[],
  historyCaseTotalRec: 0,
  currentpage: 1,
  menudata: {},
  activeCaseSppiner: false,
  historyCaseSppiner: false,
  continuationToken:null,
  updatedCaseHistory: [],
};

const caseActivityReducer = (state = initialState, action: actions.ACTIONS) => {
  switch (action.type) {
    case actions.SET_ACTIVE_CASES_LIST:
      return {
        ...state,
        casedata: [...action.casedata],
        activeCaseTotalRec: action.activeCaseTotalRec,
        currentpage: action.currentpage,
        activeCaseSppiner: false,
      };
    case actions.GET_ACTIVE_CASES: {
      return {
        ...state,
        casedata: [],
        activeCaseSppiner: true,
      };
    }
    case actions.GET_ACTIVE_CASES_FAIL: {
      return {
        ...state,
        casedata: [],
        activeCaseSppiner: false,
      };
    }
    case actions.GET_HISTORY_CASES: {
      return { 
        ...state,
        // historydata: [],
        historyCaseSppiner: true,
      };
    }
    case actions.GET_HISTORY_CASES_FAIL: {
      return {
        ...state,
        // historydata: [],
        historyCaseSppiner: false,
        historyCaseTotalRec: 0,
      };
    }
    case actions.SET_HISTORY_CASES_LIST:
      // console.log(state.historydata,'>>>>currentstate',action.historydata,'>>>>actionstate');
      let homeHistoryData:any=[];//Home page Data
      homeHistoryData=[...action.historydata,...state.historydata];    // Show recent records in first    
      console.log("homeHistoryData",homeHistoryData);
      let filterData=[];
      if(homeHistoryData.length>0){
        filterData = homeHistoryData.filter((ele: any, ind: any) => ind === homeHistoryData.findIndex((elem: any) => elem.caseId === ele.caseId));
       }

      let completeHistoryData:any=[];//Case history second page  Data
      completeHistoryData=[...state.caseHistoryData,...action.historydata]; // Show recent records in last    
      let completeHistoryDataFilter=[];
     
      if(completeHistoryData.length>0){
        completeHistoryDataFilter = completeHistoryData.filter((ele: any, ind: any) => ind === completeHistoryData.findIndex((elem: any) => elem.caseId === ele.caseId));
       }
      return {
        ...state,
        historydata: filterData, //[...state.historydata,...action.historydata], //filterData,
        historyCaseTotalRec: action.historyCaseTotalRec,
        historyCaseSppiner: false,
        continuationToken:action.continuationToken,
        caseHistoryData: completeHistoryDataFilter,
        updatedCaseHistory: action.historydata
      };
    case actions.CLEAR_HISTORY_ON_MOUNT:
      return {
        ...state=initialState
      }
    case actions.SET_MENU_DATALIST:
      return {
        ...state,
        menudata: action.menudata,
      };
    default:
      return state;
  }
};

export default caseActivityReducer;
