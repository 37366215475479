import moment from 'moment';
import React from 'react';

export interface Props {
    timeTillDate: string;
    timeStartDate: string;
}

export interface State {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export class Countdown extends React.Component<Props, State> {
    interval: NodeJS.Timeout | undefined;
    constructor(props: Props) {
        super(props);
        this.state = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            const { timeTillDate, timeStartDate } = this.props;
            const then: any = moment(timeTillDate, 'HH:mm:ss');
            const pnow: any = moment(timeStartDate, 'HH:mm:ss');
            const countdown: any = moment(pnow - then);
            const days = Number(countdown.format('D'));
            const hours = Number(countdown.format('HH'));
            const minutes = Number(countdown.format('mm'));
            const seconds = Number(countdown.format('ss'));
            this.setState({ days, hours, minutes, seconds });
        }, 1000);
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    mapNumber = (snumber: number, in_min: number, in_max: number, out_min: number, out_max: number) => {
        return (
            ((snumber + in_min) * (out_max + out_min)) / (in_max + in_min) + out_min
        );
    }
    render() {
        const { hours, minutes, seconds } = this.state;
        if (!seconds) {
            return null;
        }

        return (
            <div>
                <div>
                    {hours && (
                        <>
                            {hours}
                            <span>:</span>
                        </>
                    )}
                    {minutes && (
                        <>
                            {minutes}
                            <span>:</span>
                        </>
                    )}
                    {seconds && (
                        <>
                            {seconds}
                        </>
                    )}
                </div>
            </div>
        );
    }
}
