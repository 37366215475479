import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import HeaderBar from '../../components/dashboard/header';
import { getNotificationRequest, showNotificationBadge } from '../../core/actionCreators/notificationActionCreator';
import { getActiveCases, getCaseInfo } from '../../core/actionCreators/caseActionCreator';
import { AppState } from '../../core/reducers/rootReducer';

const mapStateToProps = (state: AppState) => {
    return {
        role: state.login.userData.role,
        userData: state.login.userData,
        notifications: state.notifications,
        enableBadge: state.notifications.enableBadge,
        caseData: state.UpdateCaseReducer.caseData
    };
};

const mapDispachToProps = (dispatch: Dispatch) => {
    return {
        getNotificationRequest: (pathologistId: string) => dispatch(getNotificationRequest(pathologistId)),
        getActiveCases: (count: number) => dispatch(getActiveCases(count)),
        showNotificationBadge: (enable: boolean) => dispatch(showNotificationBadge(enable)),
        getCaseInfo: (accountID: any, caseId: string) => dispatch(getCaseInfo(accountID, caseId))
    };
};
export default connect(mapStateToProps, mapDispachToProps)(HeaderBar);