// eslint-disable-next-line import/no-cycle
// import CaseInfoState from '../models/CaseInfoData';

export const CASE_FILE_UPLOAD = 'caseActionType/CASE_FILE_UPLOAD';
export const CASE_FILE_UPLOAD_SUCCESS = 'caseActionType/CASE_FILE_UPLOAD_SUCCESS';

export interface caseFileUploadAction {
    type: typeof CASE_FILE_UPLOAD;
    caseId: string;
    siteId: string;
    fileName: string;
    fileData: any;
    fileExtension: string;
    accountId: any;
}

// export interface caseFileUploadSuccess {
//     type: typeof CASE_FILE_UPLOAD_SUCCESS;
//     fileData: any;
//     fileName: string;
//     fileExtension: string;
// }


export type ACTIONS =
    | caseFileUploadAction;
    // | caseFileUploadSuccess;
