const JSZip = require("jszip");
const saveAs = require('file-saver');
const JSZipUtils = require("jszip-utils");
let count = 0;
let zip = new JSZip();

export function createZipFilesAndDownload(dcmImageUrls: any, reportName: any, fileType: any) {
    console.log('inside createZipDownload');
    let extension = '.dcm';//chnage this back .dcm
    let uniqueId = '';
    if (fileType == "export")
        extension = '.jpg';
    if (dcmImageUrls.length > 0) {
        zip = new JSZip();
        dcmImageUrls.forEach(function (dataInfo: any, index: any) {
            reportName = reportName.replaceAll(':', '-');
            if (fileType == "images") {
                let link = document.createElement('a');
                link.href = 'data:image/jpg;base64,' + dataInfo;
                link.download = reportName + extension;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            else {
                let zipFolderName = reportName.split('_');
                zipFolderName.splice(zipFolderName.length - 4);
                let zipFolderNameFormated = zipFolderName.join('_');
                if (fileType != "images")
                    uniqueId = '_' + (index + 1);
                let formatedReportName = reportName + uniqueId + extension;
                JSZipUtils.getBinaryContent("data:image/jpeg;base64," + dataInfo, function (err: any, data: any) {
                    zip.file(formatedReportName, data, { binary: true });
                    if (err) {
                        throw err; // or handle the error
                    }
                    count = count + 1;
                    if (count == dcmImageUrls.length) {
                        zip.generateAsync({ type: 'base64' }).then(function (content: any) {
                            saveAs("data:application/zip;base64," + content, zipFolderNameFormated + ".zip");
                        });
                    }
                });
            }
        });
        count = 0;
    }
}