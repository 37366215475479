/* eslint-disable no-console */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { all, delay, fork, put, takeEvery } from 'redux-saga/effects';
import * as actionCreators from '../actionCreators/updateCaseHistoryActionCreator';
import * as actionTypes from '../actionTypes/updateCaseHistoryActionTypes';
// import updateCaseModel from '../models/updateCaseModel';
// import { decodeBlob } from '../protobuf/serialization/case';
import { encodeMessage } from '../protobuf/serialization/updateCaseHistorySerialize';
import { updateCaseHistory } from '../services/PUT/updateCaseHistoryService';
/* istanbul ignore next */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* updateHistory(action: any) {
  try {
    const caseId: any = action.postMessage.CaseId;
    const encodemessage: any = yield encodeMessage(action.postMessage);
    const response: Response = yield updateCaseHistory(encodemessage, caseId, action.accountID);
    const apiStatus: number = response.status;
    switch (apiStatus) {
      case 200:
        yield put(actionCreators.setUpdateCaseInfo(apiStatus, 'Caseupdatedsuccessfully'));
        // yield setTimeout(function*(){ yield put(actionCreators.setUpdateCaseInfo(apiStatus, ''));}, 3000)
        yield delay(3000);
        yield put(actionCreators.setUpdateCaseInfo(apiStatus, ''));
        break;
      case 400:
      case 401:
      case 500:
      case 403:
        yield put(actionCreators.getFailureMessage('Caseupdatefailed'));
        // yield delay(3000);
        yield delay(3000);
        yield put(actionCreators.getFailureMessage(''));

        break;
      default:
        break;
    }
  } catch (error) {
    console.log("History Page", error);
    yield put(actionCreators.getFailureMessage('Caseupdatefailed'));
    yield delay(3000);
    yield put(actionCreators.getFailureMessage(''));
  }

}

function* watchUpdateHistory() {
  yield takeEvery(actionTypes.POST_UPDATE_CASE, updateHistory);
}

export default function* PostUpdateCase() {
  yield all([fork(watchUpdateHistory)]);
}