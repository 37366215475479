export const onCanvasObjectMove = (evt: any) => {
  console.log('inside canvas method file');

  const actObj = evt.target;
  const padding = 2;
  // if object is too big ignore
  if (
    actObj.currentHeight > actObj.canvas.height - padding * 2 ||
    actObj.currentWidth > actObj.canvas.width - padding * 2
  ) {
    return;
  }
  actObj.setCoords();
  // top-left corner
  if (actObj.getBoundingRect().top < padding || actObj.getBoundingRect().left < padding) {
    actObj.top = Math.max(actObj.top, actObj.top - actObj.getBoundingRect().top + padding);
    actObj.left = Math.max(actObj.left, actObj.left - actObj.getBoundingRect().left + padding);
  }
  // bot-right corner
  if (
    actObj.getBoundingRect().top + actObj.getBoundingRect().height > actObj.canvas.height - padding ||
    actObj.getBoundingRect().left + actObj.getBoundingRect().width > actObj.canvas.width - padding
  ) {
    actObj.top = Math.min(
      actObj.top,
      actObj.canvas.height - actObj.getBoundingRect().height + actObj.top - actObj.getBoundingRect().top - padding,
    );
    actObj.left = Math.min(
      actObj.left,
      actObj.canvas.width - actObj.getBoundingRect().width + actObj.left - actObj.getBoundingRect().left - padding,
    );
  }
};

export const onCanvasMouseMove = (
  canvas: any,
  evt: any,
  mouseDown: any,
  start: any,
  end: any,
  canvasOffsetLeft: any,
  canvasOffsetTop: any,
  isLine: any,
) => {
  const pointer = canvas.getPointer(evt.e);
  const activeObject = canvas.getActiveObject();
  const context = canvas.getContext('2d');

  if (mouseDown && context) {
    start = {
      x: end.x,
      y: end.y,
    };
    end = {
      x: evt.pointer.x - canvasOffsetLeft,
      y: evt.pointer.y - canvasOffsetTop,
    };

    if (isLine) {
      activeObject.set({
        x2: pointer.x,
        y2: pointer.y,
      });
      activeObject.setCoords();
      canvas.renderAll();
    }
  }

  let returnObj = {
    start: start,
    end: end,
    canvas: canvas,
  };
  return returnObj;
};

export const onCanvasMouseUp = (panning: any, mouseDown: any, isLine: any, linePoints: any, evt: any, fabric: any, canvas: any) => {
  panning = false;
  mouseDown = false;
  console.log('mouse:up');
  if (isLine) {
    linePoints.endX = evt.pointer.x;
    linePoints.endY = evt.pointer.y;
    isLine = false;
    // need to check
    fabric.Object.prototype.selectable = true;
    canvas.fire('object:modified');
  }
  let returnObj = {
    panningVal : panning,
    mouseDownVal: mouseDown,
    isLineVal: isLine,
    linePointsVal : linePoints
  }

  return returnObj
};

//TODO : additional implementations are needed to get this method working from this file
export const onCanvasMouseDown = (
  mouseDown: any,
  start: any,
  end: any,
  canvasOffsetLeft: any,
  canvasOffsetTop: any,
  evt: any,
  isLine: any,
  panning: any,
  linePoints: any,
  lineName: any,
  objectName: any,
  fabric: any,
  propsData: any,
  canvas: any,
  isAddText: any,
  isSquare: any,
  isEmptySquare: any,
  isCircle: any,
  isPenActive: any,
) => {
  console.log('mouse:down');

  mouseDown = true;
  start = {
    x: evt.pointer.x - canvasOffsetLeft,
    y: evt.pointer.y - canvasOffsetTop,
  };
  end = {
    x: evt.pointer.x - canvasOffsetLeft,
    y: evt.pointer.y - canvasOffsetTop,
  };

  if (isLine) {
    panning = false;
    const points = [start.x, start.y, end.x, end.y];
    linePoints.startX = start.x;
    linePoints.startY = start.y;
    lineName = (objectName += 1).toString();
    const lineNew = new fabric.Line(points, {
      strokeWidth: Number(propsData.annotationSetting.activeThickLine),
      stroke: propsData.annotationSetting.activeColorLine,
      originX: 'center',
      originY: 'center',
      name: lineName,
      scaleX: 1,
      scaleY: 1,
      hasControls: true,
      selectable: true,
      visible: true,
    });
    canvas.add(lineNew);
    canvas.setActiveObject(lineNew);
    canvas.renderAll();
  }
  if (isAddText) {
    panning = false;
    const width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
    let startx = start.x;
    const diffWidth = width - startx;
    if (diffWidth <= 320) {
      startx -= 300;
    }
    const name = (objectName += 1).toString();
    let textporp = {
      left: startx,
      top: start.y,
      width: 300,
      fontSize: Number(propsData.annotationSetting.activeThickText),
      fontFamily: 'Arial',
      name,
      hasRotatingPoint: false,
      lockSkewingX: true,
      lockScalingY: true,
      fill: propsData.annotationSetting.activeColorText, // text color
      text: '',
      selectable: true,
      visible: true,
    };
    const textBox = new fabric.Textbox('', textporp);
    canvas.add(textBox).setActiveObject(textBox);
    textBox.enterEditing();
    let textVal: any = textBox.text;
    textporp.text = textVal;
    const originalRender = fabric.Textbox.prototype._render;
    fabric.Textbox.prototype._render = function (ctx:any) {
      console.log(this, ' this');

      originalRender.call(this, ctx);
      const widthBorder: any = this.width;
      const heightBorder: any = this.height;
      const startX: any = -widthBorder / 2;
      const startY: any = -heightBorder / 2;
      ctx.beginPath();
      ctx.moveTo(startX, startY);
      ctx.lineTo(startX + widthBorder, startY);
      ctx.lineTo(startX + widthBorder, startY + heightBorder);
      ctx.lineTo(startX, startY + heightBorder);
      ctx.lineTo(startX, startY);
      ctx.closePath();
      const stroke = ctx.strokeStyle;
      ctx.strokeStyle = propsData.annotationSetting.activeColorText;
      ctx.stroke();
      ctx.strokeStyle = stroke;
    };
    isAddText = false;
  } else if (isSquare || isEmptySquare || isCircle) {
    panning = true;
  } else if (isPenActive) {
    panning = false;
  } else {
    panning = true;
  }
};

export const updateTextSize = (e: any) => {
  let textBox: any = e.target;
  const controlPoint = textBox.__corner;
  let lastHeight = 0;
  if (controlPoint && controlPoint !== 'mr' && controlPoint !== 'ml') {
    lastHeight = textBox.height * textBox.scaleY;
  }
  const minHeight: number = textBox.lineHeight * textBox.fontSize * textBox.textLines.length + 5;
  if (lastHeight <= minHeight) lastHeight = minHeight;
  textBox.set({
    height: lastHeight || textBox.height,
    scaleY: 1,
  });
};
