import RequestMessage from '../../core/webscoket/RequestMessage';
import WebSocketManager from '../../core/webscoket/WebSocketManager';

export const sendDenoiseRequest = (
  isDenoiseFailed: boolean,
  props: any,
  stateSlider: any,
  caseId: any,
  deviceSiteId: any,
  accountId: any,
  isShortlistedForReport: any,
  qualityProbabilityFrom: any,
) => {
  isDenoiseFailed = isDenoiseFailed;
  console.log('I came inside send denoise req');

  //----------------- Sending denoise messaging -------------------------------------------------------------
  console.log('--sending denoise apply--');
  let denoiseMessage: RequestMessage = new RequestMessage();
  let updatedDataList =
    props.imageData[stateSlider.updateCount == 0 ? props.imageData.length - 1 : stateSlider.updateCount - 1];

  denoiseMessage.imageid = updatedDataList.imageid; 
  denoiseMessage.type = 'denoise'; 
  denoiseMessage.url = updatedDataList.url;

  WebSocketManager.getWebsocketManager().sendMessageForDenoise(denoiseMessage);
  denoiseMessage.resetData();

  const { setDeNoiseApplyFinish } = props;
  setDeNoiseApplyFinish(false);
  //----------------------------------------------------------------------------------------------------------
};


const KEY_DENOISE_LEVEL_LOW = 10
const KEY_DENOISE_LEVEL_MEDIUM = 11
const KEY_DENOISE_LEVEL_HIGH = 12
const KEY_DENOISE_ORIGINAL = 1

export const getDenoiseKeyByValue = (denoiseVal: string) => {
  let denoiseKey: number = 0;
  switch (denoiseVal) {
    case "7":
      denoiseKey = KEY_DENOISE_ORIGINAL;//load original autobrightness image if selected none
      break;
    case "1":
      denoiseKey = KEY_DENOISE_LEVEL_LOW;
      break;
    case "2":
      denoiseKey = KEY_DENOISE_LEVEL_MEDIUM;
      break;
    case "3":
      denoiseKey = KEY_DENOISE_LEVEL_HIGH;
      break;
    default:
      denoiseKey = KEY_DENOISE_ORIGINAL;//load original autobrightness image default
  }
  return denoiseKey;
}
