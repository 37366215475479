import CaseInfoData from "../../core/models/CaseInfoData";


export const initialExportModelData = {
    caseId: 'string',
    deviceSiteId: 'string',
    imageIds: [],
    dcmImageUrls: [],
    reportUrl: '',
    isAnnoatationEnabled: false,
    accountId: '',
    seriesId: ''
  }

export  const cases: CaseInfoData[] = [
    {
      firstName: '',
      lastName: '',
      patientId: '',
      genderValue: '',
      dob: '',
      surgeonFirstName: '',
      surgeonLastName: '',
      surgeonEmail: '',
      caseId: '',
      imageId: '',
    },
  ];

export let propsData = {
    hasSpot: false,
    IsShortlistedForReport: false,
    annotationSetting: {
      activeColor: '#00A6FB',
      activeColorLine: '#00A6FB',
      activeColorText: '#00A6FB',
      activeThickPen: "5",
      activeThickLine: "5",
      activeThickText: "25",
      applyFilterToAll: false,
      applyImgFilterToAll: false,
      colorMapFilterType: "grayscale",
      denoiseValue: "None",
      brightnessFilterVal: 0,
      contrastFilterVal: 0,
      isInvert: false,
      isAutoBrightness: false,
      isHideAnnotation: false,
      isColorMap: false,
      isDenoiseApply: false,
      isDenoiseOnLoadDownloaded: false,
      isAnnotatedImageAutoBrightnessChanged: false,
      isManualyAB: false
  
    },
    isAutoBrightnessReloadImage: false,
    annotationList: [],
    totalImageFiles: 0,
    isRender: true,
    spotDetails: {
      label: null,
      color: "",
      comment: "",
      location: "",
      side: "",
      tissueType: "",
    },
    imagemeta: {
      resolution: "",
      focus: 0,
      gain: 0,
      laserpower: 0,
      imagetype: 0
    },
    sliderPlayTime: 0,
    getOriginalAnnotation: '',
    saveAnnotation: false,
    footerSliderData: {
      seriesCount: "",
      rangeMaxVal: 0,
      rangeCurrentVal: 0,
    },
    isLoaded: false,
    isCanvasLoaded: true,
    checkedExportProps: new Map,
  }