/* eslint-disable no-console */
/* eslint-disable vars-on-top */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* istanbul ignore file */

const Schema = require('../../protofile/convivo_pb');

const decodeBlob = async (data: ArrayBuffer): Promise<any[]> => {
  try {
    // const messageData: any[] = [];
    // const commentList: any[] = [];
    const byteArray: Uint8Array = new Uint8Array(data);
    const message = Schema.SavedImagesForCase.deserializeBinary(byteArray);
    console.log('----message----', message);

    const getImageObject = message.toObject();
    console.log('----getImageObject----', getImageObject);

    // const getImageObjectList = message.getImage();
    // console.log('----getImageObjectList----', getImageObjectList);

    // // console.log('----getImageObjectList----', getImageObjectList);
    // messageData.push(getImageObjectList);
    // const getOverLayObjectlist = message.getOverlay() ? message.getOverlay().toObject() : [];
    // // console.log('----getOverLayObjectlist----', getOverLayObjectlist);
    // messageData.push(getOverLayObjectlist);
    // const getComentlist = message.getCommentsList();
    // // console.log('----getComentlist----', getComentlist);
    // for (const comment of getComentlist) {
    //   commentList.push(comment.toObject());
    // }
    // messageData.push(commentList);
    // console.log("--Final Message Data---", messageData)

    return getImageObject;
  } catch (error) {
    console.log('----Annotation DecodeBlob Error----', error);
    throw error;
  }
};
export { decodeBlob };

