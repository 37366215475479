
import * as React from 'react'
import { useIntl } from 'react-intl';
import { Form } from 'react-bootstrap';

interface Props {
    errormessageid: string;
}


function FormElementErrorMessage(props: Props) {
    
    const intl = useIntl();
    const showmessage = (message: string) => {
        return intl.formatMessage({ id: message })
    }
    return props.errormessageid ? (
        <Form.Label column sm="12" className="errorMsg">
            {showmessage(props.errormessageid)}
        </Form.Label>
    ) : (<></>)
}

export default FormElementErrorMessage;