/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as actions from '../../actionTypes/admin/SiteActionTypes';
import SiteData from '../../models/admin/siteData';

/**
 * Initial site dummy data
 */
const initialSite: SiteData = {
  id: '',
  name: '',
  phoneNumber: '',
  description: '',
  accountId: '',
  accountName: '',
  isDefaultSite: false,
  siteStatus: 0,
};

/**
 * Intial data
 */
const initialData: SiteData = initialSite;

/**
 * Interface site data
 */
export interface SiteState {
  site: SiteData;
  message: string;
  isSuccess: boolean;
  errorMsg: string;
  accErrorMsg: string;
  createSiteErrorMsg: string;
  editSiteErrorMsg: string;
  editSiteAdminErrorMsg: string;
  associateSpinner: boolean;
  mangeAccountSpinner: boolean;
  manageCreateSiteSpinner: boolean;
  manageEditSiteSpinner: boolean;
}

/**
 * Initial site state
 */
const initialState: SiteState = {
  site: initialData,
  message: '',
  isSuccess: false,
  errorMsg: '',
  accErrorMsg: '',
  createSiteErrorMsg: '',
  editSiteErrorMsg: '',
  editSiteAdminErrorMsg: '',
  associateSpinner: false,
  mangeAccountSpinner: false,
  manageCreateSiteSpinner: false,
  manageEditSiteSpinner: false,
};

const siteReducer = (state = initialState, action: actions.ACTIONS) => {
  switch (action.type) {
    case actions.CREATE_SITE:
      return {
        ...state,
        siteData: action.siteData,
        createSiteErrorMsg: '',
        manageCreateSiteSpinner: true,
      };

    case actions.UPDATE_SITE:
      return {
        ...state,
        siteData: action.siteData,
        editSiteErrorMsg: '',
        manageEditSiteSpinner: true,
      };
    case actions.GET_SITE:
      return {
        ...state,
        siteId: action.siteId,
        editSiteErrorMsg: '',
        manageEditSiteSpinner: false,
      };
    case actions.SAVE_SITE_RESPONSE:
      return {
        ...state,
        message: action.message,
        isSuccess: action.isSuccess,
      };
    case actions.CREATE_ASSOCIATIONS:
      return {
        ...state,
        siteData: action.siteData,
        errorMsg: "",
        associateSpinner: true,
      };

    case actions.DELETE_MEMBER:
      return {
        ...state,
        deleteData: action.deleteData,
      };
    case actions.UPDATE_SITE_ASSOCATIONS:
      return {
        ...state,
        updateAssociationData: action.updateAssociationData,
      };

    case actions.CREATE_ASSOCIATIONS_FAIL:
      return {
        ...state,
        errorMsg: action.errorMsg,
        associateSpinner: false,
      };
    case actions.CREATE_ASSOCIATIONS_SUCESS:
      return {
        ...state,
        errorMsg: "",
        associateSpinner: false,
      };
    case actions.CLEAR_ASSOCIATION_STATUS_MESSAGE:
      return{
        ...state,
        errorMsg: "",
        associateSpinner: false,
      };
    case actions.MANAGE_ADD_SITE_FAIL:
      return {
        ...state,
        createSiteErrorMsg: action.createSiteErrorMsg,
        manageCreateSiteSpinner: false,
      };
    case actions.MANAGE_ADD_SITE_SUCESS:
      return {
        ...state,
        createSiteErrorMsg: action.createSiteErrorMsg,
        manageCreateSiteSpinner: false,
      };
    case actions.CLEAR_ADD_SITE_MESSAGE:
      return {
        ...state,
        createSiteErrorMsg: '',
        manageCreateSiteSpinner: false,
      };
    case actions.CLEAR_EDIT_SITE_MESSAGE:
      return {
        ...state,
        editSiteErrorMsg: '',
        manageEditSiteSpinner: false,
      };
    case actions.MANAGE_EDIT_SITE_FAIL:
      return {
        ...state,
        editSiteErrorMsg: action.editSiteErrorMsg,
        manageEditSiteSpinner: false,
      };
    case actions.MANAGE_EDIT_SITE_SUCESS:
      return {
        ...state,
        editSiteErrorMsg: action.editSiteErrorMsg,
        manageEditSiteSpinner: false,
      };

    case actions.MANAGE_EDIT_SITE_ADMIN_USER:
      return {
        ...state,
        editSiteErrorMsg: action.editSiteAdminErrorMsg,
        manageEditSiteSpinner: false,
      };


    case actions.MANGE_ACCOUNT_SUCESS:
      return {
        ...state,
        accErrorMsg: action.accErrorMsg,
        mangeAccountSpinner: false,
      };
    case actions.MANGE_ACCOUNT_FAIL:
      return {
        ...state,
        accErrorMsg: action.accErrorMsg,
        mangeAccountSpinner: false,
      };
    case actions.MANGE_ACCOUNT:
      return {
        ...state,
        addAdminRequestData: action.addAdminRequestData,
        accErrorMsg: "",
        mangeAccountSpinner: true
      };
    case actions.UPDATE_DEFAULT_SITE_ASSOCATIONS:
      return {
        ...state,
        UpdateDefaultSiteAssociationData: action.UpdateDefaultSiteAssociationData,
      };
    default:
      return state;
  }
};

export default siteReducer;
