/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/label-has-associated-control */
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icons, ViewerControl } from '../../../constants/viewControl';
import { Sex } from '../../../core/models/Case/Sex';
interface Props {
  currentCase: any;
  caseData?: any;
}

export class PatientInfo extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  displayGender = () => {
    const { caseData } = this.props;
    if (caseData) {
      let genderVal = parseInt(caseData.patient.sex.toString());
      switch (genderVal) {
        // case Sex.UNKNOWN:
        //   return  <ViewerControl icon="gender-unknown" />
        case Sex.FEMALE:
          return <img alt="female" src={Icons.femaleGenderIcon} />
        case Sex.MALE:
          return <img alt="male" src={Icons.maleGenderIcon} />
        case Sex.OTHER:
          return <ViewerControl icon="gender-other" />
        default:
          console.log("no gender")
      }
    }
  }
  render() {
    const { currentCase, caseData } = this.props;
    let patientAge: any = caseData && caseData.patient.age ? caseData.patient.age : undefined;
    if (patientAge && patientAge == -1) {
      patientAge = '>90';
    }
    let sideText = currentCase.brainside;
    let tissueType = currentCase.tissuetype;
    if (sideText && sideText.charAt(0) == '#') {
      sideText = sideText.slice(1)
    }
    if (tissueType && tissueType.charAt(0) == '#') {
      tissueType = tissueType.slice(1)
    }
    return (
      <>
        <div className="patient">
          <div className="patient-name">
            <label className="head"><FormattedMessage id="PatientInformation" /></label>
            <p>
              {caseData?.patient?.firstname || caseData?.patient?.lastname ? <>{caseData?.patient?.firstname}  {caseData?.patient?.lastname}</> : caseData?.patient?.patientid?.patientid}
            </p>
            <div className="patient-details">
              <p>
                <span>
                  {this.displayGender()}
                </span>{''}
                {/* {caseData?.patient?.dateofbirth && caseData?.patient?.sex != '0' ? `| ${caseData && moment(caseData?.patient?.dateofbirth).format('DD. MMM. YYYY')}${patientAge ? ` (${patientAge})` : ''}` : `${patientAge && caseData?.patient?.sex != '0' ? `| (${patientAge})` : patientAge ? `(${patientAge})` : ''}`}
                {(caseData && caseData.patient?.firstname || caseData && caseData.patient?.lastname) ?
                  `${caseData?.patient?.sex != '0' || (caseData && caseData.patient?.dateofbirth || patientAge) ? ` | ${caseData?.patient?.patientid?.patientid}` : `${caseData?.patient?.patientid?.patientid}`}` : ''} */}
                {caseData && caseData.patient?.dateofbirth ? `${caseData.patient?.sex !== '0' ? `| ${caseData && moment(caseData.patient.dateofbirth).format('DD. MMM. YYYY')}${patientAge && ` (${patientAge})`}` : `${caseData && moment(caseData.patient.dateofbirth).format('DD. MMM. YYYY')}${patientAge && ` (${patientAge})`}`}`
                  : `${patientAge && caseData?.patient?.sex !== '0' ? `| (${patientAge})` : patientAge ? `(${patientAge})` : ''}`}
                {(caseData?.patient?.firstname || caseData?.patient?.lastname) ?
                  `${caseData?.patient?.sex !== '0' || (caseData && (caseData.patient?.dateofbirth || patientAge)) ? ` | ${caseData?.patient?.patientid?.patientid}` : `${caseData?.patient?.patientid?.patientid}`}` : ''}
                {/* {caseData && caseData.patient.dateOfBirth && currentCase.genderValue !== '0' ?`| ${caseData.patient.dateOfBirth}${patientAge?`(${patientAge})`:''}`:''}
                               {currentCase.firstName || currentCase.lastName?
                               `${currentCase.genderValue !== '0' && (caseData && caseData.patient.dateOfBirth || patientAge)?` | ${currentCase.patientId}`: `${currentCase.patientId}`}`:'' } */}
                {/* {currentCase.genderValue=='0' && !caseData.patient.dateOfBirth ? '':<span className="separator">|</span>} {caseData.patient.dateOfBirth} {caseData.patient.age ? ` (${caseData.patient.age}) ` : '' }{' '}
                {currentCase.firstName || currentCase.lastName ? currentCase.genderValue=='0' || !caseData.patient.dateOfBirth ? <><span>{currentCase.patientId}</span></>:<><i className="separator" /> <span>{currentCase.patientId}</span></> : ''} */}
              </p>
            </div>
          </div>
          <div className="patient-name">
            <label className="head"><FormattedMessage id="MedicalInformation" /></label>
            <p>{currentCase.tissuelocation}{sideText ? currentCase.tissuelocation ? `, ${sideText}` : `${sideText}` : ''}</p>
            <div className="patient-details">
              <p className="third"><FormattedMessage id="TumorLocation" /></p>
            </div>
          </div>
          <div className="patient-name">
            <label>&nbsp;</label>
            <p>{tissueType} </p>
            <div className="patient-details">
              <p className="third"><FormattedMessage id="PreoperativeDiagnosis" /></p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PatientInfo;
