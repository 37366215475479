/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/prefer-default-export */
import * as actions from '../actionTypes/caseActionTypes';
import Case from '../models/Case';
import CaseInfoState from '../models/CaseInfoData';

export function setCaseInfo(casedata: CaseInfoState): actions.SetCaseAction {
  return {
    type: actions.SET_CASE,
    casedata,
  };
}

export function setCompleteCaseAction(casedata: Case): actions.SetCompleteCaseAction {
  return {
    type: actions.SET_COMPLETE_CASE,
    casedata,
  };
}
export function getCaseInfo(accountId: string, caseId: string): actions.GetCaseAction {
  return {
    type: actions.GET_CASE,
    caseId,
    accountId
  };
}

export function getCaseSuccess(): actions.GetCaseSuccess {
  return {
    type: actions.GET_CASE_SUCCESS,
    fetchCaseMessage: "",
    fetchCaseSpinner: false,
  };
}

export function getCaseFail(fetchCaseMessage?: any, fetchCaseSpinner?: any): actions.GetCaseFail {
  return {
    type: actions.GET_CASE_FAILURE,
    fetchCaseMessage,
    fetchCaseSpinner,
  };
}

export function getCaseClear(): actions.GetCaseClear {
  return {
    type: actions.GET_CASE_CLEAR,
    fetchCaseMessage: '',
  };
}

export function getActiveCases(pageNumber?: number) {
  return {
    type: actions.GET_ACTIVE_CASES,
    pageNumber,
  };
}

export function getActiveCasesFail() {
  return {
    type: actions.GET_ACTIVE_CASES_FAIL,
  };
}

export function setCasesListData(casedata: any[], activeCaseTotalRec: number, currentpage: number, continuationToken: string) {
  return {
    type: actions.SET_ACTIVE_CASES_LIST,
    casedata,
    activeCaseTotalRec,
    currentpage,
    continuationToken
  };
}

export function getHistoryCaseList(pageCount: number, isReportHistory?: boolean, continuationToken?: any): actions.GetHistoryCases {
  return {
    type: actions.GET_HISTORY_CASES,
    pageCount,
    isReportHistory,
    continuationToken
  };
}

export function getHistoryCaseListFail() {
  return {
    type: actions.GET_HISTORY_CASES_FAIL,
  };
}
export function SetHistoryCaseData(historydata: any[], historyCaseTotalRec: number, continuationToken: any) {
  return {
    type: actions.SET_HISTORY_CASES_LIST,
    historydata,
    historyCaseTotalRec,
    continuationToken
  };
}
export function clearHistoryData() {
  return {
    type: actions.CLEAR_HISTORY_ON_MOUNT
  }
}
export function getMenuDataList() {
  return {
    type: actions.GET_MENU_DATALIST,
  };
}

export function setMenuDataList(menudata: any) {
  return {
    type: actions.SET_MENU_DATALIST,
    menudata,
  };
}
