/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import * as actionCreators from '../actionCreators/admin/getSitesActionCreator';
import * as actionTypes from '../actionTypes/admin/getSiteListActionTypes';
import SiteInfo from '../models/SiteInfo';
import { decodeSitesBuffer } from '../protobuf/deserialization/siteListDeserialize';
import { fetchSiteList } from '../services/GET/getSitesListService';

/* istanbul ignore next */
function* siteListAsync(action: any) {
  try {
    const response: Response = yield fetchSiteList(action.accountID);
    const apiStatus: number = response.status;
    switch (apiStatus) {
      case 200:
        const data: ArrayBuffer = yield response.arrayBuffer();
        const decodedData: SiteInfo[] = yield decodeSitesBuffer(data);
        yield put(actionCreators.setSiteList(decodedData));
        break;
      case 401:
      case 500:
      case 403:
        yield put(actionCreators.getSiteListFail());
        break;
      default:
        yield put(actionCreators.getSiteListFail());
        break;
    }
  } catch (error) {
    console.log('get site Api ', error);
    yield put(actionCreators.getSiteListFail());
  }
}

export function* watchPageEntries() {
  yield takeEvery(actionTypes.GET_SITE_LIST, siteListAsync);
}

export default function* GetSiteListSaga() {
  yield all([fork(watchPageEntries)]);
}
