export const GET_ACTION_USER_DETAIL = 'userActionType/GET_ACTION_DETAIL';
export const GET_ACTION_USER_DETAIL_SUCCESS = 'userActionType/GET_ACTION_DETAIL_SUCCESS';
export const UPDATE_ACTION_USER_DETAIL_SUCCESS = 'userActionType/UPDATE_ACTION_USER_DETAIL_SUCCESS';
export const UPDATE_USER_DETAIL_ACTION_COMPLETE='userActionType/UPDATE_USER_DETAIL_ACTION_COMPLETE'

export interface GetUserDetailAction {
    type: typeof GET_ACTION_USER_DETAIL;
    email: string
}

export interface GetUserDetailActionSuccess {
    type: typeof GET_ACTION_USER_DETAIL_SUCCESS;
    userDetails: any;
}

export interface UpdateActionUserDetailSuccess {
    type: typeof UPDATE_ACTION_USER_DETAIL_SUCCESS;
    userId: string;
    userDetails: any;
    communication:any;
}

export interface UpdateUserDetailsCompleteAction{
    type: typeof UPDATE_USER_DETAIL_ACTION_COMPLETE;
    updateSettingsSpinner:boolean;
}
export type ACTIONS = GetUserDetailAction | GetUserDetailActionSuccess | UpdateActionUserDetailSuccess|UpdateUserDetailsCompleteAction;