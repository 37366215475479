/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { env } from '../../../environments/environment';
/* istanbul ignore next */
const fetchSiteAssocaitesList = async (accountId: any, siteId: any) => {
  // @todo - need to add new end point once it is available
  const url = `${env.getSiteUsersListEndPoint}/${accountId}/sites/${siteId}/associations`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/protobuf' },
  });
  return response;
};
export { fetchSiteAssocaitesList };

