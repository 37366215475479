/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import SwitchButton from 'bootstrap-switch-button-react';
import $ from 'jquery';
import * as React from 'react';
import { Form, Popover, Tab, Tabs } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import AppUtils from '../../../core/utils/AppUtils';
import { Spinner } from '../../common/loader/spinner';

interface Props {
  handleColorMap(key: any): any;
  // filterCheck(key: any): void;
  // imgFilterCheck(key: any): void;
  invertSwitchToggle(key: any): any;
  applyFinished: boolean;
  denoiseApplyFinish: boolean;
  autoContrastApplyFinish: boolean;
  applyDenoiseFilter(key: any): any;
  applyBrightnessFilter(key: any): void;
  applyContrastFilter(key: any): void;
  applyAutoBrightnessFilter(key: any, autoContrastVal: any): void;
  annotationSetting: any;
  showLoader:boolean
}

interface State {
  denoiseValue: any;
  brightValue: any;
  contrastValue: any;
  denoiseSwitch: boolean;
  invertSwitch: boolean;
  brightSwitch: boolean;
  radioChecked: any;
  denoiseradioCheked: any;
  // filterCheck: boolean;
  // imgFilterCheck: boolean;
}

export class ImageEditFilter extends React.Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props);
    let brightVal =
      this.props.annotationSetting.brightnessFilterVal === undefined
        ? 0
        : this.props.annotationSetting.brightnessFilterVal;
    let contrastVal =
      this.props.annotationSetting.contrastFilterVal === undefined ? 0 : this.props.annotationSetting.contrastFilterVal;
    console.log(
      '[this.props.annotationSetting.denoiseValue] -->',
      [this.props.annotationSetting.denoiseValue],
      'this.props.annotationSetting.denoiseValue -->',
      this.props.annotationSetting.denoiseValue,
    );
    this.state = {
      denoiseValue: [this.props.annotationSetting.denoiseValue],
      brightValue: [brightVal],
      contrastValue: [contrastVal],
      denoiseSwitch: false,
      invertSwitch: this.props.annotationSetting.isInvert,
      brightSwitch: this.props.annotationSetting.isAutoBrightness,
      radioChecked: this.props.annotationSetting.colorMapFilterType,
      denoiseradioCheked: this.getDenoiseName(this.props.annotationSetting.denoiseValue), //'None',
      // filterCheck: false,
      // imgFilterCheck: false,
    };
  }

  componentDidMount() {
    $("[type='number']").keypress(function (evt) {
      evt.preventDefault();
    });
  }

  /* denoise items */

  handleDenoiseValue = (e: any) => {
    this.setState({ denoiseValue: [e.target.value] });
  };

  handleDenoiseBar = (denoiseValue: any) => {
    this.setState({ denoiseValue });
  };

    /* brightness items */
    handleBrightThrottle = AppUtils.throttle((ev:any) => this.handleBrightBar(ev),200)

  handleBrightValue = (e: any) => {
    // this.setState({ brightValue: [e.currentTarget.value] });
    this.setState({ brightValue: [e] });
  };

  handleBrightBar = (ev: any) => {
    //this.setState({ brightValue });
    this.handleBrightValue(ev);
    // this.setState({ brightValue: ev.currentTarget.value });
    // this.props.applyBrightnessFilter([ev.currentTarget.value]);
    // if (ev.currentTarget.value !== 0) {
    //   this.setState({ brightSwitch: false });
    // }
    this.setState({ brightValue: ev });
    this.props.applyBrightnessFilter([ev]);
    if (ev !== 0) {
      this.setState({ brightSwitch: false });
    }
  };

  /* contrast items */
handleContrastThrottle = AppUtils.throttle((ev:any) => this.handleContrastBar(ev),200)

  handleContrastValue = (e: any) => {
    // console.log(">>>>>>>>>>>>>>", e.currentTarget.value);
    console.log('>>>>>>>>>>>>>>', e);

    // this.setState({ contrastValue: [e.currentTarget.value] });
    this.setState({ contrastValue: [e] });
  };

  handleContrastBar = (ev: any) => {
    //this.setState({ contrastValue });
    this.handleContrastValue(ev);
    // console.log(">>>>>>>>>>>>>>", ev.currentTarget.value);
    // this.setState({ contrastValue: ev.currentTarget.value });
    // this.props.applyContrastFilter([ev.currentTarget.value]);
    // if (ev.currentTarget.value !== 0) {
    //   this.setState({ brightSwitch: false });
    // }
    console.log('>>>>>>>>>>>>>>', ev);
    this.setState({ contrastValue: ev });
    this.props.applyContrastFilter([ev]);
    if (ev !== 0) {
      this.setState({ brightSwitch: false });
    }
  };

  /* ................ */

  denoiseSwitchToggle = (denoiseSwitch: any) => {
    this.setState({ denoiseSwitch });
  };

  invertSwitchToggle = (invertSwitch: any) => {
    this.setState({ invertSwitch: invertSwitch }, this.props.invertSwitchToggle(invertSwitch));
    // if (invertSwitch) {
    //   this.setState({ brightSwitch: false });
    // }
  };

  applyAutoBrightnessFilter = (brightSwitch: any) => {
    this.setState({ brightSwitch });
    this.props.applyAutoBrightnessFilter(brightSwitch, this.props.annotationSetting.contrastFilterVal);
    console.log('event', brightSwitch);
    console.log('applyAutoBrightness annotationSetting>', this.props.annotationSetting);
    if (brightSwitch) {
      let contrastValue = this.props.annotationSetting.contrastFilterVal;
      let brightness = this.props.annotationSetting.brightnessFilterVal;
      this.setState({ contrastValue: [contrastValue], brightValue: [brightness] });
      let applyAutoBrightnessValContrast: any = document.querySelector('#applyAutoBrightnessValContrast');
      let applyAutoBrightnessValBright: any = document.querySelector('#applyAutoBrightnessValBright');
      applyAutoBrightnessValContrast.value = parseInt(this.props.annotationSetting.contrastFilterVal);
      applyAutoBrightnessValBright.value = parseInt(this.props.annotationSetting.brightnessFilterVal);
    }
  };

  handleColorMap = (e: any) => {
    this.setState({ radioChecked: e.target.value }, this.props.handleColorMap(e.target.value));
    console.log(`value radio  ${e.target.value}`);
    console.log('annotationSetting>', this.props.annotationSetting);
    // if (e.target.value == 'grayscale' || e.target.value == 'fire' || e.target.value == 'rainbow' || e.target.value == 'fireblue' || e.target.value == 'redhot' || e.target.value == 'unionjack') {
    //   this.setState({ contrastValue: [0], brightValue: [0], invertSwitch: false, brightSwitch: true });
    // }
    // if (this.state.denoiseradioCheked != 'None') {
    //   this.setState({ denoiseradioCheked: "None" });
    // }
  };

  handleDenoiseIntensity = (e: any) => {
    if (e.target.value === 'None') {
      this.setState({ denoiseradioCheked: e.target.value }, this.props.applyDenoiseFilter('7'));
    } else if (e.target.value === 'Low') {
      this.setState({ denoiseradioCheked: e.target.value }, this.props.applyDenoiseFilter('1'));
    } else if (e.target.value === 'Medium') {
      this.setState({ denoiseradioCheked: e.target.value }, this.props.applyDenoiseFilter('2'));
    } else if (e.target.value === 'High') {
      this.setState({ denoiseradioCheked: e.target.value }, this.props.applyDenoiseFilter('3'));
    }
    // if (e.target.value == 'Low' || e.target.value == 'Medium' || e.target.value == 'High') {
    // this.setState({ brightSwitch: true });
    // }
    // if (this.state.radioChecked != 'grayscale') {
    //   this.setState({ radioChecked: "grayscale" });
    // }
  };

  // filterCheck = (e: any) => {
  //   this.props.filterCheck(e.target.checked);
  //   this.setState({ filterCheck: e.target.checked });
  // };

  // imgFilterCheck = (e: any) => {
  //   this.props.imgFilterCheck(e.target.checked);
  //   this.setState({ imgFilterCheck: e.target.checked });
  // };

  applyBrightnessFilter = () => {
    this.props.applyBrightnessFilter(this.state.brightValue);
    console.log('applyBrightnessFilter annotationSetting>', this.props.annotationSetting);
  };
  applyContrastFilter = () => {
    this.props.applyContrastFilter(this.state.contrastValue);
    console.log('applyContrastFilter annotationSetting>', this.props.annotationSetting);
  };
  getDenoiseName = (denoiseVal: any) => {
    let denoiseKey: string = 'None';
    switch (denoiseVal) {
      case '7':
        denoiseKey = 'None'; //load greyscale image
        break;
      case '1':
        denoiseKey = 'Low';
        break;
      case '2':
        denoiseKey = 'Medium';
        break;
      case '3':
        denoiseKey = 'High';
        break;
      default:
        denoiseKey = 'None'; //load greyscale image
    }
    return denoiseKey;
  };
  render() {
    return (
      <Popover id="popover-filter-edit" {...this.props}>
        <Popover.Content>
          <div className="filter-main-block" style={
            this.props.showLoader 
              ? {pointerEvents: 'none', opacity:0.4}
              : {pointerEvents: 'all'}
          }>
            <Tabs defaultActiveKey="imgEdit" id="img-filter">
              <Tab eventKey="imgEdit" title={<FormattedMessage id="imageEditing" />}>
                <div className="img-edit-main">
                  <div className="settings-main">
                    <div className="settings-list" style={{ marginBottom: '12px' }}>
                      <div className="left">
                        <p>
                          {' '}
                          <FormattedMessage id="autoBrightness" />
                        </p>
                      </div>
                      <div className="right">
                        <div className="switch-toggle">
                          <SwitchButton
                            checked={this.state.brightSwitch}
                            size="xs"
                            onChange={this.applyAutoBrightnessFilter.bind(this)}
                          />
                        </div>
                        {/* {this.state.brightSwitch && !this.props.autoContrastApplyFinish ? (<div>
                          <Spinner />
                        </div>) : null} */}
                      </div>
                    </div>
                    <div className="settings-list">
                      <div className="left item">
                        <p>
                          <FormattedMessage id="brightness" />
                        </p>
                      </div>
                      <div className="right">
                        <div className="range-bar-denoise">
                          <input
                            onChange={(e) => this.handleBrightThrottle(e.currentTarget.value)}
                            type="range"
                            min="-100"
                            defaultValue={this.state.brightValue}
                            max="100"
                            step="1"
                            id="applyAutoBrightnessValBright"
                            className="customSlider"
                          />
                          <div className="form-group">
                            <Form.Control
                              disabled
                              name="brightValue"
                              type="number"
                              min="-100"
                              max="100"
                              value={this.state.brightValue}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="settings-list">
                      <div className="left item">
                        <p>
                          <FormattedMessage id="contrast" />
                        </p>
                      </div>
                      <div className="right">
                        <div className="range-bar-denoise">
                          <input
                            onChange={(e) => this.handleContrastThrottle(e.currentTarget.value)}
                            type="range"
                            min="0"
                            defaultValue={this.state.contrastValue}
                            max="100"
                            step="1"
                            id="applyAutoBrightnessValContrast"
                            className="customSlider"
                          />
                          <div className="form-group">
                            <Form.Control
                              disabled
                              name="contrastValue"
                              type="number"
                              min="0"
                              max="100"
                              value={this.state.contrastValue}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="settings-list">
                      <div className="check">
                        <label className="checkbox-item">
                          Apply to all frames of this series
                          <input type="checkbox" disabled
                            checked={this.state.imgFilterCheck}
                            onChange={this.imgFilterCheck}
                            className="default-request" />
                          <span className="checkbox" style={{opacity:'0.3'}} />
                        </label>
                      </div>
                    </div> */}
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="imgFilter"
                title={<FormattedMessage id="filter" />}
                onClick={() => {
                  $("[type='number']").keypress(function (evt) {
                    evt.preventDefault();
                  });
                }}>
                <div className="img-edit-main">
                  <div className="settings-main">
                    <div className="settings-list">
                      <div className="left">
                        <p>
                          {' '}
                          <FormattedMessage id="denoise" />
                        </p>
                      </div>
                      <div className="right">
                        {!this.props.denoiseApplyFinish && (
                          <div>
                            <Spinner />
                          </div>
                        )}
                        {this.props.denoiseApplyFinish && (
                          <div className="main-block">
                            <div className="radio-item-list">
                              <div className="radio-item">
                                <label className="label">
                                  <input
                                    type="radio"
                                    defaultChecked
                                    name="denoiseintensity"
                                    value="None"
                                    checked={this.state.denoiseradioCheked === 'None'}
                                    onChange={this.handleDenoiseIntensity}
                                  />
                                  <FormattedMessage id="none" />
                                  <span className="radio" />
                                </label>
                              </div>
                              <div className="radio-item">
                                <label className="label">
                                  <input
                                    type="radio"
                                    name="denoiseintensity"
                                    value="Low"
                                    checked={this.state.denoiseradioCheked === 'Low'}
                                    onChange={this.handleDenoiseIntensity}
                                  />
                                  <FormattedMessage id="Low" />
                                  <span className="radio" />
                                </label>
                              </div>
                            </div>
                            <div className="radio-item-list">
                              <div className="radio-item">
                                <label className="label">
                                  <input
                                    type="radio"
                                    name="denoiseintensity"
                                    value="Medium"
                                    checked={this.state.denoiseradioCheked === 'Medium'}
                                    onChange={this.handleDenoiseIntensity}
                                  />
                                  <p>
                                    <FormattedMessage id="Medium" />
                                  </p>
                                  <span className="radio" />
                                </label>
                              </div>
                              <div className="radio-item">
                                <label className="label">
                                  <input
                                    type="radio"
                                    name="denoiseintensity"
                                    value="High"
                                    checked={this.state.denoiseradioCheked === 'High'}
                                    onChange={this.handleDenoiseIntensity}
                                  />
                                  <p>
                                    <FormattedMessage id="High" />
                                  </p>
                                  <span className="radio" />
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* <div className="right">
                        <div className="switch-toggle">
                          <SwitchButton
                            checked={this.state.denoiseSwitch}
                            size="xs"
                            onChange={this.denoiseSwitchToggle}
                          />
                        </div>

                        {this.state.denoiseSwitch ? (
                              <>
                              <div className="range-bar-denoise">
                              <Range
                                values={this.state.denoiseValue}
                                step={1}
                                min={1}
                                max={5}
                                onChange={this.handleDenoiseBar}
                                renderTrack={({ props, children }) => (
                                  <div
                                    onMouseDown={props.onMouseDown}
                                    onTouchStart={props.onTouchStart}
                                    style={{
                                      ...props.style,
                                      cursor: 'pointer !important',
                                      height: '36px',
                                      display: 'flex',
                                      width: '100%',
                                    }}>
                                    <div
                                      ref={props.ref}
                                      style={{
                                        height: '2px',
                                        width: '100%',
                                        borderRadius: '3px',
                                        background: getTrackBackground({
                                          values: this.state.denoiseValue,
                                          colors: ['#D1D9E5', '#07090D'],
                                          min:1,
                                          max: 5,
                                        }),
                                        alignSelf: 'center',
                                      }}>
                                      {children}
                                    </div>
                                  </div>
                                )}
                                renderThumb={({ props, isDragged }) => (
                                  <div
                                    {...props}
                                    style={{
                                      ...props.style,
                                      height: '8px',
                                      width: '8px',
                                      borderRadius: '3px',
                                      cursor: 'pointer !important',
                                      backgroundColor: '#555E6B',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  />
                                )}
                              />
                              <div className="form-group">
                                <Form.Control
                                  name="denoiseValue"
                                  value={this.state.denoiseValue}
                                  onChange={this.handleDenoiseValue}
                                  type="number"
                                  placeholder=""
                                  min="1"
                                  max="5"
                                />
                              </div>
                            </div>
    
                            <div className="btn">
                              <button type="button" onClick={this.resetDenoise} className="reset btn btn-outline-secondary">
                                Reset
                              </button>
                              <button type="button"  onClick={(val) => this.applyDenoiseFilter(this.state.denoiseValue)}    className="apply btn btn-outline-secondary">
                                Apply
                              </button>
                            </div>
                            </>
                        ): ( "" )}
                      </div> */}
                    </div>
                    <div className="settings-list radio">
                      <div className="left">
                        <p>
                          COLOR MAP
                          {/* <FormattedMessage id="colorMap" /> */}
                        </p>
                      </div>

                      <div className="right">
                        {!this.props.applyFinished && (
                          <div>
                            <Spinner />
                          </div>
                        )}
                        {this.props.applyFinished && (
                          <div className="main-block">
                            <div className="radio-item-list">
                              <div className="radio-item">
                                <label className="label">
                                  <input
                                    type="radio"
                                    defaultChecked
                                    name="colormap"
                                    value="grayscale"
                                    checked={this.state.radioChecked === 'grayscale'}
                                    onChange={this.handleColorMap}
                                  />
                                  Greyscale
                                  {/* <FormattedMessage id="greyscale" /> */}
                                  <i className="gradient gray" />
                                  <span className="radio" />
                                </label>
                              </div>
                              <div className="radio-item">
                                <label className="label">
                                  <input
                                    type="radio"
                                    name="colormap"
                                    value="fire"
                                    checked={this.state.radioChecked === 'fire'}
                                    onChange={this.handleColorMap}
                                  />
                                  {/* <FormattedMessage id="fire" />   */}
                                  Fire
                                  <i className="gradient fire" />
                                  <span className="radio" />
                                </label>
                              </div>
                              <div className="radio-item">
                                <label className="label">
                                  <input
                                    type="radio"
                                    name="colormap"
                                    value="rainbow"
                                    checked={this.state.radioChecked === 'rainbow'}
                                    onChange={this.handleColorMap}
                                  />
                                  {/* <FormattedMessage id="rainbow" />  */}
                                  Rainbow
                                  <i className="gradient rainbow" />
                                  <span className="radio" />
                                </label>
                              </div>
                            </div>
                            <div className="radio-item-list">
                              <div className="radio-item">
                                <label className="label">
                                  <input
                                    type="radio"
                                    name="colormap"
                                    value="fireblue"
                                    checked={this.state.radioChecked === 'fireblue'}
                                    onChange={this.handleColorMap}
                                  />
                                  Green Fire Blue
                                  {/* <FormattedMessage id="greenFireBlue" />  */}
                                  <i className="gradient fireblue" />
                                  <span className="radio" />
                                </label>
                              </div>
                              <div className="radio-item">
                                <label className="label">
                                  <input
                                    type="radio"
                                    name="colormap"
                                    value="redhot"
                                    checked={this.state.radioChecked === 'redhot'}
                                    onChange={this.handleColorMap}
                                  />
                                  Red Hot
                                  {/* <FormattedMessage id="redHot" />  */}
                                  <i className="gradient redhot" />
                                  <span className="radio" />
                                </label>
                              </div>
                              <div className="radio-item">
                                <label className="label">
                                  <input
                                    type="radio"
                                    name="colormap"
                                    value="unionjack"
                                    checked={this.state.radioChecked === 'unionjack'}
                                    onChange={this.handleColorMap}
                                  />
                                  Unionjack
                                  {/* <FormattedMessage id="unionjack" />  */}
                                  <i className="gradient union" />
                                  <span className="radio" />
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="settings-list">
                      <div className="left">
                        <p>
                          <FormattedMessage id="invert" />{' '}
                        </p>
                      </div>
                      <div className="right">
                        <div className="invert">
                          <div className="switch-toggle">
                            <SwitchButton
                              checked={this.state.invertSwitch}
                              size="xs"
                              onChange={this.invertSwitchToggle}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="settings-list">
                      <div className="check">
                        <label className="checkbox-item">
                          Apply to all frames of this series
                          <input
                            type="checkbox"
                            disabled
                            checked={this.state.filterCheck}
                            onChange={this.filterCheck}
                            className="default-request"
                          />
                          <span className="checkbox" style={{opacity:'0.3'}} />
                        </label>
                      </div>
                    </div> */}
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Popover.Content>
      </Popover>
    );
  }
}

export default ImageEditFilter;
