/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-empty */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prefer-template */
import { env } from '../../../environments/environment';
/* istanbul ignore next */
/**
 * This api will feth the image information by image ID
 */
const getImagesBySeriesId = async (caseId: string, seriesId: string, accountId: string, isShortlistedForReport: boolean, qualityProbabilityFrom: any) => {
  let url = env.annotationEndpoint + accountId + '/cases/' + caseId + '/series/' + seriesId + '?isAnnotationEnabled=true&QualityProbabilityFrom=' + qualityProbabilityFrom + '&QualityProbabilityTo=1';
  if (isShortlistedForReport)
    url = url + '&IsShortlistedForReport=' + isShortlistedForReport;
  console.log('--Annotation APIURL --' + url);
  const response = await fetch(url, {
    method: 'GET',
    mode: 'no-cors',
    headers: { 'Content-Type': 'application/protobuf' },
  });
  console.log('----Response API-------', response);
  return response;
};

export { getImagesBySeriesId };

