/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { env } from '../../../environments/environment';
/* istanbul ignore next */
const fetchSiteUsersList = async (accountId: any, siteId: any) => {
  const url = env.getSiteUsersListEndPoint + accountId + "/sites/" + siteId + "/users";

  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/protobuf' },
  });
  return response;
};


export { fetchSiteUsersList };
