/* eslint-disable */
/* istanbul ignore file */
// source: convivo.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
goog.exportSymbol('proto.czm.convivo.AccountAssociations', null, global);
goog.exportSymbol('proto.czm.convivo.AccountDetails', null, global);
goog.exportSymbol('proto.czm.convivo.AddAdminRequest', null, global);
goog.exportSymbol('proto.czm.convivo.AdditionalInfo', null, global);
goog.exportSymbol('proto.czm.convivo.AdminRoles', null, global);
goog.exportSymbol('proto.czm.convivo.AssociationStatus', null, global);
goog.exportSymbol('proto.czm.convivo.AvailablePathologists', null, global);
goog.exportSymbol('proto.czm.convivo.BinaryReport', null, global);
goog.exportSymbol('proto.czm.convivo.CaptureImage', null, global);
goog.exportSymbol('proto.czm.convivo.CaptureType', null, global);
goog.exportSymbol('proto.czm.convivo.Case', null, global);
goog.exportSymbol('proto.czm.convivo.CaseEnd', null, global);
goog.exportSymbol('proto.czm.convivo.CaseEndedNotification', null, global);
goog.exportSymbol('proto.czm.convivo.CaseFilters', null, global);
goog.exportSymbol('proto.czm.convivo.CaseResponse', null, global);
goog.exportSymbol('proto.czm.convivo.CaseSearch', null, global);
goog.exportSymbol('proto.czm.convivo.CaseStartedNotification', null, global);
goog.exportSymbol('proto.czm.convivo.CaseStatus', null, global);
goog.exportSymbol('proto.czm.convivo.Cases', null, global);
goog.exportSymbol('proto.czm.convivo.CasesResponse', null, global);
goog.exportSymbol('proto.czm.convivo.CommentForImage', null, global);
goog.exportSymbol('proto.czm.convivo.CommentsForImage', null, global);
goog.exportSymbol('proto.czm.convivo.ConsultationCancel', null, global);
goog.exportSymbol('proto.czm.convivo.ContactDetails', null, global);
goog.exportSymbol('proto.czm.convivo.ContactFormRequest', null, global);
goog.exportSymbol('proto.czm.convivo.CreateResponse', null, global);
goog.exportSymbol('proto.czm.convivo.CreateSiteRequest', null, global);
goog.exportSymbol('proto.czm.convivo.DefaultSiteAssociation', null, global);
goog.exportSymbol('proto.czm.convivo.DeviceLogin', null, global);
goog.exportSymbol('proto.czm.convivo.DeviceLogout', null, global);
goog.exportSymbol('proto.czm.convivo.DicomRequest', null, global);
goog.exportSymbol('proto.czm.convivo.DicomResponse', null, global);
goog.exportSymbol('proto.czm.convivo.Error', null, global);
goog.exportSymbol('proto.czm.convivo.Error.ErrorType', null, global);
goog.exportSymbol('proto.czm.convivo.ExportImagesRequest', null, global);
goog.exportSymbol('proto.czm.convivo.ExportImagesResponse', null, global);
goog.exportSymbol('proto.czm.convivo.ExternalFile', null, global);
goog.exportSymbol('proto.czm.convivo.File', null, global);
goog.exportSymbol('proto.czm.convivo.Filter', null, global);
goog.exportSymbol('proto.czm.convivo.FilterType', null, global);
goog.exportSymbol('proto.czm.convivo.GeneralEquipment', null, global);
goog.exportSymbol('proto.czm.convivo.GeneralSeries', null, global);
goog.exportSymbol('proto.czm.convivo.GeneralStudy', null, global);
goog.exportSymbol('proto.czm.convivo.GetRolesResponse', null, global);
goog.exportSymbol('proto.czm.convivo.GetSiteAssociationsResponse', null, global);
goog.exportSymbol('proto.czm.convivo.GetSitesResponse', null, global);
goog.exportSymbol('proto.czm.convivo.Image', null, global);
goog.exportSymbol('proto.czm.convivo.ImageForCase', null, global);
goog.exportSymbol('proto.czm.convivo.ImageFormat', null, global);
goog.exportSymbol('proto.czm.convivo.ImageMeta', null, global);
goog.exportSymbol('proto.czm.convivo.ImageSeriesType', null, global);
goog.exportSymbol('proto.czm.convivo.ImageType', null, global);
goog.exportSymbol('proto.czm.convivo.ImageTypeCount', null, global);
goog.exportSymbol('proto.czm.convivo.ImagesCount', null, global);
goog.exportSymbol('proto.czm.convivo.InviteUserRequest', null, global);
goog.exportSymbol('proto.czm.convivo.InviteUserResponse', null, global);
goog.exportSymbol('proto.czm.convivo.InviteUserResponse.UserStatus', null, global);
goog.exportSymbol('proto.czm.convivo.Links', null, global);
goog.exportSymbol('proto.czm.convivo.MachineDetails', null, global);
goog.exportSymbol('proto.czm.convivo.MedicalInfo', null, global);
goog.exportSymbol('proto.czm.convivo.Message', null, global);
goog.exportSymbol('proto.czm.convivo.Message.MessageType', null, global);
goog.exportSymbol('proto.czm.convivo.MetaData', null, global);
goog.exportSymbol('proto.czm.convivo.Notification', null, global);
goog.exportSymbol('proto.czm.convivo.NotificationKeys', null, global);
goog.exportSymbol('proto.czm.convivo.NotificationStatus', null, global);
goog.exportSymbol('proto.czm.convivo.Overlay', null, global);
goog.exportSymbol('proto.czm.convivo.PaginationMeta', null, global);
goog.exportSymbol('proto.czm.convivo.PathoLinkOrDelinkResponse', null, global);
goog.exportSymbol('proto.czm.convivo.Pathologist', null, global);
goog.exportSymbol('proto.czm.convivo.PathologistInfo', null, global);
goog.exportSymbol('proto.czm.convivo.PathologistSite', null, global);
goog.exportSymbol('proto.czm.convivo.PathologistStatus', null, global);
goog.exportSymbol('proto.czm.convivo.Patient', null, global);
goog.exportSymbol('proto.czm.convivo.PatientID', null, global);
goog.exportSymbol('proto.czm.convivo.PatientInfo', null, global);
goog.exportSymbol('proto.czm.convivo.PhysicianInfo', null, global);
goog.exportSymbol('proto.czm.convivo.Preferences', null, global);
goog.exportSymbol('proto.czm.convivo.Preferences.SystemNotification', null, global);
goog.exportSymbol('proto.czm.convivo.RGBColour', null, global);
goog.exportSymbol('proto.czm.convivo.Report', null, global);
goog.exportSymbol('proto.czm.convivo.ReportStatus', null, global);
goog.exportSymbol('proto.czm.convivo.ReportStatusConfirmation', null, global);
goog.exportSymbol('proto.czm.convivo.Resolution', null, global);
goog.exportSymbol('proto.czm.convivo.Role', null, global);
goog.exportSymbol('proto.czm.convivo.RoleInfo', null, global);
goog.exportSymbol('proto.czm.convivo.SavedImageForCase', null, global);
goog.exportSymbol('proto.czm.convivo.SavedImageForCase.CaptureRole', null, global);
goog.exportSymbol('proto.czm.convivo.SavedImagesForCase', null, global);
goog.exportSymbol('proto.czm.convivo.ScanStatus', null, global);
goog.exportSymbol('proto.czm.convivo.Session', null, global);
goog.exportSymbol('proto.czm.convivo.Sex', null, global);
goog.exportSymbol('proto.czm.convivo.Site', null, global);
goog.exportSymbol('proto.czm.convivo.Site.UserStatus', null, global);
goog.exportSymbol('proto.czm.convivo.SiteAssociation', null, global);
goog.exportSymbol('proto.czm.convivo.SiteAssociationRequest', null, global);
goog.exportSymbol('proto.czm.convivo.SiteDetails', null, global);
goog.exportSymbol('proto.czm.convivo.SiteStatus', null, global);
goog.exportSymbol('proto.czm.convivo.Spot', null, global);
goog.exportSymbol('proto.czm.convivo.SpotImage', null, global);
goog.exportSymbol('proto.czm.convivo.SpotImages', null, global);
goog.exportSymbol('proto.czm.convivo.SpotImagesByLabel', null, global);
goog.exportSymbol('proto.czm.convivo.SpotImagesCount', null, global);
goog.exportSymbol('proto.czm.convivo.Spots', null, global);
goog.exportSymbol('proto.czm.convivo.UpdateCaseRequest', null, global);
goog.exportSymbol('proto.czm.convivo.UpdateCaseStatusRequest', null, global);
goog.exportSymbol('proto.czm.convivo.UpdateCaseStatusResponse', null, global);
goog.exportSymbol('proto.czm.convivo.UpdateDefaultSiteAssociation', null, global);
goog.exportSymbol('proto.czm.convivo.UpdateImageRequest', null, global);
goog.exportSymbol('proto.czm.convivo.UpdateSiteAssociation', null, global);
goog.exportSymbol('proto.czm.convivo.UpdateSiteRequest', null, global);
goog.exportSymbol('proto.czm.convivo.UpdateSiteResponse', null, global);
goog.exportSymbol('proto.czm.convivo.UpdateUserRequest', null, global);
goog.exportSymbol('proto.czm.convivo.UpdateUserRequest.UserStatus', null, global);
goog.exportSymbol('proto.czm.convivo.User', null, global);
goog.exportSymbol('proto.czm.convivo.User.UserStatus', null, global);
goog.exportSymbol('proto.czm.convivo.UserInfo', null, global);
goog.exportSymbol('proto.czm.convivo.UserInfo.Role', null, global);
goog.exportSymbol('proto.czm.convivo.UserList', null, global);
goog.exportSymbol('proto.czm.convivo.UserRolesUpdationResult', null, global);
goog.exportSymbol('proto.czm.convivo.WebNotification', null, global);
goog.exportSymbol('proto.czm.convivo.WebNotifications', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.Message = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.Message, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.Message.displayName = 'proto.czm.convivo.Message';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.DeviceLogin = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.DeviceLogin, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.DeviceLogin.displayName = 'proto.czm.convivo.DeviceLogin';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.DeviceLogout = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.DeviceLogout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.DeviceLogout.displayName = 'proto.czm.convivo.DeviceLogout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.Session = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.Session.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.Session, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.Session.displayName = 'proto.czm.convivo.Session';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.Error = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.Error.displayName = 'proto.czm.convivo.Error';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.PathologistSite = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.PathologistSite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.PathologistSite.displayName = 'proto.czm.convivo.PathologistSite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.MachineDetails = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.MachineDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.MachineDetails.displayName = 'proto.czm.convivo.MachineDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.Cases = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.Cases.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.Cases, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.Cases.displayName = 'proto.czm.convivo.Cases';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.Case = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.Case.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.Case, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.Case.displayName = 'proto.czm.convivo.Case';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.PathologistInfo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.PathologistInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.PathologistInfo.displayName = 'proto.czm.convivo.PathologistInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.GeneralStudy = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.GeneralStudy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.GeneralStudy.displayName = 'proto.czm.convivo.GeneralStudy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.GeneralSeries = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.GeneralSeries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.GeneralSeries.displayName = 'proto.czm.convivo.GeneralSeries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.GeneralEquipment = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.GeneralEquipment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.GeneralEquipment.displayName = 'proto.czm.convivo.GeneralEquipment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.MedicalInfo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.MedicalInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.MedicalInfo.displayName = 'proto.czm.convivo.MedicalInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.CaseEnd = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.CaseEnd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.CaseEnd.displayName = 'proto.czm.convivo.CaseEnd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.ScanStatus = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.ScanStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.ScanStatus.displayName = 'proto.czm.convivo.ScanStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.Image = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.Image, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.Image.displayName = 'proto.czm.convivo.Image';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.ImageMeta = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.ImageMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.ImageMeta.displayName = 'proto.czm.convivo.ImageMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.Spot = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.Spot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.Spot.displayName = 'proto.czm.convivo.Spot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.RGBColour = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.RGBColour, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.RGBColour.displayName = 'proto.czm.convivo.RGBColour';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.Spots = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.Spots.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.Spots, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.Spots.displayName = 'proto.czm.convivo.Spots';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.SpotImagesCount = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.SpotImagesCount.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.SpotImagesCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.SpotImagesCount.displayName = 'proto.czm.convivo.SpotImagesCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.ImageTypeCount = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.ImageTypeCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.ImageTypeCount.displayName = 'proto.czm.convivo.ImageTypeCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.ImageForCase = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.ImageForCase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.ImageForCase.displayName = 'proto.czm.convivo.ImageForCase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.ImageSeriesType = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.ImageSeriesType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.ImageSeriesType.displayName = 'proto.czm.convivo.ImageSeriesType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.CaptureImage = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.CaptureImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.CaptureImage.displayName = 'proto.czm.convivo.CaptureImage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.Patient = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.Patient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.Patient.displayName = 'proto.czm.convivo.Patient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.PatientID = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.PatientID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.PatientID.displayName = 'proto.czm.convivo.PatientID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.AvailablePathologists = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.AvailablePathologists.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.AvailablePathologists, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.AvailablePathologists.displayName = 'proto.czm.convivo.AvailablePathologists';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.Pathologist = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.Pathologist, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.Pathologist.displayName = 'proto.czm.convivo.Pathologist';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.UserInfo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.UserInfo.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.UserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.UserInfo.displayName = 'proto.czm.convivo.UserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.RoleInfo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.RoleInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.RoleInfo.displayName = 'proto.czm.convivo.RoleInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.CreateResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.CreateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.CreateResponse.displayName = 'proto.czm.convivo.CreateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.Site = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.Site.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.Site, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.Site.displayName = 'proto.czm.convivo.Site';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.PatientInfo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.PatientInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.PatientInfo.displayName = 'proto.czm.convivo.PatientInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.PhysicianInfo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.PhysicianInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.PhysicianInfo.displayName = 'proto.czm.convivo.PhysicianInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.CaseResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.CaseResponse.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.CaseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.CaseResponse.displayName = 'proto.czm.convivo.CaseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.AdditionalInfo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.AdditionalInfo.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.AdditionalInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.AdditionalInfo.displayName = 'proto.czm.convivo.AdditionalInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.Report = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.Report.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.Report, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.Report.displayName = 'proto.czm.convivo.Report';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.BinaryReport = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.BinaryReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.BinaryReport.displayName = 'proto.czm.convivo.BinaryReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.ReportStatusConfirmation = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.ReportStatusConfirmation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.ReportStatusConfirmation.displayName = 'proto.czm.convivo.ReportStatusConfirmation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.File = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.File, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.File.displayName = 'proto.czm.convivo.File';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.CasesResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.CasesResponse.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.CasesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.CasesResponse.displayName = 'proto.czm.convivo.CasesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.GetSitesResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.GetSitesResponse.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.GetSitesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.GetSitesResponse.displayName = 'proto.czm.convivo.GetSitesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.SiteDetails = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.SiteDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.SiteDetails.displayName = 'proto.czm.convivo.SiteDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.PaginationMeta = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.PaginationMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.PaginationMeta.displayName = 'proto.czm.convivo.PaginationMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.SavedImageForCase = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.SavedImageForCase.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.SavedImageForCase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.SavedImageForCase.displayName = 'proto.czm.convivo.SavedImageForCase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.SavedImagesForCase = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.SavedImagesForCase.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.SavedImagesForCase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.SavedImagesForCase.displayName = 'proto.czm.convivo.SavedImagesForCase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.SpotImagesByLabel = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.SpotImagesByLabel.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.SpotImagesByLabel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.SpotImagesByLabel.displayName = 'proto.czm.convivo.SpotImagesByLabel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.SpotImages = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.SpotImages.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.SpotImages, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.SpotImages.displayName = 'proto.czm.convivo.SpotImages';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.SpotImage = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.SpotImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.SpotImage.displayName = 'proto.czm.convivo.SpotImage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.CommentForImage = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.CommentForImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.CommentForImage.displayName = 'proto.czm.convivo.CommentForImage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.CommentsForImage = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.CommentsForImage.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.CommentsForImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.CommentsForImage.displayName = 'proto.czm.convivo.CommentsForImage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.Filter = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.Filter.displayName = 'proto.czm.convivo.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.MetaData = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.MetaData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.MetaData.displayName = 'proto.czm.convivo.MetaData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.Overlay = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.Overlay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.Overlay.displayName = 'proto.czm.convivo.Overlay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.Notification = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.Notification.displayName = 'proto.czm.convivo.Notification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.WebNotifications = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.WebNotifications.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.WebNotifications, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.WebNotifications.displayName = 'proto.czm.convivo.WebNotifications';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.WebNotification = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.WebNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.WebNotification.displayName = 'proto.czm.convivo.WebNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.PathoLinkOrDelinkResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.PathoLinkOrDelinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.PathoLinkOrDelinkResponse.displayName = 'proto.czm.convivo.PathoLinkOrDelinkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.PathologistStatus = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.PathologistStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.PathologistStatus.displayName = 'proto.czm.convivo.PathologistStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.AccountDetails = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.AccountDetails.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.AccountDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.AccountDetails.displayName = 'proto.czm.convivo.AccountDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.ContactDetails = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.ContactDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.ContactDetails.displayName = 'proto.czm.convivo.ContactDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.InviteUserRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.InviteUserRequest.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.InviteUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.InviteUserRequest.displayName = 'proto.czm.convivo.InviteUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.InviteUserResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.InviteUserResponse.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.InviteUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.InviteUserResponse.displayName = 'proto.czm.convivo.InviteUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.UserList = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.UserList.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.UserList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.UserList.displayName = 'proto.czm.convivo.UserList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.User = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.User.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.User.displayName = 'proto.czm.convivo.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.Role = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.Role, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.Role.displayName = 'proto.czm.convivo.Role';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.Preferences = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.Preferences, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.Preferences.displayName = 'proto.czm.convivo.Preferences';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.UpdateUserRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.UpdateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.UpdateUserRequest.displayName = 'proto.czm.convivo.UpdateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.UpdateCaseRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.UpdateCaseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.UpdateCaseRequest.displayName = 'proto.czm.convivo.UpdateCaseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.UpdateImageRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.UpdateImageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.UpdateImageRequest.displayName = 'proto.czm.convivo.UpdateImageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.ExternalFile = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.ExternalFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.ExternalFile.displayName = 'proto.czm.convivo.ExternalFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.GetRolesResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.GetRolesResponse.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.GetRolesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.GetRolesResponse.displayName = 'proto.czm.convivo.GetRolesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.CaseFilters = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.CaseFilters.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.CaseFilters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.CaseFilters.displayName = 'proto.czm.convivo.CaseFilters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.CaseSearch = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.CaseSearch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.CaseSearch.displayName = 'proto.czm.convivo.CaseSearch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.SiteAssociation = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.SiteAssociation.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.SiteAssociation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.SiteAssociation.displayName = 'proto.czm.convivo.SiteAssociation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.GetSiteAssociationsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.GetSiteAssociationsResponse.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.GetSiteAssociationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.GetSiteAssociationsResponse.displayName = 'proto.czm.convivo.GetSiteAssociationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.UpdateCaseStatusRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.UpdateCaseStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.UpdateCaseStatusRequest.displayName = 'proto.czm.convivo.UpdateCaseStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.UpdateCaseStatusResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.UpdateCaseStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.UpdateCaseStatusResponse.displayName = 'proto.czm.convivo.UpdateCaseStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.SiteAssociationRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.SiteAssociationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.SiteAssociationRequest.displayName = 'proto.czm.convivo.SiteAssociationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.UpdateSiteAssociation = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.UpdateSiteAssociation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.UpdateSiteAssociation.displayName = 'proto.czm.convivo.UpdateSiteAssociation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.CreateSiteRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.CreateSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.CreateSiteRequest.displayName = 'proto.czm.convivo.CreateSiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.UpdateSiteRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.UpdateSiteRequest.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.UpdateSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.UpdateSiteRequest.displayName = 'proto.czm.convivo.UpdateSiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.UpdateSiteResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.UpdateSiteResponse.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.UpdateSiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.UpdateSiteResponse.displayName = 'proto.czm.convivo.UpdateSiteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.UserRolesUpdationResult = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.UserRolesUpdationResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.UserRolesUpdationResult.displayName = 'proto.czm.convivo.UserRolesUpdationResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.ContactFormRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.ContactFormRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.ContactFormRequest.displayName = 'proto.czm.convivo.ContactFormRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.Links = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.Links, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.Links.displayName = 'proto.czm.convivo.Links';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.AccountAssociations = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.AccountAssociations.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.AccountAssociations, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.AccountAssociations.displayName = 'proto.czm.convivo.AccountAssociations';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.ConsultationCancel = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.ConsultationCancel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.ConsultationCancel.displayName = 'proto.czm.convivo.ConsultationCancel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.AddAdminRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.AddAdminRequest.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.AddAdminRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.AddAdminRequest.displayName = 'proto.czm.convivo.AddAdminRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.DicomRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.DicomRequest.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.DicomRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.DicomRequest.displayName = 'proto.czm.convivo.DicomRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.DicomResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.DicomResponse.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.DicomResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.DicomResponse.displayName = 'proto.czm.convivo.DicomResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.CaseStartedNotification = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.CaseStartedNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.CaseStartedNotification.displayName = 'proto.czm.convivo.CaseStartedNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.CaseEndedNotification = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.CaseEndedNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.CaseEndedNotification.displayName = 'proto.czm.convivo.CaseEndedNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.DefaultSiteAssociation = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.DefaultSiteAssociation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.DefaultSiteAssociation.displayName = 'proto.czm.convivo.DefaultSiteAssociation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.UpdateDefaultSiteAssociation = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.UpdateDefaultSiteAssociation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.UpdateDefaultSiteAssociation.displayName = 'proto.czm.convivo.UpdateDefaultSiteAssociation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.ExportImagesRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.ExportImagesRequest.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.ExportImagesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.ExportImagesRequest.displayName = 'proto.czm.convivo.ExportImagesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.ExportImagesResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.czm.convivo.ExportImagesResponse.repeatedFields_, null);
};
goog.inherits(proto.czm.convivo.ExportImagesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.ExportImagesResponse.displayName = 'proto.czm.convivo.ExportImagesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.czm.convivo.ImagesCount = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.czm.convivo.ImagesCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.czm.convivo.ImagesCount.displayName = 'proto.czm.convivo.ImagesCount';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.Message.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.Message.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.Message} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.Message.toObject = function (includeInstance, msg) {
    var f, obj = {
      type: jspb.Message.getFieldWithDefault(msg, 1, 0),
      details: (f = msg.getDetails()) && google_protobuf_any_pb.Any.toObject(includeInstance, f),
      sessionid: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.Message}
 */
proto.czm.convivo.Message.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.Message;
  return proto.czm.convivo.Message.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.Message} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.Message}
 */
proto.czm.convivo.Message.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.czm.convivo.Message.MessageType} */ (reader.readEnum());
        msg.setType(value);
        break;
      case 2:
        var value = new google_protobuf_any_pb.Any;
        reader.readMessage(value, google_protobuf_any_pb.Any.deserializeBinaryFromReader);
        msg.setDetails(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setSessionid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.Message.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.Message.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.Message} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.Message.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
  f = message.getSessionid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.czm.convivo.Message.MessageType = {
  UNKNOWN: 0,
  LIVE_IMAGE: 1,
  RECORDED_IMAGE: 2,
  SCANNING_STATUS: 3,
  AVAILABLE_PATHOLOGISTS: 4,
  ERROR_OCCURRED: 5,
  START_CASE: 6,
  CASE_STARTED: 7,
  END_CASE: 8,
  CASE_ENDED: 9,
  OPEN_CASES: 10,
  DEVICE_LOGIN: 11,
  DEVICE_LOGOUT: 12,
  NOTIFICATION: 13,
  PATHOLOGIST_STATUS: 14,
  PATHOLOGIST_IMAGE_CAPTURE: 15,
  PATHOLOGIST_SERIES_CAPTURE_START: 16,
  PATHOLOGIST_SERIES_CAPTURE_STOP: 17,
  CONSULTATION_CANCELLED: 18,
  NEW_CASE_STARTED: 19,
  CAPTURE_COMPLETE: 20
};

/**
 * optional MessageType type = 1;
 * @return {!proto.czm.convivo.Message.MessageType}
 */
proto.czm.convivo.Message.prototype.getType = function () {
  return /** @type {!proto.czm.convivo.Message.MessageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.czm.convivo.Message.MessageType} value
 * @return {!proto.czm.convivo.Message} returns this
 */
proto.czm.convivo.Message.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.protobuf.Any details = 2;
 * @return {?proto.google.protobuf.Any}
 */
proto.czm.convivo.Message.prototype.getDetails = function () {
  return /** @type{?proto.google.protobuf.Any} */ (
    jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 2));
};


/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.czm.convivo.Message} returns this
*/
proto.czm.convivo.Message.prototype.setDetails = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.Message} returns this
 */
proto.czm.convivo.Message.prototype.clearDetails = function () {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.Message.prototype.hasDetails = function () {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string sessionID = 3;
 * @return {string}
 */
proto.czm.convivo.Message.prototype.getSessionid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Message} returns this
 */
proto.czm.convivo.Message.prototype.setSessionid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.DeviceLogin.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.DeviceLogin.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.DeviceLogin} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.DeviceLogin.toObject = function (includeInstance, msg) {
    var f, obj = {
      serialnumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
      devicesiteid: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.DeviceLogin}
 */
proto.czm.convivo.DeviceLogin.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.DeviceLogin;
  return proto.czm.convivo.DeviceLogin.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.DeviceLogin} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.DeviceLogin}
 */
proto.czm.convivo.DeviceLogin.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setSerialnumber(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setDevicesiteid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.DeviceLogin.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.DeviceLogin.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.DeviceLogin} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.DeviceLogin.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSerialnumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDevicesiteid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string serialNumber = 1;
 * @return {string}
 */
proto.czm.convivo.DeviceLogin.prototype.getSerialnumber = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.DeviceLogin} returns this
 */
proto.czm.convivo.DeviceLogin.prototype.setSerialnumber = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deviceSiteID = 2;
 * @return {string}
 */
proto.czm.convivo.DeviceLogin.prototype.getDevicesiteid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.DeviceLogin} returns this
 */
proto.czm.convivo.DeviceLogin.prototype.setDevicesiteid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.DeviceLogout.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.DeviceLogout.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.DeviceLogout} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.DeviceLogout.toObject = function (includeInstance, msg) {
    var f, obj = {
      enddatetime: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.DeviceLogout}
 */
proto.czm.convivo.DeviceLogout.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.DeviceLogout;
  return proto.czm.convivo.DeviceLogout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.DeviceLogout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.DeviceLogout}
 */
proto.czm.convivo.DeviceLogout.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setEnddatetime(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.DeviceLogout.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.DeviceLogout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.DeviceLogout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.DeviceLogout.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getEnddatetime();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string endDateTime = 1;
 * @return {string}
 */
proto.czm.convivo.DeviceLogout.prototype.getEnddatetime = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.DeviceLogout} returns this
 */
proto.czm.convivo.DeviceLogout.prototype.setEnddatetime = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.Session.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.Session.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.Session.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.Session} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.Session.toObject = function (includeInstance, msg) {
    var f, obj = {
      sessionid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      authenticated: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      pathologistsitesList: jspb.Message.toObjectList(msg.getPathologistsitesList(),
        proto.czm.convivo.PathologistSite.toObject, includeInstance),
      devicesitename: jspb.Message.getFieldWithDefault(msg, 4, ""),
      error: (f = msg.getError()) && proto.czm.convivo.Error.toObject(includeInstance, f),
      accountname: jspb.Message.getFieldWithDefault(msg, 6, ""),
      phonenumber: jspb.Message.getFieldWithDefault(msg, 7, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.Session}
 */
proto.czm.convivo.Session.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.Session;
  return proto.czm.convivo.Session.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.Session} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.Session}
 */
proto.czm.convivo.Session.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setSessionid(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setAuthenticated(value);
        break;
      case 3:
        var value = new proto.czm.convivo.PathologistSite;
        reader.readMessage(value, proto.czm.convivo.PathologistSite.deserializeBinaryFromReader);
        msg.addPathologistsites(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setDevicesitename(value);
        break;
      case 5:
        var value = new proto.czm.convivo.Error;
        reader.readMessage(value, proto.czm.convivo.Error.deserializeBinaryFromReader);
        msg.setError(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccountname(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setPhonenumber(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.Session.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.Session.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.Session} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.Session.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSessionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuthenticated();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getPathologistsitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.czm.convivo.PathologistSite.serializeBinaryToWriter
    );
  }
  f = message.getDevicesitename();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.czm.convivo.Error.serializeBinaryToWriter
    );
  }
  f = message.getAccountname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string sessionID = 1;
 * @return {string}
 */
proto.czm.convivo.Session.prototype.getSessionid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Session} returns this
 */
proto.czm.convivo.Session.prototype.setSessionid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool authenticated = 2;
 * @return {boolean}
 */
proto.czm.convivo.Session.prototype.getAuthenticated = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.czm.convivo.Session} returns this
 */
proto.czm.convivo.Session.prototype.setAuthenticated = function (value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated PathologistSite pathologistSites = 3;
 * @return {!Array<!proto.czm.convivo.PathologistSite>}
 */
proto.czm.convivo.Session.prototype.getPathologistsitesList = function () {
  return /** @type{!Array<!proto.czm.convivo.PathologistSite>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.PathologistSite, 3));
};


/**
 * @param {!Array<!proto.czm.convivo.PathologistSite>} value
 * @return {!proto.czm.convivo.Session} returns this
*/
proto.czm.convivo.Session.prototype.setPathologistsitesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.czm.convivo.PathologistSite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.PathologistSite}
 */
proto.czm.convivo.Session.prototype.addPathologistsites = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.czm.convivo.PathologistSite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.Session} returns this
 */
proto.czm.convivo.Session.prototype.clearPathologistsitesList = function () {
  return this.setPathologistsitesList([]);
};


/**
 * optional string deviceSiteName = 4;
 * @return {string}
 */
proto.czm.convivo.Session.prototype.getDevicesitename = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Session} returns this
 */
proto.czm.convivo.Session.prototype.setDevicesitename = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Error error = 5;
 * @return {?proto.czm.convivo.Error}
 */
proto.czm.convivo.Session.prototype.getError = function () {
  return /** @type{?proto.czm.convivo.Error} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.Error, 5));
};


/**
 * @param {?proto.czm.convivo.Error|undefined} value
 * @return {!proto.czm.convivo.Session} returns this
*/
proto.czm.convivo.Session.prototype.setError = function (value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.Session} returns this
 */
proto.czm.convivo.Session.prototype.clearError = function () {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.Session.prototype.hasError = function () {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string accountName = 6;
 * @return {string}
 */
proto.czm.convivo.Session.prototype.getAccountname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Session} returns this
 */
proto.czm.convivo.Session.prototype.setAccountname = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string phoneNumber = 7;
 * @return {string}
 */
proto.czm.convivo.Session.prototype.getPhonenumber = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Session} returns this
 */
proto.czm.convivo.Session.prototype.setPhonenumber = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.Error.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.Error.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.Error} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.Error.toObject = function (includeInstance, msg) {
    var f, obj = {
      type: jspb.Message.getFieldWithDefault(msg, 1, 0),
      message: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.Error}
 */
proto.czm.convivo.Error.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.Error;
  return proto.czm.convivo.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.Error}
 */
proto.czm.convivo.Error.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.czm.convivo.Error.ErrorType} */ (reader.readEnum());
        msg.setType(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMessage(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.Error.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.Error.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.czm.convivo.Error.ErrorType = {
  UNKNOWN: 0,
  USER_ALREADY_LOGGED_IN: 1,
  USER_SESSION_EXPIRED: 2,
  USER_AUTHENTICATION_SERVICE_NOT_AVAILABLE: 3,
  LOW_FPS_IN_BOUND: 4,
  LOW_FPS_OUT_BOUND: 5,
  IMAGE_COUNTER_INCONSISTENT: 6,
  USER_INVALID_CREDENTIALS: 7,
  CASE_CREATION_ERROR: 8,
  CREATE_CASE_PATHOLOGIST_BUSY: 9,
  DEVICE_SITE_ID_NOT_VALID: 10,
  LICENCE_NOT_VALID: 11,
  PATHOLOGIST_SITE_ID_NOT_VALID: 12,
  CASE_UPDATE_STATUS_ERROR: 13,
  FAILED_TO_GET_ASSOCIATED_ACCOUNTS: 14,
  GET_CASE_ERROR: 15,
  FAILED_TO_GET_ACCOUNTS_BY_ID: 16
};

/**
 * optional ErrorType type = 1;
 * @return {!proto.czm.convivo.Error.ErrorType}
 */
proto.czm.convivo.Error.prototype.getType = function () {
  return /** @type {!proto.czm.convivo.Error.ErrorType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.czm.convivo.Error.ErrorType} value
 * @return {!proto.czm.convivo.Error} returns this
 */
proto.czm.convivo.Error.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.czm.convivo.Error.prototype.getMessage = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Error} returns this
 */
proto.czm.convivo.Error.prototype.setMessage = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.PathologistSite.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.PathologistSite.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.PathologistSite} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.PathologistSite.toObject = function (includeInstance, msg) {
    var f, obj = {
      pathologistsiteid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      pathologistsitename: jspb.Message.getFieldWithDefault(msg, 2, ""),
      defaultconsultingsite: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.PathologistSite}
 */
proto.czm.convivo.PathologistSite.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.PathologistSite;
  return proto.czm.convivo.PathologistSite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.PathologistSite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.PathologistSite}
 */
proto.czm.convivo.PathologistSite.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setPathologistsiteid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setPathologistsitename(value);
        break;
      case 3:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setDefaultconsultingsite(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.PathologistSite.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.PathologistSite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.PathologistSite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.PathologistSite.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPathologistsiteid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPathologistsitename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDefaultconsultingsite();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string pathologistSiteID = 1;
 * @return {string}
 */
proto.czm.convivo.PathologistSite.prototype.getPathologistsiteid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.PathologistSite} returns this
 */
proto.czm.convivo.PathologistSite.prototype.setPathologistsiteid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pathologistSiteName = 2;
 * @return {string}
 */
proto.czm.convivo.PathologistSite.prototype.getPathologistsitename = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.PathologistSite} returns this
 */
proto.czm.convivo.PathologistSite.prototype.setPathologistsitename = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool defaultConsultingSite = 3;
 * @return {boolean}
 */
proto.czm.convivo.PathologistSite.prototype.getDefaultconsultingsite = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.czm.convivo.PathologistSite} returns this
 */
proto.czm.convivo.PathologistSite.prototype.setDefaultconsultingsite = function (value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.MachineDetails.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.MachineDetails.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.MachineDetails} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.MachineDetails.toObject = function (includeInstance, msg) {
    var f, obj = {
      serialnumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
      softwareversion: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.MachineDetails}
 */
proto.czm.convivo.MachineDetails.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.MachineDetails;
  return proto.czm.convivo.MachineDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.MachineDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.MachineDetails}
 */
proto.czm.convivo.MachineDetails.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setSerialnumber(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setSoftwareversion(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.MachineDetails.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.MachineDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.MachineDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.MachineDetails.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSerialnumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSoftwareversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string serialNumber = 1;
 * @return {string}
 */
proto.czm.convivo.MachineDetails.prototype.getSerialnumber = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.MachineDetails} returns this
 */
proto.czm.convivo.MachineDetails.prototype.setSerialnumber = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string softwareVersion = 2;
 * @return {string}
 */
proto.czm.convivo.MachineDetails.prototype.getSoftwareversion = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.MachineDetails} returns this
 */
proto.czm.convivo.MachineDetails.prototype.setSoftwareversion = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.Cases.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.Cases.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.Cases.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.Cases} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.Cases.toObject = function (includeInstance, msg) {
    var f, obj = {
      casesList: jspb.Message.toObjectList(msg.getCasesList(),
        proto.czm.convivo.Case.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.Cases}
 */
proto.czm.convivo.Cases.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.Cases;
  return proto.czm.convivo.Cases.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.Cases} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.Cases}
 */
proto.czm.convivo.Cases.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.czm.convivo.Case;
        reader.readMessage(value, proto.czm.convivo.Case.deserializeBinaryFromReader);
        msg.addCases(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.Cases.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.Cases.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.Cases} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.Cases.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCasesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.czm.convivo.Case.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Case cases = 1;
 * @return {!Array<!proto.czm.convivo.Case>}
 */
proto.czm.convivo.Cases.prototype.getCasesList = function () {
  return /** @type{!Array<!proto.czm.convivo.Case>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.Case, 1));
};


/**
 * @param {!Array<!proto.czm.convivo.Case>} value
 * @return {!proto.czm.convivo.Cases} returns this
*/
proto.czm.convivo.Cases.prototype.setCasesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.czm.convivo.Case=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.Case}
 */
proto.czm.convivo.Cases.prototype.addCases = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.czm.convivo.Case, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.Cases} returns this
 */
proto.czm.convivo.Cases.prototype.clearCasesList = function () {
  return this.setCasesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.Case.repeatedFields_ = [18];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.Case.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.Case.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.Case} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.Case.toObject = function (includeInstance, msg) {
    var f, obj = {
      caseid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      deidentificationon: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      pathologistsiteid: jspb.Message.getFieldWithDefault(msg, 3, ""),
      patient: (f = msg.getPatient()) && proto.czm.convivo.Patient.toObject(includeInstance, f),
      machinedetails: (f = msg.getMachinedetails()) && proto.czm.convivo.MachineDetails.toObject(includeInstance, f),
      devicesurgeondata: (f = msg.getDevicesurgeondata()) && proto.czm.convivo.UserInfo.toObject(includeInstance, f),
      pathologistdata: (f = msg.getPathologistdata()) && proto.czm.convivo.UserInfo.toObject(includeInstance, f),
      medicalinfo: (f = msg.getMedicalinfo()) && proto.czm.convivo.MedicalInfo.toObject(includeInstance, f),
      notification: (f = msg.getNotification()) && proto.czm.convivo.Notification.toObject(includeInstance, f),
      generalstudy: (f = msg.getGeneralstudy()) && proto.czm.convivo.GeneralStudy.toObject(includeInstance, f),
      generalseries: (f = msg.getGeneralseries()) && proto.czm.convivo.GeneralSeries.toObject(includeInstance, f),
      generalequipment: (f = msg.getGeneralequipment()) && proto.czm.convivo.GeneralEquipment.toObject(includeInstance, f),
      status: jspb.Message.getFieldWithDefault(msg, 13, ""),
      pathologistsitename: jspb.Message.getFieldWithDefault(msg, 14, ""),
      devicesiteid: jspb.Message.getFieldWithDefault(msg, 15, ""),
      devicesitename: jspb.Message.getFieldWithDefault(msg, 16, ""),
      startdatetime: jspb.Message.getFieldWithDefault(msg, 17, ""),
      pathologistinfoList: jspb.Message.toObjectList(msg.getPathologistinfoList(),
        proto.czm.convivo.PathologistInfo.toObject, includeInstance),
      aliascaseid: jspb.Message.getFieldWithDefault(msg, 19, ""),
      startdatetimeinutc: jspb.Message.getFieldWithDefault(msg, 20, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.Case}
 */
proto.czm.convivo.Case.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.Case;
  return proto.czm.convivo.Case.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.Case} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.Case}
 */
proto.czm.convivo.Case.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCaseid(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setDeidentificationon(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setPathologistsiteid(value);
        break;
      case 4:
        var value = new proto.czm.convivo.Patient;
        reader.readMessage(value, proto.czm.convivo.Patient.deserializeBinaryFromReader);
        msg.setPatient(value);
        break;
      case 5:
        var value = new proto.czm.convivo.MachineDetails;
        reader.readMessage(value, proto.czm.convivo.MachineDetails.deserializeBinaryFromReader);
        msg.setMachinedetails(value);
        break;
      case 6:
        var value = new proto.czm.convivo.UserInfo;
        reader.readMessage(value, proto.czm.convivo.UserInfo.deserializeBinaryFromReader);
        msg.setDevicesurgeondata(value);
        break;
      case 7:
        var value = new proto.czm.convivo.UserInfo;
        reader.readMessage(value, proto.czm.convivo.UserInfo.deserializeBinaryFromReader);
        msg.setPathologistdata(value);
        break;
      case 8:
        var value = new proto.czm.convivo.MedicalInfo;
        reader.readMessage(value, proto.czm.convivo.MedicalInfo.deserializeBinaryFromReader);
        msg.setMedicalinfo(value);
        break;
      case 9:
        var value = new proto.czm.convivo.Notification;
        reader.readMessage(value, proto.czm.convivo.Notification.deserializeBinaryFromReader);
        msg.setNotification(value);
        break;
      case 10:
        var value = new proto.czm.convivo.GeneralStudy;
        reader.readMessage(value, proto.czm.convivo.GeneralStudy.deserializeBinaryFromReader);
        msg.setGeneralstudy(value);
        break;
      case 11:
        var value = new proto.czm.convivo.GeneralSeries;
        reader.readMessage(value, proto.czm.convivo.GeneralSeries.deserializeBinaryFromReader);
        msg.setGeneralseries(value);
        break;
      case 12:
        var value = new proto.czm.convivo.GeneralEquipment;
        reader.readMessage(value, proto.czm.convivo.GeneralEquipment.deserializeBinaryFromReader);
        msg.setGeneralequipment(value);
        break;
      case 13:
        var value = /** @type {string} */ (reader.readString());
        msg.setStatus(value);
        break;
      case 14:
        var value = /** @type {string} */ (reader.readString());
        msg.setPathologistsitename(value);
        break;
      case 15:
        var value = /** @type {string} */ (reader.readString());
        msg.setDevicesiteid(value);
        break;
      case 16:
        var value = /** @type {string} */ (reader.readString());
        msg.setDevicesitename(value);
        break;
      case 17:
        var value = /** @type {string} */ (reader.readString());
        msg.setStartdatetime(value);
        break;
      case 18:
        var value = new proto.czm.convivo.PathologistInfo;
        reader.readMessage(value, proto.czm.convivo.PathologistInfo.deserializeBinaryFromReader);
        msg.addPathologistinfo(value);
        break;
      case 19:
        var value = /** @type {string} */ (reader.readString());
        msg.setAliascaseid(value);
        break;
      case 20:
        var value = /** @type {string} */ (reader.readString());
        msg.setStartdatetimeinutc(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.Case.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.Case.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.Case} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.Case.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCaseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeidentificationon();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getPathologistsiteid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPatient();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.czm.convivo.Patient.serializeBinaryToWriter
    );
  }
  f = message.getMachinedetails();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.czm.convivo.MachineDetails.serializeBinaryToWriter
    );
  }
  f = message.getDevicesurgeondata();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.czm.convivo.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getPathologistdata();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.czm.convivo.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getMedicalinfo();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.czm.convivo.MedicalInfo.serializeBinaryToWriter
    );
  }
  f = message.getNotification();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.czm.convivo.Notification.serializeBinaryToWriter
    );
  }
  f = message.getGeneralstudy();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.czm.convivo.GeneralStudy.serializeBinaryToWriter
    );
  }
  f = message.getGeneralseries();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.czm.convivo.GeneralSeries.serializeBinaryToWriter
    );
  }
  f = message.getGeneralequipment();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.czm.convivo.GeneralEquipment.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPathologistsitename();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getDevicesiteid();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getDevicesitename();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getStartdatetime();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPathologistinfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.czm.convivo.PathologistInfo.serializeBinaryToWriter
    );
  }
  f = message.getAliascaseid();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getStartdatetimeinutc();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional string caseID = 1;
 * @return {string}
 */
proto.czm.convivo.Case.prototype.getCaseid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Case} returns this
 */
proto.czm.convivo.Case.prototype.setCaseid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool deIdentificationOn = 2;
 * @return {boolean}
 */
proto.czm.convivo.Case.prototype.getDeidentificationon = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.czm.convivo.Case} returns this
 */
proto.czm.convivo.Case.prototype.setDeidentificationon = function (value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string pathologistSiteID = 3;
 * @return {string}
 */
proto.czm.convivo.Case.prototype.getPathologistsiteid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Case} returns this
 */
proto.czm.convivo.Case.prototype.setPathologistsiteid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Patient patient = 4;
 * @return {?proto.czm.convivo.Patient}
 */
proto.czm.convivo.Case.prototype.getPatient = function () {
  return /** @type{?proto.czm.convivo.Patient} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.Patient, 4));
};


/**
 * @param {?proto.czm.convivo.Patient|undefined} value
 * @return {!proto.czm.convivo.Case} returns this
*/
proto.czm.convivo.Case.prototype.setPatient = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.Case} returns this
 */
proto.czm.convivo.Case.prototype.clearPatient = function () {
  return this.setPatient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.Case.prototype.hasPatient = function () {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional MachineDetails machineDetails = 5;
 * @return {?proto.czm.convivo.MachineDetails}
 */
proto.czm.convivo.Case.prototype.getMachinedetails = function () {
  return /** @type{?proto.czm.convivo.MachineDetails} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.MachineDetails, 5));
};


/**
 * @param {?proto.czm.convivo.MachineDetails|undefined} value
 * @return {!proto.czm.convivo.Case} returns this
*/
proto.czm.convivo.Case.prototype.setMachinedetails = function (value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.Case} returns this
 */
proto.czm.convivo.Case.prototype.clearMachinedetails = function () {
  return this.setMachinedetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.Case.prototype.hasMachinedetails = function () {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional UserInfo deviceSurgeonData = 6;
 * @return {?proto.czm.convivo.UserInfo}
 */
proto.czm.convivo.Case.prototype.getDevicesurgeondata = function () {
  return /** @type{?proto.czm.convivo.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.UserInfo, 6));
};


/**
 * @param {?proto.czm.convivo.UserInfo|undefined} value
 * @return {!proto.czm.convivo.Case} returns this
*/
proto.czm.convivo.Case.prototype.setDevicesurgeondata = function (value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.Case} returns this
 */
proto.czm.convivo.Case.prototype.clearDevicesurgeondata = function () {
  return this.setDevicesurgeondata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.Case.prototype.hasDevicesurgeondata = function () {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional UserInfo pathologistData = 7;
 * @return {?proto.czm.convivo.UserInfo}
 */
proto.czm.convivo.Case.prototype.getPathologistdata = function () {
  return /** @type{?proto.czm.convivo.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.UserInfo, 7));
};


/**
 * @param {?proto.czm.convivo.UserInfo|undefined} value
 * @return {!proto.czm.convivo.Case} returns this
*/
proto.czm.convivo.Case.prototype.setPathologistdata = function (value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.Case} returns this
 */
proto.czm.convivo.Case.prototype.clearPathologistdata = function () {
  return this.setPathologistdata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.Case.prototype.hasPathologistdata = function () {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional MedicalInfo medicalInfo = 8;
 * @return {?proto.czm.convivo.MedicalInfo}
 */
proto.czm.convivo.Case.prototype.getMedicalinfo = function () {
  return /** @type{?proto.czm.convivo.MedicalInfo} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.MedicalInfo, 8));
};


/**
 * @param {?proto.czm.convivo.MedicalInfo|undefined} value
 * @return {!proto.czm.convivo.Case} returns this
*/
proto.czm.convivo.Case.prototype.setMedicalinfo = function (value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.Case} returns this
 */
proto.czm.convivo.Case.prototype.clearMedicalinfo = function () {
  return this.setMedicalinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.Case.prototype.hasMedicalinfo = function () {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Notification notification = 9;
 * @return {?proto.czm.convivo.Notification}
 */
proto.czm.convivo.Case.prototype.getNotification = function () {
  return /** @type{?proto.czm.convivo.Notification} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.Notification, 9));
};


/**
 * @param {?proto.czm.convivo.Notification|undefined} value
 * @return {!proto.czm.convivo.Case} returns this
*/
proto.czm.convivo.Case.prototype.setNotification = function (value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.Case} returns this
 */
proto.czm.convivo.Case.prototype.clearNotification = function () {
  return this.setNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.Case.prototype.hasNotification = function () {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional GeneralStudy generalStudy = 10;
 * @return {?proto.czm.convivo.GeneralStudy}
 */
proto.czm.convivo.Case.prototype.getGeneralstudy = function () {
  return /** @type{?proto.czm.convivo.GeneralStudy} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.GeneralStudy, 10));
};


/**
 * @param {?proto.czm.convivo.GeneralStudy|undefined} value
 * @return {!proto.czm.convivo.Case} returns this
*/
proto.czm.convivo.Case.prototype.setGeneralstudy = function (value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.Case} returns this
 */
proto.czm.convivo.Case.prototype.clearGeneralstudy = function () {
  return this.setGeneralstudy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.Case.prototype.hasGeneralstudy = function () {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GeneralSeries generalSeries = 11;
 * @return {?proto.czm.convivo.GeneralSeries}
 */
proto.czm.convivo.Case.prototype.getGeneralseries = function () {
  return /** @type{?proto.czm.convivo.GeneralSeries} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.GeneralSeries, 11));
};


/**
 * @param {?proto.czm.convivo.GeneralSeries|undefined} value
 * @return {!proto.czm.convivo.Case} returns this
*/
proto.czm.convivo.Case.prototype.setGeneralseries = function (value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.Case} returns this
 */
proto.czm.convivo.Case.prototype.clearGeneralseries = function () {
  return this.setGeneralseries(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.Case.prototype.hasGeneralseries = function () {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional GeneralEquipment generalEquipment = 12;
 * @return {?proto.czm.convivo.GeneralEquipment}
 */
proto.czm.convivo.Case.prototype.getGeneralequipment = function () {
  return /** @type{?proto.czm.convivo.GeneralEquipment} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.GeneralEquipment, 12));
};


/**
 * @param {?proto.czm.convivo.GeneralEquipment|undefined} value
 * @return {!proto.czm.convivo.Case} returns this
*/
proto.czm.convivo.Case.prototype.setGeneralequipment = function (value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.Case} returns this
 */
proto.czm.convivo.Case.prototype.clearGeneralequipment = function () {
  return this.setGeneralequipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.Case.prototype.hasGeneralequipment = function () {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string status = 13;
 * @return {string}
 */
proto.czm.convivo.Case.prototype.getStatus = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Case} returns this
 */
proto.czm.convivo.Case.prototype.setStatus = function (value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string pathologistSiteName = 14;
 * @return {string}
 */
proto.czm.convivo.Case.prototype.getPathologistsitename = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Case} returns this
 */
proto.czm.convivo.Case.prototype.setPathologistsitename = function (value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string deviceSiteID = 15;
 * @return {string}
 */
proto.czm.convivo.Case.prototype.getDevicesiteid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Case} returns this
 */
proto.czm.convivo.Case.prototype.setDevicesiteid = function (value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string deviceSiteName = 16;
 * @return {string}
 */
proto.czm.convivo.Case.prototype.getDevicesitename = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Case} returns this
 */
proto.czm.convivo.Case.prototype.setDevicesitename = function (value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string startDateTime = 17;
 * @return {string}
 */
proto.czm.convivo.Case.prototype.getStartdatetime = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Case} returns this
 */
proto.czm.convivo.Case.prototype.setStartdatetime = function (value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * repeated PathologistInfo pathologistInfo = 18;
 * @return {!Array<!proto.czm.convivo.PathologistInfo>}
 */
proto.czm.convivo.Case.prototype.getPathologistinfoList = function () {
  return /** @type{!Array<!proto.czm.convivo.PathologistInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.PathologistInfo, 18));
};


/**
 * @param {!Array<!proto.czm.convivo.PathologistInfo>} value
 * @return {!proto.czm.convivo.Case} returns this
*/
proto.czm.convivo.Case.prototype.setPathologistinfoList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.czm.convivo.PathologistInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.PathologistInfo}
 */
proto.czm.convivo.Case.prototype.addPathologistinfo = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.czm.convivo.PathologistInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.Case} returns this
 */
proto.czm.convivo.Case.prototype.clearPathologistinfoList = function () {
  return this.setPathologistinfoList([]);
};


/**
 * optional string aliasCaseID = 19;
 * @return {string}
 */
proto.czm.convivo.Case.prototype.getAliascaseid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Case} returns this
 */
proto.czm.convivo.Case.prototype.setAliascaseid = function (value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string startDateTimeInUTC = 20;
 * @return {string}
 */
proto.czm.convivo.Case.prototype.getStartdatetimeinutc = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Case} returns this
 */
proto.czm.convivo.Case.prototype.setStartdatetimeinutc = function (value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.PathologistInfo.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.PathologistInfo.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.PathologistInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.PathologistInfo.toObject = function (includeInstance, msg) {
    var f, obj = {
      userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      datetime: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.PathologistInfo}
 */
proto.czm.convivo.PathologistInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.PathologistInfo;
  return proto.czm.convivo.PathologistInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.PathologistInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.PathologistInfo}
 */
proto.czm.convivo.PathologistInfo.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setUserid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setDatetime(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.PathologistInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.PathologistInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.PathologistInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.PathologistInfo.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDatetime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.czm.convivo.PathologistInfo.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.PathologistInfo} returns this
 */
proto.czm.convivo.PathologistInfo.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.czm.convivo.PathologistInfo.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.PathologistInfo} returns this
 */
proto.czm.convivo.PathologistInfo.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string dateTime = 3;
 * @return {string}
 */
proto.czm.convivo.PathologistInfo.prototype.getDatetime = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.PathologistInfo} returns this
 */
proto.czm.convivo.PathologistInfo.prototype.setDatetime = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.GeneralStudy.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.GeneralStudy.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.GeneralStudy} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.GeneralStudy.toObject = function (includeInstance, msg) {
    var f, obj = {
      referringphysicianname: jspb.Message.getFieldWithDefault(msg, 1, ""),
      studyid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      accessionnumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
      studydescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
      procedurecodesequence: jspb.Message.getFieldWithDefault(msg, 5, ""),
      studyinstanceuid: jspb.Message.getFieldWithDefault(msg, 6, ""),
      studydatetime: jspb.Message.getFieldWithDefault(msg, 7, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.GeneralStudy}
 */
proto.czm.convivo.GeneralStudy.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.GeneralStudy;
  return proto.czm.convivo.GeneralStudy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.GeneralStudy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.GeneralStudy}
 */
proto.czm.convivo.GeneralStudy.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setReferringphysicianname(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setStudyid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccessionnumber(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setStudydescription(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setProcedurecodesequence(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setStudyinstanceuid(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setStudydatetime(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.GeneralStudy.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.GeneralStudy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.GeneralStudy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.GeneralStudy.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getReferringphysicianname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStudyid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccessionnumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStudydescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProcedurecodesequence();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStudyinstanceuid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStudydatetime();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string referringPhysicianName = 1;
 * @return {string}
 */
proto.czm.convivo.GeneralStudy.prototype.getReferringphysicianname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.GeneralStudy} returns this
 */
proto.czm.convivo.GeneralStudy.prototype.setReferringphysicianname = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string studyID = 2;
 * @return {string}
 */
proto.czm.convivo.GeneralStudy.prototype.getStudyid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.GeneralStudy} returns this
 */
proto.czm.convivo.GeneralStudy.prototype.setStudyid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string accessionNumber = 3;
 * @return {string}
 */
proto.czm.convivo.GeneralStudy.prototype.getAccessionnumber = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.GeneralStudy} returns this
 */
proto.czm.convivo.GeneralStudy.prototype.setAccessionnumber = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string studyDescription = 4;
 * @return {string}
 */
proto.czm.convivo.GeneralStudy.prototype.getStudydescription = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.GeneralStudy} returns this
 */
proto.czm.convivo.GeneralStudy.prototype.setStudydescription = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string procedureCodeSequence = 5;
 * @return {string}
 */
proto.czm.convivo.GeneralStudy.prototype.getProcedurecodesequence = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.GeneralStudy} returns this
 */
proto.czm.convivo.GeneralStudy.prototype.setProcedurecodesequence = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string studyInstanceUid = 6;
 * @return {string}
 */
proto.czm.convivo.GeneralStudy.prototype.getStudyinstanceuid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.GeneralStudy} returns this
 */
proto.czm.convivo.GeneralStudy.prototype.setStudyinstanceuid = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string studyDateTime = 7;
 * @return {string}
 */
proto.czm.convivo.GeneralStudy.prototype.getStudydatetime = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.GeneralStudy} returns this
 */
proto.czm.convivo.GeneralStudy.prototype.setStudydatetime = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.GeneralSeries.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.GeneralSeries.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.GeneralSeries} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.GeneralSeries.toObject = function (includeInstance, msg) {
    var f, obj = {
      requestattributessequence: jspb.Message.getFieldWithDefault(msg, 1, ""),
      requestedprocedureid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      requestedproceduredescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
      requestedprocedurecodesequence: jspb.Message.getFieldWithDefault(msg, 4, ""),
      includecodesequencemacro: jspb.Message.getFieldWithDefault(msg, 5, ""),
      scheduledprocedurestepid: jspb.Message.getFieldWithDefault(msg, 6, ""),
      scheduledprocedurestepdescription: jspb.Message.getFieldWithDefault(msg, 7, ""),
      scheduledprotocolcodesequence: jspb.Message.getFieldWithDefault(msg, 8, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.GeneralSeries}
 */
proto.czm.convivo.GeneralSeries.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.GeneralSeries;
  return proto.czm.convivo.GeneralSeries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.GeneralSeries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.GeneralSeries}
 */
proto.czm.convivo.GeneralSeries.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setRequestattributessequence(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setRequestedprocedureid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setRequestedproceduredescription(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setRequestedprocedurecodesequence(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setIncludecodesequencemacro(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setScheduledprocedurestepid(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setScheduledprocedurestepdescription(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setScheduledprotocolcodesequence(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.GeneralSeries.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.GeneralSeries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.GeneralSeries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.GeneralSeries.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getRequestattributessequence();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequestedprocedureid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRequestedproceduredescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRequestedprocedurecodesequence();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIncludecodesequencemacro();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getScheduledprocedurestepid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getScheduledprocedurestepdescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getScheduledprotocolcodesequence();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string requestAttributesSequence = 1;
 * @return {string}
 */
proto.czm.convivo.GeneralSeries.prototype.getRequestattributessequence = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.GeneralSeries} returns this
 */
proto.czm.convivo.GeneralSeries.prototype.setRequestattributessequence = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string requestedProcedureID = 2;
 * @return {string}
 */
proto.czm.convivo.GeneralSeries.prototype.getRequestedprocedureid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.GeneralSeries} returns this
 */
proto.czm.convivo.GeneralSeries.prototype.setRequestedprocedureid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string requestedProcedureDescription = 3;
 * @return {string}
 */
proto.czm.convivo.GeneralSeries.prototype.getRequestedproceduredescription = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.GeneralSeries} returns this
 */
proto.czm.convivo.GeneralSeries.prototype.setRequestedproceduredescription = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string requestedProcedureCodeSequence = 4;
 * @return {string}
 */
proto.czm.convivo.GeneralSeries.prototype.getRequestedprocedurecodesequence = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.GeneralSeries} returns this
 */
proto.czm.convivo.GeneralSeries.prototype.setRequestedprocedurecodesequence = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string includeCodeSequenceMacro = 5;
 * @return {string}
 */
proto.czm.convivo.GeneralSeries.prototype.getIncludecodesequencemacro = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.GeneralSeries} returns this
 */
proto.czm.convivo.GeneralSeries.prototype.setIncludecodesequencemacro = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string scheduledProcedureStepID = 6;
 * @return {string}
 */
proto.czm.convivo.GeneralSeries.prototype.getScheduledprocedurestepid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.GeneralSeries} returns this
 */
proto.czm.convivo.GeneralSeries.prototype.setScheduledprocedurestepid = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string scheduledProcedureStepDescription = 7;
 * @return {string}
 */
proto.czm.convivo.GeneralSeries.prototype.getScheduledprocedurestepdescription = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.GeneralSeries} returns this
 */
proto.czm.convivo.GeneralSeries.prototype.setScheduledprocedurestepdescription = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string scheduledProtocolCodeSequence = 8;
 * @return {string}
 */
proto.czm.convivo.GeneralSeries.prototype.getScheduledprotocolcodesequence = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.GeneralSeries} returns this
 */
proto.czm.convivo.GeneralSeries.prototype.setScheduledprotocolcodesequence = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.GeneralEquipment.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.GeneralEquipment.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.GeneralEquipment} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.GeneralEquipment.toObject = function (includeInstance, msg) {
    var f, obj = {
      institutionname: jspb.Message.getFieldWithDefault(msg, 1, ""),
      institutionaddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
      stationname: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.GeneralEquipment}
 */
proto.czm.convivo.GeneralEquipment.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.GeneralEquipment;
  return proto.czm.convivo.GeneralEquipment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.GeneralEquipment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.GeneralEquipment}
 */
proto.czm.convivo.GeneralEquipment.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setInstitutionname(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setInstitutionaddress(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setStationname(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.GeneralEquipment.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.GeneralEquipment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.GeneralEquipment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.GeneralEquipment.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getInstitutionname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstitutionaddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStationname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string institutionName = 1;
 * @return {string}
 */
proto.czm.convivo.GeneralEquipment.prototype.getInstitutionname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.GeneralEquipment} returns this
 */
proto.czm.convivo.GeneralEquipment.prototype.setInstitutionname = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string institutionAddress = 2;
 * @return {string}
 */
proto.czm.convivo.GeneralEquipment.prototype.getInstitutionaddress = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.GeneralEquipment} returns this
 */
proto.czm.convivo.GeneralEquipment.prototype.setInstitutionaddress = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string stationName = 3;
 * @return {string}
 */
proto.czm.convivo.GeneralEquipment.prototype.getStationname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.GeneralEquipment} returns this
 */
proto.czm.convivo.GeneralEquipment.prototype.setStationname = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.MedicalInfo.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.MedicalInfo.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.MedicalInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.MedicalInfo.toObject = function (includeInstance, msg) {
    var f, obj = {
      tissuelocation: jspb.Message.getFieldWithDefault(msg, 1, ""),
      brainside: jspb.Message.getFieldWithDefault(msg, 2, ""),
      tissuetype: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.MedicalInfo}
 */
proto.czm.convivo.MedicalInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.MedicalInfo;
  return proto.czm.convivo.MedicalInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.MedicalInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.MedicalInfo}
 */
proto.czm.convivo.MedicalInfo.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTissuelocation(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setBrainside(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setTissuetype(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.MedicalInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.MedicalInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.MedicalInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.MedicalInfo.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTissuelocation();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBrainside();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTissuetype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string tissueLocation = 1;
 * @return {string}
 */
proto.czm.convivo.MedicalInfo.prototype.getTissuelocation = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.MedicalInfo} returns this
 */
proto.czm.convivo.MedicalInfo.prototype.setTissuelocation = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string brainSide = 2;
 * @return {string}
 */
proto.czm.convivo.MedicalInfo.prototype.getBrainside = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.MedicalInfo} returns this
 */
proto.czm.convivo.MedicalInfo.prototype.setBrainside = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tissueType = 3;
 * @return {string}
 */
proto.czm.convivo.MedicalInfo.prototype.getTissuetype = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.MedicalInfo} returns this
 */
proto.czm.convivo.MedicalInfo.prototype.setTissuetype = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.CaseEnd.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.CaseEnd.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.CaseEnd} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.CaseEnd.toObject = function (includeInstance, msg) {
    var f, obj = {
      caseid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      enddatetime: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.CaseEnd}
 */
proto.czm.convivo.CaseEnd.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.CaseEnd;
  return proto.czm.convivo.CaseEnd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.CaseEnd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.CaseEnd}
 */
proto.czm.convivo.CaseEnd.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCaseid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setEnddatetime(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.CaseEnd.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.CaseEnd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.CaseEnd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.CaseEnd.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCaseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnddatetime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string caseID = 1;
 * @return {string}
 */
proto.czm.convivo.CaseEnd.prototype.getCaseid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CaseEnd} returns this
 */
proto.czm.convivo.CaseEnd.prototype.setCaseid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string endDateTime = 2;
 * @return {string}
 */
proto.czm.convivo.CaseEnd.prototype.getEnddatetime = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CaseEnd} returns this
 */
proto.czm.convivo.CaseEnd.prototype.setEnddatetime = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.ScanStatus.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.ScanStatus.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.ScanStatus} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.ScanStatus.toObject = function (includeInstance, msg) {
    var f, obj = {
      active: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
      caseid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      galleryimagescount: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.ScanStatus}
 */
proto.czm.convivo.ScanStatus.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.ScanStatus;
  return proto.czm.convivo.ScanStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.ScanStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.ScanStatus}
 */
proto.czm.convivo.ScanStatus.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setActive(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCaseid(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setGalleryimagescount(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.ScanStatus.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.ScanStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.ScanStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.ScanStatus.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getActive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getCaseid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGalleryimagescount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional bool active = 1;
 * @return {boolean}
 */
proto.czm.convivo.ScanStatus.prototype.getActive = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.czm.convivo.ScanStatus} returns this
 */
proto.czm.convivo.ScanStatus.prototype.setActive = function (value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string caseID = 2;
 * @return {string}
 */
proto.czm.convivo.ScanStatus.prototype.getCaseid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ScanStatus} returns this
 */
proto.czm.convivo.ScanStatus.prototype.setCaseid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 galleryImagesCount = 3;
 * @return {number}
 */
proto.czm.convivo.ScanStatus.prototype.getGalleryimagescount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.ScanStatus} returns this
 */
proto.czm.convivo.ScanStatus.prototype.setGalleryimagescount = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.Image.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.Image.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.Image} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.Image.toObject = function (includeInstance, msg) {
    var f, obj = {
      format: jspb.Message.getFieldWithDefault(msg, 1, 0),
      data: msg.getData_asB64(),
      resolutionwidth: jspb.Message.getFieldWithDefault(msg, 3, 0),
      resolutionheight: jspb.Message.getFieldWithDefault(msg, 4, 0),
      targetresolutionwidth: jspb.Message.getFieldWithDefault(msg, 5, 0),
      targetresolutionheight: jspb.Message.getFieldWithDefault(msg, 6, 0),
      timestamp: jspb.Message.getFieldWithDefault(msg, 7, 0),
      spot: (f = msg.getSpot()) && proto.czm.convivo.Spot.toObject(includeInstance, f),
      contentdate: jspb.Message.getFieldWithDefault(msg, 9, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.Image}
 */
proto.czm.convivo.Image.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.Image;
  return proto.czm.convivo.Image.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.Image} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.Image}
 */
proto.czm.convivo.Image.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.czm.convivo.ImageFormat} */ (reader.readEnum());
        msg.setFormat(value);
        break;
      case 2:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setData(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setResolutionwidth(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setResolutionheight(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setTargetresolutionwidth(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setTargetresolutionheight(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setTimestamp(value);
        break;
      case 8:
        var value = new proto.czm.convivo.Spot;
        reader.readMessage(value, proto.czm.convivo.Spot.deserializeBinaryFromReader);
        msg.setSpot(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setContentdate(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.Image.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.Image.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.Image} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.Image.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getResolutionwidth();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getResolutionheight();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTargetresolutionwidth();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTargetresolutionheight();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getSpot();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.czm.convivo.Spot.serializeBinaryToWriter
    );
  }
  f = message.getContentdate();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional ImageFormat format = 1;
 * @return {!proto.czm.convivo.ImageFormat}
 */
proto.czm.convivo.Image.prototype.getFormat = function () {
  return /** @type {!proto.czm.convivo.ImageFormat} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.czm.convivo.ImageFormat} value
 * @return {!proto.czm.convivo.Image} returns this
 */
proto.czm.convivo.Image.prototype.setFormat = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bytes data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.czm.convivo.Image.prototype.getData = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.czm.convivo.Image.prototype.getData_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
    this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.czm.convivo.Image.prototype.getData_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
    this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.czm.convivo.Image} returns this
 */
proto.czm.convivo.Image.prototype.setData = function (value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional int32 resolutionWidth = 3;
 * @return {number}
 */
proto.czm.convivo.Image.prototype.getResolutionwidth = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.Image} returns this
 */
proto.czm.convivo.Image.prototype.setResolutionwidth = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 resolutionHeight = 4;
 * @return {number}
 */
proto.czm.convivo.Image.prototype.getResolutionheight = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.Image} returns this
 */
proto.czm.convivo.Image.prototype.setResolutionheight = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 targetResolutionWidth = 5;
 * @return {number}
 */
proto.czm.convivo.Image.prototype.getTargetresolutionwidth = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.Image} returns this
 */
proto.czm.convivo.Image.prototype.setTargetresolutionwidth = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 targetResolutionHeight = 6;
 * @return {number}
 */
proto.czm.convivo.Image.prototype.getTargetresolutionheight = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.Image} returns this
 */
proto.czm.convivo.Image.prototype.setTargetresolutionheight = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 timestamp = 7;
 * @return {number}
 */
proto.czm.convivo.Image.prototype.getTimestamp = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.Image} returns this
 */
proto.czm.convivo.Image.prototype.setTimestamp = function (value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional Spot spot = 8;
 * @return {?proto.czm.convivo.Spot}
 */
proto.czm.convivo.Image.prototype.getSpot = function () {
  return /** @type{?proto.czm.convivo.Spot} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.Spot, 8));
};


/**
 * @param {?proto.czm.convivo.Spot|undefined} value
 * @return {!proto.czm.convivo.Image} returns this
*/
proto.czm.convivo.Image.prototype.setSpot = function (value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.Image} returns this
 */
proto.czm.convivo.Image.prototype.clearSpot = function () {
  return this.setSpot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.Image.prototype.hasSpot = function () {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string contentDate = 9;
 * @return {string}
 */
proto.czm.convivo.Image.prototype.getContentdate = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Image} returns this
 */
proto.czm.convivo.Image.prototype.setContentdate = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.ImageMeta.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.ImageMeta.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.ImageMeta} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.ImageMeta.toObject = function (includeInstance, msg) {
    var f, obj = {
      focus: jspb.Message.getFieldWithDefault(msg, 1, 0),
      contrast: jspb.Message.getFieldWithDefault(msg, 2, 0),
      autocontrast: jspb.Message.getFieldWithDefault(msg, 3, 0),
      gain: jspb.Message.getFieldWithDefault(msg, 4, 0),
      isautocontrast: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
      laserpower: jspb.Message.getFieldWithDefault(msg, 6, 0),
      calibratedfocusdepth: jspb.Message.getFieldWithDefault(msg, 7, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.ImageMeta}
 */
proto.czm.convivo.ImageMeta.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.ImageMeta;
  return proto.czm.convivo.ImageMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.ImageMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.ImageMeta}
 */
proto.czm.convivo.ImageMeta.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setFocus(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setContrast(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setAutocontrast(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setGain(value);
        break;
      case 5:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsautocontrast(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setLaserpower(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCalibratedfocusdepth(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.ImageMeta.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.ImageMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.ImageMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.ImageMeta.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFocus();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getContrast();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAutocontrast();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getGain();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getIsautocontrast();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getLaserpower();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getCalibratedfocusdepth();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 focus = 1;
 * @return {number}
 */
proto.czm.convivo.ImageMeta.prototype.getFocus = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.ImageMeta} returns this
 */
proto.czm.convivo.ImageMeta.prototype.setFocus = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 contrast = 2;
 * @return {number}
 */
proto.czm.convivo.ImageMeta.prototype.getContrast = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.ImageMeta} returns this
 */
proto.czm.convivo.ImageMeta.prototype.setContrast = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 autoContrast = 3;
 * @return {number}
 */
proto.czm.convivo.ImageMeta.prototype.getAutocontrast = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.ImageMeta} returns this
 */
proto.czm.convivo.ImageMeta.prototype.setAutocontrast = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 gain = 4;
 * @return {number}
 */
proto.czm.convivo.ImageMeta.prototype.getGain = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.ImageMeta} returns this
 */
proto.czm.convivo.ImageMeta.prototype.setGain = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool isAutoContrast = 5;
 * @return {boolean}
 */
proto.czm.convivo.ImageMeta.prototype.getIsautocontrast = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.czm.convivo.ImageMeta} returns this
 */
proto.czm.convivo.ImageMeta.prototype.setIsautocontrast = function (value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 laserPower = 6;
 * @return {number}
 */
proto.czm.convivo.ImageMeta.prototype.getLaserpower = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.ImageMeta} returns this
 */
proto.czm.convivo.ImageMeta.prototype.setLaserpower = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 calibratedFocusDepth = 7;
 * @return {number}
 */
proto.czm.convivo.ImageMeta.prototype.getCalibratedfocusdepth = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.ImageMeta} returns this
 */
proto.czm.convivo.ImageMeta.prototype.setCalibratedfocusdepth = function (value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.Spot.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.Spot.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.Spot} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.Spot.toObject = function (includeInstance, msg) {
    var f, obj = {
      label: jspb.Message.getFieldWithDefault(msg, 1, ""),
      colour: (f = msg.getColour()) && proto.czm.convivo.RGBColour.toObject(includeInstance, f),
      comment: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.Spot}
 */
proto.czm.convivo.Spot.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.Spot;
  return proto.czm.convivo.Spot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.Spot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.Spot}
 */
proto.czm.convivo.Spot.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setLabel(value);
        break;
      case 2:
        var value = new proto.czm.convivo.RGBColour;
        reader.readMessage(value, proto.czm.convivo.RGBColour.deserializeBinaryFromReader);
        msg.setColour(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setComment(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.Spot.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.Spot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.Spot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.Spot.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColour();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.czm.convivo.RGBColour.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.czm.convivo.Spot.prototype.getLabel = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Spot} returns this
 */
proto.czm.convivo.Spot.prototype.setLabel = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RGBColour colour = 2;
 * @return {?proto.czm.convivo.RGBColour}
 */
proto.czm.convivo.Spot.prototype.getColour = function () {
  return /** @type{?proto.czm.convivo.RGBColour} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.RGBColour, 2));
};


/**
 * @param {?proto.czm.convivo.RGBColour|undefined} value
 * @return {!proto.czm.convivo.Spot} returns this
*/
proto.czm.convivo.Spot.prototype.setColour = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.Spot} returns this
 */
proto.czm.convivo.Spot.prototype.clearColour = function () {
  return this.setColour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.Spot.prototype.hasColour = function () {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.czm.convivo.Spot.prototype.getComment = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Spot} returns this
 */
proto.czm.convivo.Spot.prototype.setComment = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.RGBColour.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.RGBColour.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.RGBColour} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.RGBColour.toObject = function (includeInstance, msg) {
    var f, obj = {
      r: jspb.Message.getFieldWithDefault(msg, 1, 0),
      g: jspb.Message.getFieldWithDefault(msg, 2, 0),
      b: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.RGBColour}
 */
proto.czm.convivo.RGBColour.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.RGBColour;
  return proto.czm.convivo.RGBColour.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.RGBColour} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.RGBColour}
 */
proto.czm.convivo.RGBColour.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setR(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setG(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setB(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.RGBColour.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.RGBColour.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.RGBColour} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.RGBColour.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getR();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getG();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getB();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 r = 1;
 * @return {number}
 */
proto.czm.convivo.RGBColour.prototype.getR = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.RGBColour} returns this
 */
proto.czm.convivo.RGBColour.prototype.setR = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 g = 2;
 * @return {number}
 */
proto.czm.convivo.RGBColour.prototype.getG = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.RGBColour} returns this
 */
proto.czm.convivo.RGBColour.prototype.setG = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 b = 3;
 * @return {number}
 */
proto.czm.convivo.RGBColour.prototype.getB = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.RGBColour} returns this
 */
proto.czm.convivo.RGBColour.prototype.setB = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.Spots.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.Spots.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.Spots.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.Spots} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.Spots.toObject = function (includeInstance, msg) {
    var f, obj = {
      spotsList: jspb.Message.toObjectList(msg.getSpotsList(),
        proto.czm.convivo.SpotImagesCount.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.Spots}
 */
proto.czm.convivo.Spots.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.Spots;
  return proto.czm.convivo.Spots.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.Spots} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.Spots}
 */
proto.czm.convivo.Spots.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.czm.convivo.SpotImagesCount;
        reader.readMessage(value, proto.czm.convivo.SpotImagesCount.deserializeBinaryFromReader);
        msg.addSpots(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.Spots.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.Spots.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.Spots} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.Spots.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSpotsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.czm.convivo.SpotImagesCount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SpotImagesCount spots = 1;
 * @return {!Array<!proto.czm.convivo.SpotImagesCount>}
 */
proto.czm.convivo.Spots.prototype.getSpotsList = function () {
  return /** @type{!Array<!proto.czm.convivo.SpotImagesCount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.SpotImagesCount, 1));
};


/**
 * @param {!Array<!proto.czm.convivo.SpotImagesCount>} value
 * @return {!proto.czm.convivo.Spots} returns this
*/
proto.czm.convivo.Spots.prototype.setSpotsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.czm.convivo.SpotImagesCount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.SpotImagesCount}
 */
proto.czm.convivo.Spots.prototype.addSpots = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.czm.convivo.SpotImagesCount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.Spots} returns this
 */
proto.czm.convivo.Spots.prototype.clearSpotsList = function () {
  return this.setSpotsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.SpotImagesCount.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.SpotImagesCount.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.SpotImagesCount.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.SpotImagesCount} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.SpotImagesCount.toObject = function (includeInstance, msg) {
    var f, obj = {
      spot: jspb.Message.getFieldWithDefault(msg, 1, ""),
      imagetypecountList: jspb.Message.toObjectList(msg.getImagetypecountList(),
        proto.czm.convivo.ImageTypeCount.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.SpotImagesCount}
 */
proto.czm.convivo.SpotImagesCount.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.SpotImagesCount;
  return proto.czm.convivo.SpotImagesCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.SpotImagesCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.SpotImagesCount}
 */
proto.czm.convivo.SpotImagesCount.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setSpot(value);
        break;
      case 2:
        var value = new proto.czm.convivo.ImageTypeCount;
        reader.readMessage(value, proto.czm.convivo.ImageTypeCount.deserializeBinaryFromReader);
        msg.addImagetypecount(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.SpotImagesCount.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.SpotImagesCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.SpotImagesCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.SpotImagesCount.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSpot();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImagetypecountList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.czm.convivo.ImageTypeCount.serializeBinaryToWriter
    );
  }
};


/**
 * optional string spot = 1;
 * @return {string}
 */
proto.czm.convivo.SpotImagesCount.prototype.getSpot = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SpotImagesCount} returns this
 */
proto.czm.convivo.SpotImagesCount.prototype.setSpot = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ImageTypeCount imageTypeCount = 2;
 * @return {!Array<!proto.czm.convivo.ImageTypeCount>}
 */
proto.czm.convivo.SpotImagesCount.prototype.getImagetypecountList = function () {
  return /** @type{!Array<!proto.czm.convivo.ImageTypeCount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.ImageTypeCount, 2));
};


/**
 * @param {!Array<!proto.czm.convivo.ImageTypeCount>} value
 * @return {!proto.czm.convivo.SpotImagesCount} returns this
*/
proto.czm.convivo.SpotImagesCount.prototype.setImagetypecountList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.czm.convivo.ImageTypeCount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.ImageTypeCount}
 */
proto.czm.convivo.SpotImagesCount.prototype.addImagetypecount = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.czm.convivo.ImageTypeCount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.SpotImagesCount} returns this
 */
proto.czm.convivo.SpotImagesCount.prototype.clearImagetypecountList = function () {
  return this.setImagetypecountList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.ImageTypeCount.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.ImageTypeCount.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.ImageTypeCount} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.ImageTypeCount.toObject = function (includeInstance, msg) {
    var f, obj = {
      imagetype: jspb.Message.getFieldWithDefault(msg, 1, ""),
      count: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.ImageTypeCount}
 */
proto.czm.convivo.ImageTypeCount.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.ImageTypeCount;
  return proto.czm.convivo.ImageTypeCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.ImageTypeCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.ImageTypeCount}
 */
proto.czm.convivo.ImageTypeCount.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setImagetype(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCount(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.ImageTypeCount.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.ImageTypeCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.ImageTypeCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.ImageTypeCount.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getImagetype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string imageType = 1;
 * @return {string}
 */
proto.czm.convivo.ImageTypeCount.prototype.getImagetype = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ImageTypeCount} returns this
 */
proto.czm.convivo.ImageTypeCount.prototype.setImagetype = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.czm.convivo.ImageTypeCount.prototype.getCount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.ImageTypeCount} returns this
 */
proto.czm.convivo.ImageTypeCount.prototype.setCount = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.ImageForCase.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.ImageForCase.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.ImageForCase} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.ImageForCase.toObject = function (includeInstance, msg) {
    var f, obj = {
      image: (f = msg.getImage()) && proto.czm.convivo.Image.toObject(includeInstance, f),
      meta: (f = msg.getMeta()) && proto.czm.convivo.ImageMeta.toObject(includeInstance, f),
      caseid: jspb.Message.getFieldWithDefault(msg, 3, ""),
      counter: jspb.Message.getFieldWithDefault(msg, 4, 0),
      imageseriestype: (f = msg.getImageseriestype()) && proto.czm.convivo.ImageSeriesType.toObject(includeInstance, f),
      imageid: jspb.Message.getFieldWithDefault(msg, 6, 0),
      galleryimagescount: jspb.Message.getFieldWithDefault(msg, 7, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.ImageForCase}
 */
proto.czm.convivo.ImageForCase.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.ImageForCase;
  return proto.czm.convivo.ImageForCase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.ImageForCase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.ImageForCase}
 */
proto.czm.convivo.ImageForCase.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.czm.convivo.Image;
        reader.readMessage(value, proto.czm.convivo.Image.deserializeBinaryFromReader);
        msg.setImage(value);
        break;
      case 2:
        var value = new proto.czm.convivo.ImageMeta;
        reader.readMessage(value, proto.czm.convivo.ImageMeta.deserializeBinaryFromReader);
        msg.setMeta(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setCaseid(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setCounter(value);
        break;
      case 5:
        var value = new proto.czm.convivo.ImageSeriesType;
        reader.readMessage(value, proto.czm.convivo.ImageSeriesType.deserializeBinaryFromReader);
        msg.setImageseriestype(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setImageid(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setGalleryimagescount(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.ImageForCase.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.ImageForCase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.ImageForCase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.ImageForCase.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getImage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.czm.convivo.Image.serializeBinaryToWriter
    );
  }
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.czm.convivo.ImageMeta.serializeBinaryToWriter
    );
  }
  f = message.getCaseid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCounter();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getImageseriestype();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.czm.convivo.ImageSeriesType.serializeBinaryToWriter
    );
  }
  f = message.getImageid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getGalleryimagescount();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional Image image = 1;
 * @return {?proto.czm.convivo.Image}
 */
proto.czm.convivo.ImageForCase.prototype.getImage = function () {
  return /** @type{?proto.czm.convivo.Image} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.Image, 1));
};


/**
 * @param {?proto.czm.convivo.Image|undefined} value
 * @return {!proto.czm.convivo.ImageForCase} returns this
*/
proto.czm.convivo.ImageForCase.prototype.setImage = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.ImageForCase} returns this
 */
proto.czm.convivo.ImageForCase.prototype.clearImage = function () {
  return this.setImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.ImageForCase.prototype.hasImage = function () {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ImageMeta meta = 2;
 * @return {?proto.czm.convivo.ImageMeta}
 */
proto.czm.convivo.ImageForCase.prototype.getMeta = function () {
  return /** @type{?proto.czm.convivo.ImageMeta} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.ImageMeta, 2));
};


/**
 * @param {?proto.czm.convivo.ImageMeta|undefined} value
 * @return {!proto.czm.convivo.ImageForCase} returns this
*/
proto.czm.convivo.ImageForCase.prototype.setMeta = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.ImageForCase} returns this
 */
proto.czm.convivo.ImageForCase.prototype.clearMeta = function () {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.ImageForCase.prototype.hasMeta = function () {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string caseID = 3;
 * @return {string}
 */
proto.czm.convivo.ImageForCase.prototype.getCaseid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ImageForCase} returns this
 */
proto.czm.convivo.ImageForCase.prototype.setCaseid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 counter = 4;
 * @return {number}
 */
proto.czm.convivo.ImageForCase.prototype.getCounter = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.ImageForCase} returns this
 */
proto.czm.convivo.ImageForCase.prototype.setCounter = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional ImageSeriesType imageSeriesType = 5;
 * @return {?proto.czm.convivo.ImageSeriesType}
 */
proto.czm.convivo.ImageForCase.prototype.getImageseriestype = function () {
  return /** @type{?proto.czm.convivo.ImageSeriesType} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.ImageSeriesType, 5));
};


/**
 * @param {?proto.czm.convivo.ImageSeriesType|undefined} value
 * @return {!proto.czm.convivo.ImageForCase} returns this
*/
proto.czm.convivo.ImageForCase.prototype.setImageseriestype = function (value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.ImageForCase} returns this
 */
proto.czm.convivo.ImageForCase.prototype.clearImageseriestype = function () {
  return this.setImageseriestype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.ImageForCase.prototype.hasImageseriestype = function () {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 imageID = 6;
 * @return {number}
 */
proto.czm.convivo.ImageForCase.prototype.getImageid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.ImageForCase} returns this
 */
proto.czm.convivo.ImageForCase.prototype.setImageid = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 galleryImagesCount = 7;
 * @return {number}
 */
proto.czm.convivo.ImageForCase.prototype.getGalleryimagescount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.ImageForCase} returns this
 */
proto.czm.convivo.ImageForCase.prototype.setGalleryimagescount = function (value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.ImageSeriesType.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.ImageSeriesType.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.ImageSeriesType} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.ImageSeriesType.toObject = function (includeInstance, msg) {
    var f, obj = {
      type: jspb.Message.getFieldWithDefault(msg, 1, 0),
      groupid: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.ImageSeriesType}
 */
proto.czm.convivo.ImageSeriesType.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.ImageSeriesType;
  return proto.czm.convivo.ImageSeriesType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.ImageSeriesType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.ImageSeriesType}
 */
proto.czm.convivo.ImageSeriesType.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.czm.convivo.ImageType} */ (reader.readEnum());
        msg.setType(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.ImageSeriesType.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.ImageSeriesType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.ImageSeriesType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.ImageSeriesType.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getGroupid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ImageType type = 1;
 * @return {!proto.czm.convivo.ImageType}
 */
proto.czm.convivo.ImageSeriesType.prototype.getType = function () {
  return /** @type {!proto.czm.convivo.ImageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.czm.convivo.ImageType} value
 * @return {!proto.czm.convivo.ImageSeriesType} returns this
 */
proto.czm.convivo.ImageSeriesType.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string groupID = 2;
 * @return {string}
 */
proto.czm.convivo.ImageSeriesType.prototype.getGroupid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ImageSeriesType} returns this
 */
proto.czm.convivo.ImageSeriesType.prototype.setGroupid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.CaptureImage.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.CaptureImage.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.CaptureImage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.CaptureImage.toObject = function (includeInstance, msg) {
    var f, obj = {
      imageid: jspb.Message.getFieldWithDefault(msg, 1, 0),
      contrast: jspb.Message.getFieldWithDefault(msg, 2, 0),
      brightness: jspb.Message.getFieldWithDefault(msg, 3, 0),
      imagetype: jspb.Message.getFieldWithDefault(msg, 4, 0),
      capturetype: jspb.Message.getFieldWithDefault(msg, 5, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.CaptureImage}
 */
proto.czm.convivo.CaptureImage.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.CaptureImage;
  return proto.czm.convivo.CaptureImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.CaptureImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.CaptureImage}
 */
proto.czm.convivo.CaptureImage.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setImageid(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setContrast(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setBrightness(value);
        break;
      case 4:
        var value = /** @type {!proto.czm.convivo.ImageType} */ (reader.readEnum());
        msg.setImagetype(value);
        break;
      case 5:
        var value = /** @type {!proto.czm.convivo.CaptureType} */ (reader.readEnum());
        msg.setCapturetype(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.CaptureImage.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.CaptureImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.CaptureImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.CaptureImage.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getImageid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getContrast();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getBrightness();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getImagetype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCapturetype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int32 imageID = 1;
 * @return {number}
 */
proto.czm.convivo.CaptureImage.prototype.getImageid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.CaptureImage} returns this
 */
proto.czm.convivo.CaptureImage.prototype.setImageid = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 contrast = 2;
 * @return {number}
 */
proto.czm.convivo.CaptureImage.prototype.getContrast = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.CaptureImage} returns this
 */
proto.czm.convivo.CaptureImage.prototype.setContrast = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 brightness = 3;
 * @return {number}
 */
proto.czm.convivo.CaptureImage.prototype.getBrightness = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.CaptureImage} returns this
 */
proto.czm.convivo.CaptureImage.prototype.setBrightness = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional ImageType imageType = 4;
 * @return {!proto.czm.convivo.ImageType}
 */
proto.czm.convivo.CaptureImage.prototype.getImagetype = function () {
  return /** @type {!proto.czm.convivo.ImageType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.czm.convivo.ImageType} value
 * @return {!proto.czm.convivo.CaptureImage} returns this
 */
proto.czm.convivo.CaptureImage.prototype.setImagetype = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional CaptureType captureType = 5;
 * @return {!proto.czm.convivo.CaptureType}
 */
proto.czm.convivo.CaptureImage.prototype.getCapturetype = function () {
  return /** @type {!proto.czm.convivo.CaptureType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.czm.convivo.CaptureType} value
 * @return {!proto.czm.convivo.CaptureImage} returns this
 */
proto.czm.convivo.CaptureImage.prototype.setCapturetype = function (value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.Patient.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.Patient.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.Patient} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.Patient.toObject = function (includeInstance, msg) {
    var f, obj = {
      patientid: (f = msg.getPatientid()) && proto.czm.convivo.PatientID.toObject(includeInstance, f),
      sex: jspb.Message.getFieldWithDefault(msg, 2, 0),
      firstname: jspb.Message.getFieldWithDefault(msg, 3, ""),
      lastname: jspb.Message.getFieldWithDefault(msg, 4, ""),
      dateofbirth: jspb.Message.getFieldWithDefault(msg, 5, ""),
      comment: jspb.Message.getFieldWithDefault(msg, 6, ""),
      age: jspb.Message.getFieldWithDefault(msg, 7, 0),
      otherpatientid: jspb.Message.getFieldWithDefault(msg, 8, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.Patient}
 */
proto.czm.convivo.Patient.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.Patient;
  return proto.czm.convivo.Patient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.Patient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.Patient}
 */
proto.czm.convivo.Patient.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.czm.convivo.PatientID;
        reader.readMessage(value, proto.czm.convivo.PatientID.deserializeBinaryFromReader);
        msg.setPatientid(value);
        break;
      case 2:
        var value = /** @type {!proto.czm.convivo.Sex} */ (reader.readEnum());
        msg.setSex(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setFirstname(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setLastname(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setDateofbirth(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setComment(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setAge(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setOtherpatientid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.Patient.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.Patient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.Patient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.Patient.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPatientid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.czm.convivo.PatientID.serializeBinaryToWriter
    );
  }
  f = message.getSex();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDateofbirth();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAge();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getOtherpatientid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional PatientID patientID = 1;
 * @return {?proto.czm.convivo.PatientID}
 */
proto.czm.convivo.Patient.prototype.getPatientid = function () {
  return /** @type{?proto.czm.convivo.PatientID} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.PatientID, 1));
};


/**
 * @param {?proto.czm.convivo.PatientID|undefined} value
 * @return {!proto.czm.convivo.Patient} returns this
*/
proto.czm.convivo.Patient.prototype.setPatientid = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.Patient} returns this
 */
proto.czm.convivo.Patient.prototype.clearPatientid = function () {
  return this.setPatientid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.Patient.prototype.hasPatientid = function () {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Sex sex = 2;
 * @return {!proto.czm.convivo.Sex}
 */
proto.czm.convivo.Patient.prototype.getSex = function () {
  return /** @type {!proto.czm.convivo.Sex} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.czm.convivo.Sex} value
 * @return {!proto.czm.convivo.Patient} returns this
 */
proto.czm.convivo.Patient.prototype.setSex = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string firstName = 3;
 * @return {string}
 */
proto.czm.convivo.Patient.prototype.getFirstname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Patient} returns this
 */
proto.czm.convivo.Patient.prototype.setFirstname = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string lastName = 4;
 * @return {string}
 */
proto.czm.convivo.Patient.prototype.getLastname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Patient} returns this
 */
proto.czm.convivo.Patient.prototype.setLastname = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string dateOfBirth = 5;
 * @return {string}
 */
proto.czm.convivo.Patient.prototype.getDateofbirth = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Patient} returns this
 */
proto.czm.convivo.Patient.prototype.setDateofbirth = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string comment = 6;
 * @return {string}
 */
proto.czm.convivo.Patient.prototype.getComment = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Patient} returns this
 */
proto.czm.convivo.Patient.prototype.setComment = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 age = 7;
 * @return {number}
 */
proto.czm.convivo.Patient.prototype.getAge = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.Patient} returns this
 */
proto.czm.convivo.Patient.prototype.setAge = function (value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string otherPatientID = 8;
 * @return {string}
 */
proto.czm.convivo.Patient.prototype.getOtherpatientid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Patient} returns this
 */
proto.czm.convivo.Patient.prototype.setOtherpatientid = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.PatientID.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.PatientID.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.PatientID} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.PatientID.toObject = function (includeInstance, msg) {
    var f, obj = {
      patientid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      issuer: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.PatientID}
 */
proto.czm.convivo.PatientID.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.PatientID;
  return proto.czm.convivo.PatientID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.PatientID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.PatientID}
 */
proto.czm.convivo.PatientID.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setPatientid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setIssuer(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.PatientID.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.PatientID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.PatientID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.PatientID.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPatientid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIssuer();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string patientID = 1;
 * @return {string}
 */
proto.czm.convivo.PatientID.prototype.getPatientid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.PatientID} returns this
 */
proto.czm.convivo.PatientID.prototype.setPatientid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string issuer = 2;
 * @return {string}
 */
proto.czm.convivo.PatientID.prototype.getIssuer = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.PatientID} returns this
 */
proto.czm.convivo.PatientID.prototype.setIssuer = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.AvailablePathologists.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.AvailablePathologists.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.AvailablePathologists.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.AvailablePathologists} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.AvailablePathologists.toObject = function (includeInstance, msg) {
    var f, obj = {
      pathologistsList: jspb.Message.toObjectList(msg.getPathologistsList(),
        proto.czm.convivo.Pathologist.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.AvailablePathologists}
 */
proto.czm.convivo.AvailablePathologists.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.AvailablePathologists;
  return proto.czm.convivo.AvailablePathologists.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.AvailablePathologists} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.AvailablePathologists}
 */
proto.czm.convivo.AvailablePathologists.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.czm.convivo.Pathologist;
        reader.readMessage(value, proto.czm.convivo.Pathologist.deserializeBinaryFromReader);
        msg.addPathologists(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.AvailablePathologists.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.AvailablePathologists.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.AvailablePathologists} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.AvailablePathologists.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPathologistsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.czm.convivo.Pathologist.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Pathologist pathologists = 1;
 * @return {!Array<!proto.czm.convivo.Pathologist>}
 */
proto.czm.convivo.AvailablePathologists.prototype.getPathologistsList = function () {
  return /** @type{!Array<!proto.czm.convivo.Pathologist>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.Pathologist, 1));
};


/**
 * @param {!Array<!proto.czm.convivo.Pathologist>} value
 * @return {!proto.czm.convivo.AvailablePathologists} returns this
*/
proto.czm.convivo.AvailablePathologists.prototype.setPathologistsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.czm.convivo.Pathologist=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.Pathologist}
 */
proto.czm.convivo.AvailablePathologists.prototype.addPathologists = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.czm.convivo.Pathologist, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.AvailablePathologists} returns this
 */
proto.czm.convivo.AvailablePathologists.prototype.clearPathologistsList = function () {
  return this.setPathologistsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.Pathologist.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.Pathologist.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.Pathologist} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.Pathologist.toObject = function (includeInstance, msg) {
    var f, obj = {
      userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      title: jspb.Message.getFieldWithDefault(msg, 2, ""),
      firstname: jspb.Message.getFieldWithDefault(msg, 3, ""),
      lastname: jspb.Message.getFieldWithDefault(msg, 4, ""),
      emailaddress: jspb.Message.getFieldWithDefault(msg, 5, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.Pathologist}
 */
proto.czm.convivo.Pathologist.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.Pathologist;
  return proto.czm.convivo.Pathologist.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.Pathologist} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.Pathologist}
 */
proto.czm.convivo.Pathologist.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setUserid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setTitle(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setFirstname(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setLastname(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setEmailaddress(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.Pathologist.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.Pathologist.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.Pathologist} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.Pathologist.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmailaddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.czm.convivo.Pathologist.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Pathologist} returns this
 */
proto.czm.convivo.Pathologist.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.czm.convivo.Pathologist.prototype.getTitle = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Pathologist} returns this
 */
proto.czm.convivo.Pathologist.prototype.setTitle = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string firstName = 3;
 * @return {string}
 */
proto.czm.convivo.Pathologist.prototype.getFirstname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Pathologist} returns this
 */
proto.czm.convivo.Pathologist.prototype.setFirstname = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string lastName = 4;
 * @return {string}
 */
proto.czm.convivo.Pathologist.prototype.getLastname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Pathologist} returns this
 */
proto.czm.convivo.Pathologist.prototype.setLastname = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string emailAddress = 5;
 * @return {string}
 */
proto.czm.convivo.Pathologist.prototype.getEmailaddress = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Pathologist} returns this
 */
proto.czm.convivo.Pathologist.prototype.setEmailaddress = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.UserInfo.repeatedFields_ = [5, 11];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.UserInfo.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.UserInfo.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.UserInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.UserInfo.toObject = function (includeInstance, msg) {
    var f, obj = {
      firstname: jspb.Message.getFieldWithDefault(msg, 1, ""),
      lastname: jspb.Message.getFieldWithDefault(msg, 2, ""),
      userid: jspb.Message.getFieldWithDefault(msg, 3, ""),
      emailaddress: jspb.Message.getFieldWithDefault(msg, 4, ""),
      roleList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
      title: jspb.Message.getFieldWithDefault(msg, 6, ""),
      accountid: jspb.Message.getFieldWithDefault(msg, 7, ""),
      phonenumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
      issiteadmin: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
      isaccountadmin: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
      roleinfoList: jspb.Message.toObjectList(msg.getRoleinfoList(),
        proto.czm.convivo.RoleInfo.toObject, includeInstance),
      status: jspb.Message.getFieldWithDefault(msg, 12, ""),
      lastlogin: jspb.Message.getFieldWithDefault(msg, 13, ""),
      language: jspb.Message.getFieldWithDefault(msg, 14, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.UserInfo}
 */
proto.czm.convivo.UserInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.UserInfo;
  return proto.czm.convivo.UserInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.UserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.UserInfo}
 */
proto.czm.convivo.UserInfo.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setFirstname(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setLastname(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setUserid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setEmailaddress(value);
        break;
      case 5:
        var value = /** @type {!Array<!proto.czm.convivo.UserInfo.Role>} */ (reader.readPackedEnum());
        msg.setRoleList(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setTitle(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccountid(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setPhonenumber(value);
        break;
      case 9:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIssiteadmin(value);
        break;
      case 10:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsaccountadmin(value);
        break;
      case 11:
        var value = new proto.czm.convivo.RoleInfo;
        reader.readMessage(value, proto.czm.convivo.RoleInfo.deserializeBinaryFromReader);
        msg.addRoleinfo(value);
        break;
      case 12:
        var value = /** @type {string} */ (reader.readString());
        msg.setStatus(value);
        break;
      case 13:
        var value = /** @type {string} */ (reader.readString());
        msg.setLastlogin(value);
        break;
      case 14:
        var value = /** @type {string} */ (reader.readString());
        msg.setLanguage(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.UserInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.UserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.UserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.UserInfo.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmailaddress();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRoleList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIssiteadmin();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsaccountadmin();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getRoleinfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.czm.convivo.RoleInfo.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLastlogin();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.czm.convivo.UserInfo.Role = {
  NONE: 0,
  SURGEON: 1,
  PATHOLOGIST: 2
};

/**
 * optional string firstName = 1;
 * @return {string}
 */
proto.czm.convivo.UserInfo.prototype.getFirstname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UserInfo} returns this
 */
proto.czm.convivo.UserInfo.prototype.setFirstname = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string lastName = 2;
 * @return {string}
 */
proto.czm.convivo.UserInfo.prototype.getLastname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UserInfo} returns this
 */
proto.czm.convivo.UserInfo.prototype.setLastname = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string userID = 3;
 * @return {string}
 */
proto.czm.convivo.UserInfo.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UserInfo} returns this
 */
proto.czm.convivo.UserInfo.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string emailAddress = 4;
 * @return {string}
 */
proto.czm.convivo.UserInfo.prototype.getEmailaddress = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UserInfo} returns this
 */
proto.czm.convivo.UserInfo.prototype.setEmailaddress = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Role role = 5;
 * @return {!Array<!proto.czm.convivo.UserInfo.Role>}
 */
proto.czm.convivo.UserInfo.prototype.getRoleList = function () {
  return /** @type {!Array<!proto.czm.convivo.UserInfo.Role>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.czm.convivo.UserInfo.Role>} value
 * @return {!proto.czm.convivo.UserInfo} returns this
 */
proto.czm.convivo.UserInfo.prototype.setRoleList = function (value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.czm.convivo.UserInfo.Role} value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.UserInfo} returns this
 */
proto.czm.convivo.UserInfo.prototype.addRole = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.UserInfo} returns this
 */
proto.czm.convivo.UserInfo.prototype.clearRoleList = function () {
  return this.setRoleList([]);
};


/**
 * optional string title = 6;
 * @return {string}
 */
proto.czm.convivo.UserInfo.prototype.getTitle = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UserInfo} returns this
 */
proto.czm.convivo.UserInfo.prototype.setTitle = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string accountID = 7;
 * @return {string}
 */
proto.czm.convivo.UserInfo.prototype.getAccountid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UserInfo} returns this
 */
proto.czm.convivo.UserInfo.prototype.setAccountid = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string phoneNumber = 8;
 * @return {string}
 */
proto.czm.convivo.UserInfo.prototype.getPhonenumber = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UserInfo} returns this
 */
proto.czm.convivo.UserInfo.prototype.setPhonenumber = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool isSiteAdmin = 9;
 * @return {boolean}
 */
proto.czm.convivo.UserInfo.prototype.getIssiteadmin = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.czm.convivo.UserInfo} returns this
 */
proto.czm.convivo.UserInfo.prototype.setIssiteadmin = function (value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool isAccountAdmin = 10;
 * @return {boolean}
 */
proto.czm.convivo.UserInfo.prototype.getIsaccountadmin = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.czm.convivo.UserInfo} returns this
 */
proto.czm.convivo.UserInfo.prototype.setIsaccountadmin = function (value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * repeated RoleInfo roleInfo = 11;
 * @return {!Array<!proto.czm.convivo.RoleInfo>}
 */
proto.czm.convivo.UserInfo.prototype.getRoleinfoList = function () {
  return /** @type{!Array<!proto.czm.convivo.RoleInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.RoleInfo, 11));
};


/**
 * @param {!Array<!proto.czm.convivo.RoleInfo>} value
 * @return {!proto.czm.convivo.UserInfo} returns this
*/
proto.czm.convivo.UserInfo.prototype.setRoleinfoList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.czm.convivo.RoleInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.RoleInfo}
 */
proto.czm.convivo.UserInfo.prototype.addRoleinfo = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.czm.convivo.RoleInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.UserInfo} returns this
 */
proto.czm.convivo.UserInfo.prototype.clearRoleinfoList = function () {
  return this.setRoleinfoList([]);
};


/**
 * optional string status = 12;
 * @return {string}
 */
proto.czm.convivo.UserInfo.prototype.getStatus = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UserInfo} returns this
 */
proto.czm.convivo.UserInfo.prototype.setStatus = function (value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string lastLogin = 13;
 * @return {string}
 */
proto.czm.convivo.UserInfo.prototype.getLastlogin = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UserInfo} returns this
 */
proto.czm.convivo.UserInfo.prototype.setLastlogin = function (value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string language = 14;
 * @return {string}
 */
proto.czm.convivo.UserInfo.prototype.getLanguage = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UserInfo} returns this
 */
proto.czm.convivo.UserInfo.prototype.setLanguage = function (value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.RoleInfo.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.RoleInfo.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.RoleInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.RoleInfo.toObject = function (includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      weightage: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.RoleInfo}
 */
proto.czm.convivo.RoleInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.RoleInfo;
  return proto.czm.convivo.RoleInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.RoleInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.RoleInfo}
 */
proto.czm.convivo.RoleInfo.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setWeightage(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.RoleInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.RoleInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.RoleInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.RoleInfo.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWeightage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.czm.convivo.RoleInfo.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.RoleInfo} returns this
 */
proto.czm.convivo.RoleInfo.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.czm.convivo.RoleInfo.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.RoleInfo} returns this
 */
proto.czm.convivo.RoleInfo.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 weightage = 3;
 * @return {number}
 */
proto.czm.convivo.RoleInfo.prototype.getWeightage = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.RoleInfo} returns this
 */
proto.czm.convivo.RoleInfo.prototype.setWeightage = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.CreateResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.CreateResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.CreateResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.CreateResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      aliascaseid: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.CreateResponse}
 */
proto.czm.convivo.CreateResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.CreateResponse;
  return proto.czm.convivo.CreateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.CreateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.CreateResponse}
 */
proto.czm.convivo.CreateResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setAliascaseid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.CreateResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.CreateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.CreateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.CreateResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAliascaseid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.czm.convivo.CreateResponse.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CreateResponse} returns this
 */
proto.czm.convivo.CreateResponse.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string aliasCaseID = 2;
 * @return {string}
 */
proto.czm.convivo.CreateResponse.prototype.getAliascaseid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CreateResponse} returns this
 */
proto.czm.convivo.CreateResponse.prototype.setAliascaseid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.Site.repeatedFields_ = [15, 17];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.Site.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.Site.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.Site} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.Site.toObject = function (includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      phonenumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
      description: jspb.Message.getFieldWithDefault(msg, 4, ""),
      isdefaultsite: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
      status: jspb.Message.getFieldWithDefault(msg, 6, 0),
      accountid: jspb.Message.getFieldWithDefault(msg, 7, ""),
      accountname: jspb.Message.getFieldWithDefault(msg, 8, ""),
      address1: jspb.Message.getFieldWithDefault(msg, 9, ""),
      address2: jspb.Message.getFieldWithDefault(msg, 10, ""),
      createdby: jspb.Message.getFieldWithDefault(msg, 11, ""),
      createddatetime: jspb.Message.getFieldWithDefault(msg, 12, ""),
      updatedby: jspb.Message.getFieldWithDefault(msg, 13, ""),
      updateddatetime: jspb.Message.getFieldWithDefault(msg, 14, ""),
      adminsList: jspb.Message.toObjectList(msg.getAdminsList(),
        proto.czm.convivo.UserInfo.toObject, includeInstance),
      userscount: jspb.Message.getFieldWithDefault(msg, 16, 0),
      defaultsiteassociationsList: jspb.Message.toObjectList(msg.getDefaultsiteassociationsList(),
        proto.czm.convivo.DefaultSiteAssociation.toObject, includeInstance),
      userstatus: jspb.Message.getFieldWithDefault(msg, 18, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.Site}
 */
proto.czm.convivo.Site.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.Site;
  return proto.czm.convivo.Site.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.Site} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.Site}
 */
proto.czm.convivo.Site.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setPhonenumber(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      case 5:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsdefaultsite(value);
        break;
      case 6:
        var value = /** @type {!proto.czm.convivo.SiteStatus} */ (reader.readEnum());
        msg.setStatus(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccountid(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccountname(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setAddress1(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setAddress2(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setCreatedby(value);
        break;
      case 12:
        var value = /** @type {string} */ (reader.readString());
        msg.setCreateddatetime(value);
        break;
      case 13:
        var value = /** @type {string} */ (reader.readString());
        msg.setUpdatedby(value);
        break;
      case 14:
        var value = /** @type {string} */ (reader.readString());
        msg.setUpdateddatetime(value);
        break;
      case 15:
        var value = new proto.czm.convivo.UserInfo;
        reader.readMessage(value, proto.czm.convivo.UserInfo.deserializeBinaryFromReader);
        msg.addAdmins(value);
        break;
      case 16:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setUserscount(value);
        break;
      case 17:
        var value = new proto.czm.convivo.DefaultSiteAssociation;
        reader.readMessage(value, proto.czm.convivo.DefaultSiteAssociation.deserializeBinaryFromReader);
        msg.addDefaultsiteassociations(value);
        break;
      case 18:
        var value = /** @type {!proto.czm.convivo.Site.UserStatus} */ (reader.readEnum());
        msg.setUserstatus(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.Site.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.Site.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.Site} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.Site.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsdefaultsite();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAccountname();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAddress1();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCreatedby();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCreateddatetime();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUpdatedby();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getUpdateddatetime();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAdminsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.czm.convivo.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getUserscount();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getDefaultsiteassociationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.czm.convivo.DefaultSiteAssociation.serializeBinaryToWriter
    );
  }
  f = message.getUserstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.czm.convivo.Site.UserStatus = {
  ACTIVE: 0,
  INACTIVE: 1,
  INVITED: 2
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.czm.convivo.Site.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Site} returns this
 */
proto.czm.convivo.Site.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.czm.convivo.Site.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Site} returns this
 */
proto.czm.convivo.Site.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phoneNumber = 3;
 * @return {string}
 */
proto.czm.convivo.Site.prototype.getPhonenumber = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Site} returns this
 */
proto.czm.convivo.Site.prototype.setPhonenumber = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.czm.convivo.Site.prototype.getDescription = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Site} returns this
 */
proto.czm.convivo.Site.prototype.setDescription = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool isDefaultSite = 5;
 * @return {boolean}
 */
proto.czm.convivo.Site.prototype.getIsdefaultsite = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.czm.convivo.Site} returns this
 */
proto.czm.convivo.Site.prototype.setIsdefaultsite = function (value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional SiteStatus status = 6;
 * @return {!proto.czm.convivo.SiteStatus}
 */
proto.czm.convivo.Site.prototype.getStatus = function () {
  return /** @type {!proto.czm.convivo.SiteStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.czm.convivo.SiteStatus} value
 * @return {!proto.czm.convivo.Site} returns this
 */
proto.czm.convivo.Site.prototype.setStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string accountId = 7;
 * @return {string}
 */
proto.czm.convivo.Site.prototype.getAccountid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Site} returns this
 */
proto.czm.convivo.Site.prototype.setAccountid = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string AccountName = 8;
 * @return {string}
 */
proto.czm.convivo.Site.prototype.getAccountname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Site} returns this
 */
proto.czm.convivo.Site.prototype.setAccountname = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string address1 = 9;
 * @return {string}
 */
proto.czm.convivo.Site.prototype.getAddress1 = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Site} returns this
 */
proto.czm.convivo.Site.prototype.setAddress1 = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string address2 = 10;
 * @return {string}
 */
proto.czm.convivo.Site.prototype.getAddress2 = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Site} returns this
 */
proto.czm.convivo.Site.prototype.setAddress2 = function (value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string createdBy = 11;
 * @return {string}
 */
proto.czm.convivo.Site.prototype.getCreatedby = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Site} returns this
 */
proto.czm.convivo.Site.prototype.setCreatedby = function (value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string createdDateTime = 12;
 * @return {string}
 */
proto.czm.convivo.Site.prototype.getCreateddatetime = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Site} returns this
 */
proto.czm.convivo.Site.prototype.setCreateddatetime = function (value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string updatedBy = 13;
 * @return {string}
 */
proto.czm.convivo.Site.prototype.getUpdatedby = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Site} returns this
 */
proto.czm.convivo.Site.prototype.setUpdatedby = function (value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string updatedDateTime = 14;
 * @return {string}
 */
proto.czm.convivo.Site.prototype.getUpdateddatetime = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Site} returns this
 */
proto.czm.convivo.Site.prototype.setUpdateddatetime = function (value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * repeated UserInfo Admins = 15;
 * @return {!Array<!proto.czm.convivo.UserInfo>}
 */
proto.czm.convivo.Site.prototype.getAdminsList = function () {
  return /** @type{!Array<!proto.czm.convivo.UserInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.UserInfo, 15));
};


/**
 * @param {!Array<!proto.czm.convivo.UserInfo>} value
 * @return {!proto.czm.convivo.Site} returns this
*/
proto.czm.convivo.Site.prototype.setAdminsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.czm.convivo.UserInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.UserInfo}
 */
proto.czm.convivo.Site.prototype.addAdmins = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.czm.convivo.UserInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.Site} returns this
 */
proto.czm.convivo.Site.prototype.clearAdminsList = function () {
  return this.setAdminsList([]);
};


/**
 * optional int32 usersCount = 16;
 * @return {number}
 */
proto.czm.convivo.Site.prototype.getUserscount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.Site} returns this
 */
proto.czm.convivo.Site.prototype.setUserscount = function (value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * repeated DefaultSiteAssociation defaultSiteAssociations = 17;
 * @return {!Array<!proto.czm.convivo.DefaultSiteAssociation>}
 */
proto.czm.convivo.Site.prototype.getDefaultsiteassociationsList = function () {
  return /** @type{!Array<!proto.czm.convivo.DefaultSiteAssociation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.DefaultSiteAssociation, 17));
};


/**
 * @param {!Array<!proto.czm.convivo.DefaultSiteAssociation>} value
 * @return {!proto.czm.convivo.Site} returns this
*/
proto.czm.convivo.Site.prototype.setDefaultsiteassociationsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.czm.convivo.DefaultSiteAssociation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.DefaultSiteAssociation}
 */
proto.czm.convivo.Site.prototype.addDefaultsiteassociations = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.czm.convivo.DefaultSiteAssociation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.Site} returns this
 */
proto.czm.convivo.Site.prototype.clearDefaultsiteassociationsList = function () {
  return this.setDefaultsiteassociationsList([]);
};


/**
 * optional UserStatus userStatus = 18;
 * @return {!proto.czm.convivo.Site.UserStatus}
 */
proto.czm.convivo.Site.prototype.getUserstatus = function () {
  return /** @type {!proto.czm.convivo.Site.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.czm.convivo.Site.UserStatus} value
 * @return {!proto.czm.convivo.Site} returns this
 */
proto.czm.convivo.Site.prototype.setUserstatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.PatientInfo.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.PatientInfo.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.PatientInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.PatientInfo.toObject = function (includeInstance, msg) {
    var f, obj = {
      patientid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
      givenname: jspb.Message.getFieldWithDefault(msg, 3, ""),
      dob: jspb.Message.getFieldWithDefault(msg, 4, ""),
      gender: jspb.Message.getFieldWithDefault(msg, 5, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.PatientInfo}
 */
proto.czm.convivo.PatientInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.PatientInfo;
  return proto.czm.convivo.PatientInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.PatientInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.PatientInfo}
 */
proto.czm.convivo.PatientInfo.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setPatientid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setFirstname(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setGivenname(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setDob(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setGender(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.PatientInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.PatientInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.PatientInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.PatientInfo.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPatientid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGivenname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDob();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGender();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string patientId = 1;
 * @return {string}
 */
proto.czm.convivo.PatientInfo.prototype.getPatientid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.PatientInfo} returns this
 */
proto.czm.convivo.PatientInfo.prototype.setPatientid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string firstName = 2;
 * @return {string}
 */
proto.czm.convivo.PatientInfo.prototype.getFirstname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.PatientInfo} returns this
 */
proto.czm.convivo.PatientInfo.prototype.setFirstname = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string givenName = 3;
 * @return {string}
 */
proto.czm.convivo.PatientInfo.prototype.getGivenname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.PatientInfo} returns this
 */
proto.czm.convivo.PatientInfo.prototype.setGivenname = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string dob = 4;
 * @return {string}
 */
proto.czm.convivo.PatientInfo.prototype.getDob = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.PatientInfo} returns this
 */
proto.czm.convivo.PatientInfo.prototype.setDob = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string gender = 5;
 * @return {string}
 */
proto.czm.convivo.PatientInfo.prototype.getGender = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.PatientInfo} returns this
 */
proto.czm.convivo.PatientInfo.prototype.setGender = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.PhysicianInfo.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.PhysicianInfo.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.PhysicianInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.PhysicianInfo.toObject = function (includeInstance, msg) {
    var f, obj = {
      fullname: jspb.Message.getFieldWithDefault(msg, 1, ""),
      email: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.PhysicianInfo}
 */
proto.czm.convivo.PhysicianInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.PhysicianInfo;
  return proto.czm.convivo.PhysicianInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.PhysicianInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.PhysicianInfo}
 */
proto.czm.convivo.PhysicianInfo.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setFullname(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setEmail(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.PhysicianInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.PhysicianInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.PhysicianInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.PhysicianInfo.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFullname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string fullName = 1;
 * @return {string}
 */
proto.czm.convivo.PhysicianInfo.prototype.getFullname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.PhysicianInfo} returns this
 */
proto.czm.convivo.PhysicianInfo.prototype.setFullname = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.czm.convivo.PhysicianInfo.prototype.getEmail = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.PhysicianInfo} returns this
 */
proto.czm.convivo.PhysicianInfo.prototype.setEmail = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.CaseResponse.repeatedFields_ = [15, 16];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.CaseResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.CaseResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.CaseResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.CaseResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      caseid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      isresearchdata: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      patient: (f = msg.getPatient()) && proto.czm.convivo.Patient.toObject(includeInstance, f),
      devicesurgeondata: (f = msg.getDevicesurgeondata()) && proto.czm.convivo.UserInfo.toObject(includeInstance, f),
      medicalinfo: (f = msg.getMedicalinfo()) && proto.czm.convivo.MedicalInfo.toObject(includeInstance, f),
      devicesiteid: jspb.Message.getFieldWithDefault(msg, 6, ""),
      devicesitename: jspb.Message.getFieldWithDefault(msg, 7, ""),
      consultationsiteid: jspb.Message.getFieldWithDefault(msg, 8, ""),
      consultationsitename: jspb.Message.getFieldWithDefault(msg, 9, ""),
      casestatus: jspb.Message.getFieldWithDefault(msg, 10, 0),
      additionalinfo: (f = msg.getAdditionalinfo()) && proto.czm.convivo.AdditionalInfo.toObject(includeInstance, f),
      createddate: jspb.Message.getFieldWithDefault(msg, 12, ""),
      interpretationtext: jspb.Message.getFieldWithDefault(msg, 13, ""),
      comment: jspb.Message.getFieldWithDefault(msg, 14, ""),
      externalfilesList: jspb.Message.toObjectList(msg.getExternalfilesList(),
        proto.czm.convivo.File.toObject, includeInstance),
      reportsList: jspb.Message.toObjectList(msg.getReportsList(),
        proto.czm.convivo.Report.toObject, includeInstance),
      casealiasid: jspb.Message.getFieldWithDefault(msg, 17, ""),
      numberofimages: jspb.Message.getFieldWithDefault(msg, 18, 0),
      casestartdatetime: jspb.Message.getFieldWithDefault(msg, 19, ""),
      accountid: jspb.Message.getFieldWithDefault(msg, 20, ""),
      caseenddate: jspb.Message.getFieldWithDefault(msg, 21, ""),
      duration: jspb.Message.getFieldWithDefault(msg, 22, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.CaseResponse}
 */
proto.czm.convivo.CaseResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.CaseResponse;
  return proto.czm.convivo.CaseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.CaseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.CaseResponse}
 */
proto.czm.convivo.CaseResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCaseid(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsresearchdata(value);
        break;
      case 3:
        var value = new proto.czm.convivo.Patient;
        reader.readMessage(value, proto.czm.convivo.Patient.deserializeBinaryFromReader);
        msg.setPatient(value);
        break;
      case 4:
        var value = new proto.czm.convivo.UserInfo;
        reader.readMessage(value, proto.czm.convivo.UserInfo.deserializeBinaryFromReader);
        msg.setDevicesurgeondata(value);
        break;
      case 5:
        var value = new proto.czm.convivo.MedicalInfo;
        reader.readMessage(value, proto.czm.convivo.MedicalInfo.deserializeBinaryFromReader);
        msg.setMedicalinfo(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setDevicesiteid(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setDevicesitename(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setConsultationsiteid(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setConsultationsitename(value);
        break;
      case 10:
        var value = /** @type {!proto.czm.convivo.CaseStatus} */ (reader.readEnum());
        msg.setCasestatus(value);
        break;
      case 11:
        var value = new proto.czm.convivo.AdditionalInfo;
        reader.readMessage(value, proto.czm.convivo.AdditionalInfo.deserializeBinaryFromReader);
        msg.setAdditionalinfo(value);
        break;
      case 12:
        var value = /** @type {string} */ (reader.readString());
        msg.setCreateddate(value);
        break;
      case 13:
        var value = /** @type {string} */ (reader.readString());
        msg.setInterpretationtext(value);
        break;
      case 14:
        var value = /** @type {string} */ (reader.readString());
        msg.setComment(value);
        break;
      case 15:
        var value = new proto.czm.convivo.File;
        reader.readMessage(value, proto.czm.convivo.File.deserializeBinaryFromReader);
        msg.addExternalfiles(value);
        break;
      case 16:
        var value = new proto.czm.convivo.Report;
        reader.readMessage(value, proto.czm.convivo.Report.deserializeBinaryFromReader);
        msg.addReports(value);
        break;
      case 17:
        var value = /** @type {string} */ (reader.readString());
        msg.setCasealiasid(value);
        break;
      case 18:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setNumberofimages(value);
        break;
      case 19:
        var value = /** @type {string} */ (reader.readString());
        msg.setCasestartdatetime(value);
        break;
      case 20:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccountid(value);
        break;
      case 21:
        var value = /** @type {string} */ (reader.readString());
        msg.setCaseenddate(value);
        break;
      case 22:
        var value = /** @type {string} */ (reader.readString());
        msg.setDuration(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.CaseResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.CaseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.CaseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.CaseResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCaseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsresearchdata();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getPatient();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.czm.convivo.Patient.serializeBinaryToWriter
    );
  }
  f = message.getDevicesurgeondata();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.czm.convivo.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getMedicalinfo();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.czm.convivo.MedicalInfo.serializeBinaryToWriter
    );
  }
  f = message.getDevicesiteid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDevicesitename();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getConsultationsiteid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getConsultationsitename();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCasestatus();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getAdditionalinfo();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.czm.convivo.AdditionalInfo.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getInterpretationtext();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getExternalfilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.czm.convivo.File.serializeBinaryToWriter
    );
  }
  f = message.getReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.czm.convivo.Report.serializeBinaryToWriter
    );
  }
  f = message.getCasealiasid();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getNumberofimages();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getCasestartdatetime();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getCaseenddate();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getDuration();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
};


/**
 * optional string caseID = 1;
 * @return {string}
 */
proto.czm.convivo.CaseResponse.prototype.getCaseid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CaseResponse} returns this
 */
proto.czm.convivo.CaseResponse.prototype.setCaseid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isResearchData = 2;
 * @return {boolean}
 */
proto.czm.convivo.CaseResponse.prototype.getIsresearchdata = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.czm.convivo.CaseResponse} returns this
 */
proto.czm.convivo.CaseResponse.prototype.setIsresearchdata = function (value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional Patient patient = 3;
 * @return {?proto.czm.convivo.Patient}
 */
proto.czm.convivo.CaseResponse.prototype.getPatient = function () {
  return /** @type{?proto.czm.convivo.Patient} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.Patient, 3));
};


/**
 * @param {?proto.czm.convivo.Patient|undefined} value
 * @return {!proto.czm.convivo.CaseResponse} returns this
*/
proto.czm.convivo.CaseResponse.prototype.setPatient = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.CaseResponse} returns this
 */
proto.czm.convivo.CaseResponse.prototype.clearPatient = function () {
  return this.setPatient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.CaseResponse.prototype.hasPatient = function () {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional UserInfo deviceSurgeonData = 4;
 * @return {?proto.czm.convivo.UserInfo}
 */
proto.czm.convivo.CaseResponse.prototype.getDevicesurgeondata = function () {
  return /** @type{?proto.czm.convivo.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.UserInfo, 4));
};


/**
 * @param {?proto.czm.convivo.UserInfo|undefined} value
 * @return {!proto.czm.convivo.CaseResponse} returns this
*/
proto.czm.convivo.CaseResponse.prototype.setDevicesurgeondata = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.CaseResponse} returns this
 */
proto.czm.convivo.CaseResponse.prototype.clearDevicesurgeondata = function () {
  return this.setDevicesurgeondata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.CaseResponse.prototype.hasDevicesurgeondata = function () {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional MedicalInfo medicalInfo = 5;
 * @return {?proto.czm.convivo.MedicalInfo}
 */
proto.czm.convivo.CaseResponse.prototype.getMedicalinfo = function () {
  return /** @type{?proto.czm.convivo.MedicalInfo} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.MedicalInfo, 5));
};


/**
 * @param {?proto.czm.convivo.MedicalInfo|undefined} value
 * @return {!proto.czm.convivo.CaseResponse} returns this
*/
proto.czm.convivo.CaseResponse.prototype.setMedicalinfo = function (value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.CaseResponse} returns this
 */
proto.czm.convivo.CaseResponse.prototype.clearMedicalinfo = function () {
  return this.setMedicalinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.CaseResponse.prototype.hasMedicalinfo = function () {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string deviceSiteID = 6;
 * @return {string}
 */
proto.czm.convivo.CaseResponse.prototype.getDevicesiteid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CaseResponse} returns this
 */
proto.czm.convivo.CaseResponse.prototype.setDevicesiteid = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string deviceSiteName = 7;
 * @return {string}
 */
proto.czm.convivo.CaseResponse.prototype.getDevicesitename = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CaseResponse} returns this
 */
proto.czm.convivo.CaseResponse.prototype.setDevicesitename = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string consultationSiteID = 8;
 * @return {string}
 */
proto.czm.convivo.CaseResponse.prototype.getConsultationsiteid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CaseResponse} returns this
 */
proto.czm.convivo.CaseResponse.prototype.setConsultationsiteid = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string consultationSiteName = 9;
 * @return {string}
 */
proto.czm.convivo.CaseResponse.prototype.getConsultationsitename = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CaseResponse} returns this
 */
proto.czm.convivo.CaseResponse.prototype.setConsultationsitename = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional CaseStatus caseStatus = 10;
 * @return {!proto.czm.convivo.CaseStatus}
 */
proto.czm.convivo.CaseResponse.prototype.getCasestatus = function () {
  return /** @type {!proto.czm.convivo.CaseStatus} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.czm.convivo.CaseStatus} value
 * @return {!proto.czm.convivo.CaseResponse} returns this
 */
proto.czm.convivo.CaseResponse.prototype.setCasestatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional AdditionalInfo additionalInfo = 11;
 * @return {?proto.czm.convivo.AdditionalInfo}
 */
proto.czm.convivo.CaseResponse.prototype.getAdditionalinfo = function () {
  return /** @type{?proto.czm.convivo.AdditionalInfo} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.AdditionalInfo, 11));
};


/**
 * @param {?proto.czm.convivo.AdditionalInfo|undefined} value
 * @return {!proto.czm.convivo.CaseResponse} returns this
*/
proto.czm.convivo.CaseResponse.prototype.setAdditionalinfo = function (value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.CaseResponse} returns this
 */
proto.czm.convivo.CaseResponse.prototype.clearAdditionalinfo = function () {
  return this.setAdditionalinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.CaseResponse.prototype.hasAdditionalinfo = function () {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string createdDate = 12;
 * @return {string}
 */
proto.czm.convivo.CaseResponse.prototype.getCreateddate = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CaseResponse} returns this
 */
proto.czm.convivo.CaseResponse.prototype.setCreateddate = function (value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string interpretationText = 13;
 * @return {string}
 */
proto.czm.convivo.CaseResponse.prototype.getInterpretationtext = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CaseResponse} returns this
 */
proto.czm.convivo.CaseResponse.prototype.setInterpretationtext = function (value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string comment = 14;
 * @return {string}
 */
proto.czm.convivo.CaseResponse.prototype.getComment = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CaseResponse} returns this
 */
proto.czm.convivo.CaseResponse.prototype.setComment = function (value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * repeated File externalFiles = 15;
 * @return {!Array<!proto.czm.convivo.File>}
 */
proto.czm.convivo.CaseResponse.prototype.getExternalfilesList = function () {
  return /** @type{!Array<!proto.czm.convivo.File>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.File, 15));
};


/**
 * @param {!Array<!proto.czm.convivo.File>} value
 * @return {!proto.czm.convivo.CaseResponse} returns this
*/
proto.czm.convivo.CaseResponse.prototype.setExternalfilesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.czm.convivo.File=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.File}
 */
proto.czm.convivo.CaseResponse.prototype.addExternalfiles = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.czm.convivo.File, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.CaseResponse} returns this
 */
proto.czm.convivo.CaseResponse.prototype.clearExternalfilesList = function () {
  return this.setExternalfilesList([]);
};


/**
 * repeated Report reports = 16;
 * @return {!Array<!proto.czm.convivo.Report>}
 */
proto.czm.convivo.CaseResponse.prototype.getReportsList = function () {
  return /** @type{!Array<!proto.czm.convivo.Report>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.Report, 16));
};


/**
 * @param {!Array<!proto.czm.convivo.Report>} value
 * @return {!proto.czm.convivo.CaseResponse} returns this
*/
proto.czm.convivo.CaseResponse.prototype.setReportsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.czm.convivo.Report=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.Report}
 */
proto.czm.convivo.CaseResponse.prototype.addReports = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.czm.convivo.Report, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.CaseResponse} returns this
 */
proto.czm.convivo.CaseResponse.prototype.clearReportsList = function () {
  return this.setReportsList([]);
};


/**
 * optional string caseAliasID = 17;
 * @return {string}
 */
proto.czm.convivo.CaseResponse.prototype.getCasealiasid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CaseResponse} returns this
 */
proto.czm.convivo.CaseResponse.prototype.setCasealiasid = function (value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional int32 numberOfImages = 18;
 * @return {number}
 */
proto.czm.convivo.CaseResponse.prototype.getNumberofimages = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.CaseResponse} returns this
 */
proto.czm.convivo.CaseResponse.prototype.setNumberofimages = function (value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string caseStartDateTime = 19;
 * @return {string}
 */
proto.czm.convivo.CaseResponse.prototype.getCasestartdatetime = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CaseResponse} returns this
 */
proto.czm.convivo.CaseResponse.prototype.setCasestartdatetime = function (value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string accountID = 20;
 * @return {string}
 */
proto.czm.convivo.CaseResponse.prototype.getAccountid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CaseResponse} returns this
 */
proto.czm.convivo.CaseResponse.prototype.setAccountid = function (value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string caseEndDate = 21;
 * @return {string}
 */
proto.czm.convivo.CaseResponse.prototype.getCaseenddate = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CaseResponse} returns this
 */
proto.czm.convivo.CaseResponse.prototype.setCaseenddate = function (value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string duration = 22;
 * @return {string}
 */
proto.czm.convivo.CaseResponse.prototype.getDuration = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CaseResponse} returns this
 */
proto.czm.convivo.CaseResponse.prototype.setDuration = function (value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.AdditionalInfo.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.AdditionalInfo.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.AdditionalInfo.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.AdditionalInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.AdditionalInfo.toObject = function (includeInstance, msg) {
    var f, obj = {
      alerttimer: jspb.Message.getFieldWithDefault(msg, 1, 0),
      pathologistinfoList: jspb.Message.toObjectList(msg.getPathologistinfoList(),
        proto.czm.convivo.PathologistInfo.toObject, includeInstance),
      thumbnailurl: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.AdditionalInfo}
 */
proto.czm.convivo.AdditionalInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.AdditionalInfo;
  return proto.czm.convivo.AdditionalInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.AdditionalInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.AdditionalInfo}
 */
proto.czm.convivo.AdditionalInfo.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setAlerttimer(value);
        break;
      case 2:
        var value = new proto.czm.convivo.PathologistInfo;
        reader.readMessage(value, proto.czm.convivo.PathologistInfo.deserializeBinaryFromReader);
        msg.addPathologistinfo(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setThumbnailurl(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.AdditionalInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.AdditionalInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.AdditionalInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.AdditionalInfo.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getAlerttimer();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPathologistinfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.czm.convivo.PathologistInfo.serializeBinaryToWriter
    );
  }
  f = message.getThumbnailurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 alertTimer = 1;
 * @return {number}
 */
proto.czm.convivo.AdditionalInfo.prototype.getAlerttimer = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.AdditionalInfo} returns this
 */
proto.czm.convivo.AdditionalInfo.prototype.setAlerttimer = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated PathologistInfo pathologistInfo = 2;
 * @return {!Array<!proto.czm.convivo.PathologistInfo>}
 */
proto.czm.convivo.AdditionalInfo.prototype.getPathologistinfoList = function () {
  return /** @type{!Array<!proto.czm.convivo.PathologistInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.PathologistInfo, 2));
};


/**
 * @param {!Array<!proto.czm.convivo.PathologistInfo>} value
 * @return {!proto.czm.convivo.AdditionalInfo} returns this
*/
proto.czm.convivo.AdditionalInfo.prototype.setPathologistinfoList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.czm.convivo.PathologistInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.PathologistInfo}
 */
proto.czm.convivo.AdditionalInfo.prototype.addPathologistinfo = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.czm.convivo.PathologistInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.AdditionalInfo} returns this
 */
proto.czm.convivo.AdditionalInfo.prototype.clearPathologistinfoList = function () {
  return this.setPathologistinfoList([]);
};


/**
 * optional string thumbnailUrl = 3;
 * @return {string}
 */
proto.czm.convivo.AdditionalInfo.prototype.getThumbnailurl = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.AdditionalInfo} returns this
 */
proto.czm.convivo.AdditionalInfo.prototype.setThumbnailurl = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.Report.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.Report.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.Report.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.Report} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.Report.toObject = function (includeInstance, msg) {
    var f, obj = {
      imageidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      reportfilename: jspb.Message.getFieldWithDefault(msg, 2, ""),
      reportversion: jspb.Message.getFieldWithDefault(msg, 3, ""),
      reporturl: jspb.Message.getFieldWithDefault(msg, 4, ""),
      updatedby: jspb.Message.getFieldWithDefault(msg, 5, ""),
      updateddatetime: jspb.Message.getFieldWithDefault(msg, 6, ""),
      reportdatetime: jspb.Message.getFieldWithDefault(msg, 7, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.Report}
 */
proto.czm.convivo.Report.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.Report;
  return proto.czm.convivo.Report.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.Report} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.Report}
 */
proto.czm.convivo.Report.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.addImageids(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setReportfilename(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setReportversion(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setReporturl(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setUpdatedby(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setUpdateddatetime(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setReportdatetime(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.Report.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.Report.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.Report} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.Report.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getImageidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getReportfilename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReportversion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReporturl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUpdatedby();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUpdateddatetime();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getReportdatetime();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * repeated string imageIDs = 1;
 * @return {!Array<string>}
 */
proto.czm.convivo.Report.prototype.getImageidsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.czm.convivo.Report} returns this
 */
proto.czm.convivo.Report.prototype.setImageidsList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.Report} returns this
 */
proto.czm.convivo.Report.prototype.addImageids = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.Report} returns this
 */
proto.czm.convivo.Report.prototype.clearImageidsList = function () {
  return this.setImageidsList([]);
};


/**
 * optional string reportFileName = 2;
 * @return {string}
 */
proto.czm.convivo.Report.prototype.getReportfilename = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Report} returns this
 */
proto.czm.convivo.Report.prototype.setReportfilename = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string reportVersion = 3;
 * @return {string}
 */
proto.czm.convivo.Report.prototype.getReportversion = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Report} returns this
 */
proto.czm.convivo.Report.prototype.setReportversion = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string reportURL = 4;
 * @return {string}
 */
proto.czm.convivo.Report.prototype.getReporturl = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Report} returns this
 */
proto.czm.convivo.Report.prototype.setReporturl = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string updatedBy = 5;
 * @return {string}
 */
proto.czm.convivo.Report.prototype.getUpdatedby = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Report} returns this
 */
proto.czm.convivo.Report.prototype.setUpdatedby = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string updatedDateTime = 6;
 * @return {string}
 */
proto.czm.convivo.Report.prototype.getUpdateddatetime = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Report} returns this
 */
proto.czm.convivo.Report.prototype.setUpdateddatetime = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string reportDateTime = 7;
 * @return {string}
 */
proto.czm.convivo.Report.prototype.getReportdatetime = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Report} returns this
 */
proto.czm.convivo.Report.prototype.setReportdatetime = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.BinaryReport.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.BinaryReport.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.BinaryReport} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.BinaryReport.toObject = function (includeInstance, msg) {
    var f, obj = {
      report: (f = msg.getReport()) && proto.czm.convivo.Report.toObject(includeInstance, f),
      data: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.BinaryReport}
 */
proto.czm.convivo.BinaryReport.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.BinaryReport;
  return proto.czm.convivo.BinaryReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.BinaryReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.BinaryReport}
 */
proto.czm.convivo.BinaryReport.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.czm.convivo.Report;
        reader.readMessage(value, proto.czm.convivo.Report.deserializeBinaryFromReader);
        msg.setReport(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setData(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.BinaryReport.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.BinaryReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.BinaryReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.BinaryReport.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getReport();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.czm.convivo.Report.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Report report = 1;
 * @return {?proto.czm.convivo.Report}
 */
proto.czm.convivo.BinaryReport.prototype.getReport = function () {
  return /** @type{?proto.czm.convivo.Report} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.Report, 1));
};


/**
 * @param {?proto.czm.convivo.Report|undefined} value
 * @return {!proto.czm.convivo.BinaryReport} returns this
*/
proto.czm.convivo.BinaryReport.prototype.setReport = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.BinaryReport} returns this
 */
proto.czm.convivo.BinaryReport.prototype.clearReport = function () {
  return this.setReport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.BinaryReport.prototype.hasReport = function () {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.czm.convivo.BinaryReport.prototype.getData = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.BinaryReport} returns this
 */
proto.czm.convivo.BinaryReport.prototype.setData = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.ReportStatusConfirmation.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.ReportStatusConfirmation.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.ReportStatusConfirmation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.ReportStatusConfirmation.toObject = function (includeInstance, msg) {
    var f, obj = {
      report: (f = msg.getReport()) && proto.czm.convivo.Report.toObject(includeInstance, f),
      reportstatus: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.ReportStatusConfirmation}
 */
proto.czm.convivo.ReportStatusConfirmation.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.ReportStatusConfirmation;
  return proto.czm.convivo.ReportStatusConfirmation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.ReportStatusConfirmation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.ReportStatusConfirmation}
 */
proto.czm.convivo.ReportStatusConfirmation.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.czm.convivo.Report;
        reader.readMessage(value, proto.czm.convivo.Report.deserializeBinaryFromReader);
        msg.setReport(value);
        break;
      case 2:
        var value = /** @type {!proto.czm.convivo.ReportStatus} */ (reader.readEnum());
        msg.setReportstatus(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.ReportStatusConfirmation.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.ReportStatusConfirmation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.ReportStatusConfirmation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.ReportStatusConfirmation.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getReport();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.czm.convivo.Report.serializeBinaryToWriter
    );
  }
  f = message.getReportstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional Report report = 1;
 * @return {?proto.czm.convivo.Report}
 */
proto.czm.convivo.ReportStatusConfirmation.prototype.getReport = function () {
  return /** @type{?proto.czm.convivo.Report} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.Report, 1));
};


/**
 * @param {?proto.czm.convivo.Report|undefined} value
 * @return {!proto.czm.convivo.ReportStatusConfirmation} returns this
*/
proto.czm.convivo.ReportStatusConfirmation.prototype.setReport = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.ReportStatusConfirmation} returns this
 */
proto.czm.convivo.ReportStatusConfirmation.prototype.clearReport = function () {
  return this.setReport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.ReportStatusConfirmation.prototype.hasReport = function () {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ReportStatus reportStatus = 2;
 * @return {!proto.czm.convivo.ReportStatus}
 */
proto.czm.convivo.ReportStatusConfirmation.prototype.getReportstatus = function () {
  return /** @type {!proto.czm.convivo.ReportStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.czm.convivo.ReportStatus} value
 * @return {!proto.czm.convivo.ReportStatusConfirmation} returns this
 */
proto.czm.convivo.ReportStatusConfirmation.prototype.setReportstatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.File.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.File.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.File} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.File.toObject = function (includeInstance, msg) {
    var f, obj = {
      filename: jspb.Message.getFieldWithDefault(msg, 1, ""),
      url: jspb.Message.getFieldWithDefault(msg, 2, ""),
      updatedby: jspb.Message.getFieldWithDefault(msg, 3, ""),
      updateddatetime: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.File}
 */
proto.czm.convivo.File.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.File;
  return proto.czm.convivo.File.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.File} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.File}
 */
proto.czm.convivo.File.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setFilename(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setUrl(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setUpdatedby(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setUpdateddatetime(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.File.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.File.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.File} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.File.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUpdatedby();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUpdateddatetime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string fileName = 1;
 * @return {string}
 */
proto.czm.convivo.File.prototype.getFilename = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.File} returns this
 */
proto.czm.convivo.File.prototype.setFilename = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.czm.convivo.File.prototype.getUrl = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.File} returns this
 */
proto.czm.convivo.File.prototype.setUrl = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string updatedBy = 3;
 * @return {string}
 */
proto.czm.convivo.File.prototype.getUpdatedby = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.File} returns this
 */
proto.czm.convivo.File.prototype.setUpdatedby = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string updatedDateTime = 4;
 * @return {string}
 */
proto.czm.convivo.File.prototype.getUpdateddatetime = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.File} returns this
 */
proto.czm.convivo.File.prototype.setUpdateddatetime = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.CasesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.CasesResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.CasesResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.CasesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.CasesResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      dataList: jspb.Message.toObjectList(msg.getDataList(),
        proto.czm.convivo.CaseResponse.toObject, includeInstance),
      continuationtoken: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.CasesResponse}
 */
proto.czm.convivo.CasesResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.CasesResponse;
  return proto.czm.convivo.CasesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.CasesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.CasesResponse}
 */
proto.czm.convivo.CasesResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.czm.convivo.CaseResponse;
        reader.readMessage(value, proto.czm.convivo.CaseResponse.deserializeBinaryFromReader);
        msg.addData(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setContinuationtoken(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.CasesResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.CasesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.CasesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.CasesResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.czm.convivo.CaseResponse.serializeBinaryToWriter
    );
  }
  f = message.getContinuationtoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated CaseResponse data = 1;
 * @return {!Array<!proto.czm.convivo.CaseResponse>}
 */
proto.czm.convivo.CasesResponse.prototype.getDataList = function () {
  return /** @type{!Array<!proto.czm.convivo.CaseResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.CaseResponse, 1));
};


/**
 * @param {!Array<!proto.czm.convivo.CaseResponse>} value
 * @return {!proto.czm.convivo.CasesResponse} returns this
*/
proto.czm.convivo.CasesResponse.prototype.setDataList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.czm.convivo.CaseResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.CaseResponse}
 */
proto.czm.convivo.CasesResponse.prototype.addData = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.czm.convivo.CaseResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.CasesResponse} returns this
 */
proto.czm.convivo.CasesResponse.prototype.clearDataList = function () {
  return this.setDataList([]);
};


/**
 * optional string continuationToken = 2;
 * @return {string}
 */
proto.czm.convivo.CasesResponse.prototype.getContinuationtoken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CasesResponse} returns this
 */
proto.czm.convivo.CasesResponse.prototype.setContinuationtoken = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.GetSitesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.GetSitesResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.GetSitesResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.GetSitesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.GetSitesResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      meta: (f = msg.getMeta()) && proto.czm.convivo.PaginationMeta.toObject(includeInstance, f),
      dataList: jspb.Message.toObjectList(msg.getDataList(),
        proto.czm.convivo.Site.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.GetSitesResponse}
 */
proto.czm.convivo.GetSitesResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.GetSitesResponse;
  return proto.czm.convivo.GetSitesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.GetSitesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.GetSitesResponse}
 */
proto.czm.convivo.GetSitesResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.czm.convivo.PaginationMeta;
        reader.readMessage(value, proto.czm.convivo.PaginationMeta.deserializeBinaryFromReader);
        msg.setMeta(value);
        break;
      case 2:
        var value = new proto.czm.convivo.Site;
        reader.readMessage(value, proto.czm.convivo.Site.deserializeBinaryFromReader);
        msg.addData(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.GetSitesResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.GetSitesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.GetSitesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.GetSitesResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.czm.convivo.PaginationMeta.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.czm.convivo.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional PaginationMeta meta = 1;
 * @return {?proto.czm.convivo.PaginationMeta}
 */
proto.czm.convivo.GetSitesResponse.prototype.getMeta = function () {
  return /** @type{?proto.czm.convivo.PaginationMeta} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.PaginationMeta, 1));
};


/**
 * @param {?proto.czm.convivo.PaginationMeta|undefined} value
 * @return {!proto.czm.convivo.GetSitesResponse} returns this
*/
proto.czm.convivo.GetSitesResponse.prototype.setMeta = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.GetSitesResponse} returns this
 */
proto.czm.convivo.GetSitesResponse.prototype.clearMeta = function () {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.GetSitesResponse.prototype.hasMeta = function () {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Site data = 2;
 * @return {!Array<!proto.czm.convivo.Site>}
 */
proto.czm.convivo.GetSitesResponse.prototype.getDataList = function () {
  return /** @type{!Array<!proto.czm.convivo.Site>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.Site, 2));
};


/**
 * @param {!Array<!proto.czm.convivo.Site>} value
 * @return {!proto.czm.convivo.GetSitesResponse} returns this
*/
proto.czm.convivo.GetSitesResponse.prototype.setDataList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.czm.convivo.Site=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.Site}
 */
proto.czm.convivo.GetSitesResponse.prototype.addData = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.czm.convivo.Site, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.GetSitesResponse} returns this
 */
proto.czm.convivo.GetSitesResponse.prototype.clearDataList = function () {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.SiteDetails.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.SiteDetails.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.SiteDetails} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.SiteDetails.toObject = function (includeInstance, msg) {
    var f, obj = {
      site: (f = msg.getSite()) && proto.czm.convivo.Site.toObject(includeInstance, f),
      customerid: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.SiteDetails}
 */
proto.czm.convivo.SiteDetails.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.SiteDetails;
  return proto.czm.convivo.SiteDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.SiteDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.SiteDetails}
 */
proto.czm.convivo.SiteDetails.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.czm.convivo.Site;
        reader.readMessage(value, proto.czm.convivo.Site.deserializeBinaryFromReader);
        msg.setSite(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCustomerid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.SiteDetails.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.SiteDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.SiteDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.SiteDetails.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.czm.convivo.Site.serializeBinaryToWriter
    );
  }
  f = message.getCustomerid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Site site = 1;
 * @return {?proto.czm.convivo.Site}
 */
proto.czm.convivo.SiteDetails.prototype.getSite = function () {
  return /** @type{?proto.czm.convivo.Site} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.Site, 1));
};


/**
 * @param {?proto.czm.convivo.Site|undefined} value
 * @return {!proto.czm.convivo.SiteDetails} returns this
*/
proto.czm.convivo.SiteDetails.prototype.setSite = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.SiteDetails} returns this
 */
proto.czm.convivo.SiteDetails.prototype.clearSite = function () {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.SiteDetails.prototype.hasSite = function () {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string customerID = 2;
 * @return {string}
 */
proto.czm.convivo.SiteDetails.prototype.getCustomerid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SiteDetails} returns this
 */
proto.czm.convivo.SiteDetails.prototype.setCustomerid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.PaginationMeta.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.PaginationMeta.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.PaginationMeta} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.PaginationMeta.toObject = function (includeInstance, msg) {
    var f, obj = {
      totalcount: jspb.Message.getFieldWithDefault(msg, 1, 0),
      pagecount: jspb.Message.getFieldWithDefault(msg, 2, 0),
      pagesize: jspb.Message.getFieldWithDefault(msg, 3, 0),
      currentpage: jspb.Message.getFieldWithDefault(msg, 4, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.PaginationMeta}
 */
proto.czm.convivo.PaginationMeta.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.PaginationMeta;
  return proto.czm.convivo.PaginationMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.PaginationMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.PaginationMeta}
 */
proto.czm.convivo.PaginationMeta.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setTotalcount(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setPagecount(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setPagesize(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setCurrentpage(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.PaginationMeta.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.PaginationMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.PaginationMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.PaginationMeta.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPagecount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getCurrentpage();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint32 totalCount = 1;
 * @return {number}
 */
proto.czm.convivo.PaginationMeta.prototype.getTotalcount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.PaginationMeta} returns this
 */
proto.czm.convivo.PaginationMeta.prototype.setTotalcount = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 pageCount = 2;
 * @return {number}
 */
proto.czm.convivo.PaginationMeta.prototype.getPagecount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.PaginationMeta} returns this
 */
proto.czm.convivo.PaginationMeta.prototype.setPagecount = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 pageSize = 3;
 * @return {number}
 */
proto.czm.convivo.PaginationMeta.prototype.getPagesize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.PaginationMeta} returns this
 */
proto.czm.convivo.PaginationMeta.prototype.setPagesize = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 currentPage = 4;
 * @return {number}
 */
proto.czm.convivo.PaginationMeta.prototype.getCurrentpage = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.PaginationMeta} returns this
 */
proto.czm.convivo.PaginationMeta.prototype.setCurrentpage = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.SavedImageForCase.repeatedFields_ = [22, 27];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.SavedImageForCase.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.SavedImageForCase.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.SavedImageForCase} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.SavedImageForCase.toObject = function (includeInstance, msg) {
    var f, obj = {
      caseid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      imageid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      seriesid: jspb.Message.getFieldWithDefault(msg, 3, ""),
      sequencenumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
      capturedby: jspb.Message.getFieldWithDefault(msg, 5, 0),
      url: jspb.Message.getFieldWithDefault(msg, 6, ""),
      resolutionwidth: jspb.Message.getFieldWithDefault(msg, 7, 0),
      resolutionheight: jspb.Message.getFieldWithDefault(msg, 8, 0),
      targetresolutionwidth: jspb.Message.getFieldWithDefault(msg, 9, 0),
      targetresolutionheight: jspb.Message.getFieldWithDefault(msg, 10, 0),
      devicetimestamp: jspb.Message.getFieldWithDefault(msg, 11, 0),
      resolution: jspb.Message.getFieldWithDefault(msg, 12, 0),
      isgalleryimage: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
      isupdated: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
      isshortlistedforreport: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
      qualityprobability: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
      thumbnailurl: jspb.Message.getFieldWithDefault(msg, 17, ""),
      thumbnailheight: jspb.Message.getFieldWithDefault(msg, 18, 0),
      thumbnailwidth: jspb.Message.getFieldWithDefault(msg, 19, 0),
      datecreated: jspb.Message.getFieldWithDefault(msg, 20, ""),
      dateupdated: jspb.Message.getFieldWithDefault(msg, 21, ""),
      previousimageidsList: (f = jspb.Message.getRepeatedField(msg, 22)) == null ? undefined : f,
      imagemeta: (f = msg.getImagemeta()) && proto.czm.convivo.ImageMeta.toObject(includeInstance, f),
      spot: (f = msg.getSpot()) && proto.czm.convivo.Spot.toObject(includeInstance, f),
      annotation: jspb.Message.getFieldWithDefault(msg, 25, ""),
      imagetype: jspb.Message.getFieldWithDefault(msg, 26, 0),
      commentsList: jspb.Message.toObjectList(msg.getCommentsList(),
        proto.czm.convivo.CommentForImage.toObject, includeInstance),
      machinedetails: (f = msg.getMachinedetails()) && proto.czm.convivo.MachineDetails.toObject(includeInstance, f),
      annotatedurl: jspb.Message.getFieldWithDefault(msg, 29, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.SavedImageForCase}
 */
proto.czm.convivo.SavedImageForCase.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.SavedImageForCase;
  return proto.czm.convivo.SavedImageForCase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.SavedImageForCase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.SavedImageForCase}
 */
proto.czm.convivo.SavedImageForCase.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCaseid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setImageid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setSeriesid(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setSequencenumber(value);
        break;
      case 5:
        var value = /** @type {!proto.czm.convivo.SavedImageForCase.CaptureRole} */ (reader.readEnum());
        msg.setCapturedby(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setUrl(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setResolutionwidth(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setResolutionheight(value);
        break;
      case 9:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setTargetresolutionwidth(value);
        break;
      case 10:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setTargetresolutionheight(value);
        break;
      case 11:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setDevicetimestamp(value);
        break;
      case 12:
        var value = /** @type {!proto.czm.convivo.Resolution} */ (reader.readEnum());
        msg.setResolution(value);
        break;
      case 13:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsgalleryimage(value);
        break;
      case 14:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsupdated(value);
        break;
      case 15:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsshortlistedforreport(value);
        break;
      case 16:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setQualityprobability(value);
        break;
      case 17:
        var value = /** @type {string} */ (reader.readString());
        msg.setThumbnailurl(value);
        break;
      case 18:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setThumbnailheight(value);
        break;
      case 19:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setThumbnailwidth(value);
        break;
      case 20:
        var value = /** @type {string} */ (reader.readString());
        msg.setDatecreated(value);
        break;
      case 21:
        var value = /** @type {string} */ (reader.readString());
        msg.setDateupdated(value);
        break;
      case 22:
        var value = /** @type {string} */ (reader.readString());
        msg.addPreviousimageids(value);
        break;
      case 23:
        var value = new proto.czm.convivo.ImageMeta;
        reader.readMessage(value, proto.czm.convivo.ImageMeta.deserializeBinaryFromReader);
        msg.setImagemeta(value);
        break;
      case 24:
        var value = new proto.czm.convivo.Spot;
        reader.readMessage(value, proto.czm.convivo.Spot.deserializeBinaryFromReader);
        msg.setSpot(value);
        break;
      case 25:
        var value = /** @type {string} */ (reader.readString());
        msg.setAnnotation(value);
        break;
      case 26:
        var value = /** @type {!proto.czm.convivo.ImageType} */ (reader.readEnum());
        msg.setImagetype(value);
        break;
      case 27:
        var value = new proto.czm.convivo.CommentForImage;
        reader.readMessage(value, proto.czm.convivo.CommentForImage.deserializeBinaryFromReader);
        msg.addComments(value);
        break;
      case 28:
        var value = new proto.czm.convivo.MachineDetails;
        reader.readMessage(value, proto.czm.convivo.MachineDetails.deserializeBinaryFromReader);
        msg.setMachinedetails(value);
        break;
      case 29:
        var value = /** @type {string} */ (reader.readString());
        msg.setAnnotatedurl(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.SavedImageForCase.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.SavedImageForCase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.SavedImageForCase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.SavedImageForCase.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCaseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImageid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSeriesid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSequencenumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCapturedby();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getResolutionwidth();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getResolutionheight();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getTargetresolutionwidth();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getTargetresolutionheight();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getDevicetimestamp();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getResolution();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getIsgalleryimage();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getIsupdated();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getIsshortlistedforreport();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getQualityprobability();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getThumbnailurl();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getThumbnailheight();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getThumbnailwidth();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getDateupdated();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getPreviousimageidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      22,
      f
    );
  }
  f = message.getImagemeta();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.czm.convivo.ImageMeta.serializeBinaryToWriter
    );
  }
  f = message.getSpot();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.czm.convivo.Spot.serializeBinaryToWriter
    );
  }
  f = message.getAnnotation();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getImagetype();
  if (f !== 0.0) {
    writer.writeEnum(
      26,
      f
    );
  }
  f = message.getCommentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      27,
      f,
      proto.czm.convivo.CommentForImage.serializeBinaryToWriter
    );
  }
  f = message.getMachinedetails();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.czm.convivo.MachineDetails.serializeBinaryToWriter
    );
  }
  f = message.getAnnotatedurl();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.czm.convivo.SavedImageForCase.CaptureRole = {
  SURGEON: 0,
  PATHOLOGIST: 1
};

/**
 * optional string caseId = 1;
 * @return {string}
 */
proto.czm.convivo.SavedImageForCase.prototype.getCaseid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setCaseid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string imageId = 2;
 * @return {string}
 */
proto.czm.convivo.SavedImageForCase.prototype.getImageid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setImageid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string seriesId = 3;
 * @return {string}
 */
proto.czm.convivo.SavedImageForCase.prototype.getSeriesid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setSeriesid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 sequenceNumber = 4;
 * @return {number}
 */
proto.czm.convivo.SavedImageForCase.prototype.getSequencenumber = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setSequencenumber = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional CaptureRole capturedBy = 5;
 * @return {!proto.czm.convivo.SavedImageForCase.CaptureRole}
 */
proto.czm.convivo.SavedImageForCase.prototype.getCapturedby = function () {
  return /** @type {!proto.czm.convivo.SavedImageForCase.CaptureRole} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.czm.convivo.SavedImageForCase.CaptureRole} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setCapturedby = function (value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string url = 6;
 * @return {string}
 */
proto.czm.convivo.SavedImageForCase.prototype.getUrl = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setUrl = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 resolutionWidth = 7;
 * @return {number}
 */
proto.czm.convivo.SavedImageForCase.prototype.getResolutionwidth = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setResolutionwidth = function (value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 resolutionHeight = 8;
 * @return {number}
 */
proto.czm.convivo.SavedImageForCase.prototype.getResolutionheight = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setResolutionheight = function (value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 targetResolutionWidth = 9;
 * @return {number}
 */
proto.czm.convivo.SavedImageForCase.prototype.getTargetresolutionwidth = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setTargetresolutionwidth = function (value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 targetResolutionHeight = 10;
 * @return {number}
 */
proto.czm.convivo.SavedImageForCase.prototype.getTargetresolutionheight = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setTargetresolutionheight = function (value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 deviceTimestamp = 11;
 * @return {number}
 */
proto.czm.convivo.SavedImageForCase.prototype.getDevicetimestamp = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setDevicetimestamp = function (value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional Resolution resolution = 12;
 * @return {!proto.czm.convivo.Resolution}
 */
proto.czm.convivo.SavedImageForCase.prototype.getResolution = function () {
  return /** @type {!proto.czm.convivo.Resolution} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.czm.convivo.Resolution} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setResolution = function (value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional bool isGalleryImage = 13;
 * @return {boolean}
 */
proto.czm.convivo.SavedImageForCase.prototype.getIsgalleryimage = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setIsgalleryimage = function (value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool isUpdated = 14;
 * @return {boolean}
 */
proto.czm.convivo.SavedImageForCase.prototype.getIsupdated = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setIsupdated = function (value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool isShortlistedForReport = 15;
 * @return {boolean}
 */
proto.czm.convivo.SavedImageForCase.prototype.getIsshortlistedforreport = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setIsshortlistedforreport = function (value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional double qualityProbability = 16;
 * @return {number}
 */
proto.czm.convivo.SavedImageForCase.prototype.getQualityprobability = function () {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setQualityprobability = function (value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional string thumbnailUrl = 17;
 * @return {string}
 */
proto.czm.convivo.SavedImageForCase.prototype.getThumbnailurl = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setThumbnailurl = function (value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional int32 thumbnailHeight = 18;
 * @return {number}
 */
proto.czm.convivo.SavedImageForCase.prototype.getThumbnailheight = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setThumbnailheight = function (value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int32 thumbnailWidth = 19;
 * @return {number}
 */
proto.czm.convivo.SavedImageForCase.prototype.getThumbnailwidth = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setThumbnailwidth = function (value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional string dateCreated = 20;
 * @return {string}
 */
proto.czm.convivo.SavedImageForCase.prototype.getDatecreated = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setDatecreated = function (value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string dateUpdated = 21;
 * @return {string}
 */
proto.czm.convivo.SavedImageForCase.prototype.getDateupdated = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setDateupdated = function (value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * repeated string previousImageIds = 22;
 * @return {!Array<string>}
 */
proto.czm.convivo.SavedImageForCase.prototype.getPreviousimageidsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 22));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setPreviousimageidsList = function (value) {
  return jspb.Message.setField(this, 22, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.addPreviousimageids = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 22, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.clearPreviousimageidsList = function () {
  return this.setPreviousimageidsList([]);
};


/**
 * optional ImageMeta imageMeta = 23;
 * @return {?proto.czm.convivo.ImageMeta}
 */
proto.czm.convivo.SavedImageForCase.prototype.getImagemeta = function () {
  return /** @type{?proto.czm.convivo.ImageMeta} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.ImageMeta, 23));
};


/**
 * @param {?proto.czm.convivo.ImageMeta|undefined} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
*/
proto.czm.convivo.SavedImageForCase.prototype.setImagemeta = function (value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.clearImagemeta = function () {
  return this.setImagemeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.SavedImageForCase.prototype.hasImagemeta = function () {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional Spot spot = 24;
 * @return {?proto.czm.convivo.Spot}
 */
proto.czm.convivo.SavedImageForCase.prototype.getSpot = function () {
  return /** @type{?proto.czm.convivo.Spot} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.Spot, 24));
};


/**
 * @param {?proto.czm.convivo.Spot|undefined} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
*/
proto.czm.convivo.SavedImageForCase.prototype.setSpot = function (value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.clearSpot = function () {
  return this.setSpot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.SavedImageForCase.prototype.hasSpot = function () {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional string annotation = 25;
 * @return {string}
 */
proto.czm.convivo.SavedImageForCase.prototype.getAnnotation = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setAnnotation = function (value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional ImageType imageType = 26;
 * @return {!proto.czm.convivo.ImageType}
 */
proto.czm.convivo.SavedImageForCase.prototype.getImagetype = function () {
  return /** @type {!proto.czm.convivo.ImageType} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {!proto.czm.convivo.ImageType} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setImagetype = function (value) {
  return jspb.Message.setProto3EnumField(this, 26, value);
};


/**
 * repeated CommentForImage comments = 27;
 * @return {!Array<!proto.czm.convivo.CommentForImage>}
 */
proto.czm.convivo.SavedImageForCase.prototype.getCommentsList = function () {
  return /** @type{!Array<!proto.czm.convivo.CommentForImage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.CommentForImage, 27));
};


/**
 * @param {!Array<!proto.czm.convivo.CommentForImage>} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
*/
proto.czm.convivo.SavedImageForCase.prototype.setCommentsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 27, value);
};


/**
 * @param {!proto.czm.convivo.CommentForImage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.CommentForImage}
 */
proto.czm.convivo.SavedImageForCase.prototype.addComments = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 27, opt_value, proto.czm.convivo.CommentForImage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.clearCommentsList = function () {
  return this.setCommentsList([]);
};


/**
 * optional MachineDetails machineDetails = 28;
 * @return {?proto.czm.convivo.MachineDetails}
 */
proto.czm.convivo.SavedImageForCase.prototype.getMachinedetails = function () {
  return /** @type{?proto.czm.convivo.MachineDetails} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.MachineDetails, 28));
};


/**
 * @param {?proto.czm.convivo.MachineDetails|undefined} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
*/
proto.czm.convivo.SavedImageForCase.prototype.setMachinedetails = function (value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.clearMachinedetails = function () {
  return this.setMachinedetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.SavedImageForCase.prototype.hasMachinedetails = function () {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional string annotatedURL = 29;
 * @return {string}
 */
proto.czm.convivo.SavedImageForCase.prototype.getAnnotatedurl = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SavedImageForCase} returns this
 */
proto.czm.convivo.SavedImageForCase.prototype.setAnnotatedurl = function (value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.SavedImagesForCase.repeatedFields_ = [1, 2];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.SavedImagesForCase.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.SavedImagesForCase.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.SavedImagesForCase} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.SavedImagesForCase.toObject = function (includeInstance, msg) {
    var f, obj = {
      imagesList: jspb.Message.toObjectList(msg.getImagesList(),
        proto.czm.convivo.SavedImageForCase.toObject, includeInstance),
      stitchedimageurlList: jspb.Message.toObjectList(msg.getStitchedimageurlList(),
        proto.czm.convivo.SavedImageForCase.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.SavedImagesForCase}
 */
proto.czm.convivo.SavedImagesForCase.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.SavedImagesForCase;
  return proto.czm.convivo.SavedImagesForCase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.SavedImagesForCase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.SavedImagesForCase}
 */
proto.czm.convivo.SavedImagesForCase.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.czm.convivo.SavedImageForCase;
        reader.readMessage(value, proto.czm.convivo.SavedImageForCase.deserializeBinaryFromReader);
        msg.addImages(value);
        break;
      case 2:
        var value = new proto.czm.convivo.SavedImageForCase;
        reader.readMessage(value, proto.czm.convivo.SavedImageForCase.deserializeBinaryFromReader);
        msg.addStitchedimageurl(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.SavedImagesForCase.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.SavedImagesForCase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.SavedImagesForCase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.SavedImagesForCase.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.czm.convivo.SavedImageForCase.serializeBinaryToWriter
    );
  }
  f = message.getStitchedimageurlList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.czm.convivo.SavedImageForCase.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SavedImageForCase images = 1;
 * @return {!Array<!proto.czm.convivo.SavedImageForCase>}
 */
proto.czm.convivo.SavedImagesForCase.prototype.getImagesList = function () {
  return /** @type{!Array<!proto.czm.convivo.SavedImageForCase>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.SavedImageForCase, 1));
};


/**
 * @param {!Array<!proto.czm.convivo.SavedImageForCase>} value
 * @return {!proto.czm.convivo.SavedImagesForCase} returns this
*/
proto.czm.convivo.SavedImagesForCase.prototype.setImagesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.czm.convivo.SavedImageForCase=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.SavedImageForCase}
 */
proto.czm.convivo.SavedImagesForCase.prototype.addImages = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.czm.convivo.SavedImageForCase, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.SavedImagesForCase} returns this
 */
proto.czm.convivo.SavedImagesForCase.prototype.clearImagesList = function () {
  return this.setImagesList([]);
};


/**
 * repeated SavedImageForCase stitchedImageUrl = 2;
 * @return {!Array<!proto.czm.convivo.SavedImageForCase>}
 */
proto.czm.convivo.SavedImagesForCase.prototype.getStitchedimageurlList = function () {
  return /** @type{!Array<!proto.czm.convivo.SavedImageForCase>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.SavedImageForCase, 2));
};


/**
 * @param {!Array<!proto.czm.convivo.SavedImageForCase>} value
 * @return {!proto.czm.convivo.SavedImagesForCase} returns this
*/
proto.czm.convivo.SavedImagesForCase.prototype.setStitchedimageurlList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.czm.convivo.SavedImageForCase=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.SavedImageForCase}
 */
proto.czm.convivo.SavedImagesForCase.prototype.addStitchedimageurl = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.czm.convivo.SavedImageForCase, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.SavedImagesForCase} returns this
 */
proto.czm.convivo.SavedImagesForCase.prototype.clearStitchedimageurlList = function () {
  return this.setStitchedimageurlList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.SpotImagesByLabel.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.SpotImagesByLabel.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.SpotImagesByLabel.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.SpotImagesByLabel} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.SpotImagesByLabel.toObject = function (includeInstance, msg) {
    var f, obj = {
      spotimagesList: jspb.Message.toObjectList(msg.getSpotimagesList(),
        proto.czm.convivo.SpotImages.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.SpotImagesByLabel}
 */
proto.czm.convivo.SpotImagesByLabel.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.SpotImagesByLabel;
  return proto.czm.convivo.SpotImagesByLabel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.SpotImagesByLabel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.SpotImagesByLabel}
 */
proto.czm.convivo.SpotImagesByLabel.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.czm.convivo.SpotImages;
        reader.readMessage(value, proto.czm.convivo.SpotImages.deserializeBinaryFromReader);
        msg.addSpotimages(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.SpotImagesByLabel.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.SpotImagesByLabel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.SpotImagesByLabel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.SpotImagesByLabel.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSpotimagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.czm.convivo.SpotImages.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SpotImages spotImages = 1;
 * @return {!Array<!proto.czm.convivo.SpotImages>}
 */
proto.czm.convivo.SpotImagesByLabel.prototype.getSpotimagesList = function () {
  return /** @type{!Array<!proto.czm.convivo.SpotImages>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.SpotImages, 1));
};


/**
 * @param {!Array<!proto.czm.convivo.SpotImages>} value
 * @return {!proto.czm.convivo.SpotImagesByLabel} returns this
*/
proto.czm.convivo.SpotImagesByLabel.prototype.setSpotimagesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.czm.convivo.SpotImages=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.SpotImages}
 */
proto.czm.convivo.SpotImagesByLabel.prototype.addSpotimages = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.czm.convivo.SpotImages, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.SpotImagesByLabel} returns this
 */
proto.czm.convivo.SpotImagesByLabel.prototype.clearSpotimagesList = function () {
  return this.setSpotimagesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.SpotImages.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.SpotImages.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.SpotImages.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.SpotImages} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.SpotImages.toObject = function (includeInstance, msg) {
    var f, obj = {
      imagesList: jspb.Message.toObjectList(msg.getImagesList(),
        proto.czm.convivo.SpotImage.toObject, includeInstance),
      spot: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.SpotImages}
 */
proto.czm.convivo.SpotImages.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.SpotImages;
  return proto.czm.convivo.SpotImages.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.SpotImages} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.SpotImages}
 */
proto.czm.convivo.SpotImages.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.czm.convivo.SpotImage;
        reader.readMessage(value, proto.czm.convivo.SpotImage.deserializeBinaryFromReader);
        msg.addImages(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setSpot(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.SpotImages.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.SpotImages.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.SpotImages} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.SpotImages.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.czm.convivo.SpotImage.serializeBinaryToWriter
    );
  }
  f = message.getSpot();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated SpotImage images = 1;
 * @return {!Array<!proto.czm.convivo.SpotImage>}
 */
proto.czm.convivo.SpotImages.prototype.getImagesList = function () {
  return /** @type{!Array<!proto.czm.convivo.SpotImage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.SpotImage, 1));
};


/**
 * @param {!Array<!proto.czm.convivo.SpotImage>} value
 * @return {!proto.czm.convivo.SpotImages} returns this
*/
proto.czm.convivo.SpotImages.prototype.setImagesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.czm.convivo.SpotImage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.SpotImage}
 */
proto.czm.convivo.SpotImages.prototype.addImages = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.czm.convivo.SpotImage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.SpotImages} returns this
 */
proto.czm.convivo.SpotImages.prototype.clearImagesList = function () {
  return this.setImagesList([]);
};


/**
 * optional string spot = 2;
 * @return {string}
 */
proto.czm.convivo.SpotImages.prototype.getSpot = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SpotImages} returns this
 */
proto.czm.convivo.SpotImages.prototype.setSpot = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.SpotImage.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.SpotImage.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.SpotImage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.SpotImage.toObject = function (includeInstance, msg) {
    var f, obj = {
      seriesid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      imageid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      imagetype: jspb.Message.getFieldWithDefault(msg, 3, ""),
      thumbnail: jspb.Message.getFieldWithDefault(msg, 4, ""),
      seriesduration: jspb.Message.getFieldWithDefault(msg, 5, ""),
      hasreportimages: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
      hasannotatedimages: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.SpotImage}
 */
proto.czm.convivo.SpotImage.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.SpotImage;
  return proto.czm.convivo.SpotImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.SpotImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.SpotImage}
 */
proto.czm.convivo.SpotImage.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setSeriesid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setImageid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setImagetype(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setThumbnail(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setSeriesduration(value);
        break;
      case 6:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setHasreportimages(value);
        break;
      case 7:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setHasannotatedimages(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.SpotImage.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.SpotImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.SpotImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.SpotImage.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSeriesid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImageid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImagetype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getThumbnail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSeriesduration();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getHasreportimages();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getHasannotatedimages();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string seriesID = 1;
 * @return {string}
 */
proto.czm.convivo.SpotImage.prototype.getSeriesid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SpotImage} returns this
 */
proto.czm.convivo.SpotImage.prototype.setSeriesid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string imageID = 2;
 * @return {string}
 */
proto.czm.convivo.SpotImage.prototype.getImageid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SpotImage} returns this
 */
proto.czm.convivo.SpotImage.prototype.setImageid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string imageType = 3;
 * @return {string}
 */
proto.czm.convivo.SpotImage.prototype.getImagetype = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SpotImage} returns this
 */
proto.czm.convivo.SpotImage.prototype.setImagetype = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string thumbnail = 4;
 * @return {string}
 */
proto.czm.convivo.SpotImage.prototype.getThumbnail = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SpotImage} returns this
 */
proto.czm.convivo.SpotImage.prototype.setThumbnail = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string seriesDuration = 5;
 * @return {string}
 */
proto.czm.convivo.SpotImage.prototype.getSeriesduration = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SpotImage} returns this
 */
proto.czm.convivo.SpotImage.prototype.setSeriesduration = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool hasReportImages = 6;
 * @return {boolean}
 */
proto.czm.convivo.SpotImage.prototype.getHasreportimages = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.czm.convivo.SpotImage} returns this
 */
proto.czm.convivo.SpotImage.prototype.setHasreportimages = function (value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool hasAnnotatedImages = 7;
 * @return {boolean}
 */
proto.czm.convivo.SpotImage.prototype.getHasannotatedimages = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.czm.convivo.SpotImage} returns this
 */
proto.czm.convivo.SpotImage.prototype.setHasannotatedimages = function (value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.CommentForImage.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.CommentForImage.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.CommentForImage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.CommentForImage.toObject = function (includeInstance, msg) {
    var f, obj = {
      comment: jspb.Message.getFieldWithDefault(msg, 1, ""),
      createdby: (f = msg.getCreatedby()) && proto.czm.convivo.UserInfo.toObject(includeInstance, f),
      createdat: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.CommentForImage}
 */
proto.czm.convivo.CommentForImage.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.CommentForImage;
  return proto.czm.convivo.CommentForImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.CommentForImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.CommentForImage}
 */
proto.czm.convivo.CommentForImage.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setComment(value);
        break;
      case 2:
        var value = new proto.czm.convivo.UserInfo;
        reader.readMessage(value, proto.czm.convivo.UserInfo.deserializeBinaryFromReader);
        msg.setCreatedby(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setCreatedat(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.CommentForImage.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.CommentForImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.CommentForImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.CommentForImage.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.czm.convivo.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getCreatedat();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string comment = 1;
 * @return {string}
 */
proto.czm.convivo.CommentForImage.prototype.getComment = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CommentForImage} returns this
 */
proto.czm.convivo.CommentForImage.prototype.setComment = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UserInfo createdBy = 2;
 * @return {?proto.czm.convivo.UserInfo}
 */
proto.czm.convivo.CommentForImage.prototype.getCreatedby = function () {
  return /** @type{?proto.czm.convivo.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.UserInfo, 2));
};


/**
 * @param {?proto.czm.convivo.UserInfo|undefined} value
 * @return {!proto.czm.convivo.CommentForImage} returns this
*/
proto.czm.convivo.CommentForImage.prototype.setCreatedby = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.CommentForImage} returns this
 */
proto.czm.convivo.CommentForImage.prototype.clearCreatedby = function () {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.CommentForImage.prototype.hasCreatedby = function () {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string createdAt = 3;
 * @return {string}
 */
proto.czm.convivo.CommentForImage.prototype.getCreatedat = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CommentForImage} returns this
 */
proto.czm.convivo.CommentForImage.prototype.setCreatedat = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.CommentsForImage.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.CommentsForImage.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.CommentsForImage.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.CommentsForImage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.CommentsForImage.toObject = function (includeInstance, msg) {
    var f, obj = {
      commentsList: jspb.Message.toObjectList(msg.getCommentsList(),
        proto.czm.convivo.CommentForImage.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.CommentsForImage}
 */
proto.czm.convivo.CommentsForImage.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.CommentsForImage;
  return proto.czm.convivo.CommentsForImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.CommentsForImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.CommentsForImage}
 */
proto.czm.convivo.CommentsForImage.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.czm.convivo.CommentForImage;
        reader.readMessage(value, proto.czm.convivo.CommentForImage.deserializeBinaryFromReader);
        msg.addComments(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.CommentsForImage.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.CommentsForImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.CommentsForImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.CommentsForImage.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCommentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.czm.convivo.CommentForImage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CommentForImage comments = 1;
 * @return {!Array<!proto.czm.convivo.CommentForImage>}
 */
proto.czm.convivo.CommentsForImage.prototype.getCommentsList = function () {
  return /** @type{!Array<!proto.czm.convivo.CommentForImage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.CommentForImage, 1));
};


/**
 * @param {!Array<!proto.czm.convivo.CommentForImage>} value
 * @return {!proto.czm.convivo.CommentsForImage} returns this
*/
proto.czm.convivo.CommentsForImage.prototype.setCommentsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.czm.convivo.CommentForImage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.CommentForImage}
 */
proto.czm.convivo.CommentsForImage.prototype.addComments = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.czm.convivo.CommentForImage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.CommentsForImage} returns this
 */
proto.czm.convivo.CommentsForImage.prototype.clearCommentsList = function () {
  return this.setCommentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.Filter.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.Filter.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.Filter} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.Filter.toObject = function (includeInstance, msg) {
    var f, obj = {
      type: jspb.Message.getFieldWithDefault(msg, 1, 0),
      color: jspb.Message.getFieldWithDefault(msg, 2, ""),
      range: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.Filter}
 */
proto.czm.convivo.Filter.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.Filter;
  return proto.czm.convivo.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.Filter}
 */
proto.czm.convivo.Filter.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.czm.convivo.FilterType} */ (reader.readEnum());
        msg.setType(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setColor(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setRange(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.Filter.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.Filter.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRange();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional FilterType type = 1;
 * @return {!proto.czm.convivo.FilterType}
 */
proto.czm.convivo.Filter.prototype.getType = function () {
  return /** @type {!proto.czm.convivo.FilterType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.czm.convivo.FilterType} value
 * @return {!proto.czm.convivo.Filter} returns this
 */
proto.czm.convivo.Filter.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string color = 2;
 * @return {string}
 */
proto.czm.convivo.Filter.prototype.getColor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Filter} returns this
 */
proto.czm.convivo.Filter.prototype.setColor = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double range = 3;
 * @return {number}
 */
proto.czm.convivo.Filter.prototype.getRange = function () {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.Filter} returns this
 */
proto.czm.convivo.Filter.prototype.setRange = function (value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.MetaData.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.MetaData.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.MetaData} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.MetaData.toObject = function (includeInstance, msg) {
    var f, obj = {
      brightness: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
      contrast: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
      colorintensity: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.MetaData}
 */
proto.czm.convivo.MetaData.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.MetaData;
  return proto.czm.convivo.MetaData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.MetaData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.MetaData}
 */
proto.czm.convivo.MetaData.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setBrightness(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setContrast(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setColorintensity(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.MetaData.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.MetaData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.MetaData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.MetaData.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getBrightness();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getContrast();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getColorintensity();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double brightness = 1;
 * @return {number}
 */
proto.czm.convivo.MetaData.prototype.getBrightness = function () {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.MetaData} returns this
 */
proto.czm.convivo.MetaData.prototype.setBrightness = function (value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double contrast = 2;
 * @return {number}
 */
proto.czm.convivo.MetaData.prototype.getContrast = function () {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.MetaData} returns this
 */
proto.czm.convivo.MetaData.prototype.setContrast = function (value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double colorIntensity = 3;
 * @return {number}
 */
proto.czm.convivo.MetaData.prototype.getColorintensity = function () {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.MetaData} returns this
 */
proto.czm.convivo.MetaData.prototype.setColorintensity = function (value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.Overlay.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.Overlay.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.Overlay} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.Overlay.toObject = function (includeInstance, msg) {
    var f, obj = {
      annotations: jspb.Message.getFieldWithDefault(msg, 1, ""),
      metadata: (f = msg.getMetadata()) && proto.czm.convivo.MetaData.toObject(includeInstance, f),
      filter: (f = msg.getFilter()) && proto.czm.convivo.Filter.toObject(includeInstance, f),
      createdby: (f = msg.getCreatedby()) && proto.czm.convivo.UserInfo.toObject(includeInstance, f),
      createdat: jspb.Message.getFieldWithDefault(msg, 5, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.Overlay}
 */
proto.czm.convivo.Overlay.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.Overlay;
  return proto.czm.convivo.Overlay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.Overlay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.Overlay}
 */
proto.czm.convivo.Overlay.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAnnotations(value);
        break;
      case 2:
        var value = new proto.czm.convivo.MetaData;
        reader.readMessage(value, proto.czm.convivo.MetaData.deserializeBinaryFromReader);
        msg.setMetadata(value);
        break;
      case 3:
        var value = new proto.czm.convivo.Filter;
        reader.readMessage(value, proto.czm.convivo.Filter.deserializeBinaryFromReader);
        msg.setFilter(value);
        break;
      case 4:
        var value = new proto.czm.convivo.UserInfo;
        reader.readMessage(value, proto.czm.convivo.UserInfo.deserializeBinaryFromReader);
        msg.setCreatedby(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setCreatedat(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.Overlay.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.Overlay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.Overlay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.Overlay.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getAnnotations();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.czm.convivo.MetaData.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.czm.convivo.Filter.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.czm.convivo.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getCreatedat();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string annotations = 1;
 * @return {string}
 */
proto.czm.convivo.Overlay.prototype.getAnnotations = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Overlay} returns this
 */
proto.czm.convivo.Overlay.prototype.setAnnotations = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional MetaData metadata = 2;
 * @return {?proto.czm.convivo.MetaData}
 */
proto.czm.convivo.Overlay.prototype.getMetadata = function () {
  return /** @type{?proto.czm.convivo.MetaData} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.MetaData, 2));
};


/**
 * @param {?proto.czm.convivo.MetaData|undefined} value
 * @return {!proto.czm.convivo.Overlay} returns this
*/
proto.czm.convivo.Overlay.prototype.setMetadata = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.Overlay} returns this
 */
proto.czm.convivo.Overlay.prototype.clearMetadata = function () {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.Overlay.prototype.hasMetadata = function () {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Filter filter = 3;
 * @return {?proto.czm.convivo.Filter}
 */
proto.czm.convivo.Overlay.prototype.getFilter = function () {
  return /** @type{?proto.czm.convivo.Filter} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.Filter, 3));
};


/**
 * @param {?proto.czm.convivo.Filter|undefined} value
 * @return {!proto.czm.convivo.Overlay} returns this
*/
proto.czm.convivo.Overlay.prototype.setFilter = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.Overlay} returns this
 */
proto.czm.convivo.Overlay.prototype.clearFilter = function () {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.Overlay.prototype.hasFilter = function () {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional UserInfo createdBy = 4;
 * @return {?proto.czm.convivo.UserInfo}
 */
proto.czm.convivo.Overlay.prototype.getCreatedby = function () {
  return /** @type{?proto.czm.convivo.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.UserInfo, 4));
};


/**
 * @param {?proto.czm.convivo.UserInfo|undefined} value
 * @return {!proto.czm.convivo.Overlay} returns this
*/
proto.czm.convivo.Overlay.prototype.setCreatedby = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.Overlay} returns this
 */
proto.czm.convivo.Overlay.prototype.clearCreatedby = function () {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.Overlay.prototype.hasCreatedby = function () {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string createdAt = 5;
 * @return {string}
 */
proto.czm.convivo.Overlay.prototype.getCreatedat = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Overlay} returns this
 */
proto.czm.convivo.Overlay.prototype.setCreatedat = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.Notification.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.Notification.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.Notification} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.Notification.toObject = function (includeInstance, msg) {
    var f, obj = {
      caseid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      alerttime: jspb.Message.getFieldWithDefault(msg, 2, 0),
      message: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.Notification}
 */
proto.czm.convivo.Notification.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.Notification;
  return proto.czm.convivo.Notification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.Notification}
 */
proto.czm.convivo.Notification.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCaseid(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setAlerttime(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setMessage(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.Notification.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.Notification.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCaseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAlerttime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string caseID = 1;
 * @return {string}
 */
proto.czm.convivo.Notification.prototype.getCaseid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Notification} returns this
 */
proto.czm.convivo.Notification.prototype.setCaseid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 alertTime = 2;
 * @return {number}
 */
proto.czm.convivo.Notification.prototype.getAlerttime = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.Notification} returns this
 */
proto.czm.convivo.Notification.prototype.setAlerttime = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.czm.convivo.Notification.prototype.getMessage = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Notification} returns this
 */
proto.czm.convivo.Notification.prototype.setMessage = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.WebNotifications.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.WebNotifications.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.WebNotifications.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.WebNotifications} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.WebNotifications.toObject = function (includeInstance, msg) {
    var f, obj = {
      notificationsList: jspb.Message.toObjectList(msg.getNotificationsList(),
        proto.czm.convivo.WebNotification.toObject, includeInstance),
      hasunreadnotification: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.WebNotifications}
 */
proto.czm.convivo.WebNotifications.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.WebNotifications;
  return proto.czm.convivo.WebNotifications.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.WebNotifications} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.WebNotifications}
 */
proto.czm.convivo.WebNotifications.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.czm.convivo.WebNotification;
        reader.readMessage(value, proto.czm.convivo.WebNotification.deserializeBinaryFromReader);
        msg.addNotifications(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setHasunreadnotification(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.WebNotifications.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.WebNotifications.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.WebNotifications} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.WebNotifications.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getNotificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.czm.convivo.WebNotification.serializeBinaryToWriter
    );
  }
  f = message.getHasunreadnotification();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated WebNotification notifications = 1;
 * @return {!Array<!proto.czm.convivo.WebNotification>}
 */
proto.czm.convivo.WebNotifications.prototype.getNotificationsList = function () {
  return /** @type{!Array<!proto.czm.convivo.WebNotification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.WebNotification, 1));
};


/**
 * @param {!Array<!proto.czm.convivo.WebNotification>} value
 * @return {!proto.czm.convivo.WebNotifications} returns this
*/
proto.czm.convivo.WebNotifications.prototype.setNotificationsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.czm.convivo.WebNotification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.WebNotification}
 */
proto.czm.convivo.WebNotifications.prototype.addNotifications = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.czm.convivo.WebNotification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.WebNotifications} returns this
 */
proto.czm.convivo.WebNotifications.prototype.clearNotificationsList = function () {
  return this.setNotificationsList([]);
};


/**
 * optional bool hasUnreadNotification = 2;
 * @return {boolean}
 */
proto.czm.convivo.WebNotifications.prototype.getHasunreadnotification = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.czm.convivo.WebNotifications} returns this
 */
proto.czm.convivo.WebNotifications.prototype.setHasunreadnotification = function (value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.WebNotification.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.WebNotification.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.WebNotification} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.WebNotification.toObject = function (includeInstance, msg) {
    var f, obj = {
      userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      caseid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      notificationid: jspb.Message.getFieldWithDefault(msg, 3, ""),
      message: jspb.Message.getFieldWithDefault(msg, 4, ""),
      status: jspb.Message.getFieldWithDefault(msg, 5, 0),
      alerttimer: jspb.Message.getFieldWithDefault(msg, 6, 0),
      surgeonname: jspb.Message.getFieldWithDefault(msg, 7, ""),
      createddate: jspb.Message.getFieldWithDefault(msg, 8, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.WebNotification}
 */
proto.czm.convivo.WebNotification.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.WebNotification;
  return proto.czm.convivo.WebNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.WebNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.WebNotification}
 */
proto.czm.convivo.WebNotification.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setUserid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCaseid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setNotificationid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setMessage(value);
        break;
      case 5:
        var value = /** @type {!proto.czm.convivo.NotificationStatus} */ (reader.readEnum());
        msg.setStatus(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setAlerttimer(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setSurgeonname(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setCreateddate(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.WebNotification.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.WebNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.WebNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.WebNotification.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaseid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNotificationid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAlerttimer();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getSurgeonname();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreateddate();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string UserID = 1;
 * @return {string}
 */
proto.czm.convivo.WebNotification.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.WebNotification} returns this
 */
proto.czm.convivo.WebNotification.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string caseID = 2;
 * @return {string}
 */
proto.czm.convivo.WebNotification.prototype.getCaseid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.WebNotification} returns this
 */
proto.czm.convivo.WebNotification.prototype.setCaseid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string notificationID = 3;
 * @return {string}
 */
proto.czm.convivo.WebNotification.prototype.getNotificationid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.WebNotification} returns this
 */
proto.czm.convivo.WebNotification.prototype.setNotificationid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string message = 4;
 * @return {string}
 */
proto.czm.convivo.WebNotification.prototype.getMessage = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.WebNotification} returns this
 */
proto.czm.convivo.WebNotification.prototype.setMessage = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional NotificationStatus status = 5;
 * @return {!proto.czm.convivo.NotificationStatus}
 */
proto.czm.convivo.WebNotification.prototype.getStatus = function () {
  return /** @type {!proto.czm.convivo.NotificationStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.czm.convivo.NotificationStatus} value
 * @return {!proto.czm.convivo.WebNotification} returns this
 */
proto.czm.convivo.WebNotification.prototype.setStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 alertTimer = 6;
 * @return {number}
 */
proto.czm.convivo.WebNotification.prototype.getAlerttimer = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.WebNotification} returns this
 */
proto.czm.convivo.WebNotification.prototype.setAlerttimer = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string surgeonName = 7;
 * @return {string}
 */
proto.czm.convivo.WebNotification.prototype.getSurgeonname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.WebNotification} returns this
 */
proto.czm.convivo.WebNotification.prototype.setSurgeonname = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string createdDate = 8;
 * @return {string}
 */
proto.czm.convivo.WebNotification.prototype.getCreateddate = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.WebNotification} returns this
 */
proto.czm.convivo.WebNotification.prototype.setCreateddate = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.PathoLinkOrDelinkResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.PathoLinkOrDelinkResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.PathoLinkOrDelinkResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.PathoLinkOrDelinkResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      message: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.PathoLinkOrDelinkResponse}
 */
proto.czm.convivo.PathoLinkOrDelinkResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.PathoLinkOrDelinkResponse;
  return proto.czm.convivo.PathoLinkOrDelinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.PathoLinkOrDelinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.PathoLinkOrDelinkResponse}
 */
proto.czm.convivo.PathoLinkOrDelinkResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMessage(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.PathoLinkOrDelinkResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.PathoLinkOrDelinkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.PathoLinkOrDelinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.PathoLinkOrDelinkResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.czm.convivo.PathoLinkOrDelinkResponse.prototype.getMessage = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.PathoLinkOrDelinkResponse} returns this
 */
proto.czm.convivo.PathoLinkOrDelinkResponse.prototype.setMessage = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.PathologistStatus.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.PathologistStatus.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.PathologistStatus} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.PathologistStatus.toObject = function (includeInstance, msg) {
    var f, obj = {
      pathologist: (f = msg.getPathologist()) && proto.czm.convivo.UserInfo.toObject(includeInstance, f),
      active: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      caseid: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.PathologistStatus}
 */
proto.czm.convivo.PathologistStatus.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.PathologistStatus;
  return proto.czm.convivo.PathologistStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.PathologistStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.PathologistStatus}
 */
proto.czm.convivo.PathologistStatus.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.czm.convivo.UserInfo;
        reader.readMessage(value, proto.czm.convivo.UserInfo.deserializeBinaryFromReader);
        msg.setPathologist(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setActive(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setCaseid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.PathologistStatus.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.PathologistStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.PathologistStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.PathologistStatus.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPathologist();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.czm.convivo.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getCaseid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional UserInfo pathologist = 1;
 * @return {?proto.czm.convivo.UserInfo}
 */
proto.czm.convivo.PathologistStatus.prototype.getPathologist = function () {
  return /** @type{?proto.czm.convivo.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.UserInfo, 1));
};


/**
 * @param {?proto.czm.convivo.UserInfo|undefined} value
 * @return {!proto.czm.convivo.PathologistStatus} returns this
*/
proto.czm.convivo.PathologistStatus.prototype.setPathologist = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.PathologistStatus} returns this
 */
proto.czm.convivo.PathologistStatus.prototype.clearPathologist = function () {
  return this.setPathologist(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.PathologistStatus.prototype.hasPathologist = function () {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool active = 2;
 * @return {boolean}
 */
proto.czm.convivo.PathologistStatus.prototype.getActive = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.czm.convivo.PathologistStatus} returns this
 */
proto.czm.convivo.PathologistStatus.prototype.setActive = function (value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string caseID = 3;
 * @return {string}
 */
proto.czm.convivo.PathologistStatus.prototype.getCaseid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.PathologistStatus} returns this
 */
proto.czm.convivo.PathologistStatus.prototype.setCaseid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.AccountDetails.repeatedFields_ = [4, 12];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.AccountDetails.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.AccountDetails.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.AccountDetails} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.AccountDetails.toObject = function (includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      sapid: jspb.Message.getFieldWithDefault(msg, 3, ""),
      admininfoList: jspb.Message.toObjectList(msg.getAdmininfoList(),
        proto.czm.convivo.UserInfo.toObject, includeInstance),
      contactdetails: (f = msg.getContactdetails()) && proto.czm.convivo.ContactDetails.toObject(includeInstance, f),
      createdby: jspb.Message.getFieldWithDefault(msg, 6, ""),
      createdat: jspb.Message.getFieldWithDefault(msg, 7, ""),
      identificationtype: jspb.Message.getFieldWithDefault(msg, 8, ""),
      accounttype: jspb.Message.getFieldWithDefault(msg, 9, ""),
      updatedby: jspb.Message.getFieldWithDefault(msg, 10, ""),
      updatedat: jspb.Message.getFieldWithDefault(msg, 11, ""),
      linksList: jspb.Message.toObjectList(msg.getLinksList(),
        proto.czm.convivo.Links.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.AccountDetails}
 */
proto.czm.convivo.AccountDetails.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.AccountDetails;
  return proto.czm.convivo.AccountDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.AccountDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.AccountDetails}
 */
proto.czm.convivo.AccountDetails.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setSapid(value);
        break;
      case 4:
        var value = new proto.czm.convivo.UserInfo;
        reader.readMessage(value, proto.czm.convivo.UserInfo.deserializeBinaryFromReader);
        msg.addAdmininfo(value);
        break;
      case 5:
        var value = new proto.czm.convivo.ContactDetails;
        reader.readMessage(value, proto.czm.convivo.ContactDetails.deserializeBinaryFromReader);
        msg.setContactdetails(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setCreatedby(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setCreatedat(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setIdentificationtype(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccounttype(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setUpdatedby(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setUpdatedat(value);
        break;
      case 12:
        var value = new proto.czm.convivo.Links;
        reader.readMessage(value, proto.czm.convivo.Links.deserializeBinaryFromReader);
        msg.addLinks(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.AccountDetails.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.AccountDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.AccountDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.AccountDetails.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSapid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdmininfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.czm.convivo.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getContactdetails();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.czm.convivo.ContactDetails.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedat();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIdentificationtype();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAccounttype();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUpdatedby();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getUpdatedat();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.czm.convivo.Links.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.czm.convivo.AccountDetails.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.AccountDetails} returns this
 */
proto.czm.convivo.AccountDetails.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.czm.convivo.AccountDetails.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.AccountDetails} returns this
 */
proto.czm.convivo.AccountDetails.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sapId = 3;
 * @return {string}
 */
proto.czm.convivo.AccountDetails.prototype.getSapid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.AccountDetails} returns this
 */
proto.czm.convivo.AccountDetails.prototype.setSapid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated UserInfo adminInfo = 4;
 * @return {!Array<!proto.czm.convivo.UserInfo>}
 */
proto.czm.convivo.AccountDetails.prototype.getAdmininfoList = function () {
  return /** @type{!Array<!proto.czm.convivo.UserInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.UserInfo, 4));
};


/**
 * @param {!Array<!proto.czm.convivo.UserInfo>} value
 * @return {!proto.czm.convivo.AccountDetails} returns this
*/
proto.czm.convivo.AccountDetails.prototype.setAdmininfoList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.czm.convivo.UserInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.UserInfo}
 */
proto.czm.convivo.AccountDetails.prototype.addAdmininfo = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.czm.convivo.UserInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.AccountDetails} returns this
 */
proto.czm.convivo.AccountDetails.prototype.clearAdmininfoList = function () {
  return this.setAdmininfoList([]);
};


/**
 * optional ContactDetails contactDetails = 5;
 * @return {?proto.czm.convivo.ContactDetails}
 */
proto.czm.convivo.AccountDetails.prototype.getContactdetails = function () {
  return /** @type{?proto.czm.convivo.ContactDetails} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.ContactDetails, 5));
};


/**
 * @param {?proto.czm.convivo.ContactDetails|undefined} value
 * @return {!proto.czm.convivo.AccountDetails} returns this
*/
proto.czm.convivo.AccountDetails.prototype.setContactdetails = function (value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.AccountDetails} returns this
 */
proto.czm.convivo.AccountDetails.prototype.clearContactdetails = function () {
  return this.setContactdetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.AccountDetails.prototype.hasContactdetails = function () {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string createdBy = 6;
 * @return {string}
 */
proto.czm.convivo.AccountDetails.prototype.getCreatedby = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.AccountDetails} returns this
 */
proto.czm.convivo.AccountDetails.prototype.setCreatedby = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string createdAt = 7;
 * @return {string}
 */
proto.czm.convivo.AccountDetails.prototype.getCreatedat = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.AccountDetails} returns this
 */
proto.czm.convivo.AccountDetails.prototype.setCreatedat = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string identificationType = 8;
 * @return {string}
 */
proto.czm.convivo.AccountDetails.prototype.getIdentificationtype = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.AccountDetails} returns this
 */
proto.czm.convivo.AccountDetails.prototype.setIdentificationtype = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string accountType = 9;
 * @return {string}
 */
proto.czm.convivo.AccountDetails.prototype.getAccounttype = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.AccountDetails} returns this
 */
proto.czm.convivo.AccountDetails.prototype.setAccounttype = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string updatedBy = 10;
 * @return {string}
 */
proto.czm.convivo.AccountDetails.prototype.getUpdatedby = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.AccountDetails} returns this
 */
proto.czm.convivo.AccountDetails.prototype.setUpdatedby = function (value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string updatedAt = 11;
 * @return {string}
 */
proto.czm.convivo.AccountDetails.prototype.getUpdatedat = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.AccountDetails} returns this
 */
proto.czm.convivo.AccountDetails.prototype.setUpdatedat = function (value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated Links links = 12;
 * @return {!Array<!proto.czm.convivo.Links>}
 */
proto.czm.convivo.AccountDetails.prototype.getLinksList = function () {
  return /** @type{!Array<!proto.czm.convivo.Links>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.Links, 12));
};


/**
 * @param {!Array<!proto.czm.convivo.Links>} value
 * @return {!proto.czm.convivo.AccountDetails} returns this
*/
proto.czm.convivo.AccountDetails.prototype.setLinksList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.czm.convivo.Links=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.Links}
 */
proto.czm.convivo.AccountDetails.prototype.addLinks = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.czm.convivo.Links, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.AccountDetails} returns this
 */
proto.czm.convivo.AccountDetails.prototype.clearLinksList = function () {
  return this.setLinksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.ContactDetails.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.ContactDetails.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.ContactDetails} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.ContactDetails.toObject = function (includeInstance, msg) {
    var f, obj = {
      addressline1: jspb.Message.getFieldWithDefault(msg, 1, ""),
      addressline2: jspb.Message.getFieldWithDefault(msg, 2, ""),
      city: jspb.Message.getFieldWithDefault(msg, 3, ""),
      district: jspb.Message.getFieldWithDefault(msg, 4, ""),
      state: jspb.Message.getFieldWithDefault(msg, 5, ""),
      country: jspb.Message.getFieldWithDefault(msg, 6, ""),
      postalcode: jspb.Message.getFieldWithDefault(msg, 7, ""),
      email: jspb.Message.getFieldWithDefault(msg, 8, ""),
      phonenumber: jspb.Message.getFieldWithDefault(msg, 9, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.ContactDetails}
 */
proto.czm.convivo.ContactDetails.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.ContactDetails;
  return proto.czm.convivo.ContactDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.ContactDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.ContactDetails}
 */
proto.czm.convivo.ContactDetails.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAddressline1(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setAddressline2(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setCity(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setDistrict(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setState(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setCountry(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setPostalcode(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setEmail(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setPhonenumber(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.ContactDetails.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.ContactDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.ContactDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.ContactDetails.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getAddressline1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddressline2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDistrict();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPostalcode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string addressLine1 = 1;
 * @return {string}
 */
proto.czm.convivo.ContactDetails.prototype.getAddressline1 = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ContactDetails} returns this
 */
proto.czm.convivo.ContactDetails.prototype.setAddressline1 = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string addressLine2 = 2;
 * @return {string}
 */
proto.czm.convivo.ContactDetails.prototype.getAddressline2 = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ContactDetails} returns this
 */
proto.czm.convivo.ContactDetails.prototype.setAddressline2 = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 3;
 * @return {string}
 */
proto.czm.convivo.ContactDetails.prototype.getCity = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ContactDetails} returns this
 */
proto.czm.convivo.ContactDetails.prototype.setCity = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string district = 4;
 * @return {string}
 */
proto.czm.convivo.ContactDetails.prototype.getDistrict = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ContactDetails} returns this
 */
proto.czm.convivo.ContactDetails.prototype.setDistrict = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string state = 5;
 * @return {string}
 */
proto.czm.convivo.ContactDetails.prototype.getState = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ContactDetails} returns this
 */
proto.czm.convivo.ContactDetails.prototype.setState = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string country = 6;
 * @return {string}
 */
proto.czm.convivo.ContactDetails.prototype.getCountry = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ContactDetails} returns this
 */
proto.czm.convivo.ContactDetails.prototype.setCountry = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string postalCode = 7;
 * @return {string}
 */
proto.czm.convivo.ContactDetails.prototype.getPostalcode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ContactDetails} returns this
 */
proto.czm.convivo.ContactDetails.prototype.setPostalcode = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string email = 8;
 * @return {string}
 */
proto.czm.convivo.ContactDetails.prototype.getEmail = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ContactDetails} returns this
 */
proto.czm.convivo.ContactDetails.prototype.setEmail = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string phoneNumber = 9;
 * @return {string}
 */
proto.czm.convivo.ContactDetails.prototype.getPhonenumber = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ContactDetails} returns this
 */
proto.czm.convivo.ContactDetails.prototype.setPhonenumber = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.InviteUserRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.InviteUserRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.InviteUserRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.InviteUserRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.InviteUserRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      emailaddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
      roleidsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
      language: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.InviteUserRequest}
 */
proto.czm.convivo.InviteUserRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.InviteUserRequest;
  return proto.czm.convivo.InviteUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.InviteUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.InviteUserRequest}
 */
proto.czm.convivo.InviteUserRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setEmailaddress(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.addRoleids(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setLanguage(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.InviteUserRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.InviteUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.InviteUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.InviteUserRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getEmailaddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoleidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string emailAddress = 1;
 * @return {string}
 */
proto.czm.convivo.InviteUserRequest.prototype.getEmailaddress = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.InviteUserRequest} returns this
 */
proto.czm.convivo.InviteUserRequest.prototype.setEmailaddress = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string roleIds = 2;
 * @return {!Array<string>}
 */
proto.czm.convivo.InviteUserRequest.prototype.getRoleidsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.czm.convivo.InviteUserRequest} returns this
 */
proto.czm.convivo.InviteUserRequest.prototype.setRoleidsList = function (value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.InviteUserRequest} returns this
 */
proto.czm.convivo.InviteUserRequest.prototype.addRoleids = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.InviteUserRequest} returns this
 */
proto.czm.convivo.InviteUserRequest.prototype.clearRoleidsList = function () {
  return this.setRoleidsList([]);
};


/**
 * optional string language = 3;
 * @return {string}
 */
proto.czm.convivo.InviteUserRequest.prototype.getLanguage = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.InviteUserRequest} returns this
 */
proto.czm.convivo.InviteUserRequest.prototype.setLanguage = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.InviteUserResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.InviteUserResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.InviteUserResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.InviteUserResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.InviteUserResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      emailaddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
      alternateemail: jspb.Message.getFieldWithDefault(msg, 2, ""),
      firstname: jspb.Message.getFieldWithDefault(msg, 3, ""),
      lastname: jspb.Message.getFieldWithDefault(msg, 4, ""),
      contactdetailsList: jspb.Message.toObjectList(msg.getContactdetailsList(),
        proto.czm.convivo.ContactDetails.toObject, includeInstance),
      id: jspb.Message.getFieldWithDefault(msg, 6, ""),
      userstatus: jspb.Message.getFieldWithDefault(msg, 7, 0),
      preferences: (f = msg.getPreferences()) && proto.czm.convivo.Preferences.toObject(includeInstance, f),
      settings: jspb.Message.getFieldWithDefault(msg, 9, ""),
      lastlogin: jspb.Message.getFieldWithDefault(msg, 10, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.InviteUserResponse}
 */
proto.czm.convivo.InviteUserResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.InviteUserResponse;
  return proto.czm.convivo.InviteUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.InviteUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.InviteUserResponse}
 */
proto.czm.convivo.InviteUserResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setEmailaddress(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setAlternateemail(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setFirstname(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setLastname(value);
        break;
      case 5:
        var value = new proto.czm.convivo.ContactDetails;
        reader.readMessage(value, proto.czm.convivo.ContactDetails.deserializeBinaryFromReader);
        msg.addContactdetails(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 7:
        var value = /** @type {!proto.czm.convivo.InviteUserResponse.UserStatus} */ (reader.readEnum());
        msg.setUserstatus(value);
        break;
      case 8:
        var value = new proto.czm.convivo.Preferences;
        reader.readMessage(value, proto.czm.convivo.Preferences.deserializeBinaryFromReader);
        msg.setPreferences(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setSettings(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setLastlogin(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.InviteUserResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.InviteUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.InviteUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.InviteUserResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getEmailaddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAlternateemail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContactdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.czm.convivo.ContactDetails.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getPreferences();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.czm.convivo.Preferences.serializeBinaryToWriter
    );
  }
  f = message.getSettings();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLastlogin();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.czm.convivo.InviteUserResponse.UserStatus = {
  ACTIVE: 0,
  INACTIVE: 1,
  INVITED: 2
};

/**
 * optional string emailAddress = 1;
 * @return {string}
 */
proto.czm.convivo.InviteUserResponse.prototype.getEmailaddress = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.InviteUserResponse} returns this
 */
proto.czm.convivo.InviteUserResponse.prototype.setEmailaddress = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string alternateEmail = 2;
 * @return {string}
 */
proto.czm.convivo.InviteUserResponse.prototype.getAlternateemail = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.InviteUserResponse} returns this
 */
proto.czm.convivo.InviteUserResponse.prototype.setAlternateemail = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string firstName = 3;
 * @return {string}
 */
proto.czm.convivo.InviteUserResponse.prototype.getFirstname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.InviteUserResponse} returns this
 */
proto.czm.convivo.InviteUserResponse.prototype.setFirstname = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string lastName = 4;
 * @return {string}
 */
proto.czm.convivo.InviteUserResponse.prototype.getLastname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.InviteUserResponse} returns this
 */
proto.czm.convivo.InviteUserResponse.prototype.setLastname = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated ContactDetails contactDetails = 5;
 * @return {!Array<!proto.czm.convivo.ContactDetails>}
 */
proto.czm.convivo.InviteUserResponse.prototype.getContactdetailsList = function () {
  return /** @type{!Array<!proto.czm.convivo.ContactDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.ContactDetails, 5));
};


/**
 * @param {!Array<!proto.czm.convivo.ContactDetails>} value
 * @return {!proto.czm.convivo.InviteUserResponse} returns this
*/
proto.czm.convivo.InviteUserResponse.prototype.setContactdetailsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.czm.convivo.ContactDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.ContactDetails}
 */
proto.czm.convivo.InviteUserResponse.prototype.addContactdetails = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.czm.convivo.ContactDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.InviteUserResponse} returns this
 */
proto.czm.convivo.InviteUserResponse.prototype.clearContactdetailsList = function () {
  return this.setContactdetailsList([]);
};


/**
 * optional string id = 6;
 * @return {string}
 */
proto.czm.convivo.InviteUserResponse.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.InviteUserResponse} returns this
 */
proto.czm.convivo.InviteUserResponse.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional UserStatus userStatus = 7;
 * @return {!proto.czm.convivo.InviteUserResponse.UserStatus}
 */
proto.czm.convivo.InviteUserResponse.prototype.getUserstatus = function () {
  return /** @type {!proto.czm.convivo.InviteUserResponse.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.czm.convivo.InviteUserResponse.UserStatus} value
 * @return {!proto.czm.convivo.InviteUserResponse} returns this
 */
proto.czm.convivo.InviteUserResponse.prototype.setUserstatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional Preferences preferences = 8;
 * @return {?proto.czm.convivo.Preferences}
 */
proto.czm.convivo.InviteUserResponse.prototype.getPreferences = function () {
  return /** @type{?proto.czm.convivo.Preferences} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.Preferences, 8));
};


/**
 * @param {?proto.czm.convivo.Preferences|undefined} value
 * @return {!proto.czm.convivo.InviteUserResponse} returns this
*/
proto.czm.convivo.InviteUserResponse.prototype.setPreferences = function (value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.InviteUserResponse} returns this
 */
proto.czm.convivo.InviteUserResponse.prototype.clearPreferences = function () {
  return this.setPreferences(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.InviteUserResponse.prototype.hasPreferences = function () {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string settings = 9;
 * @return {string}
 */
proto.czm.convivo.InviteUserResponse.prototype.getSettings = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.InviteUserResponse} returns this
 */
proto.czm.convivo.InviteUserResponse.prototype.setSettings = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string lastLogin = 10;
 * @return {string}
 */
proto.czm.convivo.InviteUserResponse.prototype.getLastlogin = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.InviteUserResponse} returns this
 */
proto.czm.convivo.InviteUserResponse.prototype.setLastlogin = function (value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.UserList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.UserList.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.UserList.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.UserList} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.UserList.toObject = function (includeInstance, msg) {
    var f, obj = {
      usersList: jspb.Message.toObjectList(msg.getUsersList(),
        proto.czm.convivo.User.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.UserList}
 */
proto.czm.convivo.UserList.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.UserList;
  return proto.czm.convivo.UserList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.UserList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.UserList}
 */
proto.czm.convivo.UserList.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.czm.convivo.User;
        reader.readMessage(value, proto.czm.convivo.User.deserializeBinaryFromReader);
        msg.addUsers(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.UserList.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.UserList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.UserList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.UserList.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.czm.convivo.User.serializeBinaryToWriter
    );
  }
};


/**
 * repeated User users = 1;
 * @return {!Array<!proto.czm.convivo.User>}
 */
proto.czm.convivo.UserList.prototype.getUsersList = function () {
  return /** @type{!Array<!proto.czm.convivo.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.User, 1));
};


/**
 * @param {!Array<!proto.czm.convivo.User>} value
 * @return {!proto.czm.convivo.UserList} returns this
*/
proto.czm.convivo.UserList.prototype.setUsersList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.czm.convivo.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.User}
 */
proto.czm.convivo.UserList.prototype.addUsers = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.czm.convivo.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.UserList} returns this
 */
proto.czm.convivo.UserList.prototype.clearUsersList = function () {
  return this.setUsersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.User.repeatedFields_ = [7, 12];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.User.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.User.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.User} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.User.toObject = function (includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
      lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
      emailaddress: jspb.Message.getFieldWithDefault(msg, 4, ""),
      phonenumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
      status: jspb.Message.getFieldWithDefault(msg, 6, 0),
      rolesList: jspb.Message.toObjectList(msg.getRolesList(),
        proto.czm.convivo.Role.toObject, includeInstance),
      preferences: (f = msg.getPreferences()) && proto.czm.convivo.Preferences.toObject(includeInstance, f),
      lastlogindatetime: jspb.Message.getFieldWithDefault(msg, 9, ""),
      createddate: jspb.Message.getFieldWithDefault(msg, 10, ""),
      account: (f = msg.getAccount()) && proto.czm.convivo.AccountDetails.toObject(includeInstance, f),
      sitesList: jspb.Message.toObjectList(msg.getSitesList(),
        proto.czm.convivo.Site.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.User}
 */
proto.czm.convivo.User.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.User;
  return proto.czm.convivo.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.User}
 */
proto.czm.convivo.User.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setFirstname(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setLastname(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setEmailaddress(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setPhonenumber(value);
        break;
      case 6:
        var value = /** @type {!proto.czm.convivo.User.UserStatus} */ (reader.readEnum());
        msg.setStatus(value);
        break;
      case 7:
        var value = new proto.czm.convivo.Role;
        reader.readMessage(value, proto.czm.convivo.Role.deserializeBinaryFromReader);
        msg.addRoles(value);
        break;
      case 8:
        var value = new proto.czm.convivo.Preferences;
        reader.readMessage(value, proto.czm.convivo.Preferences.deserializeBinaryFromReader);
        msg.setPreferences(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setLastlogindatetime(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setCreateddate(value);
        break;
      case 11:
        var value = new proto.czm.convivo.AccountDetails;
        reader.readMessage(value, proto.czm.convivo.AccountDetails.deserializeBinaryFromReader);
        msg.setAccount(value);
        break;
      case 12:
        var value = new proto.czm.convivo.Site;
        reader.readMessage(value, proto.czm.convivo.Site.deserializeBinaryFromReader);
        msg.addSites(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.User.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.User.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmailaddress();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.czm.convivo.Role.serializeBinaryToWriter
    );
  }
  f = message.getPreferences();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.czm.convivo.Preferences.serializeBinaryToWriter
    );
  }
  f = message.getLastlogindatetime();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCreateddate();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.czm.convivo.AccountDetails.serializeBinaryToWriter
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.czm.convivo.Site.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.czm.convivo.User.UserStatus = {
  ACTIVE: 0,
  INACTIVE: 1,
  INVITED: 2
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.czm.convivo.User.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.User} returns this
 */
proto.czm.convivo.User.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string firstName = 2;
 * @return {string}
 */
proto.czm.convivo.User.prototype.getFirstname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.User} returns this
 */
proto.czm.convivo.User.prototype.setFirstname = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.czm.convivo.User.prototype.getLastname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.User} returns this
 */
proto.czm.convivo.User.prototype.setLastname = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string emailAddress = 4;
 * @return {string}
 */
proto.czm.convivo.User.prototype.getEmailaddress = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.User} returns this
 */
proto.czm.convivo.User.prototype.setEmailaddress = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phoneNumber = 5;
 * @return {string}
 */
proto.czm.convivo.User.prototype.getPhonenumber = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.User} returns this
 */
proto.czm.convivo.User.prototype.setPhonenumber = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional UserStatus status = 6;
 * @return {!proto.czm.convivo.User.UserStatus}
 */
proto.czm.convivo.User.prototype.getStatus = function () {
  return /** @type {!proto.czm.convivo.User.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.czm.convivo.User.UserStatus} value
 * @return {!proto.czm.convivo.User} returns this
 */
proto.czm.convivo.User.prototype.setStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated Role roles = 7;
 * @return {!Array<!proto.czm.convivo.Role>}
 */
proto.czm.convivo.User.prototype.getRolesList = function () {
  return /** @type{!Array<!proto.czm.convivo.Role>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.Role, 7));
};


/**
 * @param {!Array<!proto.czm.convivo.Role>} value
 * @return {!proto.czm.convivo.User} returns this
*/
proto.czm.convivo.User.prototype.setRolesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.czm.convivo.Role=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.Role}
 */
proto.czm.convivo.User.prototype.addRoles = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.czm.convivo.Role, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.User} returns this
 */
proto.czm.convivo.User.prototype.clearRolesList = function () {
  return this.setRolesList([]);
};


/**
 * optional Preferences preferences = 8;
 * @return {?proto.czm.convivo.Preferences}
 */
proto.czm.convivo.User.prototype.getPreferences = function () {
  return /** @type{?proto.czm.convivo.Preferences} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.Preferences, 8));
};


/**
 * @param {?proto.czm.convivo.Preferences|undefined} value
 * @return {!proto.czm.convivo.User} returns this
*/
proto.czm.convivo.User.prototype.setPreferences = function (value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.User} returns this
 */
proto.czm.convivo.User.prototype.clearPreferences = function () {
  return this.setPreferences(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.User.prototype.hasPreferences = function () {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string lastLoginDateTime = 9;
 * @return {string}
 */
proto.czm.convivo.User.prototype.getLastlogindatetime = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.User} returns this
 */
proto.czm.convivo.User.prototype.setLastlogindatetime = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string createdDate = 10;
 * @return {string}
 */
proto.czm.convivo.User.prototype.getCreateddate = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.User} returns this
 */
proto.czm.convivo.User.prototype.setCreateddate = function (value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional AccountDetails account = 11;
 * @return {?proto.czm.convivo.AccountDetails}
 */
proto.czm.convivo.User.prototype.getAccount = function () {
  return /** @type{?proto.czm.convivo.AccountDetails} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.AccountDetails, 11));
};


/**
 * @param {?proto.czm.convivo.AccountDetails|undefined} value
 * @return {!proto.czm.convivo.User} returns this
*/
proto.czm.convivo.User.prototype.setAccount = function (value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.User} returns this
 */
proto.czm.convivo.User.prototype.clearAccount = function () {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.User.prototype.hasAccount = function () {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated Site sites = 12;
 * @return {!Array<!proto.czm.convivo.Site>}
 */
proto.czm.convivo.User.prototype.getSitesList = function () {
  return /** @type{!Array<!proto.czm.convivo.Site>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.Site, 12));
};


/**
 * @param {!Array<!proto.czm.convivo.Site>} value
 * @return {!proto.czm.convivo.User} returns this
*/
proto.czm.convivo.User.prototype.setSitesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.czm.convivo.Site=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.Site}
 */
proto.czm.convivo.User.prototype.addSites = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.czm.convivo.Site, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.User} returns this
 */
proto.czm.convivo.User.prototype.clearSitesList = function () {
  return this.setSitesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.Role.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.Role.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.Role} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.Role.toObject = function (includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      weightage: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.Role}
 */
proto.czm.convivo.Role.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.Role;
  return proto.czm.convivo.Role.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.Role} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.Role}
 */
proto.czm.convivo.Role.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setWeightage(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.Role.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.Role.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.Role} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.Role.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWeightage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.czm.convivo.Role.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Role} returns this
 */
proto.czm.convivo.Role.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.czm.convivo.Role.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Role} returns this
 */
proto.czm.convivo.Role.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 weightage = 3;
 * @return {number}
 */
proto.czm.convivo.Role.prototype.getWeightage = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.Role} returns this
 */
proto.czm.convivo.Role.prototype.setWeightage = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.Preferences.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.Preferences.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.Preferences} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.Preferences.toObject = function (includeInstance, msg) {
    var f, obj = {
      language: jspb.Message.getFieldWithDefault(msg, 1, ""),
      emailcommunication: jspb.Message.getFieldWithDefault(msg, 2, ""),
      smscommunincation: jspb.Message.getFieldWithDefault(msg, 3, ""),
      systemnotification: jspb.Message.getFieldWithDefault(msg, 4, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.Preferences}
 */
proto.czm.convivo.Preferences.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.Preferences;
  return proto.czm.convivo.Preferences.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.Preferences} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.Preferences}
 */
proto.czm.convivo.Preferences.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setLanguage(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setEmailcommunication(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setSmscommunincation(value);
        break;
      case 4:
        var value = /** @type {!proto.czm.convivo.Preferences.SystemNotification} */ (reader.readEnum());
        msg.setSystemnotification(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.Preferences.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.Preferences.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.Preferences} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.Preferences.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmailcommunication();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSmscommunincation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSystemnotification();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.czm.convivo.Preferences.SystemNotification = {
  PLATFORMINTERNAL: 0,
  PLATFORMEMAIL: 1
};

/**
 * optional string language = 1;
 * @return {string}
 */
proto.czm.convivo.Preferences.prototype.getLanguage = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Preferences} returns this
 */
proto.czm.convivo.Preferences.prototype.setLanguage = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string emailCommunication = 2;
 * @return {string}
 */
proto.czm.convivo.Preferences.prototype.getEmailcommunication = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Preferences} returns this
 */
proto.czm.convivo.Preferences.prototype.setEmailcommunication = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string smsCommunincation = 3;
 * @return {string}
 */
proto.czm.convivo.Preferences.prototype.getSmscommunincation = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Preferences} returns this
 */
proto.czm.convivo.Preferences.prototype.setSmscommunincation = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional SystemNotification systemNotification = 4;
 * @return {!proto.czm.convivo.Preferences.SystemNotification}
 */
proto.czm.convivo.Preferences.prototype.getSystemnotification = function () {
  return /** @type {!proto.czm.convivo.Preferences.SystemNotification} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.czm.convivo.Preferences.SystemNotification} value
 * @return {!proto.czm.convivo.Preferences} returns this
 */
proto.czm.convivo.Preferences.prototype.setSystemnotification = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.UpdateUserRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.UpdateUserRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.UpdateUserRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.UpdateUserRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      preferences: (f = msg.getPreferences()) && proto.czm.convivo.Preferences.toObject(includeInstance, f),
      siteid: jspb.Message.getFieldWithDefault(msg, 3, ""),
      issiteuser: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
      isstatusupdate: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.UpdateUserRequest}
 */
proto.czm.convivo.UpdateUserRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.UpdateUserRequest;
  return proto.czm.convivo.UpdateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.UpdateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.UpdateUserRequest}
 */
proto.czm.convivo.UpdateUserRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.czm.convivo.UpdateUserRequest.UserStatus} */ (reader.readEnum());
        msg.setStatus(value);
        break;
      case 2:
        var value = new proto.czm.convivo.Preferences;
        reader.readMessage(value, proto.czm.convivo.Preferences.deserializeBinaryFromReader);
        msg.setPreferences(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setSiteid(value);
        break;
      case 4:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIssiteuser(value);
        break;
      case 6:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsstatusupdate(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.UpdateUserRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.UpdateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.UpdateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.UpdateUserRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPreferences();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.czm.convivo.Preferences.serializeBinaryToWriter
    );
  }
  f = message.getSiteid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIssiteuser();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsstatusupdate();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.czm.convivo.UpdateUserRequest.UserStatus = {
  ACTIVE: 0,
  INACTIVE: 1,
  INVITED: 2
};

/**
 * optional UserStatus status = 1;
 * @return {!proto.czm.convivo.UpdateUserRequest.UserStatus}
 */
proto.czm.convivo.UpdateUserRequest.prototype.getStatus = function () {
  return /** @type {!proto.czm.convivo.UpdateUserRequest.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.czm.convivo.UpdateUserRequest.UserStatus} value
 * @return {!proto.czm.convivo.UpdateUserRequest} returns this
 */
proto.czm.convivo.UpdateUserRequest.prototype.setStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Preferences preferences = 2;
 * @return {?proto.czm.convivo.Preferences}
 */
proto.czm.convivo.UpdateUserRequest.prototype.getPreferences = function () {
  return /** @type{?proto.czm.convivo.Preferences} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.Preferences, 2));
};


/**
 * @param {?proto.czm.convivo.Preferences|undefined} value
 * @return {!proto.czm.convivo.UpdateUserRequest} returns this
*/
proto.czm.convivo.UpdateUserRequest.prototype.setPreferences = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.UpdateUserRequest} returns this
 */
proto.czm.convivo.UpdateUserRequest.prototype.clearPreferences = function () {
  return this.setPreferences(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.UpdateUserRequest.prototype.hasPreferences = function () {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string siteID = 3;
 * @return {string}
 */
proto.czm.convivo.UpdateUserRequest.prototype.getSiteid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UpdateUserRequest} returns this
 */
proto.czm.convivo.UpdateUserRequest.prototype.setSiteid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool isSiteUser = 4;
 * @return {boolean}
 */
proto.czm.convivo.UpdateUserRequest.prototype.getIssiteuser = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.czm.convivo.UpdateUserRequest} returns this
 */
proto.czm.convivo.UpdateUserRequest.prototype.setIssiteuser = function (value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool IsStatusUpdate = 6;
 * @return {boolean}
 */
proto.czm.convivo.UpdateUserRequest.prototype.getIsstatusupdate = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.czm.convivo.UpdateUserRequest} returns this
 */
proto.czm.convivo.UpdateUserRequest.prototype.setIsstatusupdate = function (value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.UpdateCaseRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.UpdateCaseRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.UpdateCaseRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.UpdateCaseRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      interpretationtext: jspb.Message.getFieldWithDefault(msg, 1, ""),
      comment: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.UpdateCaseRequest}
 */
proto.czm.convivo.UpdateCaseRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.UpdateCaseRequest;
  return proto.czm.convivo.UpdateCaseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.UpdateCaseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.UpdateCaseRequest}
 */
proto.czm.convivo.UpdateCaseRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setInterpretationtext(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setComment(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.UpdateCaseRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.UpdateCaseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.UpdateCaseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.UpdateCaseRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getInterpretationtext();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string interpretationText = 1;
 * @return {string}
 */
proto.czm.convivo.UpdateCaseRequest.prototype.getInterpretationtext = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UpdateCaseRequest} returns this
 */
proto.czm.convivo.UpdateCaseRequest.prototype.setInterpretationtext = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string comment = 2;
 * @return {string}
 */
proto.czm.convivo.UpdateCaseRequest.prototype.getComment = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UpdateCaseRequest} returns this
 */
proto.czm.convivo.UpdateCaseRequest.prototype.setComment = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.UpdateImageRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.UpdateImageRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.UpdateImageRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.UpdateImageRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      annotation: jspb.Message.getFieldWithDefault(msg, 1, ""),
      comment: jspb.Message.getFieldWithDefault(msg, 2, ""),
      snapshot: msg.getSnapshot_asB64()
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.UpdateImageRequest}
 */
proto.czm.convivo.UpdateImageRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.UpdateImageRequest;
  return proto.czm.convivo.UpdateImageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.UpdateImageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.UpdateImageRequest}
 */
proto.czm.convivo.UpdateImageRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAnnotation(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setComment(value);
        break;
      case 3:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setSnapshot(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.UpdateImageRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.UpdateImageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.UpdateImageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.UpdateImageRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getAnnotation();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSnapshot_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional string annotation = 1;
 * @return {string}
 */
proto.czm.convivo.UpdateImageRequest.prototype.getAnnotation = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UpdateImageRequest} returns this
 */
proto.czm.convivo.UpdateImageRequest.prototype.setAnnotation = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string comment = 2;
 * @return {string}
 */
proto.czm.convivo.UpdateImageRequest.prototype.getComment = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UpdateImageRequest} returns this
 */
proto.czm.convivo.UpdateImageRequest.prototype.setComment = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes snapshot = 3;
 * @return {!(string|Uint8Array)}
 */
proto.czm.convivo.UpdateImageRequest.prototype.getSnapshot = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes snapshot = 3;
 * This is a type-conversion wrapper around `getSnapshot()`
 * @return {string}
 */
proto.czm.convivo.UpdateImageRequest.prototype.getSnapshot_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
    this.getSnapshot()));
};


/**
 * optional bytes snapshot = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSnapshot()`
 * @return {!Uint8Array}
 */
proto.czm.convivo.UpdateImageRequest.prototype.getSnapshot_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
    this.getSnapshot()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.czm.convivo.UpdateImageRequest} returns this
 */
proto.czm.convivo.UpdateImageRequest.prototype.setSnapshot = function (value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.ExternalFile.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.ExternalFile.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.ExternalFile} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.ExternalFile.toObject = function (includeInstance, msg) {
    var f, obj = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      file: msg.getFile_asB64(),
      fileextension: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.ExternalFile}
 */
proto.czm.convivo.ExternalFile.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.ExternalFile;
  return proto.czm.convivo.ExternalFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.ExternalFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.ExternalFile}
 */
proto.czm.convivo.ExternalFile.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setFile(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setFileextension(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.ExternalFile.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.ExternalFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.ExternalFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.ExternalFile.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getFileextension();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.czm.convivo.ExternalFile.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ExternalFile} returns this
 */
proto.czm.convivo.ExternalFile.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes file = 2;
 * @return {!(string|Uint8Array)}
 */
proto.czm.convivo.ExternalFile.prototype.getFile = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes file = 2;
 * This is a type-conversion wrapper around `getFile()`
 * @return {string}
 */
proto.czm.convivo.ExternalFile.prototype.getFile_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
    this.getFile()));
};


/**
 * optional bytes file = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFile()`
 * @return {!Uint8Array}
 */
proto.czm.convivo.ExternalFile.prototype.getFile_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
    this.getFile()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.czm.convivo.ExternalFile} returns this
 */
proto.czm.convivo.ExternalFile.prototype.setFile = function (value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional string fileExtension = 3;
 * @return {string}
 */
proto.czm.convivo.ExternalFile.prototype.getFileextension = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ExternalFile} returns this
 */
proto.czm.convivo.ExternalFile.prototype.setFileextension = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.GetRolesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.GetRolesResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.GetRolesResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.GetRolesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.GetRolesResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      rolesList: jspb.Message.toObjectList(msg.getRolesList(),
        proto.czm.convivo.Role.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.GetRolesResponse}
 */
proto.czm.convivo.GetRolesResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.GetRolesResponse;
  return proto.czm.convivo.GetRolesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.GetRolesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.GetRolesResponse}
 */
proto.czm.convivo.GetRolesResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.czm.convivo.Role;
        reader.readMessage(value, proto.czm.convivo.Role.deserializeBinaryFromReader);
        msg.addRoles(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.GetRolesResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.GetRolesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.GetRolesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.GetRolesResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.czm.convivo.Role.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Role roles = 1;
 * @return {!Array<!proto.czm.convivo.Role>}
 */
proto.czm.convivo.GetRolesResponse.prototype.getRolesList = function () {
  return /** @type{!Array<!proto.czm.convivo.Role>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.Role, 1));
};


/**
 * @param {!Array<!proto.czm.convivo.Role>} value
 * @return {!proto.czm.convivo.GetRolesResponse} returns this
*/
proto.czm.convivo.GetRolesResponse.prototype.setRolesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.czm.convivo.Role=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.Role}
 */
proto.czm.convivo.GetRolesResponse.prototype.addRoles = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.czm.convivo.Role, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.GetRolesResponse} returns this
 */
proto.czm.convivo.GetRolesResponse.prototype.clearRolesList = function () {
  return this.setRolesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.CaseFilters.repeatedFields_ = [1, 2, 3, 4];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.CaseFilters.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.CaseFilters.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.CaseFilters} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.CaseFilters.toObject = function (includeInstance, msg) {
    var f, obj = {
      genderList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      tissuelocationList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
      tissuetypeList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
      reportstatusList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
      fromdate: jspb.Message.getFieldWithDefault(msg, 5, ""),
      todate: jspb.Message.getFieldWithDefault(msg, 6, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.CaseFilters}
 */
proto.czm.convivo.CaseFilters.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.CaseFilters;
  return proto.czm.convivo.CaseFilters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.CaseFilters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.CaseFilters}
 */
proto.czm.convivo.CaseFilters.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!Array<!proto.czm.convivo.Sex>} */ (reader.readPackedEnum());
        msg.setGenderList(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.addTissuelocation(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.addTissuetype(value);
        break;
      case 4:
        var value = /** @type {!Array<!proto.czm.convivo.CaseStatus>} */ (reader.readPackedEnum());
        msg.setReportstatusList(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setFromdate(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setTodate(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.CaseFilters.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.CaseFilters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.CaseFilters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.CaseFilters.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGenderList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getTissuelocationList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getTissuetypeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getReportstatusList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getFromdate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTodate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * repeated Sex Gender = 1;
 * @return {!Array<!proto.czm.convivo.Sex>}
 */
proto.czm.convivo.CaseFilters.prototype.getGenderList = function () {
  return /** @type {!Array<!proto.czm.convivo.Sex>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.czm.convivo.Sex>} value
 * @return {!proto.czm.convivo.CaseFilters} returns this
 */
proto.czm.convivo.CaseFilters.prototype.setGenderList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.czm.convivo.Sex} value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.CaseFilters} returns this
 */
proto.czm.convivo.CaseFilters.prototype.addGender = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.CaseFilters} returns this
 */
proto.czm.convivo.CaseFilters.prototype.clearGenderList = function () {
  return this.setGenderList([]);
};


/**
 * repeated string TissueLocation = 2;
 * @return {!Array<string>}
 */
proto.czm.convivo.CaseFilters.prototype.getTissuelocationList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.czm.convivo.CaseFilters} returns this
 */
proto.czm.convivo.CaseFilters.prototype.setTissuelocationList = function (value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.CaseFilters} returns this
 */
proto.czm.convivo.CaseFilters.prototype.addTissuelocation = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.CaseFilters} returns this
 */
proto.czm.convivo.CaseFilters.prototype.clearTissuelocationList = function () {
  return this.setTissuelocationList([]);
};


/**
 * repeated string TissueType = 3;
 * @return {!Array<string>}
 */
proto.czm.convivo.CaseFilters.prototype.getTissuetypeList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.czm.convivo.CaseFilters} returns this
 */
proto.czm.convivo.CaseFilters.prototype.setTissuetypeList = function (value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.CaseFilters} returns this
 */
proto.czm.convivo.CaseFilters.prototype.addTissuetype = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.CaseFilters} returns this
 */
proto.czm.convivo.CaseFilters.prototype.clearTissuetypeList = function () {
  return this.setTissuetypeList([]);
};


/**
 * repeated CaseStatus ReportStatus = 4;
 * @return {!Array<!proto.czm.convivo.CaseStatus>}
 */
proto.czm.convivo.CaseFilters.prototype.getReportstatusList = function () {
  return /** @type {!Array<!proto.czm.convivo.CaseStatus>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.czm.convivo.CaseStatus>} value
 * @return {!proto.czm.convivo.CaseFilters} returns this
 */
proto.czm.convivo.CaseFilters.prototype.setReportstatusList = function (value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.czm.convivo.CaseStatus} value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.CaseFilters} returns this
 */
proto.czm.convivo.CaseFilters.prototype.addReportstatus = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.CaseFilters} returns this
 */
proto.czm.convivo.CaseFilters.prototype.clearReportstatusList = function () {
  return this.setReportstatusList([]);
};


/**
 * optional string FromDate = 5;
 * @return {string}
 */
proto.czm.convivo.CaseFilters.prototype.getFromdate = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CaseFilters} returns this
 */
proto.czm.convivo.CaseFilters.prototype.setFromdate = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ToDate = 6;
 * @return {string}
 */
proto.czm.convivo.CaseFilters.prototype.getTodate = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CaseFilters} returns this
 */
proto.czm.convivo.CaseFilters.prototype.setTodate = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.CaseSearch.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.CaseSearch.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.CaseSearch} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.CaseSearch.toObject = function (includeInstance, msg) {
    var f, obj = {
      text: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.CaseSearch}
 */
proto.czm.convivo.CaseSearch.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.CaseSearch;
  return proto.czm.convivo.CaseSearch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.CaseSearch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.CaseSearch}
 */
proto.czm.convivo.CaseSearch.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setText(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.CaseSearch.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.CaseSearch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.CaseSearch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.CaseSearch.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Text = 1;
 * @return {string}
 */
proto.czm.convivo.CaseSearch.prototype.getText = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CaseSearch} returns this
 */
proto.czm.convivo.CaseSearch.prototype.setText = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.SiteAssociation.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.SiteAssociation.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.SiteAssociation.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.SiteAssociation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.SiteAssociation.toObject = function (includeInstance, msg) {
    var f, obj = {
      associationid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      siteid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      associatedsiteid: jspb.Message.getFieldWithDefault(msg, 3, ""),
      sitename: jspb.Message.getFieldWithDefault(msg, 4, ""),
      associatedsitename: jspb.Message.getFieldWithDefault(msg, 5, ""),
      associationstatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
      createdby: jspb.Message.getFieldWithDefault(msg, 7, ""),
      createddatetime: jspb.Message.getFieldWithDefault(msg, 8, ""),
      accountid: jspb.Message.getFieldWithDefault(msg, 9, ""),
      associatedaccountid: jspb.Message.getFieldWithDefault(msg, 10, ""),
      isdefaultsite: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
      casescount: jspb.Message.getFieldWithDefault(msg, 12, 0),
      lastconsultationdate: jspb.Message.getFieldWithDefault(msg, 13, ""),
      defaultsiteassociationList: jspb.Message.toObjectList(msg.getDefaultsiteassociationList(),
        proto.czm.convivo.DefaultSiteAssociation.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.SiteAssociation}
 */
proto.czm.convivo.SiteAssociation.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.SiteAssociation;
  return proto.czm.convivo.SiteAssociation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.SiteAssociation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.SiteAssociation}
 */
proto.czm.convivo.SiteAssociation.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssociationid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setSiteid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssociatedsiteid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setSitename(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssociatedsitename(value);
        break;
      case 6:
        var value = /** @type {!proto.czm.convivo.AssociationStatus} */ (reader.readEnum());
        msg.setAssociationstatus(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setCreatedby(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setCreateddatetime(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccountid(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssociatedaccountid(value);
        break;
      case 11:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsdefaultsite(value);
        break;
      case 12:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCasescount(value);
        break;
      case 13:
        var value = /** @type {string} */ (reader.readString());
        msg.setLastconsultationdate(value);
        break;
      case 14:
        var value = new proto.czm.convivo.DefaultSiteAssociation;
        reader.readMessage(value, proto.czm.convivo.DefaultSiteAssociation.deserializeBinaryFromReader);
        msg.addDefaultsiteassociation(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.SiteAssociation.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.SiteAssociation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.SiteAssociation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.SiteAssociation.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getAssociationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssociatedsiteid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSitename();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAssociatedsitename();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAssociationstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getCreatedby();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreateddatetime();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAssociatedaccountid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIsdefaultsite();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getCasescount();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getLastconsultationdate();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDefaultsiteassociationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.czm.convivo.DefaultSiteAssociation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string associationId = 1;
 * @return {string}
 */
proto.czm.convivo.SiteAssociation.prototype.getAssociationid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SiteAssociation} returns this
 */
proto.czm.convivo.SiteAssociation.prototype.setAssociationid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string siteId = 2;
 * @return {string}
 */
proto.czm.convivo.SiteAssociation.prototype.getSiteid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SiteAssociation} returns this
 */
proto.czm.convivo.SiteAssociation.prototype.setSiteid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string associatedSiteId = 3;
 * @return {string}
 */
proto.czm.convivo.SiteAssociation.prototype.getAssociatedsiteid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SiteAssociation} returns this
 */
proto.czm.convivo.SiteAssociation.prototype.setAssociatedsiteid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string siteName = 4;
 * @return {string}
 */
proto.czm.convivo.SiteAssociation.prototype.getSitename = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SiteAssociation} returns this
 */
proto.czm.convivo.SiteAssociation.prototype.setSitename = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string associatedSiteName = 5;
 * @return {string}
 */
proto.czm.convivo.SiteAssociation.prototype.getAssociatedsitename = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SiteAssociation} returns this
 */
proto.czm.convivo.SiteAssociation.prototype.setAssociatedsitename = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional AssociationStatus associationStatus = 6;
 * @return {!proto.czm.convivo.AssociationStatus}
 */
proto.czm.convivo.SiteAssociation.prototype.getAssociationstatus = function () {
  return /** @type {!proto.czm.convivo.AssociationStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.czm.convivo.AssociationStatus} value
 * @return {!proto.czm.convivo.SiteAssociation} returns this
 */
proto.czm.convivo.SiteAssociation.prototype.setAssociationstatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string createdBy = 7;
 * @return {string}
 */
proto.czm.convivo.SiteAssociation.prototype.getCreatedby = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SiteAssociation} returns this
 */
proto.czm.convivo.SiteAssociation.prototype.setCreatedby = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string createdDateTime = 8;
 * @return {string}
 */
proto.czm.convivo.SiteAssociation.prototype.getCreateddatetime = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SiteAssociation} returns this
 */
proto.czm.convivo.SiteAssociation.prototype.setCreateddatetime = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string accountId = 9;
 * @return {string}
 */
proto.czm.convivo.SiteAssociation.prototype.getAccountid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SiteAssociation} returns this
 */
proto.czm.convivo.SiteAssociation.prototype.setAccountid = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string associatedAccountId = 10;
 * @return {string}
 */
proto.czm.convivo.SiteAssociation.prototype.getAssociatedaccountid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SiteAssociation} returns this
 */
proto.czm.convivo.SiteAssociation.prototype.setAssociatedaccountid = function (value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool IsDefaultSite = 11;
 * @return {boolean}
 */
proto.czm.convivo.SiteAssociation.prototype.getIsdefaultsite = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.czm.convivo.SiteAssociation} returns this
 */
proto.czm.convivo.SiteAssociation.prototype.setIsdefaultsite = function (value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int32 casesCount = 12;
 * @return {number}
 */
proto.czm.convivo.SiteAssociation.prototype.getCasescount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.czm.convivo.SiteAssociation} returns this
 */
proto.czm.convivo.SiteAssociation.prototype.setCasescount = function (value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string lastConsultationDate = 13;
 * @return {string}
 */
proto.czm.convivo.SiteAssociation.prototype.getLastconsultationdate = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SiteAssociation} returns this
 */
proto.czm.convivo.SiteAssociation.prototype.setLastconsultationdate = function (value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated DefaultSiteAssociation defaultSiteAssociation = 14;
 * @return {!Array<!proto.czm.convivo.DefaultSiteAssociation>}
 */
proto.czm.convivo.SiteAssociation.prototype.getDefaultsiteassociationList = function () {
  return /** @type{!Array<!proto.czm.convivo.DefaultSiteAssociation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.DefaultSiteAssociation, 14));
};


/**
 * @param {!Array<!proto.czm.convivo.DefaultSiteAssociation>} value
 * @return {!proto.czm.convivo.SiteAssociation} returns this
*/
proto.czm.convivo.SiteAssociation.prototype.setDefaultsiteassociationList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.czm.convivo.DefaultSiteAssociation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.DefaultSiteAssociation}
 */
proto.czm.convivo.SiteAssociation.prototype.addDefaultsiteassociation = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.czm.convivo.DefaultSiteAssociation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.SiteAssociation} returns this
 */
proto.czm.convivo.SiteAssociation.prototype.clearDefaultsiteassociationList = function () {
  return this.setDefaultsiteassociationList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.GetSiteAssociationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.GetSiteAssociationsResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.GetSiteAssociationsResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.GetSiteAssociationsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.GetSiteAssociationsResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      siteassociationsList: jspb.Message.toObjectList(msg.getSiteassociationsList(),
        proto.czm.convivo.SiteAssociation.toObject, includeInstance),
      meta: (f = msg.getMeta()) && proto.czm.convivo.PaginationMeta.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.GetSiteAssociationsResponse}
 */
proto.czm.convivo.GetSiteAssociationsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.GetSiteAssociationsResponse;
  return proto.czm.convivo.GetSiteAssociationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.GetSiteAssociationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.GetSiteAssociationsResponse}
 */
proto.czm.convivo.GetSiteAssociationsResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.czm.convivo.SiteAssociation;
        reader.readMessage(value, proto.czm.convivo.SiteAssociation.deserializeBinaryFromReader);
        msg.addSiteassociations(value);
        break;
      case 2:
        var value = new proto.czm.convivo.PaginationMeta;
        reader.readMessage(value, proto.czm.convivo.PaginationMeta.deserializeBinaryFromReader);
        msg.setMeta(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.GetSiteAssociationsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.GetSiteAssociationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.GetSiteAssociationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.GetSiteAssociationsResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSiteassociationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.czm.convivo.SiteAssociation.serializeBinaryToWriter
    );
  }
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.czm.convivo.PaginationMeta.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SiteAssociation siteAssociations = 1;
 * @return {!Array<!proto.czm.convivo.SiteAssociation>}
 */
proto.czm.convivo.GetSiteAssociationsResponse.prototype.getSiteassociationsList = function () {
  return /** @type{!Array<!proto.czm.convivo.SiteAssociation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.SiteAssociation, 1));
};


/**
 * @param {!Array<!proto.czm.convivo.SiteAssociation>} value
 * @return {!proto.czm.convivo.GetSiteAssociationsResponse} returns this
*/
proto.czm.convivo.GetSiteAssociationsResponse.prototype.setSiteassociationsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.czm.convivo.SiteAssociation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.SiteAssociation}
 */
proto.czm.convivo.GetSiteAssociationsResponse.prototype.addSiteassociations = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.czm.convivo.SiteAssociation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.GetSiteAssociationsResponse} returns this
 */
proto.czm.convivo.GetSiteAssociationsResponse.prototype.clearSiteassociationsList = function () {
  return this.setSiteassociationsList([]);
};


/**
 * optional PaginationMeta meta = 2;
 * @return {?proto.czm.convivo.PaginationMeta}
 */
proto.czm.convivo.GetSiteAssociationsResponse.prototype.getMeta = function () {
  return /** @type{?proto.czm.convivo.PaginationMeta} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.PaginationMeta, 2));
};


/**
 * @param {?proto.czm.convivo.PaginationMeta|undefined} value
 * @return {!proto.czm.convivo.GetSiteAssociationsResponse} returns this
*/
proto.czm.convivo.GetSiteAssociationsResponse.prototype.setMeta = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.GetSiteAssociationsResponse} returns this
 */
proto.czm.convivo.GetSiteAssociationsResponse.prototype.clearMeta = function () {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.GetSiteAssociationsResponse.prototype.hasMeta = function () {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.UpdateCaseStatusRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.UpdateCaseStatusRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.UpdateCaseStatusRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.UpdateCaseStatusRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      caseid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      status: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.UpdateCaseStatusRequest}
 */
proto.czm.convivo.UpdateCaseStatusRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.UpdateCaseStatusRequest;
  return proto.czm.convivo.UpdateCaseStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.UpdateCaseStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.UpdateCaseStatusRequest}
 */
proto.czm.convivo.UpdateCaseStatusRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCaseid(value);
        break;
      case 2:
        var value = /** @type {!proto.czm.convivo.CaseStatus} */ (reader.readEnum());
        msg.setStatus(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.UpdateCaseStatusRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.UpdateCaseStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.UpdateCaseStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.UpdateCaseStatusRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCaseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string caseId = 1;
 * @return {string}
 */
proto.czm.convivo.UpdateCaseStatusRequest.prototype.getCaseid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UpdateCaseStatusRequest} returns this
 */
proto.czm.convivo.UpdateCaseStatusRequest.prototype.setCaseid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CaseStatus status = 2;
 * @return {!proto.czm.convivo.CaseStatus}
 */
proto.czm.convivo.UpdateCaseStatusRequest.prototype.getStatus = function () {
  return /** @type {!proto.czm.convivo.CaseStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.czm.convivo.CaseStatus} value
 * @return {!proto.czm.convivo.UpdateCaseStatusRequest} returns this
 */
proto.czm.convivo.UpdateCaseStatusRequest.prototype.setStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.UpdateCaseStatusResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.UpdateCaseStatusResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.UpdateCaseStatusResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.UpdateCaseStatusResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      caseid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      status: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.UpdateCaseStatusResponse}
 */
proto.czm.convivo.UpdateCaseStatusResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.UpdateCaseStatusResponse;
  return proto.czm.convivo.UpdateCaseStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.UpdateCaseStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.UpdateCaseStatusResponse}
 */
proto.czm.convivo.UpdateCaseStatusResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCaseid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setStatus(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.UpdateCaseStatusResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.UpdateCaseStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.UpdateCaseStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.UpdateCaseStatusResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCaseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string caseId = 1;
 * @return {string}
 */
proto.czm.convivo.UpdateCaseStatusResponse.prototype.getCaseid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UpdateCaseStatusResponse} returns this
 */
proto.czm.convivo.UpdateCaseStatusResponse.prototype.setCaseid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.czm.convivo.UpdateCaseStatusResponse.prototype.getStatus = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UpdateCaseStatusResponse} returns this
 */
proto.czm.convivo.UpdateCaseStatusResponse.prototype.setStatus = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.SiteAssociationRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.SiteAssociationRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.SiteAssociationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.SiteAssociationRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      siteid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      associatingsiteid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      isdefaultsite: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.SiteAssociationRequest}
 */
proto.czm.convivo.SiteAssociationRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.SiteAssociationRequest;
  return proto.czm.convivo.SiteAssociationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.SiteAssociationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.SiteAssociationRequest}
 */
proto.czm.convivo.SiteAssociationRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setSiteid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssociatingsiteid(value);
        break;
      case 3:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsdefaultsite(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.SiteAssociationRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.SiteAssociationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.SiteAssociationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.SiteAssociationRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSiteid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssociatingsiteid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsdefaultsite();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string siteId = 1;
 * @return {string}
 */
proto.czm.convivo.SiteAssociationRequest.prototype.getSiteid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SiteAssociationRequest} returns this
 */
proto.czm.convivo.SiteAssociationRequest.prototype.setSiteid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string associatingSiteId = 2;
 * @return {string}
 */
proto.czm.convivo.SiteAssociationRequest.prototype.getAssociatingsiteid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.SiteAssociationRequest} returns this
 */
proto.czm.convivo.SiteAssociationRequest.prototype.setAssociatingsiteid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isDefaultSite = 3;
 * @return {boolean}
 */
proto.czm.convivo.SiteAssociationRequest.prototype.getIsdefaultsite = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.czm.convivo.SiteAssociationRequest} returns this
 */
proto.czm.convivo.SiteAssociationRequest.prototype.setIsdefaultsite = function (value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.UpdateSiteAssociation.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.UpdateSiteAssociation.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.UpdateSiteAssociation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.UpdateSiteAssociation.toObject = function (includeInstance, msg) {
    var f, obj = {
      associationid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      associationstatus: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.UpdateSiteAssociation}
 */
proto.czm.convivo.UpdateSiteAssociation.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.UpdateSiteAssociation;
  return proto.czm.convivo.UpdateSiteAssociation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.UpdateSiteAssociation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.UpdateSiteAssociation}
 */
proto.czm.convivo.UpdateSiteAssociation.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssociationid(value);
        break;
      case 2:
        var value = /** @type {!proto.czm.convivo.AssociationStatus} */ (reader.readEnum());
        msg.setAssociationstatus(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.UpdateSiteAssociation.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.UpdateSiteAssociation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.UpdateSiteAssociation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.UpdateSiteAssociation.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getAssociationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssociationstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string associationId = 1;
 * @return {string}
 */
proto.czm.convivo.UpdateSiteAssociation.prototype.getAssociationid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UpdateSiteAssociation} returns this
 */
proto.czm.convivo.UpdateSiteAssociation.prototype.setAssociationid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AssociationStatus associationStatus = 2;
 * @return {!proto.czm.convivo.AssociationStatus}
 */
proto.czm.convivo.UpdateSiteAssociation.prototype.getAssociationstatus = function () {
  return /** @type {!proto.czm.convivo.AssociationStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.czm.convivo.AssociationStatus} value
 * @return {!proto.czm.convivo.UpdateSiteAssociation} returns this
 */
proto.czm.convivo.UpdateSiteAssociation.prototype.setAssociationstatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.CreateSiteRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.CreateSiteRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.CreateSiteRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.CreateSiteRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      description: jspb.Message.getFieldWithDefault(msg, 2, ""),
      contactdetails: (f = msg.getContactdetails()) && proto.czm.convivo.ContactDetails.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.CreateSiteRequest}
 */
proto.czm.convivo.CreateSiteRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.CreateSiteRequest;
  return proto.czm.convivo.CreateSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.CreateSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.CreateSiteRequest}
 */
proto.czm.convivo.CreateSiteRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      case 3:
        var value = new proto.czm.convivo.ContactDetails;
        reader.readMessage(value, proto.czm.convivo.ContactDetails.deserializeBinaryFromReader);
        msg.setContactdetails(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.CreateSiteRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.CreateSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.CreateSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.CreateSiteRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContactdetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.czm.convivo.ContactDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.czm.convivo.CreateSiteRequest.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CreateSiteRequest} returns this
 */
proto.czm.convivo.CreateSiteRequest.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.czm.convivo.CreateSiteRequest.prototype.getDescription = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CreateSiteRequest} returns this
 */
proto.czm.convivo.CreateSiteRequest.prototype.setDescription = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ContactDetails contactDetails = 3;
 * @return {?proto.czm.convivo.ContactDetails}
 */
proto.czm.convivo.CreateSiteRequest.prototype.getContactdetails = function () {
  return /** @type{?proto.czm.convivo.ContactDetails} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.ContactDetails, 3));
};


/**
 * @param {?proto.czm.convivo.ContactDetails|undefined} value
 * @return {!proto.czm.convivo.CreateSiteRequest} returns this
*/
proto.czm.convivo.CreateSiteRequest.prototype.setContactdetails = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.CreateSiteRequest} returns this
 */
proto.czm.convivo.CreateSiteRequest.prototype.clearContactdetails = function () {
  return this.setContactdetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.CreateSiteRequest.prototype.hasContactdetails = function () {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.UpdateSiteRequest.repeatedFields_ = [6, 7];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.UpdateSiteRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.UpdateSiteRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.UpdateSiteRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.UpdateSiteRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      siteid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      description: jspb.Message.getFieldWithDefault(msg, 2, ""),
      phonenumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
      status: jspb.Message.getFieldWithDefault(msg, 4, 0),
      isdefaultsite: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
      useremailidsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
      roleidsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.UpdateSiteRequest}
 */
proto.czm.convivo.UpdateSiteRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.UpdateSiteRequest;
  return proto.czm.convivo.UpdateSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.UpdateSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.UpdateSiteRequest}
 */
proto.czm.convivo.UpdateSiteRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setSiteid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setPhonenumber(value);
        break;
      case 4:
        var value = /** @type {!proto.czm.convivo.SiteStatus} */ (reader.readEnum());
        msg.setStatus(value);
        break;
      case 5:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsdefaultsite(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.addUseremailids(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.addRoleids(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.UpdateSiteRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.UpdateSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.UpdateSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.UpdateSiteRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSiteid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getIsdefaultsite();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getUseremailidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getRoleidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
};


/**
 * optional string siteId = 1;
 * @return {string}
 */
proto.czm.convivo.UpdateSiteRequest.prototype.getSiteid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UpdateSiteRequest} returns this
 */
proto.czm.convivo.UpdateSiteRequest.prototype.setSiteid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.czm.convivo.UpdateSiteRequest.prototype.getDescription = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UpdateSiteRequest} returns this
 */
proto.czm.convivo.UpdateSiteRequest.prototype.setDescription = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phoneNumber = 3;
 * @return {string}
 */
proto.czm.convivo.UpdateSiteRequest.prototype.getPhonenumber = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UpdateSiteRequest} returns this
 */
proto.czm.convivo.UpdateSiteRequest.prototype.setPhonenumber = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional SiteStatus status = 4;
 * @return {!proto.czm.convivo.SiteStatus}
 */
proto.czm.convivo.UpdateSiteRequest.prototype.getStatus = function () {
  return /** @type {!proto.czm.convivo.SiteStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.czm.convivo.SiteStatus} value
 * @return {!proto.czm.convivo.UpdateSiteRequest} returns this
 */
proto.czm.convivo.UpdateSiteRequest.prototype.setStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool isDefaultSite = 5;
 * @return {boolean}
 */
proto.czm.convivo.UpdateSiteRequest.prototype.getIsdefaultsite = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.czm.convivo.UpdateSiteRequest} returns this
 */
proto.czm.convivo.UpdateSiteRequest.prototype.setIsdefaultsite = function (value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated string userEmailIDs = 6;
 * @return {!Array<string>}
 */
proto.czm.convivo.UpdateSiteRequest.prototype.getUseremailidsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.czm.convivo.UpdateSiteRequest} returns this
 */
proto.czm.convivo.UpdateSiteRequest.prototype.setUseremailidsList = function (value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.UpdateSiteRequest} returns this
 */
proto.czm.convivo.UpdateSiteRequest.prototype.addUseremailids = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.UpdateSiteRequest} returns this
 */
proto.czm.convivo.UpdateSiteRequest.prototype.clearUseremailidsList = function () {
  return this.setUseremailidsList([]);
};


/**
 * repeated string roleIDs = 7;
 * @return {!Array<string>}
 */
proto.czm.convivo.UpdateSiteRequest.prototype.getRoleidsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.czm.convivo.UpdateSiteRequest} returns this
 */
proto.czm.convivo.UpdateSiteRequest.prototype.setRoleidsList = function (value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.UpdateSiteRequest} returns this
 */
proto.czm.convivo.UpdateSiteRequest.prototype.addRoleids = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.UpdateSiteRequest} returns this
 */
proto.czm.convivo.UpdateSiteRequest.prototype.clearRoleidsList = function () {
  return this.setRoleidsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.UpdateSiteResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.UpdateSiteResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.UpdateSiteResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.UpdateSiteResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.UpdateSiteResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      failurelistList: jspb.Message.toObjectList(msg.getFailurelistList(),
        proto.czm.convivo.UserRolesUpdationResult.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.UpdateSiteResponse}
 */
proto.czm.convivo.UpdateSiteResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.UpdateSiteResponse;
  return proto.czm.convivo.UpdateSiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.UpdateSiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.UpdateSiteResponse}
 */
proto.czm.convivo.UpdateSiteResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.czm.convivo.UserRolesUpdationResult;
        reader.readMessage(value, proto.czm.convivo.UserRolesUpdationResult.deserializeBinaryFromReader);
        msg.addFailurelist(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.UpdateSiteResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.UpdateSiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.UpdateSiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.UpdateSiteResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFailurelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.czm.convivo.UserRolesUpdationResult.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserRolesUpdationResult failureList = 1;
 * @return {!Array<!proto.czm.convivo.UserRolesUpdationResult>}
 */
proto.czm.convivo.UpdateSiteResponse.prototype.getFailurelistList = function () {
  return /** @type{!Array<!proto.czm.convivo.UserRolesUpdationResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.UserRolesUpdationResult, 1));
};


/**
 * @param {!Array<!proto.czm.convivo.UserRolesUpdationResult>} value
 * @return {!proto.czm.convivo.UpdateSiteResponse} returns this
*/
proto.czm.convivo.UpdateSiteResponse.prototype.setFailurelistList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.czm.convivo.UserRolesUpdationResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.UserRolesUpdationResult}
 */
proto.czm.convivo.UpdateSiteResponse.prototype.addFailurelist = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.czm.convivo.UserRolesUpdationResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.UpdateSiteResponse} returns this
 */
proto.czm.convivo.UpdateSiteResponse.prototype.clearFailurelistList = function () {
  return this.setFailurelistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.UserRolesUpdationResult.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.UserRolesUpdationResult.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.UserRolesUpdationResult} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.UserRolesUpdationResult.toObject = function (includeInstance, msg) {
    var f, obj = {
      emailid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      message: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.UserRolesUpdationResult}
 */
proto.czm.convivo.UserRolesUpdationResult.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.UserRolesUpdationResult;
  return proto.czm.convivo.UserRolesUpdationResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.UserRolesUpdationResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.UserRolesUpdationResult}
 */
proto.czm.convivo.UserRolesUpdationResult.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setEmailid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMessage(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.UserRolesUpdationResult.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.UserRolesUpdationResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.UserRolesUpdationResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.UserRolesUpdationResult.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getEmailid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string emailID = 1;
 * @return {string}
 */
proto.czm.convivo.UserRolesUpdationResult.prototype.getEmailid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UserRolesUpdationResult} returns this
 */
proto.czm.convivo.UserRolesUpdationResult.prototype.setEmailid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.czm.convivo.UserRolesUpdationResult.prototype.getMessage = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UserRolesUpdationResult} returns this
 */
proto.czm.convivo.UserRolesUpdationResult.prototype.setMessage = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.ContactFormRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.ContactFormRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.ContactFormRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.ContactFormRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      caseid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      browserversion: jspb.Message.getFieldWithDefault(msg, 2, ""),
      systemversion: jspb.Message.getFieldWithDefault(msg, 3, ""),
      systemmonitorresolution: jspb.Message.getFieldWithDefault(msg, 4, ""),
      text: jspb.Message.getFieldWithDefault(msg, 5, ""),
      accountid: jspb.Message.getFieldWithDefault(msg, 6, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.ContactFormRequest}
 */
proto.czm.convivo.ContactFormRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.ContactFormRequest;
  return proto.czm.convivo.ContactFormRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.ContactFormRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.ContactFormRequest}
 */
proto.czm.convivo.ContactFormRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCaseid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setBrowserversion(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setSystemversion(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setSystemmonitorresolution(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setText(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccountid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.ContactFormRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.ContactFormRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.ContactFormRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.ContactFormRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCaseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBrowserversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSystemversion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSystemmonitorresolution();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string caseID = 1;
 * @return {string}
 */
proto.czm.convivo.ContactFormRequest.prototype.getCaseid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ContactFormRequest} returns this
 */
proto.czm.convivo.ContactFormRequest.prototype.setCaseid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string browserVersion = 2;
 * @return {string}
 */
proto.czm.convivo.ContactFormRequest.prototype.getBrowserversion = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ContactFormRequest} returns this
 */
proto.czm.convivo.ContactFormRequest.prototype.setBrowserversion = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string systemVersion = 3;
 * @return {string}
 */
proto.czm.convivo.ContactFormRequest.prototype.getSystemversion = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ContactFormRequest} returns this
 */
proto.czm.convivo.ContactFormRequest.prototype.setSystemversion = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string systemMonitorResolution = 4;
 * @return {string}
 */
proto.czm.convivo.ContactFormRequest.prototype.getSystemmonitorresolution = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ContactFormRequest} returns this
 */
proto.czm.convivo.ContactFormRequest.prototype.setSystemmonitorresolution = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string text = 5;
 * @return {string}
 */
proto.czm.convivo.ContactFormRequest.prototype.getText = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ContactFormRequest} returns this
 */
proto.czm.convivo.ContactFormRequest.prototype.setText = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string accountID = 6;
 * @return {string}
 */
proto.czm.convivo.ContactFormRequest.prototype.getAccountid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ContactFormRequest} returns this
 */
proto.czm.convivo.ContactFormRequest.prototype.setAccountid = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.Links.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.Links.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.Links} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.Links.toObject = function (includeInstance, msg) {
    var f, obj = {
      rel: jspb.Message.getFieldWithDefault(msg, 1, ""),
      href: jspb.Message.getFieldWithDefault(msg, 2, ""),
      action: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.Links}
 */
proto.czm.convivo.Links.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.Links;
  return proto.czm.convivo.Links.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.Links} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.Links}
 */
proto.czm.convivo.Links.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setRel(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setHref(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setAction(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.Links.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.Links.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.Links} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.Links.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getRel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHref();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string rel = 1;
 * @return {string}
 */
proto.czm.convivo.Links.prototype.getRel = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Links} returns this
 */
proto.czm.convivo.Links.prototype.setRel = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string href = 2;
 * @return {string}
 */
proto.czm.convivo.Links.prototype.getHref = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Links} returns this
 */
proto.czm.convivo.Links.prototype.setHref = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string action = 3;
 * @return {string}
 */
proto.czm.convivo.Links.prototype.getAction = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.Links} returns this
 */
proto.czm.convivo.Links.prototype.setAction = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.AccountAssociations.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.AccountAssociations.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.AccountAssociations.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.AccountAssociations} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.AccountAssociations.toObject = function (includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      associationstatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
      createdby: jspb.Message.getFieldWithDefault(msg, 3, ""),
      createddatetime: jspb.Message.getFieldWithDefault(msg, 4, ""),
      updatedby: jspb.Message.getFieldWithDefault(msg, 5, ""),
      updateddatetime: jspb.Message.getFieldWithDefault(msg, 6, ""),
      linksList: jspb.Message.toObjectList(msg.getLinksList(),
        proto.czm.convivo.Links.toObject, includeInstance),
      primaryaccount: (f = msg.getPrimaryaccount()) && proto.czm.convivo.AccountDetails.toObject(includeInstance, f),
      partneraccount: (f = msg.getPartneraccount()) && proto.czm.convivo.AccountDetails.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.AccountAssociations}
 */
proto.czm.convivo.AccountAssociations.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.AccountAssociations;
  return proto.czm.convivo.AccountAssociations.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.AccountAssociations} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.AccountAssociations}
 */
proto.czm.convivo.AccountAssociations.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {!proto.czm.convivo.AssociationStatus} */ (reader.readEnum());
        msg.setAssociationstatus(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setCreatedby(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setCreateddatetime(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setUpdatedby(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setUpdateddatetime(value);
        break;
      case 7:
        var value = new proto.czm.convivo.Links;
        reader.readMessage(value, proto.czm.convivo.Links.deserializeBinaryFromReader);
        msg.addLinks(value);
        break;
      case 8:
        var value = new proto.czm.convivo.AccountDetails;
        reader.readMessage(value, proto.czm.convivo.AccountDetails.deserializeBinaryFromReader);
        msg.setPrimaryaccount(value);
        break;
      case 9:
        var value = new proto.czm.convivo.AccountDetails;
        reader.readMessage(value, proto.czm.convivo.AccountDetails.deserializeBinaryFromReader);
        msg.setPartneraccount(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.AccountAssociations.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.AccountAssociations.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.AccountAssociations} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.AccountAssociations.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssociationstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCreatedby();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreateddatetime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUpdatedby();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUpdateddatetime();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.czm.convivo.Links.serializeBinaryToWriter
    );
  }
  f = message.getPrimaryaccount();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.czm.convivo.AccountDetails.serializeBinaryToWriter
    );
  }
  f = message.getPartneraccount();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.czm.convivo.AccountDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.czm.convivo.AccountAssociations.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.AccountAssociations} returns this
 */
proto.czm.convivo.AccountAssociations.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AssociationStatus associationStatus = 2;
 * @return {!proto.czm.convivo.AssociationStatus}
 */
proto.czm.convivo.AccountAssociations.prototype.getAssociationstatus = function () {
  return /** @type {!proto.czm.convivo.AssociationStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.czm.convivo.AssociationStatus} value
 * @return {!proto.czm.convivo.AccountAssociations} returns this
 */
proto.czm.convivo.AccountAssociations.prototype.setAssociationstatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string createdBy = 3;
 * @return {string}
 */
proto.czm.convivo.AccountAssociations.prototype.getCreatedby = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.AccountAssociations} returns this
 */
proto.czm.convivo.AccountAssociations.prototype.setCreatedby = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string createdDateTime = 4;
 * @return {string}
 */
proto.czm.convivo.AccountAssociations.prototype.getCreateddatetime = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.AccountAssociations} returns this
 */
proto.czm.convivo.AccountAssociations.prototype.setCreateddatetime = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string updatedBy = 5;
 * @return {string}
 */
proto.czm.convivo.AccountAssociations.prototype.getUpdatedby = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.AccountAssociations} returns this
 */
proto.czm.convivo.AccountAssociations.prototype.setUpdatedby = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string updatedDateTime = 6;
 * @return {string}
 */
proto.czm.convivo.AccountAssociations.prototype.getUpdateddatetime = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.AccountAssociations} returns this
 */
proto.czm.convivo.AccountAssociations.prototype.setUpdateddatetime = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated Links links = 7;
 * @return {!Array<!proto.czm.convivo.Links>}
 */
proto.czm.convivo.AccountAssociations.prototype.getLinksList = function () {
  return /** @type{!Array<!proto.czm.convivo.Links>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.czm.convivo.Links, 7));
};


/**
 * @param {!Array<!proto.czm.convivo.Links>} value
 * @return {!proto.czm.convivo.AccountAssociations} returns this
*/
proto.czm.convivo.AccountAssociations.prototype.setLinksList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.czm.convivo.Links=} opt_value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.Links}
 */
proto.czm.convivo.AccountAssociations.prototype.addLinks = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.czm.convivo.Links, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.AccountAssociations} returns this
 */
proto.czm.convivo.AccountAssociations.prototype.clearLinksList = function () {
  return this.setLinksList([]);
};


/**
 * optional AccountDetails primaryAccount = 8;
 * @return {?proto.czm.convivo.AccountDetails}
 */
proto.czm.convivo.AccountAssociations.prototype.getPrimaryaccount = function () {
  return /** @type{?proto.czm.convivo.AccountDetails} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.AccountDetails, 8));
};


/**
 * @param {?proto.czm.convivo.AccountDetails|undefined} value
 * @return {!proto.czm.convivo.AccountAssociations} returns this
*/
proto.czm.convivo.AccountAssociations.prototype.setPrimaryaccount = function (value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.AccountAssociations} returns this
 */
proto.czm.convivo.AccountAssociations.prototype.clearPrimaryaccount = function () {
  return this.setPrimaryaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.AccountAssociations.prototype.hasPrimaryaccount = function () {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional AccountDetails partnerAccount = 9;
 * @return {?proto.czm.convivo.AccountDetails}
 */
proto.czm.convivo.AccountAssociations.prototype.getPartneraccount = function () {
  return /** @type{?proto.czm.convivo.AccountDetails} */ (
    jspb.Message.getWrapperField(this, proto.czm.convivo.AccountDetails, 9));
};


/**
 * @param {?proto.czm.convivo.AccountDetails|undefined} value
 * @return {!proto.czm.convivo.AccountAssociations} returns this
*/
proto.czm.convivo.AccountAssociations.prototype.setPartneraccount = function (value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.czm.convivo.AccountAssociations} returns this
 */
proto.czm.convivo.AccountAssociations.prototype.clearPartneraccount = function () {
  return this.setPartneraccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.czm.convivo.AccountAssociations.prototype.hasPartneraccount = function () {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.ConsultationCancel.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.ConsultationCancel.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.ConsultationCancel} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.ConsultationCancel.toObject = function (includeInstance, msg) {
    var f, obj = {
      messagetype: jspb.Message.getFieldWithDefault(msg, 1, 0),
      caseid: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.ConsultationCancel}
 */
proto.czm.convivo.ConsultationCancel.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.ConsultationCancel;
  return proto.czm.convivo.ConsultationCancel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.ConsultationCancel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.ConsultationCancel}
 */
proto.czm.convivo.ConsultationCancel.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.czm.convivo.Message.MessageType} */ (reader.readEnum());
        msg.setMessagetype(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCaseid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.ConsultationCancel.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.ConsultationCancel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.ConsultationCancel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.ConsultationCancel.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getMessagetype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCaseid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Message.MessageType messageType = 1;
 * @return {!proto.czm.convivo.Message.MessageType}
 */
proto.czm.convivo.ConsultationCancel.prototype.getMessagetype = function () {
  return /** @type {!proto.czm.convivo.Message.MessageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.czm.convivo.Message.MessageType} value
 * @return {!proto.czm.convivo.ConsultationCancel} returns this
 */
proto.czm.convivo.ConsultationCancel.prototype.setMessagetype = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string caseId = 2;
 * @return {string}
 */
proto.czm.convivo.ConsultationCancel.prototype.getCaseid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ConsultationCancel} returns this
 */
proto.czm.convivo.ConsultationCancel.prototype.setCaseid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.AddAdminRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.AddAdminRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.AddAdminRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.AddAdminRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.AddAdminRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      emailsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.AddAdminRequest}
 */
proto.czm.convivo.AddAdminRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.AddAdminRequest;
  return proto.czm.convivo.AddAdminRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.AddAdminRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.AddAdminRequest}
 */
proto.czm.convivo.AddAdminRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.addEmails(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.AddAdminRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.AddAdminRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.AddAdminRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.AddAdminRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string emails = 1;
 * @return {!Array<string>}
 */
proto.czm.convivo.AddAdminRequest.prototype.getEmailsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.czm.convivo.AddAdminRequest} returns this
 */
proto.czm.convivo.AddAdminRequest.prototype.setEmailsList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.AddAdminRequest} returns this
 */
proto.czm.convivo.AddAdminRequest.prototype.addEmails = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.AddAdminRequest} returns this
 */
proto.czm.convivo.AddAdminRequest.prototype.clearEmailsList = function () {
  return this.setEmailsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.DicomRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.DicomRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.DicomRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.DicomRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.DicomRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      imageidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.DicomRequest}
 */
proto.czm.convivo.DicomRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.DicomRequest;
  return proto.czm.convivo.DicomRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.DicomRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.DicomRequest}
 */
proto.czm.convivo.DicomRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.addImageids(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.DicomRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.DicomRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.DicomRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.DicomRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getImageidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string imageIDs = 1;
 * @return {!Array<string>}
 */
proto.czm.convivo.DicomRequest.prototype.getImageidsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.czm.convivo.DicomRequest} returns this
 */
proto.czm.convivo.DicomRequest.prototype.setImageidsList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.DicomRequest} returns this
 */
proto.czm.convivo.DicomRequest.prototype.addImageids = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.DicomRequest} returns this
 */
proto.czm.convivo.DicomRequest.prototype.clearImageidsList = function () {
  return this.setImageidsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.DicomResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.DicomResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.DicomResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.DicomResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.DicomResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      urlList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.DicomResponse}
 */
proto.czm.convivo.DicomResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.DicomResponse;
  return proto.czm.convivo.DicomResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.DicomResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.DicomResponse}
 */
proto.czm.convivo.DicomResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.addUrl(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.DicomResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.DicomResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.DicomResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.DicomResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUrlList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string Url = 1;
 * @return {!Array<string>}
 */
proto.czm.convivo.DicomResponse.prototype.getUrlList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.czm.convivo.DicomResponse} returns this
 */
proto.czm.convivo.DicomResponse.prototype.setUrlList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.DicomResponse} returns this
 */
proto.czm.convivo.DicomResponse.prototype.addUrl = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.DicomResponse} returns this
 */
proto.czm.convivo.DicomResponse.prototype.clearUrlList = function () {
  return this.setUrlList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.CaseStartedNotification.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.CaseStartedNotification.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.CaseStartedNotification} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.CaseStartedNotification.toObject = function (includeInstance, msg) {
    var f, obj = {
      messagetype: jspb.Message.getFieldWithDefault(msg, 1, 0),
      caseid: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.CaseStartedNotification}
 */
proto.czm.convivo.CaseStartedNotification.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.CaseStartedNotification;
  return proto.czm.convivo.CaseStartedNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.CaseStartedNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.CaseStartedNotification}
 */
proto.czm.convivo.CaseStartedNotification.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.czm.convivo.Message.MessageType} */ (reader.readEnum());
        msg.setMessagetype(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCaseid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.CaseStartedNotification.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.CaseStartedNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.CaseStartedNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.CaseStartedNotification.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getMessagetype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCaseid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Message.MessageType messageType = 1;
 * @return {!proto.czm.convivo.Message.MessageType}
 */
proto.czm.convivo.CaseStartedNotification.prototype.getMessagetype = function () {
  return /** @type {!proto.czm.convivo.Message.MessageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.czm.convivo.Message.MessageType} value
 * @return {!proto.czm.convivo.CaseStartedNotification} returns this
 */
proto.czm.convivo.CaseStartedNotification.prototype.setMessagetype = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string caseId = 2;
 * @return {string}
 */
proto.czm.convivo.CaseStartedNotification.prototype.getCaseid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CaseStartedNotification} returns this
 */
proto.czm.convivo.CaseStartedNotification.prototype.setCaseid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.CaseEndedNotification.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.CaseEndedNotification.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.CaseEndedNotification} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.CaseEndedNotification.toObject = function (includeInstance, msg) {
    var f, obj = {
      messagetype: jspb.Message.getFieldWithDefault(msg, 1, 0),
      caseid: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.CaseEndedNotification}
 */
proto.czm.convivo.CaseEndedNotification.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.CaseEndedNotification;
  return proto.czm.convivo.CaseEndedNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.CaseEndedNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.CaseEndedNotification}
 */
proto.czm.convivo.CaseEndedNotification.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.czm.convivo.Message.MessageType} */ (reader.readEnum());
        msg.setMessagetype(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCaseid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.CaseEndedNotification.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.CaseEndedNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.CaseEndedNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.CaseEndedNotification.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getMessagetype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCaseid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Message.MessageType messageType = 1;
 * @return {!proto.czm.convivo.Message.MessageType}
 */
proto.czm.convivo.CaseEndedNotification.prototype.getMessagetype = function () {
  return /** @type {!proto.czm.convivo.Message.MessageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.czm.convivo.Message.MessageType} value
 * @return {!proto.czm.convivo.CaseEndedNotification} returns this
 */
proto.czm.convivo.CaseEndedNotification.prototype.setMessagetype = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string caseId = 2;
 * @return {string}
 */
proto.czm.convivo.CaseEndedNotification.prototype.getCaseid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.CaseEndedNotification} returns this
 */
proto.czm.convivo.CaseEndedNotification.prototype.setCaseid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.DefaultSiteAssociation.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.DefaultSiteAssociation.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.DefaultSiteAssociation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.DefaultSiteAssociation.toObject = function (includeInstance, msg) {
    var f, obj = {
      siteassociationid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      associatedsiteid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      associatedsitename: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.DefaultSiteAssociation}
 */
proto.czm.convivo.DefaultSiteAssociation.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.DefaultSiteAssociation;
  return proto.czm.convivo.DefaultSiteAssociation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.DefaultSiteAssociation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.DefaultSiteAssociation}
 */
proto.czm.convivo.DefaultSiteAssociation.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setSiteassociationid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssociatedsiteid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssociatedsitename(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.DefaultSiteAssociation.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.DefaultSiteAssociation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.DefaultSiteAssociation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.DefaultSiteAssociation.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSiteassociationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssociatedsiteid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssociatedsitename();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string siteAssociationID = 1;
 * @return {string}
 */
proto.czm.convivo.DefaultSiteAssociation.prototype.getSiteassociationid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.DefaultSiteAssociation} returns this
 */
proto.czm.convivo.DefaultSiteAssociation.prototype.setSiteassociationid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string associatedSiteID = 2;
 * @return {string}
 */
proto.czm.convivo.DefaultSiteAssociation.prototype.getAssociatedsiteid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.DefaultSiteAssociation} returns this
 */
proto.czm.convivo.DefaultSiteAssociation.prototype.setAssociatedsiteid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string associatedSiteName = 3;
 * @return {string}
 */
proto.czm.convivo.DefaultSiteAssociation.prototype.getAssociatedsitename = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.DefaultSiteAssociation} returns this
 */
proto.czm.convivo.DefaultSiteAssociation.prototype.setAssociatedsitename = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.UpdateDefaultSiteAssociation.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.UpdateDefaultSiteAssociation.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.UpdateDefaultSiteAssociation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.UpdateDefaultSiteAssociation.toObject = function (includeInstance, msg) {
    var f, obj = {
      associationid: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.UpdateDefaultSiteAssociation}
 */
proto.czm.convivo.UpdateDefaultSiteAssociation.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.UpdateDefaultSiteAssociation;
  return proto.czm.convivo.UpdateDefaultSiteAssociation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.UpdateDefaultSiteAssociation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.UpdateDefaultSiteAssociation}
 */
proto.czm.convivo.UpdateDefaultSiteAssociation.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssociationid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.UpdateDefaultSiteAssociation.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.UpdateDefaultSiteAssociation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.UpdateDefaultSiteAssociation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.UpdateDefaultSiteAssociation.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getAssociationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string associationID = 1;
 * @return {string}
 */
proto.czm.convivo.UpdateDefaultSiteAssociation.prototype.getAssociationid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.UpdateDefaultSiteAssociation} returns this
 */
proto.czm.convivo.UpdateDefaultSiteAssociation.prototype.setAssociationid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.ExportImagesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.ExportImagesRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.ExportImagesRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.ExportImagesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.ExportImagesRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      imageidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      includeannotation: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.ExportImagesRequest}
 */
proto.czm.convivo.ExportImagesRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.ExportImagesRequest;
  return proto.czm.convivo.ExportImagesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.ExportImagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.ExportImagesRequest}
 */
proto.czm.convivo.ExportImagesRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.addImageids(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIncludeannotation(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.ExportImagesRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.ExportImagesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.ExportImagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.ExportImagesRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getImageidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getIncludeannotation();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated string imageIDs = 1;
 * @return {!Array<string>}
 */
proto.czm.convivo.ExportImagesRequest.prototype.getImageidsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.czm.convivo.ExportImagesRequest} returns this
 */
proto.czm.convivo.ExportImagesRequest.prototype.setImageidsList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.ExportImagesRequest} returns this
 */
proto.czm.convivo.ExportImagesRequest.prototype.addImageids = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.ExportImagesRequest} returns this
 */
proto.czm.convivo.ExportImagesRequest.prototype.clearImageidsList = function () {
  return this.setImageidsList([]);
};


/**
 * optional bool includeAnnotation = 2;
 * @return {boolean}
 */
proto.czm.convivo.ExportImagesRequest.prototype.getIncludeannotation = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.czm.convivo.ExportImagesRequest} returns this
 */
proto.czm.convivo.ExportImagesRequest.prototype.setIncludeannotation = function (value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.czm.convivo.ExportImagesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.ExportImagesResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.ExportImagesResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.ExportImagesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.ExportImagesResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      imageurlsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.ExportImagesResponse}
 */
proto.czm.convivo.ExportImagesResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.ExportImagesResponse;
  return proto.czm.convivo.ExportImagesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.ExportImagesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.ExportImagesResponse}
 */
proto.czm.convivo.ExportImagesResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.addImageurls(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.ExportImagesResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.ExportImagesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.ExportImagesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.ExportImagesResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getImageurlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string imageURLs = 1;
 * @return {!Array<string>}
 */
proto.czm.convivo.ExportImagesResponse.prototype.getImageurlsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.czm.convivo.ExportImagesResponse} returns this
 */
proto.czm.convivo.ExportImagesResponse.prototype.setImageurlsList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.czm.convivo.ExportImagesResponse} returns this
 */
proto.czm.convivo.ExportImagesResponse.prototype.addImageurls = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.czm.convivo.ExportImagesResponse} returns this
 */
proto.czm.convivo.ExportImagesResponse.prototype.clearImageurlsList = function () {
  return this.setImageurlsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.czm.convivo.ImagesCount.prototype.toObject = function (opt_includeInstance) {
    return proto.czm.convivo.ImagesCount.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.czm.convivo.ImagesCount} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.czm.convivo.ImagesCount.toObject = function (includeInstance, msg) {
    var f, obj = {
      count: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.czm.convivo.ImagesCount}
 */
proto.czm.convivo.ImagesCount.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.czm.convivo.ImagesCount;
  return proto.czm.convivo.ImagesCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.czm.convivo.ImagesCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.czm.convivo.ImagesCount}
 */
proto.czm.convivo.ImagesCount.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCount(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.czm.convivo.ImagesCount.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.czm.convivo.ImagesCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.czm.convivo.ImagesCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.czm.convivo.ImagesCount.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string count = 1;
 * @return {string}
 */
proto.czm.convivo.ImagesCount.prototype.getCount = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.czm.convivo.ImagesCount} returns this
 */
proto.czm.convivo.ImagesCount.prototype.setCount = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.czm.convivo.ImageFormat = {
  JPG: 0
};

/**
 * @enum {number}
 */
proto.czm.convivo.ImageType = {
  NONE: 0,
  SINGLE: 1,
  SERIES: 2,
  ZSTACK: 3
};

/**
 * @enum {number}
 */
proto.czm.convivo.CaptureType = {
  START: 0,
  STOP: 1
};

/**
 * @enum {number}
 */
proto.czm.convivo.Sex = {
  UNKNOWN: 0,
  FEMALE: 1,
  MALE: 2,
  OTHER: 3
};

/**
 * @enum {number}
 */
proto.czm.convivo.SiteStatus = {
  ACTIVE: 0,
  DISABLED: 1,
  INVITED: 2,
  DECLINED: 3
};

/**
 * @enum {number}
 */
proto.czm.convivo.CaseStatus = {
  CASESTARTED: 0,
  CONSULTATIONSTARTED: 1,
  CASEENDED: 2,
  REPORTGENERATED: 3
};

/**
 * @enum {number}
 */
proto.czm.convivo.ReportStatus = {
  CANCEL: 0,
  PUBLISH: 1
};

/**
 * @enum {number}
 */
proto.czm.convivo.Resolution = {
  HIGH: 0,
  MEDIUM: 1,
  LOW: 2
};

/**
 * @enum {number}
 */
proto.czm.convivo.FilterType = {
  INVERT: 0,
  SHARPEN: 1,
  COLOR: 2
};

/**
 * @enum {number}
 */
proto.czm.convivo.NotificationStatus = {
  RECEIVED: 0,
  READ: 1
};

/**
 * @enum {number}
 */
proto.czm.convivo.AssociationStatus = {
  ACTIVE: 0,
  INACTIVE: 1,
  DECLINED: 2,
  INVITED: 3
};

/**
 * @enum {number}
 */
proto.czm.convivo.NotificationKeys = {
  ACTIVECONSULTATION: 0,
  CANCELLEDCONSULTATION: 1,
  CONSULTATIONNOTREQUIRED: 2
};

/**
 * @enum {number}
 */
proto.czm.convivo.AdminRoles = {
  ACCOUNTADMIN: 0,
  SITEADMIN: 1
};

goog.object.extend(exports, proto.czm.convivo);
