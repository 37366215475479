/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import * as actionCreators from '../actionCreators/admin/getAccAssociatesListActionCreator';
import * as actionTypes from '../actionTypes/admin/getAccAssocatieListActionTypes';
import { decodeAccAssociatessBuffer } from '../protobuf/deserialization/accAssociatesListDeserialize';
import { fetchAccAssocaitesList } from '../services/GET/getAccAssociatesListService';

/* istanbul ignore next */
function* accAssociatesListAsync(action: any) {
  try {
    const response: Response = yield yield fetchAccAssocaitesList(action.accountID);
    const apiStatus: number = response.status;
    switch (apiStatus) {
      case 200:
        const data: ArrayBuffer = yield response.arrayBuffer();
        const decodedData: any[] = yield decodeAccAssociatessBuffer(data);
        if (decodedData.length) yield put(actionCreators.setAccAssociateList(decodedData[0]));
        else yield put(actionCreators.setAccAssociateList([]));
        break;
      case 401:
      case 500:
      case 403:
        console.log('get  Account Assocatite  Api Failed ');
        yield put(actionCreators.getAccAssociateListFail('GetAssocatiteFailMessage'));
        break;
      default:
        console.log('get  Account Assocatite  Api Failed ');
        yield put(actionCreators.getAccAssociateListFail('GetAssocatiteFailMessage'));
        break;
    }
  } catch (error) {
    console.log('get  Account Assocatite  Api Failed ', error);
    yield put(actionCreators.getAccAssociateListFail('GetAssocatiteFailMessage'));
  }
}

export function* watchPageEntries() {
  yield takeEvery(actionTypes.GET_ACC_ASSOCAITES_LIST, accAssociatesListAsync);
}

export default function* GetAccAssociatesListSaga() {
  yield all([fork(watchPageEntries)]);
}
