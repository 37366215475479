import { connect } from 'react-redux';
import UserInfo from '../../components/userInfo/UserInfo';
import { AppState } from '../../core/reducers/rootReducer';
import { getLoginFailure } from '../../core/actionCreators/userInfoActionCreators';
import { Dispatch } from 'redux';

const mapStateToProps = (state: AppState) => {
  return {
    message: state.login.userData,
    isLoginSuccess: state.login.isLoginSuccess,
    isLoginFailed: state.login.isLoginFailed,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): any => ({
  onLogout: () => {
    dispatch(getLoginFailure(""));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
