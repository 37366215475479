/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as actions from '../../actionTypes/admin/inviteUserActionType';
import InviteUserData from '../../models/admin/inviteUserData';

/**
 * Initial user data
 */
const initialUser: InviteUserData = {
    emailAddress: '',
    accountId: '',
    sid: '',
    language: '',
    roleIds: [],
    status: false,
    message: '',
    isaccountadmin: false,
};

/**
 * Interface for admin list data
 */
export interface InviteUserState {
    user: InviteUserData;
    inviteNewUserErrorMsg: string;
    inviteNewUserSpinner: boolean;
    inviteExistingUserErrorMsg: string;
    inviteExistingUserSpinner: boolean;
    checkUserStatusErrorMsg: string;
    checkUserStatusSpinner: boolean;
    checkAdminUserStatusErrorMsg: string;
    checkAdminUserStatusSpinner: boolean;
}

/**
 * Initial admin site list state
 */
const initialState: InviteUserState = {
    user: initialUser,
    inviteNewUserErrorMsg: '',
    inviteNewUserSpinner: false,
    inviteExistingUserErrorMsg: '',
    inviteExistingUserSpinner: false,
    checkUserStatusErrorMsg: '',
    checkUserStatusSpinner: false,
    checkAdminUserStatusErrorMsg: '',
    checkAdminUserStatusSpinner: false,
};

const inviteUserReducer = (state = initialState, action: actions.ACTIONS) => {
    switch (action.type) {
        case actions.INVITE_USER:
            return {
                ...state,
                userData: action.userData,
                inviteNewUserErrorMsg: '',
                inviteNewUserSpinner: true,
            };
        case actions.INVITE_USER_FAIL:
            return {
                ...state,
                userData: action.userData,
                inviteNewUserErrorMsg: action.inviteNewUserErrorMsg,
                inviteNewUserSpinner: false,
            };
        case actions.INVITE_USER_SUCCESS:
            return {
                ...state,
                userData: action.userData,
                inviteNewUserErrorMsg: action.inviteNewUserErrorMsg,
                inviteNewUserSpinner: false,
            };
        case actions.INVITE_EXISTING_USER:
            return {
                ...state,
                userData: action.userData,
                inviteExistingUserErrorMsg: '',
                inviteExistingUserSpinner: true,
            };
        case actions.INVITE_EXISTING_USER_FAIL:
            return {
                ...state,
                userData: action.userData,
                inviteExistingUserErrorMsg: action.inviteExistingUserErrorMsg,
                inviteExistingUserSpinner: false,
            };
        case actions.INVITE_EXISTING_USER_SUCCESS:
            return {
                ...state,
                userData: action.userData,
                inviteExistingUserErrorMsg: action.inviteExistingUserErrorMsg,
                inviteExistingUserSpinner: false,
            };
        case actions.INVITE_USER_STATUS:
            return {
                ...state,
                userData: action.user,
                checkUserStatusErrorMsg: '',
            };
        case actions.INVITE_USER_STATUS_FAIL:
            return {
                ...state,
                user: action.user,
                checkUserStatusErrorMsg: action.checkUserStatusErrorMsg,
                checkUserStatusSpinner: false,
            };
        case actions.INVITE_USER_STATUS_SUCCESS:
            return {
                ...state,
                checkUserStatusErrorMsg: action.checkUserStatusErrorMsg,
                checkUserStatusSpinner: false,
            };
        case actions.SAVE_INVITE_USER_RESPONSE:
            return {
                ...state,
                userData: action.userData,
            };
        case actions.CLEAR_INVITE_USER_STATUS_MSG: {
            return {
                ...state,
                checkUserStatusErrorMsg: '',
            };
        }
        case actions.CLEAR_INVITE_USER_MSG: {
            return {
                ...state,
                inviteNewUserErrorMsg: '',
            };
        }
        case actions.CLEAR_INVITE_EXISTING_USER_MSG: {
            return {
                ...state,
                inviteExistingUserErrorMsg: '',
            };
        }
        case actions.INVITE_ADMIN_USER_STATUS:
            return {
                ...state,
                userData: action.user,
                checkAdminUserStatusErrorMsg: '',
            };
        case actions.INVITE_ADMIN_USER_STATUS_FAIL:
            return {
                ...state,
                user: action.user,
                checkAdminUserStatusSpinner: false,
                checkAdminUserStatusErrorMsg: action.checkAdminUserStatusErrorMsg,
            };
        case actions.INVITE_ADMIN_USER_STATUS_SUCCESS:
            return {
                ...state,
                checkAdminUserStatusSpinner: false,
                checkAdminUserStatusErrorMsg: action.checkAdminUserStatusErrorMsg,
            };
        case actions.CLEAR_INVITE_ADMIN_USER_STATUS_MSG: {
            return {
                ...state,
                checkAdminUserStatusErrorMsg: '',
            };
        }
        default:
            return state;
    }
};

export default inviteUserReducer;
