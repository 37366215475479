/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as actions from '../../actionTypes/admin/getSiteListActionTypes';


/**
 * Interface for admin list data
 */
export interface AccountDataState {
  siteId: string;
  accountID: string;
}

/**
 * Initial admin site dummy data
 */
const initialState: AccountDataState = {
  siteId: "",
  accountID: "",
}

/**
 * This is the reducer to set the site admin list users
 * @param state - App state
 * @param action - action :SET_SITE_LIST
 */
const accountDataReducer = (state = initialState, action: actions.ACTIONS): AccountDataState => {
  switch (action.type) {
    case actions.SET_SITE_ID:
      return {
        ...state,
        siteId: action.siteId
      };

    case actions.SET_ACCOUNT_ID:
      return {
        ...state,
        accountID: action.accountID
      };

    default:
      return state;
  }
};

export default accountDataReducer;
