/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { env } from '../../../environments/environment';
/* istanbul ignore next */
const fetchAccAssocaitesList = async (accountId: any ) => {
  // @todo - need to add new end point once it is available
  const url = `${env.getSiteUsersListEndPoint}/${accountId}/associations`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/protobuf' },
  });
  return response;
};
export { fetchAccAssocaitesList };

