/* eslint-disable no-console */
/* eslint-disable no-useless-catch */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* istanbul ignore file */
const Schema = require('../../protofile/convivo_pb');

const decodeAccountDetails = async (data: ArrayBuffer): Promise<any> => {
  try {
    const byteArray: Uint8Array = new Uint8Array(data);
    const message = Schema.AccountDetails.deserializeBinary(byteArray);
    
    const objectData = message.toObject();
    return {
      id: message.getId(),
      name: message.getName(),
      sapId: message.getSapid(),
      contactDetailsAddressline1: message.getContactdetails() ? message.getContactdetails().getAddressline1() : '--',
      contactDetailsAddressline2: message.getContactdetails() ? message.getContactdetails().getAddressline2() : '--',
      contactDetailsCity: message.getContactdetails() ? message.getContactdetails().getCity() : '--',
      contactDetailsCountry: message.getContactdetails() ? message.getContactdetails().getCountry() : '--',
      contactDetailsPostal: message.getContactdetails() ? message.getContactdetails().getPostalcode() : '--',
      contactDetailsState: message.getContactdetails() ? message.getContactdetails().getState() : '--',
      createdBy: message.getCreatedby(),
      createdAt: message.getCreatedat(),
      identificationType: message.getIdentificationtype(),
      accAdminFName: objectData.admininfoList.length > 0 ? objectData.admininfoList[0].firstname : '',
      accAdminLName: objectData.admininfoList.length > 0 ? objectData.admininfoList[0].lastname : '',
      admininfoList: objectData.admininfoList,
    };
  } catch (error) {
    throw error;
  }
};

export { decodeAccountDetails };

