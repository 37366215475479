import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import DashboardCaseHistory from '../../components/dashboard/casehistory';
import { clearHistoryData, getActiveCases, getCaseFail, getCaseInfo, getHistoryCaseList, setCompleteCaseAction, setMenuDataList } from '../../core/actionCreators/caseActionCreator';
import { caseFileUpload } from '../../core/actionCreators/caseFileUploadCreator';
import { getCaseFilterResponse } from '../../core/actionCreators/caseFilterActionCreator';
import { setContactFormCaseIdAction } from '../../core/actionCreators/contactFormActionCreators';
import { downloadReport } from '../../core/actionCreators/downloadReportActionCreator';
import { clearReportMessage, createReportRequest, exportDICOMRequest, sendReportConfirmStatus, showReportPreview } from '../../core/actionCreators/ExportCreators';
import { fetchData } from '../../core/actionCreators/fetchActionCreator';
import { getGalleryListData, GetGallerySpotList } from '../../core/actionCreators/galleryActionCreator';
import { getSearchResponse } from '../../core/actionCreators/searchHistoryActionCreator';
import '../../core/actionCreators/updateCaseHistoryActionCreator';
import { postUpdateCaseHistory } from '../../core/actionCreators/updateCaseHistoryActionCreator';
import Case from '../../core/models/Case';
import caseFilterData from '../../core/models/caseFilterData';
import ExportModelData from '../../core/models/ExportModelData';
import ReportStatusModel from "../../core/models/ReportStatusModel";
import UpdateCaseHistoryModel from '../../core/models/updateCaseModel';
import { AppState } from '../../core/reducers/rootReducer';

const mapStateToProps = (state: AppState) => {
    return {
        language: state.language.language,
        caseInfo: state.caseInfo,
        casedata: state.caseActivity.casedata,
        historydata: state.caseActivity.caseHistoryData,
        userData: state.login.userData,
        activeCaseTotalRec: state.caseActivity.activeCaseTotalRec,
        historyCaseTotalRec: state.caseActivity.historyCaseTotalRec,
        currentpage: state.caseActivity.currentpage,
        currentCase: state.caseSwitch.currentCase,
        galleryList: state.galleryList.galleryData,
        externalList: state.galleryList.externalFiles,
        historyCaseSppiner: state.caseActivity.historyCaseSppiner,
        updatedCaseHistory: state.caseActivity.updatedCaseHistory,
        searchData: state.searchData,
        siteId: state.accountData.siteId,
        dicomData: state.ExportDataReducer.dicomData,
        dicomGenerationMsg: state.ExportDataReducer.dicomGenerationMsg,
        dicomGenerationSpinner: state.ExportDataReducer.dicomGenerationSpinner,
        report: state.ExportDataReducer.report,
        isReportStatusSend: state.ExportDataReducer.isReportStatusSend,
        reportGenerationMsg: state.ExportDataReducer.reportGenerationMsg,
        reportGenerationSpinner: state.ExportDataReducer.reportGenerationSpinner,
        reportConfirmationSpinner: state.ExportDataReducer.reportConfirmationSpinner,
        reportConfirmationMsg: state.ExportDataReducer.reportConfirmationMsg,
        reportUrl: state.ExportDataReducer.reportUrl,
        reportData: state.ExportDataReducer,
        isFetchData: state.isFetchData,
        continuationToken: state.caseActivity.continuationToken,
        hasSpinner: state.galleryList.isSpinner,
        spotsList: state.galleryList.spotsList,
        galleryMasterData: state.galleryList.galleryMasterData,
        caseData: state.UpdateCaseReducer.caseData,
        fetchCaseMessage: state.UpdateCaseReducer.fetchCaseMessage,
        fetchCaseSpinner: state.UpdateCaseReducer.fetchCaseSpinner,
        isSpotSpinner: state.galleryList.isSpotSpinner,
        isGallerySpinner: state.galleryList.isGallerySpinner,
        updateCaseSpinner: state.updateHistoryReducer.updateCaseSpinner,
        caseUpdated: state.updateHistoryReducer.caseUpdated,
        updatecaseMessage: state.updateHistoryReducer.updatecaseMessage,
        role: state.login.userData.role,
        isPreviewEnable: state.updateHistoryReducer.isPreviewEnable,
    };
};

const mapDispachToProps = (dispatch: Dispatch) => {
    return {
        activeCasesList: (pageNumber: number) => dispatch(getActiveCases(pageNumber)),
        getHistoryCaseList: (pageCount: number, isReportHistory?: boolean, continuationToken?: any) => dispatch(getHistoryCaseList(pageCount, isReportHistory, continuationToken)),
        getGalleryListData: (accountID: any, caseId: string, devicesiteId: string, IsEdited?: boolean, ImageType?: string, IsShortlistedForReport?: boolean, QualityProbabilityFrom?: string, QualityProbabilityTo?: string, isExternal?: boolean, SpotLabel?: string) =>
            dispatch(getGalleryListData(caseId, devicesiteId, IsEdited, ImageType, IsShortlistedForReport, QualityProbabilityFrom, QualityProbabilityTo, isExternal, accountID, SpotLabel)),
        getGallerySpotList: (accountID: any, caseId: string, devicesiteId: string, IsEdited?: boolean, ImageType?: string, IsShortlistedForReport?: boolean, QualityProbabilityFrom?: string, QualityProbabilityTo?: string, isExternal?: boolean) =>
            dispatch(GetGallerySpotList(caseId, devicesiteId, IsEdited, ImageType, IsShortlistedForReport, QualityProbabilityFrom, QualityProbabilityTo, isExternal, accountID)),
        getSearchResponse: (searchMessage: string) => dispatch(getSearchResponse(searchMessage)),
        getCaseFilterResponse: (caseFilter: caseFilterData) => dispatch(getCaseFilterResponse(caseFilter)),
        caseFileUpload: (caseId: string, siteId: string, fileName: string, fileData: any, fileExtension: string, accountId: any) => dispatch(caseFileUpload(caseId, siteId, fileName, fileData, fileExtension, accountId)),
        postUpdateCaseHistory: (postMessage: UpdateCaseHistoryModel, accountID?: any, updatecaseMessage?: any) => dispatch(postUpdateCaseHistory(postMessage, accountID, updatecaseMessage)),
        clearHistoryData: () => dispatch(clearHistoryData()),
        exportDICOMRequest: (dicomExportModelData: ExportModelData, requestType: string, reportName: string) => dispatch(exportDICOMRequest(dicomExportModelData, requestType, reportName)),
        createReportRequest: (reportData: ExportModelData) => dispatch(createReportRequest(reportData)),
        showReportPreview: (visibility: boolean) => dispatch(showReportPreview(visibility)),
        sendReportConfirmStatus: (reportStatusModel: ReportStatusModel, reportConfirmationSpinner: boolean) => dispatch(sendReportConfirmStatus(reportStatusModel, reportConfirmationSpinner)),
        clearReportMessage: () => dispatch(clearReportMessage()),
        fetchData: (isFetchData: boolean) => dispatch(fetchData(isFetchData)),
        setCompleteCaseAction: (caseData: Case) => dispatch(setCompleteCaseAction(caseData)),
        getCaseInfo: (accountID: any, caseId: string) => dispatch(getCaseInfo(accountID, caseId)),
        downloadReport: (accountId: any, caseId: string, reportName: string) => dispatch(downloadReport(accountId, caseId, reportName)),
        setContactFormCaseIdAction: (caseId: string, accountId: any) => dispatch(setContactFormCaseIdAction(caseId, accountId)),
        getCaseFail: (fetchCaseMessage?: string, fetchCaseSpinner?: any) => dispatch(getCaseFail(fetchCaseMessage, fetchCaseSpinner)),
        setMenuDataList: (menuData: any) => dispatch(setMenuDataList(menuData))
    };
};
export default connect(mapStateToProps, mapDispachToProps)(DashboardCaseHistory);