/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as actions from '../../actionTypes/admin/getSiteUserListActionTypes';
import SiteUserInfo from '../../models/SiteUserInfo';

/**
 * Interface for admin list data
 */
export interface SiteUsersListState {
  siteUsersList: SiteUserInfo[];
  siteUserHasSpinner: boolean;
}

/**
 * Intial admin list data
 */
const initialData: SiteUserInfo[] = [];

/**
 * Initial admin site list state
 */
const initialState: SiteUsersListState = {
  siteUsersList: initialData,
  siteUserHasSpinner: false,
};

/**
 * This is the reducer to set the site admin list users
 * @param state - App state
 * @param action - action :SET_SITE_USERS_LIST
 */
const siteUsersListReducer = (state = initialState, action: actions.ACTIONS) => {
  switch (action.type) {
    case actions.SET_SITE_USERS_LIST:
      return {
        ...state,
        siteUsersList: [...action.siteUsersList],
        siteUserHasSpinner: false,
      };
    case actions.GET_SITE_USERS_LIST:
      return {
        ...state,
        siteUsersList: [],
        siteUserHasSpinner: true,
      };
    case actions.GET_SITE_USERS_LIST_FAIL:
      return {
        ...state,
        siteUsersList: [],
        siteUserHasSpinner: false,
      };

    default:
      return state;
  }
};

export default siteUsersListReducer;
