/* eslint-disable no-plusplus */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* istanbul ignore file */
const Schema = require('../../protofile/convivo_pb');

const exportDicomDataDeserialize = async (data: ArrayBuffer): Promise<any[]> => {
    try {
        let messageData: any;
        const byteArray: Uint8Array = new Uint8Array(data);
        const message = Schema.DicomResponse.deserializeBinary(byteArray);
        messageData = message.toObject();
        return messageData.urlList;
    } catch (error) {
        throw error;
    }
};

export { exportDicomDataDeserialize };

