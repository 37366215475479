/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-empty */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prefer-template */
import { env } from '../../../environments/environment';
/* istanbul ignore next */
/**
 * This api will feth the image information by image ID
 */
const getReportList = async (caseId: string, accountId: string) => {
  // const currentDate = moment().format('YYYY-MM-DD');
  // const currentTime = moment().format('HH:mm:ss');
  // let timeStamp = currentDate + " " + currentTime
  const url = env.annotationEndpoint + accountId + '/cases/' + caseId + '/report/download';
  console.log('--download report --' + url);
  const response = await fetch(url, {
    method: 'GET',
    mode: 'no-cors',
    headers: { 'Content-Type': 'application/protobuf' },
  });
  console.log('----Response API-------', response);
  return response;
};

export { getReportList };

