/* eslint-disable */
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icons } from '../../../constants/viewControl';

export interface Props {
  msgType: number;
  imgQuality: string;
  infoMissing: boolean;
  showLatencyWarning: boolean;
}

export interface State {
  resolutionMsg: boolean;
  patientMsg: boolean;
  latencyMsg: boolean;
  caseEnd: boolean;
}

export class WarningMsg extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      resolutionMsg: true,
      patientMsg: false,
      latencyMsg: true,
      caseEnd: false,
    };
  }

  handleResolutionClose = () => {
    this.setState({ resolutionMsg: false });
  };

  handlePatientClose = () => {
    this.setState({ patientMsg: false });
  };

  handleLatencyClose = () => {
    this.setState({ latencyMsg: false });
  };
  handleCaseEndClose = () => {
    this.setState({ caseEnd: false });
  }

  render() {
    const { imgQuality, infoMissing, msgType, showLatencyWarning } = this.props;
    return (
      <div className="warning-msg">
        {/* {(this.state.resolutionMsg && imgQuality === "Low") ? (
          <p className="resolution-msg">
            <FormattedMessage id="WarningMsg" /> <img onClick={this.handleResolutionClose} src={Icons.closeIcon} />
          </p>
        ) : (
          ''
        )}
        {(this.state.patientMsg && infoMissing) ? (
          <p className="patient-msg">
            <FormattedMessage id="WarningMsgPatient" /> <img onClick={this.handlePatientClose} src={Icons.closeIcon} />
          </p>
        ) : (
          ''
        )} */}
        {(this.state.latencyMsg && showLatencyWarning) ? (
          <p className="latency-msg">
            <FormattedMessage id="LatencyWarningMsg" /> <img onClick={this.handleLatencyClose} src={Icons.closeIcon} />
          </p>
        ) : (
            ''
          )}
        {/* {(this.state.caseEnd  &&msgType== 9)? (
          <p className="latency-msg">
           Consulting session is now completed and no new images will be streamed <img onClick={this.handleCaseEndClose} src={Icons.closeIcon} />
          </p>
        ) : (
            ''
          )} */}
      </div>
    );
  }
}

export default WarningMsg;
