/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import { Popover } from 'react-bootstrap';
import { ViewerControl } from '../../../constants/viewControl';
import CaseInfoData from '../../../core/models/CaseInfoData';
import { PatientInfo } from './tooltipPatient';
import { SurgeonInfo } from './tooltipSurgeon';
// import Case from "../../../core/models/Case";


interface Props {
  msgType: any;
  currentCase: CaseInfoData;
  caseData?: any;
  getCaseInfo?: (accountID: any, caseId: string) => void;
}

export class PopoverInfo extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  handleInfoClick = () => {
    const { currentCase, getCaseInfo } = this.props;
    getCaseInfo && getCaseInfo(currentCase.accountID, currentCase.caseId);
    const cancel = document.getElementsByClassName('info-link-btn')[0] as HTMLElement;
    cancel.click();
  };

  render() {
    const { msgType, currentCase, caseData } = this.props;
    let caseDetails;
    if(msgType== 9){
      caseDetails=null;
    }else
    caseDetails=caseData;
    return (
      <Popover id="popover-info">
        <Popover.Content>
          <div className="main-block">
            <span className="close-info-btn" onClick={this.handleInfoClick}>
              <ViewerControl icon="info-close-btn" />
            </span>
            <div className="patient-info">
              <PatientInfo currentCase={currentCase} caseData={caseDetails} />
            </div>
            <div className="surgeon-info">
              <SurgeonInfo msgType={msgType} currentCase={currentCase} caseData={caseDetails} />
            </div>
          </div>
        </Popover.Content>
      </Popover>
    );
  }
}

export default PopoverInfo;
