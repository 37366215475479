/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as actions from '../../actionTypes/admin/getSiteListActionTypes';
import SiteInfo from '../../models/SiteInfo';

/**
 * Interface for admin list data
 */
export interface SiteListState {
  siteList: SiteInfo[];
  hasSpinner: boolean;
}

const initialData: SiteInfo[] = [];

/**
 * Initial admin site list state
 */
const initialState: SiteListState = {
  siteList: initialData,
  hasSpinner: false,
};

/**
 * This is the reducer to set the site admin list users
 * @param state - App state
 * @param action - action :SET_SITE_LIST
 */
const siteListReducer = (state = initialState, action: actions.ACTIONS) => {
  switch (action.type) {
    case actions.SET_SITE_LIST:
      return {
        ...state,
        siteList: [...action.siteList],
        hasSpinner: false,
      };
    case actions.GET_SITE_LIST:
      return {
        ...state,
        siteList: [],
        hasSpinner: true,
      };
      case actions.GET_SITE_LIST_FAIL:
        return {
          ...state,
          siteList: [],
          hasSpinner: false,
        };
    default:
      return state;
  }
};

export default siteListReducer;
