/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import * as actionCreators from '../actionCreators/admin/getSiteUsersActionCreator';
import * as actionTypes from '../actionTypes/admin/getSiteUserListActionTypes';
import SiteUserInfo from '../models/SiteUserInfo';
import { decodeSiteUserseBuffer } from '../protobuf/deserialization/siteUsersListDeserialize';
import { fetchSiteUsersList } from '../services/GET/getSiteUsersListService';

/* istanbul ignore next */
function* siteUsersListAsync(action: any) {
  try {
    const response: Response = yield fetchSiteUsersList(action.accountID, action.siteId);
    const apiStatus: number = response.status;
    switch (apiStatus) {
      case 200:
        const data: ArrayBuffer = yield response.arrayBuffer();
        const decodedData: SiteUserInfo[] = yield decodeSiteUserseBuffer(data);
        yield put(actionCreators.setUsersList(decodedData));
        break;
      case 401:
      case 500:
      case 403:
        yield put(actionCreators.getUsersListFail('SiteUserListFailMessage'));
        break;
      default:
        yield put(actionCreators.getUsersListFail('SiteUserListFailMessage'));
        break;
    }
  } catch (error) {
    console.log('site user  Api Failed ', error);
    yield put(actionCreators.getUsersListFail('SiteUserListFailMessage'));
  }
}

export function* watchPageEntries() {
  yield takeEvery(actionTypes.GET_SITE_USERS_LIST, siteUsersListAsync);
}

export default function* GetSiteUsersSaga() {
  yield all([fork(watchPageEntries)]);
}
