// eslint-disable-next-line import/no-cycle

export const FETCH_DATA = 'caseActionType/FETCH_DATA';


export interface fetchData {
  type: typeof FETCH_DATA;
  isFetchData:boolean;
}

export type ACTIONS =
fetchData
