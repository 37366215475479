/* eslint-disable react/prefer-stateless-function */
/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ViewerControl } from '../../../constants/viewControl';
import { AnnotateSettings } from '../annotateSettings';

interface Props {
  addSquare(event: React.MouseEvent<HTMLElement>): void;
  addCircle(event: React.MouseEvent<HTMLElement>): void;
  addLine(event: React.MouseEvent<HTMLElement>): void;
  addText(event: React.MouseEvent<HTMLElement>): void;
  addFreeDraw(event: React.MouseEvent<HTMLElement>): void;
  handleSettingsClck(): void;
  selectedItem: any;
  showLoader:boolean
}

export class ShapeEdit extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  handleSettingsClck = () => {
    this.props.handleSettingsClck();
    const cancelOverlayTrigger = document.getElementsByClassName('shape-icon')[0] as HTMLElement;
    cancelOverlayTrigger.click();
  };

  render() {
    return (
      <Popover id="popover-shape-edit" {...this.props}>
        <Popover.Content>
          <div className='edit-list'
        style={
          this.props.showLoader
            ? {pointerEvents: 'none', opacity:0.4}
            : {pointerEvents: 'all'}
        }
        >
            <ul>
              <li className="shape-click" id="pen" onClick={this.props.addFreeDraw}>
                <ViewerControl icon="annotate-pen-icon" /> <FormattedMessage id="pen" />
              </li>
              <li className="shape-click" onClick={this.props.addLine}>
                <ViewerControl icon="annotate-line-icon" /> <FormattedMessage id="line" />
              </li>
              <li className="shape-click" onClick={this.props.addCircle}>
                <ViewerControl icon="annotate-circle-icon" /> <FormattedMessage id="circle" />
              </li>
              <li className="shape-click" onClick={this.props.addSquare}>
                <ViewerControl icon="annotate-square-icon" /> <FormattedMessage id="square" />
              </li>
              {/* <li className="shape-click" onClick={this.props.addArrow}><ViewerControl icon="annotate-arrow-icon" /> Arrow</li> */}
              <li className="shape-click" onClick={this.props.addText}>
                <ViewerControl icon="annotate-text-icon" /> <FormattedMessage id="text" />
              </li>
              <li className="settings" onClick={this.handleSettingsClck}>
                <ViewerControl icon="annotate-settings-icon" /> <FormattedMessage id="settings" />
              </li>
            </ul>
          </div>
        </Popover.Content>
      </Popover>
    );
  }
}

export default ShapeEdit;
