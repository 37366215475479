import { useLocation } from 'react-router-dom';
/**
 * This is location hook and this will help to enable the 'useLocation()' hook in class component
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useLocationHook(): any {
  const location = useLocation();
  // Dummy data
  const dummyData = {
    firstName: '',
    lastName: '',
    patientId: '',
    genderValue: '',
    dob: '',
    surgeonFirstName: '',
    surgeonLastName: '',
    surgeonEmail: '',
  };
  const data = { dummy: { dummyData }, locationData: location.state };
  return data;
}
