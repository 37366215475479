/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { env } from '../../../environments/environment';
/* istanbul ignore next */
const fetchSiteList = async (accountId: any) => {
  const url = env.getSiteUsersListEndPoint + accountId + "/sites";
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/protobuf' },
  });
  return response;
};
export { fetchSiteList };
